import styled from "styled-components";

const circlePadding = '0.5rem';

export const Top = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Title = styled.h3`

`;

export const H6 = styled.h6`
    color: var(--main-purple);
`;

export const CollapseButton = styled.button`
    display: flex;
    gap: 4px;
    align-items: center;
    color: var(--main-purple);
    font-size: 12px;
    font-weight: 600;
`;


export const PathParent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  position: relative;
  padding-left: ${circlePadding};
`;

export const Path = styled.div`
  position: absolute;
  left: 0;

  width: 1px;
  height: 100%;

  background-color: var(--main-black);
`;

export const Circle = styled.div`
  position: absolute;

  left: calc(-${circlePadding} + 0.5px);
  top: 0.5rem;
  transform: translate(-50%, 0);

  width: 0.5rem;
  height: 0.5rem;

  border-radius: 50%;
  background-color: var(--main-black);
`;
