import styled from "styled-components";

export const Competence = styled.div`
    display: flex;
    flex-direction: column;
    border-radius: 16px;
    // box-shadow: inset 0 0 0 1px #E6E6E6;
    background-color: #FFFFFF;
`;

export const Top = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    font-size: 16px;
    cursor: pointer;
`;

export const TopBody = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
`;

export const ArrowContainer = styled.div`
    transition: .3s;
`;

export const CompetenceName = styled.div`

`;

export const CompetenceLevel = styled.div`
    font-size: 12px;
`;

export const Line = styled.div`
    margin: 0 0 16px 0;
    height: 1px;
    width: 100%;
    background-color: #E6E6E6;
    transition: .3s;
`;

export const Indicators = styled.div`
    padding: 0 16px 16px 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    transition: .3s;
`;

export const Indicator = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 5px;
`;

export const IndicatorDot = styled.div`
    width: 7px;
    height: 7px;
    border-radius: 10px;
    background-color: #D9DFF4;
`;

export const IndicatorHeader = styled.div`
    font-size: 13px;
    font-weight: 600;
`;

export const IndicatorLevel = styled.div`
    font-size: 12px;
    color: gray;
`;

export const IndicatorTop = styled.div`
    display: flex;
    justify-content: space-between;
`;