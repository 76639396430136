import axios from 'axios';
import type { IUser } from '@/shared';

const API = process.env.API_URL;
const access = JSON.parse(localStorage.getItem('accessToken'));
const refresh = JSON.parse(localStorage.getItem('refreshToken'));
const userInitial = JSON.parse(localStorage.getItem('user'));

export const editProfile = async (user: IUser) => {
    const updatedData = {
        id: userInitial.id,
        email: user?.email || userInitial.email,
        first_name: user?.first_name || userInitial.first_name,
        last_name: user?.last_name || userInitial.first_name,
        patronymic: user?.patronymic || userInitial.patronymic,
        company: user?.company || userInitial.company,
        gender: user?.gender || '',
        birthday: user?.birthday || userInitial.birthday,
    }

    const res = await axios({
        url: `${API}/users/${userInitial.id}/`,
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json',
            "Access-Control-Allow-Headers": "*",
            "Authorization": `Bearer ${access}`,
        },
        data: updatedData
    })
    
    return res
}

export const setResume = async (resume: any) => {
    const updatedData = {
        resume: resume
    }

    const res = await axios({
        url: `${API}/users/${userInitial.id}/`,
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json',
            "Access-Control-Allow-Headers": "*",
            "Authorization": `Bearer ${access}`,
        },
        data: updatedData
    })
    
    return res
} 

export const addStepikCourses = async (clientId: string, clientSecret: string) => {
    const res = await axios({
        url: `${API}/stepik_clients/`,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            "Access-Control-Allow-Headers": "*",
            "Authorization": `Bearer ${access}`,
        },
        data: {
            client_id: clientId,
            client_secret: clientSecret
        }
    })

    return res
}

export const getInterests = async (id: number) => {
    const res = await axios<any[]>({
        url: `${API}/users/${id}/`,
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            "Access-Control-Allow-Headers": "*",
            "Authorization": `Bearer ${access}`,
        },
    })
    
    return res
} 

export const searchIntereses = async (term: string) => {
    const res = await axios({
        url: `${API}/interests/?search=${term}&distinct=true`,
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            "Access-Control-Allow-Headers": "*",
            "Authorization": `Bearer ${access}`,
        },
    })
    
    return res
}

export const editInterests = async (interests: any, id: number) => {
    const interestsIds: any[] = [];
    interests.forEach((skill: any) => {
        interestsIds.push(skill.id);
    });

    const res = await axios({
        url: `${API}/users/${id}/`,
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json',
            "Access-Control-Allow-Headers": "*",
            "Authorization": `Bearer ${access}`,
        },
        data: {
            interests: interests
        },
    })
    
    return res
} 

export const uploadAvatar = async (file: any) => {
    const form = new FormData();

    form.append('image', file, file?.name);

    const res = await axios({
        url: `${API}/users/upload-image/`,
        method: 'POST',
        headers: {
            "Access-Control-Allow-Headers": "*",
            "Authorization": `Bearer ${access}`,
            "Content-Type": "multipart/form-data; boundary=something",
        },
        data: form
    })

    return res
}

export const uploadResume = async (file: any) => {
    const form = new FormData();

    form.append('file', file, file?.name);

    const res = await axios({
        url: `${API}/users/upload-file/`,
        method: 'POST',
        headers: {
            "Access-Control-Allow-Headers": "*",
            "Authorization": `Bearer ${access}`,
            "Content-Type": "multipart/form-data; boundary=something",
        },
        data: form
    })

    return res
}

export const deleteResume = async () => {
    const res = await axios({
        url: `${API}/users/upload-file/`,
        method: 'DELETE',
        headers: {
            "Access-Control-Allow-Headers": "*",
            "Authorization": `Bearer ${access}`,
        },
    })

    return res
}
