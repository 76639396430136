import React, {  useEffect } from 'react';
import { FrappeGantt, Task } from "frappe-gantt-react";
import Gantt from 'frappe-gantt';

export interface DiagramGanttProps {
  tasks: Task[];
}

export const DiagramGantt: React.FC<DiagramGanttProps> = ({ tasks }) => {
  useEffect(() => {
    const gantt = new Gantt("#gantt", tasks, {
      header_height: 88,
      column_width: 45,
      step: 20,
      view_modes: ['Quarter Day', 'Half Day', 'Day', 'Week', 'Month'],
      bar_height: 57,
      bar_corner_radius: 8,
      arrow_curve: 5,
      padding: 20,
      view_mode: 'Day',
      date_format: 'YYYY-MM-DD',
      language: 'ru',
      // custom_popup_html: popup,
     });
    gantt.change_view_mode('Day')
  }, [tasks])

  useEffect(() => {
    handleScroll();
  }, [tasks])

  function handleScroll() {
    document.getElementsByClassName('today-highlight')[0].scrollIntoView();
  }

  return (
    <div
      className='diagram'
      style={{ 
        width: 'calc(100% - 210px)',
        position: 'relative',
      }}
      id="gantt"
    >
    </div>
  )
}
