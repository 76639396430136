import axios from 'axios';

const API = process.env.API_URL;
const access = JSON.parse(localStorage.getItem('accessToken'));
const refresh = JSON.parse(localStorage.getItem('refreshToken'));


export const getUnreadNotifications = async () => {
  const res = await axios<any[]>({
      url: `${API}/notifications/unread_list/`,
      method: 'GET',
      headers: {
          'Content-Type': 'application/json',
          "Access-Control-Allow-Headers": "*",
          "Authorization": `Bearer ${access}`,
      },
  })
  
  return res
}

export const setNotificationAsRead = async (notificationId: number) => {
  const res = await axios<any[]>({
      url: `${API}/notifications/${notificationId}/mark_as_read/`,
      method: 'GET',
      headers: {
          'Content-Type': 'application/json',
          "Access-Control-Allow-Headers": "*",
          "Authorization": `Bearer ${access}`,
      },
  })
  
  return res
}
