import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  padding: 1rem;
`;

export const OtherOption = styled.div`
  display: flex;
  padding: 0.5rem 0;
  gap: 1rem;
  justify-content: space-between;
`;
