import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, FormControl, FormLabel, Input, FormErrorMessage, ModalFooter, Button } from '@chakra-ui/react';
import { register } from 'module';
import React, { useState } from 'react';
import { setResume, uploadAvatar } from '../../api';
import { uploadResume } from '../../api';
import { on } from 'events';

export interface FileUploadPopupProps {
  isOpen: boolean;
  onClose: () => void;
  type: 'avatar' | 'resume';
}

export const FileUploadPopup: React.FC<FileUploadPopupProps> = ({ isOpen, onClose, type }) => {
  const [fileBin, setFileBin] = useState<any>('');
  const [files, setFiles] = useState([]);
  const [isAwait, setIsAwait] = useState<boolean>(false);
  const user = JSON.parse(localStorage.getItem('user'));

  function fileUploadHandler(event: any) {
    const files = event.target.files

    setFiles([...event.target.files]);

    let reader = new FileReader();

    reader.readAsText(files[0]);

    reader.onload = function() {
      setFileBin(reader.result);
      console.log(fileBin);
    };
  
    reader.onerror = function() {
      console.log(reader.error);
    };
  }

  function handleSubmit(e: any) {
    e.preventDefault();

    setIsAwait(true);

    if (type === 'avatar') {
      uploadAvatar(files[0])
        .then((res) => {
          location.reload();
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          setIsAwait(false);
        })
    }
    if (type === 'resume') {
      uploadResume(files[0])
        .then((res) => {
          location.reload();
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          setIsAwait(false);
        })
    }
  }

  return (
    <Modal size={'xl'} blockScrollOnMount={false} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <form onSubmit={handleSubmit}>
          <ModalContent borderRadius={16}>
            <ModalHeader alignItems={'center'}>
              <h3 style={{ textAlign: 'center' }}>Загрузите Файл</h3>
            </ModalHeader>
            <ModalCloseButton top={'14px'} />
            <ModalBody
              padding='20px'
              display='flex'
              flexDirection='column'
              gap='30px'
              borderTop='1px solid var(--addable-gray)'
              borderBottom='1px solid var(--addable-gray)'
            >
              <FormControl>
                <Input
                  multiple={false}
                  id='fileInput'
                  placeholder='https://резюме.pdf'
                  type='file'
                  size='sm'
                  variant='flushed'
                  onChange={(event) => fileUploadHandler(event)}
                  accept={type === 'resume' ? ".doc,.docx,.pdf" : ".png,.jpg,.jpeg"}
                />
                <FormErrorMessage></FormErrorMessage>
              </FormControl>
            </ModalBody>

            <ModalFooter gap='8px' display='flex'>
              <Button
                type='button'
                width='100%'
                onClick={onClose}
                variant='outline'
                color='var(--main-purple)'
                borderRadius='8px'
              >
                Отмена
              </Button>
              <Button
                isLoading={isAwait}
                type='submit'
                width='100%'
                borderRadius='8px'
                bg='var(--main-purple)'
                _hover={{ bg: 'var(--hover-purple)' }}
                color='#fff'
              >
                Сохранить изменения
              </Button>
            </ModalFooter>
          </ModalContent>
      </form>
    </Modal>
  )
}
