import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  padding: 1rem;
`;

export const Grouping = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

export const Disabled = styled.span`
  color: var(--disabled);
`;

export const RadioStats = styled.div`
  width: 28px;
  aspect-ratio: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #466DF2;
  border-radius: 5px;
  color: white;
`;
