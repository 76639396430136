import React, { useState, useEffect } from 'react';
import { Container, Inputs } from './styles';
import {
  InputGroup,
  InputLeftElement,
  Input,
  Button,
  useDisclosure
} from '@chakra-ui/react';
import AddIcon from '@/assets/icons/add.svg';
import SearchIcon from '@/assets/icons/search.svg';
import { IVacanci, useDebounce } from '@/shared';
import { searchVacanci, searchCompanyVacanci } from '@/features/vacanci';
import { observer } from 'mobx-react-lite';
import vacanciesState from '@/entities/vacanci/store/vacanciesState';
import { CreateCompetencePopup } from '@/features/competences';
import { CrossAdd } from '@/UI';

export const CompetencesTopBar: React.FC = observer(() => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [searchTerm, setSearchTerm] = useState('');

  return (
    <>
      <Container>
        <Inputs>
          <InputGroup padding={'6px'}>
            {/* <InputLeftElement pointerEvents='none'>
              <SearchIcon
                color='var(--addable-gray)'
                width={18}
                height={18}
              />
            </InputLeftElement> */}
            {/* <Input
              value={searchTerm}
              onChange={e => setSearchTerm(e.target.value)}
              color='messenger'
              height={'28px'}
              variant='flushed'
              placeholder='Поиск вакансии по названию или навыкам'
            /> */}
          </InputGroup>
          <Button
            borderRadius='8px'
            bg='var(--main-purple)'
            _hover={{ bg: '#2D53DA' }}
            // leftIcon={<AddIcon color='#fff' />}
            onClick={onOpen}
            padding='15px 10px'
            // leftIcon={<AddIcon color='#fff' />} 

          >
            <CrossAdd
              color='#FFFFFF'
            />
            {/* <h6 style={{ color: '#fff' }}>Добавить компетенцию</h6> */}
          </Button>
        </Inputs>
      </Container>
      <CreateCompetencePopup isOpen={isOpen} onClose={onClose} />
    </>
  )
});
