import React, { useState, useEffect, useRef } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  Select,
} from '@chakra-ui/react';

import {
  IndicatorTable,
  IndicatorTableHeader,
  Empty,
  IndicatorHeaderGradation,
  IndicatorTableRow,
  IndicatorMain,
  IndicatorEdit,
  IndicatorName,
  IndicatorGradation,
  IndicatorNameInput,
  IndicatorTestInput,
  IndicatorGradationValueInput,
  ErrorBlock,
} from './styles'

import { SubmitHandler, useForm } from 'react-hook-form';
import { ICompetence } from '@/shared';
import { createCompetence } from '@/features/competences';
import competencesState from '@/entities/competence/store/competencesState';
import { CrossAdd, CrossDelete } from '@/UI';
import { Gradation } from '@/widgets/LevelGradation/styles';

interface IFormFileds {
  id?: number;
  name: string;
  companies: any;
  indicators: any[];
  test: string;
  gradations: any[];
  grades: any[];
}

interface ICreateCompetencePopupProps {
  isOpen: boolean;
  onClose: () => void;
}

export const CreateCompetencePopup: React.FC<ICreateCompetencePopupProps> = ({ isOpen, onClose }) => {
  const { 
    register,
    handleSubmit,
    formState: { errors, isSubmitting } 
  } = useForm<IFormFileds>();

  const levels: any = {
    'Слабый': 'Beginner',
    'Уверенный': 'Intermediate',
    'Сильный': 'Master',
  }
  const reverseLevels: any = {
    'Beginner': 'Слабый',
    'Intermediate': 'Уверенный',
    'Master': 'Сильный',
  }

  const messageWaitTime: number = 5000;
  const [isAwaiting, setIsAwaiting] = useState<boolean>(false);

  const company = JSON.parse(localStorage.getItem('company'));

  const [indicators, setIndicators] = useState([]);

  const [errorMessages, setErrorMessages] = useState([]);
  const [isError, setIsError] = useState(true);
  
  const [newIndicator, setNewIndicator] = useState<any>({
    name: '',
    test: '',
    gradations: [33, 66],
    grades: {
      Beginner: '',
      Intermediate: '',
      Master: '',
    },
  });

  const newIndicatorNameRef = useRef(null);
  const newIndicatorTestRef = useRef(null);
  const newIndicatorGradationsRef = useRef(null);
  const newIndicatorGradeBeginnerRef = useRef(null);
  const newIndicatorGradeIntermediateRef = useRef(null);
  const newIndicatorGradeMasterRef = useRef(null);

  const delayIndicatorUpdate = {
    setDelay(func: Function, params: any) {
      this.task = func;
      this.taskParams = params;

      this.timeoutId = setTimeout(() => {
        this.task(this.taskParams);
      }, 2000);
    },

    updateDelay(func: Function, params: any) {
      if (this.timeoutId !== undefined) {
        clearTimeout(this.timeoutId);
      }

      this.setDelay(func, params);
    },

    clearDelay() {
      if (this && this.timeoutId !== undefined) {
        clearTimeout(this.timeoutId);

        if (this.task !== undefined) {
          this.task(this.taskParams);
        }
      }
    },
  }

  function removeIndicator(name: any) {
    const updatedIndicators = indicators.filter((current_indicator: any) => {
      return current_indicator?.name !== name;
    })
    setIndicators(updatedIndicators);
  }

  function updateIndicator(params: any) {
    const name = params.name;
    const value = params.value;
    const category = params.category;
    const competenceGrade = params.competenceGrade;

    let indicatorToUpdate: any;

    for (const indicator of indicators) {
      if (indicator.name === name) {
        indicatorToUpdate = indicator;
        break;
      }
    }

    if (competenceGrade !== '') {
      indicatorToUpdate[category][competenceGrade] = levels[value];
    }
    else {
      if (category === 'gradations') {
        try {
          const splittedValues = value.split(' ');

          for (let i = 0; i < splittedValues.length; i++) {
            const foundNumber = splittedValues[i].match(/\d{1,2}/);
            if (foundNumber !== null) {
              splittedValues[i] = foundNumber[0];
            }
            else {
              throw new TypeError('Incorrect value');
            }
          }
          
          switch (splittedValues.length) {
            case 0:
            case 1:
              indicatorToUpdate[category] = [33, 66];
              break;
            case 2:
              indicatorToUpdate[category] = splittedValues;
              break;
            default:
              indicatorToUpdate[category] = [splittedValues[1], splittedValues[2]];
          }
        }
        catch {
          indicatorToUpdate[category] = [33, 66];
        }
      }
      else {
        indicatorToUpdate[category] = value;
      }
    }

    let updatedIndicators = indicators;

    updatedIndicators = updatedIndicators.map((indicator: any) => {
      if (indicator.key === indicatorToUpdate.key) {
        indicatorToUpdate.key = indicatorToUpdate.name;
        return indicatorToUpdate;
      }
      return indicator;
    })

    setIndicators(updatedIndicators);
  }

  function updateNewIndicator(
    value: any,
    category: string,
    competenceGrade: string = '',
  ) {
    let tempNewIndicator: any = newIndicator;

    if (competenceGrade !== '') {
      tempNewIndicator[category][competenceGrade] = levels[value];
    }
    else {
      if (category === 'gradations') {
        try {
          const splittedValues = value.split(' ');

          for (let i = 0; i < splittedValues.length; i++) {
            const foundNumber = splittedValues[i].match(/\d{1,2}/);
            if (foundNumber !== null) {
              splittedValues[i] = foundNumber[0];
            }
            else {
              throw new TypeError('Incorrect value');
            }
          }

          switch (splittedValues.length) {
            case 0:
            case 1:
              tempNewIndicator[category] = [33, 66];
              break;
            case 2:
              tempNewIndicator[category] = splittedValues;
              break;
            default:
              tempNewIndicator[category] = [splittedValues[1], splittedValues[2]];
          }
        }
        catch {
          tempNewIndicator[category] = [33, 66];
        }
      }
      else {
        tempNewIndicator[category] = value;
      }
    }

    setNewIndicator(tempNewIndicator);
  }

  // Function that validates indicator it recieved,
  // sets error messages occured during validation and
  // adds new indicator if it was validated
  const indicatorValidator = {
    checkValidation(indicator: any, isNew: boolean = false) {
      let tempErrorMessages: any[] = [];
      if (indicator.name === '') {
        setErrorMessages(['']);
        const message = 'Укажите название индикатора';
        if (!tempErrorMessages.includes(message)) {
          tempErrorMessages.push(message);
        }
      }
      for (const grade of Object.values(indicator.grades))
        if (grade === '' || !grade) {
          setErrorMessages(['']);
          const message = 'Заполните уровни владения индикатором';
          if (!tempErrorMessages.includes(message)) {
            tempErrorMessages.push(message);
          }
        }
      let nameMatchCounter: number = 0;
      for (const existingIndicator of indicators) {
        if (indicator.name === existingIndicator.name) {
          if (!isNew) {
            nameMatchCounter++;
          }
          if (nameMatchCounter > 1 || isNew) {
            setErrorMessages(['']);
            const message = 'Индикатор с таким названием уже добавлен';
            if (!tempErrorMessages.includes(message)) {
              tempErrorMessages.push(message);
            }
          }
        }
      }
      setErrorMessages(tempErrorMessages);

      if (tempErrorMessages.length > 0) {
        this.timeoutId = setTimeout(() => {
          setErrorMessages([]);
        }, messageWaitTime);
        setIsError(true);
        return false;
      }

      setIsError(false);

      if (isNew) {
        newIndicator['key'] = newIndicator.name;
        
        setIndicators([...indicators, newIndicator]);
        setNewIndicator({
          key: '',
          name: '',
          test: '',
          gradations: [33, 66],
          grades: {
            Beginner: '',
            Intermediate: '',
            Master: '',
          },
        });

        newIndicatorNameRef.current.value = '';
        newIndicatorTestRef.current.value = '';
        newIndicatorGradationsRef.current.value = '';
        newIndicatorGradeBeginnerRef.current.value = '';
        newIndicatorGradeIntermediateRef.current.value = '';
        newIndicatorGradeMasterRef.current.value = '';
      }

      return true;
    },

    validateIndicator(indicator: any, isNew: boolean = false) {
      if (this.timeoutId !== undefined) {
        clearTimeout(this.timeoutId);
      }
      return this.checkValidation(indicator, isNew);
    }
  }

  const onSubmit: SubmitHandler<IFormFileds> = async (data) => {
    setIsAwaiting(true);

    data.companies = [company.id];
    data.gradations = [33, 66];

    delayIndicatorUpdate.clearDelay();
    indicatorValidator.validateIndicator(newIndicator, true);

    let indicatorsAreValidated: boolean = true;
    let indicatorsEntitiesTracker: any = indicators;
    
    if (newIndicator.name !== '' || newIndicator.test != '') {
      indicatorsAreValidated = indicatorValidator.validateIndicator(newIndicator, true);

      if (indicatorsEntitiesTracker === indicators && indicatorsAreValidated) {
        indicatorsEntitiesTracker.push(newIndicator);
        setNewIndicator({
          key: '',
          name: '',
          test: '',
          gradations: '',
          grades: {
            Beginner: '',
            Intermediate: '',
            Master: '',
          },
        });
      }
    }

    for (const indicator of indicatorsEntitiesTracker) {
      indicatorsAreValidated = indicatorsAreValidated && indicatorValidator.validateIndicator(
        indicator,
      );
    }

    if (indicatorsAreValidated) {
      data.indicators = indicators;

      let grades: any = {
        Beginner: {},
        Intermediate: {},
        Master: {},
      };

      for (const level of Object.values(levels)) {
        const grade = {
          name: String(level),
          indicators_levels: Array(),
        }
        for (const indicator of indicatorsEntitiesTracker) {
          grade.indicators_levels.push(
            {
              level: indicator.grades[String(level)],
              indicator: indicator,
            }
          )
        }
        grades[String(level)] = grade;
      }
      data.grades = Object.values(grades);

      createCompetence(data)
        .then((response) => {
          const competence: ICompetence = response.data;
          competencesState.setCompetences([...competencesState.competences, competence]);
          setIndicators([]);
          onClose();
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          setIsAwaiting(false);
        })
    }
    else {
      setIsAwaiting(false);
    }
  }

  return (
    <Modal size={'6xl'} blockScrollOnMount={false} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <form onSubmit={handleSubmit(onSubmit)}>
          <ModalContent borderRadius={16}>
            <ModalHeader alignItems={'center'}>
              <h3 style={{ textAlign: 'center' }}>Создание компетенции</h3>
            </ModalHeader>
            <ModalCloseButton top={'14px'} />
            <ModalBody
              padding='20px'
              display='flex'
              flexDirection='column'
              gap='30px'
              borderTop='1px solid var(--addable-gray)'
              borderBottom='1px solid var(--addable-gray)'
            >
              <FormControl isInvalid={Boolean(errors.name)}>
                <FormLabel>Название компетенции *</FormLabel>
                <Input
                  id='name'
                  {...register('name', { 
                    required: 'Обязательное поле'
                  })}
                  placeholder='Программирование'
                  type='text'
                  size='sm'
                  variant='flushed'
                />
                <FormErrorMessage>{errors.name && <>{errors.name.message}</>}</FormErrorMessage>
              </FormControl>

              <FormControl>
                <FormLabel>Тест</FormLabel>
                  <Input
                    id='test'
                    {...register('test', { 
                    })}
                    placeholder='Тест по компетенции'
                    type='text'
                    size='sm'
                    variant='flushed'
                  />
                <FormErrorMessage>{errors.test && <>{errors.test.message}</>}</FormErrorMessage>
              </FormControl>

              <FormControl>
              <FormLabel>Индикаторы</FormLabel>
                <IndicatorTable>
                  <IndicatorTableHeader>
                    <Empty/>
                    <IndicatorHeaderGradation>
                      Слабый
                    </IndicatorHeaderGradation>
                    <IndicatorHeaderGradation>
                      Уверенный
                    </IndicatorHeaderGradation>
                    <IndicatorHeaderGradation>
                      Сильный
                    </IndicatorHeaderGradation>
                  </IndicatorTableHeader>
                  {indicators.map((indicator: any) => 
                    <IndicatorTableRow
                      key={indicator.name}
                    >
                      <IndicatorMain>
                        <IndicatorEdit
                          onClick={(e) => removeIndicator(indicator.name)}
                        >
                          <CrossDelete/>
                        </IndicatorEdit>
                        <IndicatorName>
                          <IndicatorNameInput
                            onChange={(e) => {
                              delayIndicatorUpdate.updateDelay(updateIndicator, {
                                name: indicator.name,
                                value: e.target.value,
                                category: 'name',
                                competenceGrade: '',
                              })}
                            }
                            onBlur={() => delayIndicatorUpdate.clearDelay()}
                            placeholder='Название индикатора'
                            defaultValue={indicator.name}
                          />
                          <IndicatorTestInput
                            onChange={(e) => {
                              delayIndicatorUpdate.updateDelay(updateIndicator, {
                                name: indicator.name,
                                value: e.target.value,
                                category: 'test',
                                competenceGrade: '',
                              })}
                            }
                            onBlur={() => delayIndicatorUpdate.clearDelay()}
                            placeholder='Тест по индикатору'
                            defaultValue={indicator.test}
                          />
                          <IndicatorGradationValueInput
                            onChange={(e) => {
                              delayIndicatorUpdate.updateDelay(updateIndicator, {
                                name: indicator.name,
                                value: e.target.value,
                                category: 'gradations',
                                competenceGrade: '',
                              })}
                            }
                            onBlur={() => delayIndicatorUpdate.clearDelay()}
                            placeholder='Градации в %'
                            defaultValue={[...indicator.gradations].join(' ')}
                          />
                        </IndicatorName>
                      </IndicatorMain>
                      <IndicatorGradation>
                        <Select
                          isReadOnly={false}
                          defaultValue={reverseLevels[indicator.grades.Beginner]}
                          variant='unstyled'
                          placeholder='Выберите уровень'
                          _hover={{ bg: '#eeeeee' }}
                          style={{
                            padding: '16px',
                            fontSize: '12px',
                            borderRadius: '0',
                            cursor: 'pointer',
                          }}
                          onChange={(e) => {
                            delayIndicatorUpdate.updateDelay(updateIndicator, {
                              name: indicator.name,
                              value: e.target.value,
                              category: 'grades',
                              competenceGrade: 'Beginner',
                            })}
                          }
                          onBlur={() => delayIndicatorUpdate.clearDelay()}
                        >
                          <>{Object.keys(levels).map(level =>
                            <option key={level}>{level}</option>
                          )}</>
                        </Select>
                      </IndicatorGradation>
                      <IndicatorGradation>
                        <Select
                          isReadOnly={false}
                          defaultValue={reverseLevels[indicator.grades.Intermediate]}
                          variant='unstyled'
                          placeholder='Выберите уровень'
                          _hover={{ bg: '#eeeeee' }}
                          style={{
                            padding: '16px',
                            fontSize: '12px',
                            borderRadius: '0',
                            cursor: 'pointer',
                          }}
                          onChange={(e) => {
                            delayIndicatorUpdate.updateDelay(updateIndicator, {
                              name: indicator.name,
                              value: e.target.value,
                              category: 'grades',
                              competenceGrade: 'Intermediate',
                            })}
                          }
                          onBlur={() => delayIndicatorUpdate.clearDelay()}
                        >
                          <>{Object.keys(levels).map(level =>
                            <option key={level}>{level}</option>
                          )}</>
                        </Select>
                      </IndicatorGradation>
                      <IndicatorGradation>
                        <Select
                          isReadOnly={false}
                          defaultValue={reverseLevels[indicator.grades.Master]}
                          variant='unstyled'
                          placeholder='Выберите уровень'
                          _hover={{ bg: '#eeeeee' }}
                          style={{
                            padding: '16px',
                            fontSize: '12px',
                            borderRadius: '0',
                            cursor: 'pointer',
                          }}
                          onChange={(e) => {
                            delayIndicatorUpdate.updateDelay(updateIndicator, {
                              name: indicator.name,
                              value: e.target.value,
                              category: 'grades',
                              competenceGrade: 'Master',
                            })}
                          }
                          onBlur={() => delayIndicatorUpdate.clearDelay()}
                        >
                          <>{Object.keys(levels).map(level =>
                            <option key={level}>{level}</option>
                          )}</>
                        </Select>
                      </IndicatorGradation>
                    </IndicatorTableRow>
                  )}
                  <IndicatorTableRow>
                    <IndicatorMain>
                      <IndicatorEdit
                        onClick={() => indicatorValidator.validateIndicator(newIndicator, true)}
                      >
                        <CrossAdd
                          color='#888888'
                        />
                      </IndicatorEdit>
                      <IndicatorName>
                        <IndicatorNameInput
                          onChange={(e) => updateNewIndicator(e.target.value, 'name')}
                          ref={newIndicatorNameRef}
                          placeholder='Название индикатора'
                        />
                        <IndicatorTestInput
                          onChange={(e) => updateNewIndicator(e.target.value, 'test')}
                          ref={newIndicatorTestRef}
                          placeholder='Тест по индикатору'
                        />
                        <IndicatorGradationValueInput
                            onChange={(e) => updateNewIndicator(e.target.value, 'gradations')}
                            ref={newIndicatorGradationsRef}
                            placeholder='Градации в %'
                        />
                      </IndicatorName>
                    </IndicatorMain>
                    <IndicatorGradation>
                      <Select
                          isReadOnly={false}
                          variant='unstyled'
                          placeholder='Выберите уровень'
                          _hover={{ bg: '#eeeeee' }}
                          style={{
                            padding: '16px',
                            fontSize: '12px',
                            borderRadius: '0',
                            cursor: 'pointer',
                          }}
                          onChange={(e) => updateNewIndicator(e.target.value, 'grades', 'Beginner')}
                          ref={newIndicatorGradeBeginnerRef}
                        >
                          <>{Object.keys(levels).map(level =>
                            <option key={level}>{level}</option>
                          )}</>
                      </Select>
                    </IndicatorGradation>
                    <IndicatorGradation>
                      <Select
                        isReadOnly={false}
                        variant='unstyled'
                        placeholder='Выберите уровень'
                        _hover={{ bg: '#eeeeee' }}
                        style={{
                          padding: '16px',
                          fontSize: '12px',
                          borderRadius: '0',
                          cursor: 'pointer',
                        }}
                        onChange={(e) => updateNewIndicator(e.target.value, 'grades', 'Intermediate')}
                        ref={newIndicatorGradeIntermediateRef}
                      >
                          <>{Object.keys(levels).map(level =>
                            <option key={level}>{level}</option>
                          )}</>
                      </Select>
                    </IndicatorGradation>
                    <IndicatorGradation>
                      <Select
                        isReadOnly={false}
                        variant='unstyled'
                        placeholder='Выберите уровень'
                        _hover={{ bg: '#eeeeee' }}
                        style={{
                          padding: '16px',
                          fontSize: '12px',
                          borderRadius: '0',
                          cursor: 'pointer',
                        }}
                        onChange={(e) => updateNewIndicator(e.target.value, 'grades', 'Master')}
                        ref={newIndicatorGradeMasterRef}
                      >
                        <>{Object.keys(levels).map(level =>
                          <option key={level}>{level}</option>
                        )}</>
                      </Select>
                    </IndicatorGradation>
                  </IndicatorTableRow>
                </IndicatorTable>
                <ErrorBlock
                  style={{
                    color: '#F83C3C',
                    height: errorMessages ? String(18 * errorMessages.length) + 'px' : '0px',
                    opacity: errorMessages ? '1' : '0',
                    margin: errorMessages ? '12px 0 0 0' : '0',
                  }}
                >
                  {errorMessages.map((message: any) => 
                    <div key={message}>
                      {message}
                    </div>
                  )}
                  
                </ErrorBlock>
              </FormControl>
            </ModalBody>

            <ModalFooter gap='8px' display='flex'>
              <Button
                type='button'
                width='100%'
                onClick={onClose}
                variant='outline'
                color='var(--main-purple)'
                borderRadius='8px'
              >
                Отмена
              </Button>
              <Button
                type='submit'
                width='100%'
                borderRadius='8px'
                bg='var(--main-purple)'
                _hover={{ bg: '#2D53DA' }}
                color='#fff'
                isLoading={isAwaiting}
              >
                Добавить компетенцию
              </Button>
            </ModalFooter>
          </ModalContent>
      </form>
    </Modal>
  )
}
