import React, { useState, useEffect } from 'react';
import { List } from './styles';
import { CareerCard } from '../CareerCard/CareerCard';
import careerState from '../../store/careerState';
import { observer } from 'mobx-react-lite';
import { getStepsByInterests } from '../../api';

export const InterestsVariants: React.FC = observer(() => {
  const [isAwaiting, setIsAwaiting] = useState(false);
  const [selected, setSelected] = useState<any>();
  const [selectedCareer, setSelectedCareer] = useState([]);
  const vacancy = JSON.parse(localStorage.getItem('user'))?.active_vacancy;

  function handleGetSteps() {
    setIsAwaiting(true);
    getStepsByInterests()
      .then((data: any) => {
        careerState.setStepsByInterests([vacancy]);

        careerState.setInterestsCareers(data.data.steps);
        data.data.steps.forEach((steps: any) => {
          steps.forEach((newStep: any) => {
            let flag = true;
            careerState.stepsByInterests.forEach((step: any) => {
              if (step.grade === newStep.grade && step.title === newStep.title) {
                flag = false
              }
            })
  
            if (flag) {
              careerState.setStepsByInterests([...careerState.stepsByInterests, newStep]);
            }
          });
        });
      })
      .catch((e: any) => {
        console.log(e);
      })
      .finally(() => {
        setIsAwaiting(false);
      })
  }

  useEffect(() => {
    careerState.interestsCareers.forEach((career: any) => {
      career.forEach((step: any) => {
        if (step.title === selected?.title) {
          setSelectedCareer(career);
        }
      });
    });
  }, [selected])

  useEffect(() => {
    if (careerState.stepsByInterests.length === 0) {
      handleGetSteps();
    }
  }, [])

  useEffect(() => {
    careerState.setSelectedStepsByInterests(selectedCareer);
  }, [selectedCareer])

  return (
    <>
      {isAwaiting && <>Loading...</>}
      <List>
        {careerState.stepsByInterests.slice(1).map((step: any, index) =>
          <>
          {step.grade === 'Lead' &&
          <button
            onClick={() => setSelected(step)}
            style={{ textAlign: 'left' }}
          >                 
            <CareerCard
              step={step}
              isActive={selected?.title && selected?.title === step?.title ? true : false}
              key={index}
            />
          </button>
          }
          </>
        )}
      </List>
    </>
  )
});
