import axios from 'axios';

const API = process.env.API_URL;
const access = JSON.parse(localStorage.getItem('accessToken'));
const refresh = JSON.parse(localStorage.getItem('refreshToken'));

export const getTest = async (testId: number) => {
  const res = await axios({
      url: `${API}/tests/${testId}/?detailed=1` ,
      method: 'GET',
      headers: {
          'Content-Type': 'application/json',
          "Access-Control-Allow-Headers": "*",
          "Authorization": `Bearer ${access}`,
      },
  })
  
  return res
}

export const getCompanyTests = async (companyId: number, searchTerm?: string) => {
  const res = await axios({
      url: `${API}/tests/?created_by__company=${companyId}&search=${searchTerm ? searchTerm : ''}&detailed=1` ,
      method: 'GET',
      headers: {
          'Content-Type': 'application/json',
          "Access-Control-Allow-Headers": "*",
          "Authorization": `Bearer ${access}`,
      },
  })
  
  return res
}

export const getEmployeeTests = async (employeeId: number, searchTerm?: string) => {
  const res = await axios({
      url: `${API}/tests/?invited__id=${employeeId}&search=${searchTerm ? searchTerm : ''}&detailed=1` ,
      method: 'GET',
      headers: {
          'Content-Type': 'application/json',
          "Access-Control-Allow-Headers": "*",
          "Authorization": `Bearer ${access}`,
      },
  })
  
  return res
}

export const getGroupTests = async (groupIds: number[], searchTerm?: string) => {
  let query_params = '';
  for (let i = 0; i < groupIds.length; i += 1) {
    if (i === groupIds.length - 1) {
      query_params += `&invited__group=${groupIds[i]}`;
    } else {
      query_params += `&invited__group=${groupIds[i]}`;
    }
  }

  const res = await axios({
      url: `${API}/tests/?&search=${searchTerm ? searchTerm : ''}&detailed=1${query_params}`,
      method: 'GET',
      headers: {
          'Content-Type': 'application/json',
          "Access-Control-Allow-Headers": "*",
          "Authorization": `Bearer ${access}`,
      },
  })
  
  return res
}

export const getEmployeeResults = async (testId: number, userId: number) => {
  const res = await axios({
      url: `${API}/tests-complete/?test=${testId}&user=${userId}&detailed=1` ,
      method: 'GET',
      headers: {
          'Content-Type': 'application/json',
          "Access-Control-Allow-Headers": "*",
          "Authorization": `Bearer ${access}`,
      },
  })
  
  return res
}

export const getUserTestResults = async (id: number) => {
  const res = await axios({
      url: `${API}/tests-complete/${id}/`,
      method: 'GET',
      headers: {
          'Content-Type': 'application/json',
          "Access-Control-Allow-Headers": "*",
          "Authorization": `Bearer ${access}`,
      },
      data: {
        id: id,
      }
  })
  
  return res
}

export const getTestStats = async (testId: number) => {
  const res = await axios({
      url: `${API}/tests-analytics/?detailed=1&test=${testId}` ,
      method: 'GET',
      headers: {
          'Content-Type': 'application/json',
          "Access-Control-Allow-Headers": "*",
          "Authorization": `Bearer ${access}`,
      },
  })
  
  return res
}

export const getGroupsDepartmentsTestStats = async (testId: number, ids: number[]) => {
  let query_params = '&user__group=';
  for (let i = 0; i < ids.length; i += 1) {
    if (i === ids.length - 1) {
      query_params += `${ids[i]}`;
    } else {
      query_params += `${ids[i]},`;
    }
  }

  const res = await axios({
      url: `${API}/tests-analytics/?test=${testId}${query_params}` ,
      method: 'GET',
      headers: {
          'Content-Type': 'application/json',
          "Access-Control-Allow-Headers": "*",
          "Authorization": `Bearer ${access}`,
      },
  })
  
  return res
}

export const getUserTestStats = async (testId: number, ids: number[]) => {
  let query_params = '&user=';
  for (let i = 0; i < ids.length; i += 1) {
    if (i === ids.length - 1) {
      query_params += `${ids[i]}`;
    } else {
      query_params += `${ids[i]},`;
    }
  }

  const res = await axios({
      url: `${API}/tests-analytics/?test=${testId}${query_params}` ,
      method: 'GET',
      headers: {
          'Content-Type': 'application/json',
          "Access-Control-Allow-Headers": "*",
          "Authorization": `Bearer ${access}`,
      },
  })
  
  return res
}

export const getTestResults = async (id: number, offset: number, limit: number) => {
  const res = await axios({
      url: `${API}/tests-complete/?test=${id}&offset=${offset}&limit=${limit}`,
      method: 'GET',
      headers: {
          'Content-Type': 'application/json',
          "Access-Control-Allow-Headers": "*",
          "Authorization": `Bearer ${access}`,
      },
      data: {
        id: id,
      }
  })
  
  return res
}

export const getFilteredTextAnswers = async (question_id: number, offset: number, limit: number, category: string, ids: number[]) => {
  let query_params = '';
  if (category === 'employees') {
    query_params = '&user=';
  } else if (category === 'groups' || category === 'departments') {
    query_params = '&user__group=';
  }

  for (let i = 0; i < ids.length; i += 1) {
    if (i === ids.length - 1) {
      query_params += `${ids[i]}`;
    } else {
      query_params += `${ids[i]},`;
    }
  }

  const res = await axios({
      url: `${API}/text-answers/?question=${question_id}&${query_params}&offset=${offset}&limit=${limit}`,
      method: 'GET',
      headers: {
          'Content-Type': 'application/json',
          "Access-Control-Allow-Headers": "*",
          "Authorization": `Bearer ${access}`,
      },
      data: {
        question_id: question_id,
      }
  })
  
  return res
}
