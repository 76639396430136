import { makeAutoObservable } from 'mobx';

class myTasksState {
  tasks: [] = [];

  constructor() {
    makeAutoObservable(this);
  }

  setTasks(tasks: []) {
    this.tasks = tasks;
  }
}

export default new myTasksState();
