import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: start;
  padding: 8px;
  border-radius: 8px;
  background: #fff;
`;


export const Content = styled.div`
  display: flex;
  gap: 8px;
  width: 100%;
`;

export const Details = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

export const Title = styled.h5`
  font-size: 16px;
  font-weight: 600;
`;

export const Text = styled.p`
  font-size: 12px;
`;

export const Buttons = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;
