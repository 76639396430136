import styled from 'styled-components';

export const SkillsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`;

export const SkillList = styled.div`
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
`;

export const SkillButton = styled.button`
  font-size: 18px;
  color: var(--main-white);
`;
