import React from 'react';
import { EmployeesTable } from '@/widgets';
import { EmployeesTopBar } from '@/widgets';
import { ListWrapper } from './styles';
import { observer } from 'mobx-react-lite';
import employeesState from './store/employeesState';

export const Employees: React.FC = observer(() => {
  return (
    <>
      <EmployeesTopBar/>
      <ListWrapper>
        <EmployeesTable />
      </ListWrapper>
    </>
  )
});
