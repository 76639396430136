import styled from 'styled-components';

export const CardHeading = styled.h3`
  display: inline-block;
  margin-right: 1rem;
`;

export const DimmedText = styled.div`
  display: inline-block;
  font-size: 12px;
  opacity: 50%;
`;

export const InProgressHint = styled.button`
    background-color: rgba(226, 0, 203, 0.1);
    color: rgb(226, 0, 203);
    display: flex;
    gap: 0.5rem;
    align-items: center;
    font-size: 12px;
    padding: 0.4rem;
    border-radius: 5px;
`;

export const CompletedHint = styled.button`
    background-color: rgba(17, 160, 22, 0.3);
    color: rgb(17, 160, 22);
    display: flex;
    gap: 0.5rem;
    align-items: center;
    font-size: 12px;
    padding: 0.4rem;
    border-radius: 5px;
`;


export const NotStartedHint = styled.button`
    background-color: rgba(115, 115, 115, 0.1);
    color: rgb(115, 115, 115);
    display: flex;
    gap: 0.5rem;
    align-items: center;
    font-size: 12px;
    padding: 0.4rem;
    border-radius: 5px;
`;
