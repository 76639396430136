import { makeAutoObservable } from "mobx";
import type { TProject } from "@/shared";
import type { TTask } from "@/shared";

type TFilter = 'projects' | 'tasks';

class ProjectsPageState {
  filter = 'projects';
  projects: TProject[] = [];
  currentProject: TProject;
  tasks: TTask[] = [];
  currentTask: TTask;

  constructor() {
    makeAutoObservable(this);
  }

  setFilter(value: TFilter) {
    this.filter = value;
  }

  setProjects(projects: TProject[]) {
    this.projects = projects;
  }

  setCurrentProject(project: TProject) {
    this.currentProject = project;
  }

  setTasks(tasks: TTask[]) {
    this.tasks = tasks;
  }

  setCurrentTask(task: TTask) {
    this.currentTask = task;
  }

  deleteProject(project_id: number) {
    this.projects = this.projects.filter((p) => p.id !== project_id);
  }

  deleteTask(task_id: number) {
    this.tasks = this.tasks.filter((t) => t.id !== task_id);
  }
}

export default new ProjectsPageState();
