import React, { useState, useEffect } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  InputGroup,
  Select,
} from '@chakra-ui/react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { IVacanci, Skill, useDebounce } from '@/shared';
import { SkillsWrapper } from '@/entities/vacanci/ui/styles';
import { SkillList, SkillButton } from '@/features/employee/ui/EditSkillsPopup/EditSkillsPopup';
import { searchSkills } from '@/features/employee';
import { createVacancy } from '@/features/vacanci';
import vacanciesState from '@/entities/vacanci/store/vacanciesState';
import { companyVacancy } from '@/features/vacanci';
import { grades } from '@/shared';

interface IFormFileds {
  title: string;
  description: string;
  parent: number;
  skill: any[];
  grade: string;
}

interface ICreateVacancyPopupProps {
  isOpen: boolean;
  onClose: () => void;
}

export const CreateVacancyPopup: React.FC<ICreateVacancyPopupProps> = ({ isOpen, onClose }) => {
  const { 
    register,
    handleSubmit,
    formState: { errors, isSubmitting } 
  } = useForm<IFormFileds>();

  const companyId = JSON.parse(localStorage.getItem('user'))?.company[JSON.parse(localStorage.getItem('user'))?.company.length - 1];

  const [skills, setSkills] = useState([]);

  const [softSkills, setSoftSkills] = useState([]);
  const [hardSkills, setHardSkills] = useState([]);
  const [searchSoftTerm, setSearchSoftTerm] = useState('');
  const [searchHardTerm, setSearchHardTerm] = useState('');
  const [selectedGrade, setSelectedGrade] = useState<string | undefined>();

  const debouncedSearchSoftTerm = useDebounce(searchSoftTerm, 500);
  const debouncedSearchHardTerm = useDebounce(searchHardTerm, 500);

  function addSkill(addedSkill: any) {
    let flag = true;
    skills.forEach((s) => {
      if (s.name === addedSkill.name) {
        flag = false;
      } 
    })
    if (flag) {
      setSkills([...skills, addedSkill]);
    }
  } 

  function removeSkill(removedSkill: any) {
    const updatedSkills = skills.filter((skill: any) => {
      return skill?.id !== removedSkill?.id;
    })
    setSkills(updatedSkills);
  }

  function handleSoftSearch(type: string) {
    searchSkills(debouncedSearchSoftTerm, type)
    .then((res: any) => {
      setSoftSkills(res.data);
    })
    .catch((error) => {
      console.log(error);
    })
  }

  function handleHardSearch(type: string) {
    searchSkills(debouncedSearchHardTerm, type)
    .then((res: any) => {
      setHardSkills(res.data);
    })
    .catch((error) => {
      console.log(error);
    })
  }

  useEffect(() => {
    if (searchSoftTerm) {
      handleSoftSearch('Soft');
    }
  }, [debouncedSearchSoftTerm])

  useEffect(() => {
    if (searchHardTerm) {
      handleHardSearch('Hard');
    }
  }, [debouncedSearchHardTerm])

  const onSubmit: SubmitHandler<IFormFileds> = (data) => {
    data.skill = skills;
    data.parent = companyId;
    data.grade = selectedGrade;

    createVacancy(data)
      .then((res) => {
        const vacancy: IVacanci = res.data;
        companyVacancy(companyId, res.data.id)
          .then((res) => {
            vacanciesState.setCompanyVacancies([...vacanciesState.vacancies.company, vacancy]);
            onClose();
          })
          .catch((e) => {
            console.log(e);
          })
      })
      .catch((e) => {
        console.log(e);
      })
  }

  return (
    <Modal size={'xl'} blockScrollOnMount={false} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <form onSubmit={handleSubmit(onSubmit)}>
          <ModalContent borderRadius={16}>
            <ModalHeader alignItems={'center'}>
              <h3 style={{ textAlign: 'center' }}>Создание должности</h3>
            </ModalHeader>
            <ModalCloseButton top={'14px'} />
            <ModalBody
              padding='20px'
              display='flex'
              flexDirection='column'
              gap='30px'
              borderTop='1px solid var(--addable-gray)'
              borderBottom='1px solid var(--addable-gray)'
            >
              <FormControl isInvalid={Boolean(errors.title)}>
                <FormLabel>Название вакансии *</FormLabel>
                <Input
                  id='title'
                  {...register('title', { 
                    required: 'Обязательное поле'
                  })}
                  placeholder='Frontend developer'
                  type='text'
                  size='sm'
                  variant='flushed'
                />
                <FormErrorMessage>{errors.title && <>{errors.title.message}</>}</FormErrorMessage>
              </FormControl>

              <FormControl>
                <FormLabel>Грейд</FormLabel>
                  <Select
                    variant='flushed'
                    onChange={(e) => setSelectedGrade(e.target.value)}
                    value={selectedGrade}
                    defaultValue=""
                  >
                    <option hidden disabled value="">Без грейда</option>
                    {grades.map((option) =>
                      <option key={option} value={option}>{option}</option>
                    )}
                  </Select>
                <FormErrorMessage>{errors.grade && <>{errors.grade.message}</>}</FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={Boolean(errors.title)}>
                <FormLabel>Описание вакансии *</FormLabel>
                <Input
                  id='description'
                  {...register('description')}
                  placeholder='Создавать интерфейсы'
                  type='text'
                  size='sm'
                  variant='flushed'
                />
                <FormErrorMessage>{errors.title && <>{errors.title.message}</>}</FormErrorMessage>
              </FormControl>
                <FormControl>
                  <FormLabel>Hard skills *</FormLabel>
                  <InputGroup>
                    <Input
                      value={searchHardTerm}
                      onChange={e => setSearchHardTerm(e.target.value)}
                      color='messenger'
                      height={'28px'}
                      variant='flushed'
                      placeholder='Введите значение'
                    />
                  </InputGroup>
                </FormControl>
                  {searchHardTerm &&
                  <SkillsWrapper>
                  <SkillList>
                    {hardSkills.map((skill: any) =>
                      <Skill title={skill?.name} key={skill?.id}>
                        <SkillButton type='button' onClick={() => addSkill(skill)}>+</SkillButton>
                      </Skill>
                    )}
                  </SkillList>
                  {hardSkills.length === 0 &&
                      <Skill title={'Новый'}>
                        <SkillButton type='button' onClick={() => {
                          addSkill({
                            id: Date.now(),
                            name: searchHardTerm,
                            type: "Hard"
                          })
                          setSearchHardTerm('')
                        }}>+</SkillButton>
                      </Skill>
                    }
                  </SkillsWrapper>
                  }
                  <FormControl>
                    <FormLabel>Soft skills *</FormLabel>
                    <InputGroup>
                      <Input
                        value={searchSoftTerm}
                        onChange={e => setSearchSoftTerm(e.target.value)}
                        color='messenger'
                        height={'28px'}
                        variant='flushed'
                        placeholder='Введите значение'
                      />
                    </InputGroup>
                  </FormControl>
                  {searchSoftTerm &&
                    <SkillsWrapper>
                    <SkillList>
                      {softSkills && softSkills.map((skill: any) =>
                        <Skill title={skill?.name} key={skill?.id}>
                          <SkillButton type='button' onClick={() => addSkill(skill)}>+</SkillButton>
                        </Skill>
                      )}
                    </SkillList>
                    {softSkills.length === 0 &&
                      <Skill title={'Новый'}>
                        <SkillButton type='button' onClick={() => {
                          addSkill({
                            id: Date.now(),
                            name: searchSoftTerm,
                            type: "Soft"
                          })
                          setSearchSoftTerm('')
                        }}>+</SkillButton>
                      </Skill>
                    }
                    </SkillsWrapper>
                  }
                <SkillsWrapper>
                  <SkillList>
                    {skills && skills.map((skill: any) => 
                      <Skill title={skill.name} key={skill.id}>
                        <SkillButton type='button' onClick={() => removeSkill(skill)}>-</SkillButton>
                      </Skill>
                    )}
                  </SkillList>
                </SkillsWrapper>

            </ModalBody>

            <ModalFooter gap='8px' display='flex'>
              <Button
                type='button'
                width='100%'
                onClick={onClose}
                variant='outline'
                color='var(--main-purple)'
                borderRadius='8px'
              >
                Отмена
              </Button>
              <Button
                type='submit'
                width='100%'
                borderRadius='8px'
                bg='var(--main-purple)'
                _hover={{ bg: 'var(--hover-purple)' }}
                color='#fff'
                isLoading={isSubmitting ? true : false}
              >
                Добавить должность
              </Button>
            </ModalFooter>
          </ModalContent>
      </form>
    </Modal>
  )
}
