import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import testAssessmentDrawerState from '@/widgets/TestsList/store/testAssessmentDrawerState';
import testAssessmentState from './store/testAssessmentState';
import Drawer from '@mui/material/Drawer';
import { useForm, SubmitHandler } from 'react-hook-form';
import {
  CloseButton,
  Divider,
  VStack,
  Box,
  HStack,
  Button,
  Flex,
  Stack,
} from '@chakra-ui/react';
import {
  DrawerHeaderStyles,
  DimmedHeading,
} from './styles';

import {
  OpenEndedAssessment,
  SingleChoiceAssessment,
  MultipleChoiceAssessment,
} from '@/features/tests/';
import { completeTest } from '@/features/tests/';
import { IQuestion } from '@/shared';
import { SingleChoiceResult } from '@/features/tests/ui/SingleChoiceResult/SingleChoiceResult';
import { MultipleChoiceResult } from '@/features/tests/ui/MultipleChoiceResult/MultipleChoiceResult';
import { OpenEndedResult } from '@/features/tests/ui/OpenEndedResult/OpenEndedResult';
import { getEmployeeResults, getUserTestResults, getTest } from '@/entities/tests';
import dayjs from 'dayjs';

export const TestAssessmentDrawer: React.FC = observer(() => {
  const {
    handleSubmit,
  } = useForm<any>();

  const onSubmit: SubmitHandler<any> = (data) => {
    const finishedTest = testAssessmentState.processAnswers();
    completeTest(finishedTest)
      .then((res) => {
        testAssessmentState.setResults(res.data);
        testAssessmentState.complete();
        handleGetResults(testAssessmentState.test?.id);
      })
      .catch(e => {
        console.log(e);
      })
  }

  const handleDrawerClose = () => {
    testAssessmentDrawerState.closeDrawer();
  };

  const userId = JSON.parse(localStorage.getItem('user'))?.id;

  function handleGetTest(testId: number) {
    if (testId) {
      getTest(testAssessmentState.test?.id)
        .then((res) => {
          if (testId === testAssessmentState.test?.id) {
            console.log(JSON.stringify(res.data, null, '\t'));
            testAssessmentState.setTest(res.data);
          }
        })
        .catch(e => {
          console.log(e);
        })
    }
  }

  function handleGetResults(testId: number) {
    if (testId) {
      testAssessmentState.setResults({});
      getEmployeeResults(testId, userId)
        .then((res) => {
          if (testId === testAssessmentState.test?.id) {
            const id = res.data[0].id;
            const testResults = getUserTestResults(id);
            return testResults;
          }
        })
        .then((res) => {
          testAssessmentState.setResults(Object.keys(res).length === 0 ? {} : res.data);
        })
        .catch(e => {
          console.log(e);
        })
    }
  }

  useEffect(() => {
    if (testAssessmentState.test.completed) {
      handleGetResults(testAssessmentState.test?.id);
    } else {
      handleGetTest(testAssessmentState.test?.id);
    }
  }, [testAssessmentState.test?.id])

  return (
    <Drawer
      sx={{
        width: testAssessmentDrawerState.width,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: testAssessmentDrawerState.width,
          boxSizing: 'border-box',
        },
      }}
      variant="persistent"
      anchor="right"
      open={testAssessmentDrawerState.open}
    >
      <Stack p={4}>
        <DrawerHeaderStyles>
          <h3>{testAssessmentState.test.title}</h3>
          <CloseButton
            size='lg'
            onClick={handleDrawerClose}
          />
        </DrawerHeaderStyles>
      </Stack>

      <Divider />

      {testAssessmentState.test.completed &&
        <>
          <Stack p={4} spacing={4}>
            <Flex gap={8}>
              <Box>
                <DimmedHeading>Дата прохождения</DimmedHeading>
              </Box>
              <Box>
                {
                  testAssessmentState.results &&
                  testAssessmentState.results.finished_at &&
                  dayjs(testAssessmentState.results.finished_at, 'DD-MM-YYYY HH-mm')
                    .format('DD/MM/YY')
                }
              </Box>
            </Flex>
          </Stack>
          <Divider />
        </>
      }

      <Stack p={4} spacing={4}>
        {testAssessmentState.test.description &&
          <Box>{testAssessmentState.test.description}</Box>
        }

        {testAssessmentState.test.completed ?
          <Stack mt={8} spacing={8}>
            <h5>Результаты</h5>
            <Box>
              Статистика правильных ответов: <span style={{ fontWeight: 600 }}>
                {
                  testAssessmentState.results?.questions &&
                  testAssessmentState.results?.questions
                    .map((q: any) => q.good)
                    .filter((m: any) => m)
                    .length
                }
                /
                {
                  testAssessmentState.results?.questions &&
                  testAssessmentState.results?.questions
                    .map((q: any) => q.good)
                    .length
                }
              </span>
            </Box>

            <VStack spacing={8}>
              {testAssessmentState.results.questions &&
                testAssessmentState.results?.questions.map((info: any) => {
                  if (info.question.type === 'single') {
                    return (
                      <Box w="full" key={info.question.id}>
                        <SingleChoiceResult info={info} />
                      </Box>
                    );
                  } if (info.question.type === 'multiple') {
                    return (
                      <Box w="full" key={info.question.id}>
                        <MultipleChoiceResult info={info} />
                      </Box>
                    );
                  } if (info.question.type === 'text') {
                    return (
                      <Box w="full" key={info.question.id}>
                        <OpenEndedResult info={info} />
                      </Box>
                    );
                  }
                })}
            </VStack>

            <HStack w="full">
              <Button
                type='button'
                width='100%'
                variant='outline'
                onClick={handleDrawerClose}
                color='var(--main-purple)'
                borderRadius='8px'
              >
                Закрыть
              </Button>
            </HStack>
          </Stack> :

          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack>
              <h5>Вопросы</h5>

              <VStack w="full" p={6} spacing={10}>
                {testAssessmentState.test.questions?.map((question: IQuestion) => {
                  if (question.type === 'single') {
                    return (
                      <Box w="full" key={question.id}>
                        <SingleChoiceAssessment question={question} />
                      </Box>
                    );
                  } if (question.type === 'multiple') {
                    return (
                      <Box w="full" key={question.id}>
                        <MultipleChoiceAssessment question={question} />
                      </Box>
                    );
                  } if (question.type === 'text') {
                    return (
                      <Box w="full" key={question.id}>
                        <OpenEndedAssessment question={question} />
                      </Box>
                    );
                  }
                })}
              </VStack>
            </Stack>

            <HStack w="full">
              <Button
                type='button'
                width='100%'
                variant='outline'
                onClick={handleDrawerClose}
                color='var(--main-purple)'
                borderRadius='8px'
              >
                Отмена
              </Button>
              <Button
                type='submit'
                width='100%'
                borderRadius='8px'
                bg='var(--main-purple)'
                _hover={{ bg: 'var(--hover-purple)' }}
                color='#fff'
              >
                Завершить тест
              </Button>
            </HStack>
          </form>
        }
      </Stack>
    </Drawer>
  );
});
