import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';

import {
    GradationBar,
    Gradation,
    Anvelope,
  } from './styles';
import competenceAnalyticsState from '@/pages/CompetenceAnalytics/store/competenceAnalyticsState';

export interface LevelStateProps {
  instance: any;
  indicator: boolean;
}

export const LevelState: React.FC<LevelStateProps> = observer(({instance, indicator}) => {
  const [gradation, setGradation] = useState<any>({total: 0});

  const enRusMap: any = {
    'Beginner': 'Слабый',
    'Intermediate': 'Уверенный',
    'Master': 'Сильный',
  };

  const colorMap: any = {
    'Beginner': '#F0F3FF',
    'Intermediate': '#B7C6FF',
    'Master': '#6B8BFF'
  }

  useEffect(() => {
    let gradationLevelMap: any = {
      Beginner: 2,
      Intermediate: 3,
      Master: 4,
    };

    setGradation(gradationLevelMap[Object.keys(instance.levels)[0]]);
  }, []);

  useEffect(() => {
    let gradationLevelMap: any = {
      Beginner: 2,
      Intermediate: 3,
      Master: 4,
    };

    setGradation(gradationLevelMap[Object.keys(instance.levels)[0]]);
  }, [competenceAnalyticsState.current.competences])

  return (
    <Anvelope>
      {Object.keys(instance.levels).length > 0 ?
        <GradationBar>
          <Gradation
            style={{
              backgroundColor: colorMap[Object.keys(instance.levels)[0]],
              gridColumn: '1 / ' + String(gradation),
            }}
          >
            {/* {String(enRusMap[Object.keys(instance.levels)[0]])} */}
          </Gradation>
        </GradationBar>
      :
        <Gradation>
          Нет данных
        </Gradation>
      }
    </Anvelope>
  )
});