import styled from "styled-components";

export const NotificationsButton = styled.button`
  position: relative;
  display: flex;
  align-items: end; 
  width: 28px;
  height: 26px;
`;

export const Signal = styled.span`
  position: absolute;
  top: 0;
  right: 0;
  display: block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: var(--addable-red);
`;

export const NotificationsList = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  min-height: 20px;
  max-height: 206px; 
  overflow: auto;
`;
