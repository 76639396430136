import styled from 'styled-components';

export const WrongStyles = styled.div`
  background-color: rgba(248, 60, 60, 0.1);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
`;

export const RightStyles = styled.div`
  background-color: rgba(17, 160, 22, 0.1);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
`;
