import React from 'react';
import hrTestCreationDrawerState from '@/entities/tests/ui/AddTestDrawerBtn/store/hrTestCreationDrawerState';
import testInfoDrawerState from '@/widgets/TestsList/store/testInfoDrawerState';
import testEditDrawerState from '@/widgets/TestsList/store/testEditDrawerState';
import testAssessmentDrawerState from '@/widgets/TestsList/store/testAssessmentDrawerState';
import {
  Content,
} from './styles';
import { styled } from '@mui/material/styles';
import { observer } from 'mobx-react-lite';

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginRight: 0,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }), marginRight: `${testInfoDrawerState.width}px`,
  }),
}));

export interface TestDashboardProps extends React.PropsWithChildren {}

export const TestDashboard: React.FC<TestDashboardProps> = observer(({ children }) => {
  return (
    <Main open={
      hrTestCreationDrawerState.open
      || testEditDrawerState.open
      || testInfoDrawerState.open
      || testAssessmentDrawerState.open
    }>
      <Content>
        {children}
      </Content>
    </Main>
  );
});
