import React, { useState, useEffect } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Textarea,
  Select,
  Stack,
  Divider,
  InputGroup,
} from '@chakra-ui/react';
import {
  Container,
  ContainerInLine,
  ContainerInner,
  StepHeaderContainer,
  MarginBlock,
  Input,
  CustomUl,
  CustomLi,
} from './styles';
import { Skill, TTask } from '@/shared';
import { SubmitHandler, useForm, Controller } from 'react-hook-form';
import { DatePicker, ConfigProvider } from 'antd';
import { searchSkills } from '@/features/employee';
import { useDebounce } from '@/shared';
import projectsPageState from '@/pages/Projects/store/projectsPageState';
import { observer } from 'mobx-react-lite';
import { createProject, createTask } from '../../api';
import { dateConvert } from '@/shared';
import { addTask } from '@/features/projects';
import { getProjectById } from '@/entities/projects';
import { getCreatedProjects } from '@/entities/projects';
import { getCreatedTasks } from '@/entities/tasks';

const { RangePicker } = DatePicker;

interface IFormFileds {
  name: string;
  description: string;
  deadline: any[];
  skills: any[];
  difficulty: string;
  project: any;
}

interface IImportTasksPopupProps {
  isOpen: boolean;
  onClose: () => void;
}

const difficultOptions = [
  '1 stp',
  '2 stp',
  '3 stp',
  '4 stp',
  '5 stp',
];

export const ImportTasksPopup: React.FC<IImportTasksPopupProps> = observer(({ isOpen, onClose }) => {
  const {
    control,
    register,
    setValue,
    formState: { errors, isSubmitting }
  } = useForm<IFormFileds>();

  // const [dates, setDates] = useState([]);
  // const [skills, setSkills] = useState([]);
  // const [softSkills, setSoftSkills] = useState([]);
  // const [hardSkills, setHardSkills] = useState([]);
  // const [searchSoftTerm, setSearchSoftTerm] = useState('');
  // const [searchHardTerm, setSearchHardTerm] = useState('');
  // const [selectedProjectId, setSelectedProjectId] = useState<any>(undefined);
  // const [selectedDifficult, setSelectedDifficult] = useState<any>(difficultOptions[0]);
  // const [tasks, setTasks] = useState([]);
  // const [projects, setProjects] = useState([]);
  const [showBitrixManual, setShowBitrixManual] = useState(false);
  const [showYandexTrackerManual, setShowYandexTrackerManual] = useState(false);
  const [clickedBitrixButton, setClickedBitrixButton] = useState(false);
  const [clickedYandexTrackerButton, setClickedYandexTrackerButton] = useState(false);
  const [exportedFile, setExportedFile] = useState(null);

  const userId = JSON.parse(localStorage.getItem('user'))?.id;

  let tasks: Array<any> = [];
  let projects: Array<any> = [];

  // Handles getting all projects created by user
  async function handleGetCreatedProjects() {
    await getCreatedProjects(userId)
      .then((res) => {
        // const projectsWithoutGantt: any = [];
        res.forEach((project: any) => {
          projects.push(project.project);
        });
        // projects.push(...projectsWithoutGantt);
      })
      .catch((e) => {
        console.log(e);
      })
  }

  // Handles getting all tasks created by user
  async function handleGetCreatedTasks() {
    await getCreatedTasks(userId)
      .then((res) => {
        // const tasksWithoutGantt: any = [];
        res.forEach((task: any) => {
          tasks.push(task.task);
        });
        // setTasks(tasksWithoutGantt);
      })
      .catch((e) => {
        console.log(e);
      })
  }

  // Handles attaching task to a project
  async function handleAddTaskToProject(projectId: number, taskId: number) {
    async function appendToTaskArray(taskArray: Array<number>) {
      await addTask(projectId, taskArray)
        .then((addTaskResponse) => {
//           console.log(`[handleAddTaskToProject] - Task "${taskId}" was successfully added to project "${projectId}". Task array: "${taskArray}"`);
        })
        .catch(e => {
          console.log(e);
        })
    }
    // We have to get project's tasks and add another one to it
    await getProjectById(projectId)
      .then(async (projectResponse) => {

        const taskIds: Array<number> = [];
        for (const task of projectResponse.data.tasks) {
          taskIds.push(task.id);
        }

//         console.log(`[handleAddTaskToProject] - Project "${projectId}" has these tasks: "${taskIds}"`);
//         console.log(`[handleAddTaskToProject] - Adding task "${taskId}" to the list...`);

        await appendToTaskArray([...taskIds, taskId]);
      })
      .catch(e => {
        console.log(e);
      })
  }

  // Handles new task creating
  // Checks whether there is a project that task is attached to
  async function handleCreateTask(newTask: any) {
    await createTask(newTask)
      .then(async (createTaskResponse) => {
        if (newTask.project !== "") {
          let projectExists = await checkProject(newTask);

          if (projectExists !== null) {
            // If project exists, we have to add the task to this project
//             console.log(`Project exists: "${projectExists}"`);
//             console.log(`Attaching task "${createTaskResponse.data.id}" to the project "${projectExists}"...`);

            await handleAddTaskToProject(projectExists, createTaskResponse.data.id);
          }
          else {
//             console.log('Project does not exist');
//             console.log(`Creating new project and attaching task "${createTaskResponse.data.id}" to it...`);

            const newProject: any = {
              title: newTask.project,
              description: "Проект импортирован из Bitrix24",
              tasks: [createTaskResponse.data.id],
              start_date: newTask.start_date,
              end_date: newTask.end_date
            }

            await createProject(newProject)
              .then(async (createProjectResponse) => {
//                 console.log(`[handleCreateTask] - Project "${createProjectResponse.data.id}" was successfully added, task "${createTaskResponse.data.id}" was attached to it. Task array: "${createProjectResponse.data.tasks}"`);
                await handleGetCreatedProjects();
              })
              .catch(e => {
                console.log(e);
              })
          }

          await handleGetCreatedTasks();
        }
      })
      .catch(e => {
        console.log(e);
      })
  }

  useEffect(() => {
    handleGetCreatedProjects();
    handleGetCreatedTasks();
  }, [])

  function handleBitrixClick() {
    setShowBitrixManual(!showBitrixManual);
    setShowYandexTrackerManual(false);

    setClickedBitrixButton(!clickedBitrixButton);
    setClickedYandexTrackerButton(false);
  }
  function handleYandexTrackerClick() {
    setShowYandexTrackerManual(!showYandexTrackerManual);
    setShowBitrixManual(false);

    setClickedYandexTrackerButton(!clickedYandexTrackerButton);
    setClickedBitrixButton(false);
  }

  function fetchFile(event: React.ChangeEvent<HTMLInputElement>) {
    const selectedFile = event.target.files[0];
    setExportedFile(selectedFile);
  }

  function parseRow(row: HTMLTableRowElement) {
    let dataMapping = {
      title: 1,
      description: 2,
      start_date: 11,
      end_date: 4,
      status: 9,
      priority: "Medium",
      project: 10
    }

    let cellList = row.getElementsByTagName("td");

    let data = {
      title: cellList[dataMapping.title].innerHTML,
      description: cellList[dataMapping.description].innerHTML,
      start_date: cellList[dataMapping.start_date].innerHTML.split(' ')[0],
      end_date: cellList[dataMapping.end_date].innerHTML.split(' ')[0],
      status: cellList[dataMapping.status].innerHTML,
      complexity: difficultOptions[2],
      skill: Array<string>,
      priority: dataMapping.priority,
      project: cellList[dataMapping.project].innerHTML
    }

    if (data.description === "") {
      data.description = "Описание задачи"
    }

    switch (data.status) {
      case "Ждет выполнения":
        data.status = "Not started"
        break;
      case "Выполняется":
        data.status = "In process"
    }

    return data;
  }

  async function checkProject(taskData: any) {
    await handleGetCreatedProjects();

    for (const project of projects) {
      if (project.title === taskData.project) {
        return project.id;
      }
    }

    return null;
  }

  async function checkTask(taskData: any) {
    await handleGetCreatedTasks();

    for (const task of tasks) {
      if (task.title === taskData.title) {
        return true;
      }
    }

    return false;
  }

  function importTasks() {
    if (!exportedFile) {
      window.alert('Файл не был добавлен');
      return;
    }

    const reader = new FileReader();

    reader.addEventListener(
      "load",
      async () => {
        let exportedFileText: string = reader.result.toString();

        const parser = new DOMParser();
        const parsedDocument = parser.parseFromString(
          exportedFileText,
          "text/html"
        );
        const taskList = parsedDocument
          .getElementsByTagName("tbody")[0]
          .getElementsByTagName("tr");

        let taskCounter = 0;
        while (taskList.item(taskCounter)) {
          const newTask = parseRow(taskList.item(taskCounter));

          const taskExists: boolean = await checkTask(newTask);

          if (!taskExists) {
//             console.log(`\n\nTask "${newTask.title}" is new, uploading...`);
            await handleCreateTask(newTask);
//             console.log('Task uploaded, moving on...\n\n');
          }

          taskCounter++;
        }
        onClose();
      },
      false
    );

    reader.readAsText(exportedFile);
  }

  return (
    <Modal size={'4xl'} closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader alignItems={'center'}>
          <h3 style={{ textAlign: 'center' }}>Импорт задач</h3>
        </ModalHeader>

        <ModalCloseButton top={'10px'} size="lg" />

        <Divider />

        <Container>
          <MarginBlock />
          <ContainerInLine>
            <StepHeaderContainer>
              <h3>
                1.
              </h3>
            </StepHeaderContainer>
            <ContainerInner>
              <h3>
                Выберите трекер
              </h3>
              <ContainerInLine>
                <Button
                  type='button'
                  borderRadius='8px'
                  onClick={handleBitrixClick}
                  style={{
                      backgroundColor: clickedBitrixButton ? '#4F6CEA' : '#EDF2F7',
                      color: clickedBitrixButton ? '#ffffff' : 'var(--main-purple)'
                  }}
                >
                  Bitrix
                </Button>
                <Button
                  type='button'
                  borderRadius='8px'
//                   onClick={handleYandexTrackerClick}
                  style={{
                      backgroundColor: clickedYandexTrackerButton ? '#4F6CEA' : '#EDF2F7',
                      color: clickedYandexTrackerButton ? '#ffffff' : 'var(--main-purple)',
                      opacity: '50%',
                      cursor: 'not-allowed'
                  }}
                >
                  Yandex.Tracker
                </Button>
              </ContainerInLine>
            </ContainerInner>
          </ContainerInLine>

          {showBitrixManual &&
            <ContainerInner>
              <MarginBlock />
              <ContainerInner>
                <ContainerInLine>
                  <StepHeaderContainer>
                    <h3>
                      2.
                    </h3>
                  </StepHeaderContainer>
                  <ContainerInner>
                    <h3>
                      Выгрузите задачи
                    </h3>
                    <CustomUl>
                      <CustomLi>
                        Перейдите в раздел «Задачи и Проекты», нажмите на значок шестеренки,
                        выберите «Экспорт списка» в выпадающем меню, а затем - опцию «в Excel».
                      </CustomLi>
                      <CustomLi>
                        Отметьте галочку «Все поля задач», нажмите «Выполнить», скачайте получившийся файл и загрузите его в поле ниже
                      </CustomLi>
                    </CustomUl>

                    <Input
                      type="file"
                      onChange={fetchFile}
                      accept="xls html"
                    />
                    Важно: рекомендуем проверить корректность задач после их импорта


                  </ContainerInner>
                </ContainerInLine>
              </ContainerInner>
            </ContainerInner>
          }

          {showYandexTrackerManual &&
            <ContainerInner>
              <MarginBlock />
              <ContainerInner>
                <ContainerInLine>
                  <StepHeaderContainer>
                    <h3>
                      2.
                    </h3>
                  </StepHeaderContainer>
                  <ContainerInner>
                    <h3>
                      Выгрузите задачи
                    </h3>
                    Инструкция для Yandex.Tracker
                    <Input
                      type="file"
                      onChange={fetchFile}
                      accept="xls html"
                    />
                  </ContainerInner>
                </ContainerInLine>
              </ContainerInner>
              <ContainerInner>

              </ContainerInner>
            </ContainerInner>
          }
          <MarginBlock />
        </Container>

        <Divider />

        <ModalFooter gap='8px' display='flex'>
          <Button
            type='button'
            width='100%'
            onClick={onClose}
            variant='outline'
            color='var(--main-purple)'
            borderRadius='8px'
          >
            Отмена
          </Button>

          <Button
            type='button'
            width='100%'
            borderRadius='8px'
            bg='var(--main-purple)'
            _hover={{ bg: 'var(--hover-purple)' }}
            color='#fff'
            onClick={importTasks}
          >
            Импорт
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
});

