import { makeAutoObservable } from "mobx";
import { ICompetence } from "../../../shared";

class CompetencesState {
  competences = [] as ICompetence[];
  vacanciesFilter = 'global';

  constructor() {
    makeAutoObservable(this);
  }

  setCompetences(competences: any[]) {
    this.competences = competences;
  }

  // setFilter(newFilter: string) {
  //   this.vacanciesFilter = newFilter;
  // }

  // deleteVacancy(vacancyId: number) {
  //   this.vacancies.company = this.vacancies.company.filter((v) => v.id !== vacancyId)
  // }
}

export default new CompetencesState();
