export { CreateDepartmentPopup } from "./ui/CreateDepartmentPopup/CreateDepartmentPopup";
export { CreateGroupPopup } from "./ui/CreateGroupPopup.tsx/CreateGroupPopup";
export { getDepartments } from "./api";
import departmentsState from "./store/departmentsState";
export default departmentsState;
export { connectDepartmantWithCompany } from "./api";
export { getGroups } from "./api";
export { getDepartmentByName } from "./api";
export { getGroupByName, getGroupById } from "./api";
export { EditDepartmentPopup } from "./ui/EditDepartmentPopup/EditDepartmentPopup";
export { deleteDepartment } from "./api";
export { EditGroupPopup } from "./ui/EditGroupPopup/EditGroupPopup";
export { getCompanyGroups } from "./api";