import React from 'react';
import { CompanyCard } from '@/entities/company';
import { ICompany } from '@/shared';
import { Container } from './styles';
import { EmptyResponse } from '@/widgets';

export interface CompaniesListProps {
  companies: ICompany[];
}

export const CompaniesList: React.FC<CompaniesListProps> = ({ companies }) => {
  return (
    <Container>
        {companies.length === 0 && <EmptyResponse/>}
        {companies.map(company =>
          <CompanyCard company={company} key={company.id} />
        )}        
    </Container>
  )
}
