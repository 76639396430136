import React from 'react';

export interface ICrossAddProps {
  color: string;
}

export const CrossAdd: React.FC<ICrossAddProps> = ({ color='#FFFFFF' }) => {
  return (
    <div
      style={{ cursor: 'pointer' }}
    >
      <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_287_7168)">
          <path d="M1 6H11" stroke={color} strokeWidth="2" strokeLinecap="round"/>
          <path d="M6 11V1" stroke={color} strokeWidth="2" strokeLinecap="round"/>
        </g>
        <defs>
          <clipPath id="clip0_287_7168">
            <rect width="12" height="12" fill="white"/>
          </clipPath>
        </defs>
      </svg>
    </div>
  )
}
