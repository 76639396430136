import { makeAutoObservable } from "mobx";

type TCompetenceAnalyticsType = 'current' | 'inProgress';
type TFilter = 'all' | 'groups' | 'departments' | 'employee' | 'team';

class CompetenceAnalyticsState {
  competenceAnalyticsType: TCompetenceAnalyticsType = 'current';
  filter: TFilter = 'all';

  current: any = {
    competences: [],
    interests: [],
  }
  progress: any = {
    competences: [],
    interests: [],
  }

  constructor() {
    makeAutoObservable(this);
  }

  setCompetenceAnalyticType(value: TCompetenceAnalyticsType) {
    this.competenceAnalyticsType = value;
  }

  setFilter(value: TFilter) {
    this.filter = value;
  }

  setCurrentCompetences(competences: any) {
    this.current.competences = competences
  }
  setCurrentInterests(interests: any) {
    this.current.interests = interests
  }

  setProgressCompetences(competences: any) {
    this.progress.competences = competences
  }
  setProgressInterests(interests: any) {
    this.progress.interests = interests
  }

  // calculateLevelName(singleCompetence: any) {
  //   let max_values: any = [];
  //   let level: string = '';

  //   const enRusMap: any = {
  //     'Beginner': 'Слабый',
  //     'Intermediate': 'Уверенный',
  //     'Master': 'Сильный',
  //   };

  //   for (const levelName of Object.keys(singleCompetence.levels)) {
  //     if (max_values.length > 0) {
  //       if (singleCompetence.levels[levelName] > max_values[0].count) {
  //         max_values = [{name: levelName, count: singleCompetence.levels[levelName]}];
  //       }
  //       else if (singleCompetence.levels[levelName] === max_values[0].count) {
  //         max_values.push({name: levelName, count: singleCompetence.levels[levelName]});
  //       }
  //     }
  //     else {
  //       max_values = [{name: levelName, count: singleCompetence.levels[levelName]}];
  //     }
  //   }
  //   switch (max_values.length) {
  //     case 1:
  //       level = `${enRusMap[max_values[0].name]}`;
  //     case 2:
  //       level = `${max_values.map((level: any) => enRusMap[level.name]).join(' / ')}`
  //   }

  //   return level;
  // }

  clearCompetenceAnalytics() {
    this.current = {
      competences: [],
      interests: [],
    }

    this.progress = {
      competences: [],
      interests: [],
    }
  }
}

export default new CompetenceAnalyticsState();
