import React from 'react';
import {
  Stack,
  Flex,
  Image,
  Box,
} from '@chakra-ui/react';
import RightIcon from '@/assets/icons/check.svg';
import WrongIcon from '@/assets/icons/stop.svg'
import {
  WrongStyles,
  RightStyles,
} from './styles';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css'

export interface IMultipleChoiceResultProps {
  info: any;
}

export interface IAnswerProps {
  type: 'right' | 'wrong' | 'miss' | 'empty';
  isRight: boolean;
}

export const Answer: React.FC<IAnswerProps> = ({ type }) => {
  const correctColor = 'rgb(17, 160, 22)';
  const incorrectColor = 'rgb(248, 60, 60)';

  const styles = {
    width: 16,
    height: 16,
    borderRadius: 2,
    border: `2px solid var(--main-purple)`,
    backgroundColor: 'inherit',
  };

  if (type === 'right') {
    styles.border = `2px solid ${correctColor}`;
    styles.backgroundColor = correctColor;
  } else if (type === 'wrong') {
    styles.border = `2px solid ${incorrectColor}`;
    styles.backgroundColor = incorrectColor;
  } else if (type === 'miss') {
    styles.border = `2px solid ${correctColor}`;
  }

  return (
    <div style={{
      ...styles
    }}>
    </div>
  )
}

export const MultipleChoiceResult: React.FC<IMultipleChoiceResultProps> = ({ info }) => {
  const answers = info.answers;
  const title = info.question.text;
  const isRight = info.good;
  const image = info.question.images.length > 0 ? info.question.images[0].image : null;

  return (
    <Stack
      position="relative"
      w="full"
    >
      <Stack spacing={4}>
        <Flex alignItems="center" gap={2}>
          {isRight ?
            <RightStyles>
              <RightIcon color="rgb(17, 160, 22)" />
            </RightStyles> :
            <WrongStyles>
              <WrongIcon color="rgba(248, 60, 60)" />
            </WrongStyles>
          }
          <h5>{title}</h5>
        </Flex>

        {image &&
          <Flex my={2} w="50%" justify="center">
            <Zoom>
              <Image
                borderRadius="md"
                src={image}
                alt="Картинка к вопросу"
              />
            </Zoom>
          </Flex>
        }

        <Stack spacing={4}>
          {answers.map((answer: any) => {
            const type = answer.status;
              return (
                <Flex key={answer.answer.id} alignItems="center" gap={2}>
                  <Answer type={type} isRight={isRight} />
                  {answer.answer?.image ?
                    <Flex my={2} w="50%" justify="center">
                      <Zoom>
                        <Image borderRadius="md"  src={answer.answer?.image} alt="Картинка к вопросу" />
                      </Zoom>
                    </Flex> :
                    <Box>{answer.answer.text}</Box>
                  }
                </Flex>
              )
            }
          )}

        </Stack>
      </Stack>
    </Stack>
  );
}
