import styled from "styled-components";

export const Title = styled.h2`
  font-size: 48px;
  line-height: 56px;
  font-weight: 600;
  text-align: left;
`;

export const Content = styled.div`
  position: relative;
  display: flex;
  width: 100%;
`;

export const Vertical = styled.div`
  position: sticky;
  top: 0;
  left: 0.7px;
  z-index: 1000;
  width: 2px;
  height: 100vh;
  background: var(--main-purple);
`;

export const Scheme = styled.div`
  width: 100%;
`;

export const Departments = styled.div`
  display: flex;
  flex-direction: column;
`; 

export const Wrapper = styled.div`
  display: flex;
  margin-top: 40px;
`;

export const Divider = styled.div`
  width: 100%;
  height: 30px;
  border-bottom: 2px solid var(--main-purple);
  border-bottom-left-radius: 16px 16px;
`;
