import { makeAutoObservable } from "mobx";
import { IUser } from "@/shared";

class EmployeesState {
  employees: IUser[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  setEmployees(employees: IUser[]) {
    this.employees = employees;
  }
}

export default new EmployeesState();
