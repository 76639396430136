import React from 'react';
import { Skill } from '@/shared';
import { Container, More } from './styles';
import { Tooltip } from '@chakra-ui/react';

export type TSkill = {
    id: number;
    name: string;
}

export interface ISkillsListProps {
    skills: TSkill[];
}

export const SkillsList: React.FC<ISkillsListProps> = ({ skills }) => {
  const more = skills?.length - 6;

  return (
    <Container>
        {skills?.length === 0 && <>На данный момент список пуст</>}
        {skills && skills.map((skill, index) =>
          <>
            {index < 6 &&
              <Skill title={skill.name} key={skill.id} />
            }
          </>
        )}
        {more > 0 &&
          <Tooltip
            hasArrow
            bg='var(--main-white)'
            padding='8px'
            borderRadius={8}
            label={
              <Container>
                {skills && skills.map((skill, index) =>
                  <>
                    {index >= 6 &&
                      <Skill title={skill.name} key={skill.id} />
                    }
                  </>
                )}
              </Container>
            }
          >
            <More>Еще {more}</More>
          </Tooltip>
        }
    </Container>
  )
}
