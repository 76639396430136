import React from 'react';
import Drawer from '@mui/material/Drawer';
import {
  Container,
  DrawerHeaderStyles,
} from './styles';
import {
  CloseButton,
  Divider,
} from '@chakra-ui/react';
import hrTestCreationDrawerState from '@/entities/tests/ui/AddTestDrawerBtn/store/hrTestCreationDrawerState';
import { observer } from 'mobx-react-lite';
import { TestCreationForm } from '@/widgets';

export const TestCreationDrawer = observer(() => {
  const handleDrawerClose = () => {
    hrTestCreationDrawerState.closeDrawer();
  };

  return (
    <Drawer
      sx={{
        width: hrTestCreationDrawerState.width,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: hrTestCreationDrawerState.width,
          boxSizing: 'border-box',
        },
      }}
      variant="persistent"
      anchor="right"
      open={hrTestCreationDrawerState.open}
    >
      <Container>
        <DrawerHeaderStyles>
          <h3>Создание теста</h3>
          <CloseButton
            size='lg'
            onClick={handleDrawerClose}
          />
        </DrawerHeaderStyles>
      </Container>

      <Divider />

      <TestCreationForm />
    </Drawer>
  );
});

