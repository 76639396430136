import React from 'react';
import { Button } from '@chakra-ui/react';
import AddIcon from '@/assets/icons/add.svg';
import hrTestCreationDrawerState from './store/hrTestCreationDrawerState';
import testInfoDrawerState from '@/widgets/TestsList/store/testInfoDrawerState';
import testEditDrawerState from '@/widgets/TestsList/store/testEditDrawerState';

import { observer } from 'mobx-react-lite';

export const AddTestDrawerBtn: React.FC = observer(() => {
  const handleDrawerOpen = () => {
    hrTestCreationDrawerState.openDrawer();
    testInfoDrawerState.closeDrawer();
    testEditDrawerState.closeDrawer();
  };

  return (
    <Button
      borderRadius='8px'
      w="160px"
      bg='var(--main-purple)'
      _hover={{ bg: 'var(--hover-purple)' }}
      leftIcon={<AddIcon color='#fff' />} 
      onClick={handleDrawerOpen}
    >
      <h6 style={{ color: '#fff' }}>Создать тест</h6>
    </Button>
  );
});
