import React from 'react';
import {
  ProjectContainer,
  InfoText
} from './styles';
import { ProjectDrawer } from '@/widgets';
import { useDisclosure } from '@chakra-ui/react';
import { num_word } from '@/shared';
import projectState from '../ProjectDrawer/store/projectState';

interface ProjectProps {
  project: any;
}

export const Project: React.FC<ProjectProps> = ({ project }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  function handleOpen() {
    projectState.setSelectedProject(project.project);
    onOpen();
  }

  return (
    <>
      <ProjectContainer onClick={handleOpen}>
        <h5>{project.title}</h5>
        <InfoText>{project?.project?.tasks?.length} {num_word(project?.project?.tasks?.length, ['задача', 'задачи', 'задач'])}</InfoText>
      </ProjectContainer>
      <ProjectDrawer isOpen={isOpen} onClose={onClose}/>
    </>
  )
}
