import React, { useState, useEffect } from 'react';
import {
  Button,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Flex,
} from '@chakra-ui/react';
import workExperienceState from '../store/workExperienceState';
import { SubmitHandler, useForm, Controller } from 'react-hook-form';
import { observer } from 'mobx-react-lite';
import dayjs from 'dayjs';

import { DatePicker, ConfigProvider } from 'antd';
const { RangePicker } = DatePicker;

import { Card } from '@/shared';

interface IFormFields {
  position: string;
  description: string;
  deadline: Date[];
}

export const FormField: React.FC = observer(() => {
  const { 
    control,
    register,
    setValue,
    handleSubmit,
    formState: { errors, isSubmitting } 
  } = useForm<IFormFields>();

  const [position, setPosition] = useState(workExperienceState.selectedExp.position);
  const [company, setCompany] = useState(workExperienceState.selectedExp.company);
  const [city, setCity] = useState(workExperienceState.selectedExp.city);

  const [expDates, setExpDates] = useState([]);
  const [expId, setExpId] = useState(workExperienceState.selectedExp.id);

  const onSubmit: SubmitHandler<IFormFields> = (data) => {
    saveExp();
  };

  function saveExp() {
    let flag = true;

    if (position && company && city && expDates && expDates.length > 0) {
      const newExp = {
        id: expId,
        position: position,
        company_name: company,
        company_city: city,
        start_date: expDates[0].format('DD.MM.YYYY'),
        end_date: expDates[1].format('DD.MM.YYYY'),
      };

      for (let i = 0; i < workExperienceState.editedExperience.length; i += 1) {
        if (expId === workExperienceState.editedExperience[i].id) {
          const updatedExperience = workExperienceState.editedExperience.slice();
          updatedExperience.splice(i, 1, newExp);
          workExperienceState.setEditedExperience(updatedExperience);
          flag = false;
        }
      }

      if (flag) {
        workExperienceState.setEditedExperience([...workExperienceState.editedExperience, newExp]);
      }

      workExperienceState.setIsEditing(false);
    }
  }

  useEffect(() => {
    setPosition(workExperienceState.selectedExp.position);
    setCompany(workExperienceState.selectedExp.company_name);
    setCity(workExperienceState.selectedExp.company_city);

    const start_date = dayjs(workExperienceState.selectedExp.start_date, ['DD.MM.YYYY', 'DD.MM.YY']);
    const end_date = dayjs(workExperienceState.selectedExp.end_date, ['DD.MM.YYYY', 'DD.MM.YY']);

    if (workExperienceState.selectedExp.start_date &&
      workExperienceState.selectedExp.end_date) {
      setExpDates([start_date, end_date]);
    } else {
      setExpDates([]);
    }

    setExpId(workExperienceState.selectedExp.id);
  }, [workExperienceState.selectedExp.id]);
  
  function handleExpDatesChange(dates: any) {
    setExpDates(dates);
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Card>
        <FormControl isRequired>
          <FormLabel>Должность</FormLabel>
          <Input
            id='position'
            placeholder='Должность'
            type='text'
            size='sm'
            variant='flushed'
            value={position}
            onChange={(e) =>  {setPosition(e.target.value)}}
          />
          <FormErrorMessage>This field is required</FormErrorMessage>
        </FormControl>

        <FormControl isRequired>
          <FormLabel>Компания</FormLabel>
          <Input
            id='company'
            placeholder='Компания'
            type='text'
            size='sm'
            variant='flushed'
            value={company}
            onChange={(e) =>  {setCompany(e.target.value)}}
          />
          <FormErrorMessage>This field is required</FormErrorMessage>
        </FormControl>

        <FormControl isRequired>
          <FormLabel>Город</FormLabel>
          <Input
            id='city'
            placeholder='Город'
            type='text'
            size='sm'
            variant='flushed'
            value={city}
            onChange={(e) =>  {setCity(e.target.value)}}
          />
          <FormErrorMessage>This field is required</FormErrorMessage>
        </FormControl>

        <FormControl isRequired>
          <FormLabel>Дата</FormLabel>
          {/*
            By default, MUI Drawer component has a z-index of 1200
            Source: https://mui.com/material-ui/customization/z-index/
          */}
          <ConfigProvider
            theme={{
              components: {
                DatePicker: {
                  zIndexPopup: 2000,
                },
              },
            }}
          >
            {(expDates && expDates.length > 0) ?
              <RangePicker
                defaultPickerValue={[expDates[0], expDates[1]]}
                defaultValue={[expDates[0], expDates[1]]}
                value={[expDates[0], expDates[1]]}
                style={{ width: '100%', paddingInline: 0 }}
                variant="borderless"
                onChange={handleExpDatesChange}
                format="DD/MM/YY"
              /> :
              <RangePicker
                style={{ width: '100%', paddingInline: 0 }}
                variant="borderless"
                onChange={handleExpDatesChange}
                format="DD/MM/YY"
              />
            }
          </ConfigProvider>

          <FormErrorMessage>This field is required</FormErrorMessage>
        </FormControl>
        <Flex justifyContent="end" gap={4}>
          <Button
            type='button'
            variant='outline'
            color='var(--main-purple)'
            borderRadius='8px'
            onClick={() => {workExperienceState.setIsEditing(false)}}
          >
            Отмена
          </Button>

          <Button
            type='submit'
            borderRadius='8px'
            bg='var(--main-purple)'
            _hover={{ bg: 'var(--hover-purple)' }}
            color='#fff'
          >
            Сохранить
          </Button>
        </Flex>
      </Card>
    </form>
  );
});

