import React, { useState, useEffect } from 'react';
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Container,
  Heading,
  Button,
  HStack,
  VStack,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Textarea,
  Box,
} from '@chakra-ui/react';
import { PopoverButton } from './styles';
import AddIcon from '@/assets/icons/add.svg';
import {
  OpenEndedQuestion,
  SingleChoiceQuestion,
  MultipleChoiceQuestion,
} from '@/features/tests';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { DatePicker, ConfigProvider } from 'antd';
import { observer } from 'mobx-react-lite';
import testCreationState from './store/testCreationState';
import { createTest } from '@/features/tests';
import { dateConvert } from '@/shared';
import { IQuestion } from '@/shared';
import testsState from '@/pages/DashboardHRTests/store/testsState';
import dayjs from 'dayjs';

const { RangePicker } = DatePicker;

type Inputs = {
  name: string;
  datepicker: any[];
  description: string;
}

export const TestCreationForm: React.FC = observer(() => {
  const {
    register,
    handleSubmit,
    control,
    reset,
    getValues,
    formState: { errors },
  } = useForm<Inputs>();

  const [dates, setDates] = useState([]);

  function handleChange(dates: any) {
    setDates(dates);
  }

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    const newTest = {
      title: data.name,
      description: data.description,
      start_date: dateConvert((data?.datepicker)?.[0]?.['$d']),
      end_date: dateConvert((data?.datepicker)?.[1]?.['$d']),
      questions: testCreationState.test.questions,
    }

    createTest(newTest)
      .then((res) => {
        testsState.addTest(res.data);
        setDates([]);
        testCreationState.reset();
        reset();
      })  
      .catch((e) => {
        console.log(e);
      })
  }

  useEffect(() => {
    if (dates && dates.length > 0) {
      const start_date = dayjs(dates[0]).format('DD.MM.YYYY');
      const end_date = dayjs(dates[1]).format('DD.MM.YYYY');
      testCreationState.setDeadline(start_date, end_date);
    }
  }, [JSON.stringify(dates)]);

  return (
    <Container maxW="full" my={4}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <VStack spacing={4} alignItems="flex-start">
          <FormControl isRequired isInvalid={getValues('name') && getValues('name').length > 128}>
            <FormLabel>Название теста</FormLabel>
            <Input
              variant="flushed"
              placeholder='Название теста'
              {...register('name', { maxLength: 128 })}
            />
            <FormErrorMessage>
              Количество символов должно быть меньше 128
            </FormErrorMessage>
          </FormControl>

          <FormControl isRequired>
            <FormLabel>Дедлайн</FormLabel>

            {/*
              By default, MUI Drawer component has a z-index of 1200
              Source: https://mui.com/material-ui/customization/z-index/
            */}
            <ConfigProvider
              theme={{
                components: {
                  DatePicker: {
                    zIndexPopup: 2000,
                  },
                },
              }}
            >
              <Controller
                control={control}
                name="datepicker"
                render = {({ field: { onChange, value } }) => {
                  if (dates && dates.length > 0) {
                    const start_date = dates[0];
                    const end_date = dates[1];
                    return (
                      <RangePicker
                        defaultPickerValue={[start_date, end_date]}
                        defaultValue={[start_date, end_date]}
                        value={[start_date, end_date]}
                        style={{ paddingInline: 0 }}
                        variant="borderless"
                        onChange={(value) => {onChange(value); handleChange(value)}}
                        format="DD/MM/YYYY"
                      />
                    );
                  } else {
                    return (
                      <RangePicker
                        style={{ paddingInline: 0 }}
                        variant="borderless"
                        onChange={(value) => {onChange(value); handleChange(value)}}
                        format="DD/MM/YYYY"
                      />
                    );
                  }
                  }}
              />
            </ConfigProvider>

            {errors.datepicker && <span>This field is required</span>}
          </FormControl>

          <FormControl>
            <FormLabel>Описание задачи</FormLabel>
            <Textarea
              {...register('description')} 
              placeholder='Описание теста'
              size='lg'
              variant='outline'
            />
            {errors.description && <span>This field is required</span>}
          </FormControl>

          <Container mt={4} maxW="3xl">
            <VStack w="full" spacing={10}>
              {testCreationState.test.questions.map((question: IQuestion) => {
                if (question.type === 'single') {
                  return (
                    <SingleChoiceQuestion key={question.id} question={question} mode="create" />
                  );
                } else if (question.type === 'multiple') {
                  return (
                    <MultipleChoiceQuestion key={question.id} question={question} mode="create" />
                  );
                } else if (question.type === 'text') {
                  return (
                    <OpenEndedQuestion key={question.id} question={question} mode="create" />
                  );
                }
              })}
            </VStack>
          </Container>

          <Heading>
            <Popover
              placement='right-start'
            >
              <PopoverTrigger>
                <Button
                  size="lg"
                  rightIcon={<AddIcon color="var(--main-purple)"/>}
                  bg="none"
                >
                  Вопросы
                </Button>
              </PopoverTrigger>

              <PopoverContent width="full">
                <PopoverBody>
                  <PopoverButton
                    type="button"
                    onClick={() => {
                      testCreationState.addQuestion({
                        id: Date.now(),
                        type: 'single',
                        text: '',
                        answers: [],
                      });
                    }}
                  >
                    Единственный выбор
                  </PopoverButton>

                  <PopoverButton
                    type="button"
                    onClick={() => {
                      testCreationState.addQuestion({
                        id: Date.now(),
                        type: 'multiple',
                        text: '',
                        answers: [],
                      });
                    }}
                  >
                      Множественный выбор
                  </PopoverButton>
                  
                  <PopoverButton
                    type="button"
                    onClick={() => {
                      testCreationState.addQuestion({
                        id: Date.now(),
                        type: 'text',
                        text: '',
                        answers: [{
                          id: Date.now(),
                          text: '',
                        }],
                      });
                    }}
                  >
                      Развернутый
                  </PopoverButton>
                </PopoverBody>
              </PopoverContent>
            </Popover>
          </Heading>


          <HStack w="full">
            <Button
              type='button'
              width='100%'
              variant='outline'
              onClick={() => {
                testCreationState.reset();
                setDates(['', '']);
                reset();
              }}
              color='var(--main-purple)'
              borderRadius='8px'
            >
              Отмена
            </Button>
            <Button
              type='submit'
              w='full'
              borderRadius='8px'
              bg='var(--main-purple)'
              _hover={{ bg: 'var(--hover-purple)' }}
              color='#fff'
            >
              Создать тест
            </Button>
          </HStack>
        </VStack>
      </form>
    </Container>
  );
});
