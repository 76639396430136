import React from 'react';
import BookIcon from '@/assets/icons/book.svg';
import HatIcon from '@/assets/icons/hat.svg';
import DiplomaIcon from '@/assets/icons/diploma.svg';
import PlanetIcon from '@/assets/icons/planet.svg';
import { Container, Content, Details, Title, Text, Buttons } from './styles';
import { Popover, PopoverTrigger, PopoverContent, PopoverBody } from '@chakra-ui/react';
import DotsIcon from '@/assets/icons/dots.svg';
import educationState from '@/widgets/Education/store/educationState';
import { observer } from 'mobx-react-lite';

export type TEducation = {
  id: number;
  organization_name: string;
  type: string;
  start_date: string;
  end_date: string;
  country: string;
  city: string;
}

export interface EducationCardProps {
  education: TEducation;
  type: 'widget' | 'popup';
}

export const EducationCard: React.FC<EducationCardProps> = observer(({ education, type }) => {
  function handleEdit() {
    educationState.setSelected(education);
    educationState.setIsFormActive(true);
  }

  function handleRemove() {
    educationState.remove(education.id);
  }

  return (
    <Container
      style={{
        border: type === 'popup' ? '1px solid var(--addable-gray)' : '',
        background: type === 'popup' ? '1px solid var(--main-lightgray)' : ''
      }}
    >
      <Content>
        {type === 'widget' && <>
          {(
            education.type === 'Среднее общее образование (9 классов)' ||
            education.type === 'Среднее полное образование (11 классоов)' 
          ) ? <BookIcon width={20} height={20}/> :
          (
            education.type === 'Среднее профессиональное образование' ||
            education.type === 'Бакалавриат' ||
            education.type === 'Специалитет' ||
            education.type === 'Магистратура'
          ) ? <HatIcon width={20} height={20}/> : 
          (
            education.type === 'Аспирантура' ||
            education.type === 'Докторантура'    
          ) ? <DiplomaIcon width={20} height={20}/> : 
            <PlanetIcon width={20} height={20}/>
          }
        </>}
        <Details>
          <Title>{education?.organization_name}</Title>
          <Text>{education?.country} • {education?.city} • {education?.type}</Text>
          <Text>{education?.start_date}-{education?.end_date}</Text>
        </Details>
      </Content>
      {type === 'popup' && 
        <Popover placement='bottom-end'>
          <PopoverTrigger>
            <button type='button'><DotsIcon color='var(--main-purple)'/></button>
          </PopoverTrigger>
          <PopoverContent width={'120px'} height={'54px'}>
            <PopoverBody>
              <Buttons>
                <button onClick={handleEdit} style={{ textAlign: 'left' }} type='button'>
                  <Text>Редактировать</Text>
                </button>
                <button onClick={handleRemove} style={{ textAlign: 'left' }} type='button'>
                  <Text style={{ color: 'var(--addable-red)' }}>Удалить</Text>
                </button>
              </Buttons>
            </PopoverBody>
          </PopoverContent>
        </Popover>
      }
    </Container>
  )
});
