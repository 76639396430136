import React, { useState, useEffect } from 'react';

import {
    GradationBar,
    Gradation,
  } from './styles';

export interface ProfileLevelStateProps {
  instance: any;
}

export const ProfileLevelState: React.FC<ProfileLevelStateProps> = (({ instance }) => {
  const [gridGradation, setGridGradation] = useState<any>({total: 0});

  function get_level_by_score(score: number, gradations: any) {
    const level: string = 'Master';

    const gradation_level_mapping: any = {
      0: 'Beginner',
      1: 'Intermediate',
      2: 'Master',
    }

    for (let i = 0; i < gradations.length; i++) {
      if (score < gradations[i]) {
        return gradation_level_mapping[i];
      }
    }

    return level;
  }

  useEffect(() => {
    let gridGradationLevelMap: any = {
      Beginner: 2,
      Intermediate: 3,
      Master: 4,
    };

    const level = get_level_by_score(instance.score, instance.indicator.gradations);

    setGridGradation(gridGradationLevelMap[level]);
  }, []);

  return (
    <GradationBar>
        <Gradation
            style={{
                gridColumn: '1 / ' + String(gridGradation),
            }}
        />
        
    </GradationBar>
      
  )
});