import { makeAutoObservable } from "mobx";
import { TEmployeeRole } from "@/shared/types/projects/types";

class CreateProjectPopupState {
  roles: TEmployeeRole[] = [];
  isEditing = false;
  selectedRole: TEmployeeRole | any = {};

  constructor() {
    makeAutoObservable(this);
  }

  setRoles(roles: TEmployeeRole[]) {
    this.roles = roles;
  }

  setIsEditing(status: boolean) {
    this.isEditing = status;
  }

  setSelectedRole(role: TEmployeeRole) {
    this.selectedRole = role;
  }

  removeRole(role_id: number) {
    this.roles = this.roles.filter((role) => role.id !== role_id);
  }
}

export default new CreateProjectPopupState();
