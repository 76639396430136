import React from 'react';
import { TestAssessmentDrawer } from '@/widgets/TestAssessmentDrawer/TestAssessmentDrawer';
import { TestDashboard } from '@/widgets/TestDashboard/TestDashboard';
import { TestsList } from '@/widgets';
import managerTestState from './store/managerTestState';
import { observer } from 'mobx-react-lite';
import { ManagerTopBar } from '@/widgets/TestsTopBar';
import { Filters } from '@/features/common';
import { TestInfoDrawer } from '@/widgets';
import testAssessmentDrawerState from '@/widgets/TestsList/store/testAssessmentDrawerState';
import testInfoDrawerState from '@/widgets/TestsList/store/testInfoDrawerState';

export const ManagerTests: React.FC = observer(() => {
  const options = [
    {
      id: 1,
      name: 'Мои тесты',
      onClick: () => {
        managerTestState.setFilter('my');
        testAssessmentDrawerState.closeDrawer();
        testInfoDrawerState.closeDrawer();
      },
    },
    {
      id: 2,
      name: 'Тесты сотрудников',
      onClick: () => {
        managerTestState.setFilter('employees');
        testAssessmentDrawerState.closeDrawer();
        testInfoDrawerState.closeDrawer();
      }
    },
  ];

  return (
    <div>
      <TestDashboard>
        <ManagerTopBar />
        <Filters options={options}/>
        {managerTestState.filter === 'my' &&
          <>
            {managerTestState.myTestsIsLoading ?
              <div>Загрузка...</div> :
              <TestsList tests={managerTestState.myTests} />
            }
          </>
        }

        {managerTestState.filter === 'employees' &&
          <>
            {managerTestState.employeesTestsIsLoading ?
              <div>Загрузка...</div> :
              <TestsList tests={managerTestState.employeesTests} />
            }
          </>
        }
        {/*
        <TestsList
          tests={
            managerTestState.filter === 'my' ? managerTestState.myTests : 
            managerTestState.filter === 'employees' ? managerTestState.employeesTests :
            []
          }
        />
        */}
      </TestDashboard>
      <TestAssessmentDrawer/>
      <TestInfoDrawer />
    </div>
  )
});
