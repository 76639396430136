import styled from "styled-components";

export const Head = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const H6 = styled.h6`
    color: var(--main-purple);
`;

export const Competences = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: flex-start;
    gap: 8px;
    flex-wrap: wrap;
`;

export const EmptyBlock = styled.div`
    color: gray;
    padding: 2px 0;
`;

export const Competence = styled.div`
    display: flex;
    flex-direction: column;
    padding: 16px;
    border-radius: 16px;
    gap: 16px;
    // box-shadow: inset 0 0 0 1px #E6E6E6;
    background-color: #FFFFFF;
`;

export const Top = styled.div`
    display: flex;
    justify-content: space-between;
    font-size: 16px;
`;

export const TopBody = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
`;

export const CompetenceName = styled.div`

`;

export const CompetenceLevel = styled.div`
    font-size: 12px;
`;

export const Line = styled.div`
    height: 1px;
    width: 100%;
    background-color: #E6E6E6;
`;

export const Indicators = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
`;

export const Indicator = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 5px;
`;

export const IndicatorDot = styled.div`
    width: 7px;
    height: 7px;
    border-radius: 10px;
    background-color: #D9DFF4;
`;

export const IndicatorHeader = styled.div`
    font-size: 13px;
    font-weight: 600;
`;

export const IndicatorLevel = styled.div`
    font-size: 12px;
    color: gray;
`;

export const IndicatorTop = styled.div`
    display: flex;
    justify-content: space-between;
`;