import styled from "styled-components";

export const Bubbles = styled.div`
  display: flex;  
  flex-wrap: wrap;
  align-content: start;
  gap: 8px;
  width: 100%;
  height: 566px;
  overflow-y: auto;
  overflow-x: hidden;
`;

export const Bubble = styled.div`
  position: relative;
  min-width: 120px;
  max-width: 120px;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--main-purple);
  border-radius: 50%;
`;

export const Title = styled.span`
  text-align: center;
  font-size: 12px;
  font-weight: 600;
  color: #fff;
`;

export const Count = styled.span`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1; 
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: var(--main-white);
  border: 1px var(--addable-gray) solid;

  font-weight: 600;
  font-size: 12px;
  line-height: 100%;
`;
