import React from 'react';
import {
  Container,
  Content,
} from './styles';

export interface AnalyticColumnProps extends React.PropsWithChildren {
  title: string;
}

export const AnalyticColumn: React.FC<AnalyticColumnProps> = ({ title, children }) => {
  return (
    <Container>
      <h5 style={{ textAlign: 'center' }}>{title}</h5>
      <Content>{children}</Content>
    </Container>
  )
}