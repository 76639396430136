import axios from 'axios';

const API = process.env.API_URL;
const access = JSON.parse(localStorage.getItem('accessToken'));
const refresh = JSON.parse(localStorage.getItem('refreshToken'));

export const getAnalyticForCompany = async (type: string, companyId: number, startDate?: string, endDate?: string) => {
  const apiType = type === 'inProgress' ? 'in-progress' : 'current';

  const res = await axios<any>({
    url: `${API}/analytics/${apiType}/?for=company&company_id=${companyId}&start_date=${startDate ? startDate : ''}&end_date=${endDate ? endDate : ''}`,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      "Access-Control-Allow-Headers": "*",
      "Authorization": `Bearer ${access}`,
    },
  })
    
  return res
} 

export const getAnalyticForGroup = async (type: string, groupId: number, startDate?: string, endDate?: string) => {
  const apiType = type === 'inProgress' ? 'in-progress' : 'current';

  const res = await axios<any>({
    url: `${API}/analytics/${apiType}/?for=group&group_id=${groupId}&start_date=${startDate ? startDate : ''}&end_date=${endDate ? endDate : ''}`,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      "Access-Control-Allow-Headers": "*",
      "Authorization": `Bearer ${access}`,
    },
  })
    
  return res
} 

export const getAnalyticForEmployee = async (type: string, employeeId: number, startDate?: string, endDate?: string) => {
  const apiType = type === 'inProgress' ? 'in-progress' : 'current';

  const res = await axios<any>({
    url: `${API}/analytics/${apiType}/?for=user&user_id=${employeeId}&start_date=${startDate ? startDate : ''}&end_date=${endDate ? endDate : ''}`,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      "Access-Control-Allow-Headers": "*",
      "Authorization": `Bearer ${access}`,
    },
  })
    
  return res
} 
