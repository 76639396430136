import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    gap: 16px;
    max-width: calc(100vw - 340px);
    overflow-x: auto;
`;

export const AddButton = styled.button`
    display: flex;
    min-width: 90px;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    padding: 8px 0;
    color: var(--main-purple);
`;
