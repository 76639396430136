import { makeAutoObservable } from "mobx";
import { ITest } from "@/shared";

export interface SortCategoriesType {
  groups: Array<{id: number, name: string}>;
  departments: Array<{id: number, name: string}>;
  employees: Array<{id: number, name: string}>;
}

class TestInfoDrawerState {
  open = false;
  test: ITest = {
    title: '',
    start_date: '',
    end_date: '',
    invited: [],
    questions: [],
  };
  mainFilter = 'statistics';
  secondaryFilter = 'all';

  sortCategories: SortCategoriesType = {
    groups: [],
    departments: [],
    employees: [],
  };

  width = 800;

  answerCount = 10;
  offset = 10;
  answers: any = [];

  constructor() {
    makeAutoObservable(this);
  }

  openDrawer() {
    this.open = true;
    this.clearSortCategories();
  }

  closeDrawer() {
    this.open = false;
    this.clearSortCategories();
    this.resetTest();
  }

  setTest(test: ITest) {
    this.test = test;
  }

  resetTest() {
    this.test = {
      title: '',
      start_date: '',
      end_date: '',
      invited: [],
      questions: [],
    };
  }

  setMainFilter(newFilter: string) {
    this.mainFilter = newFilter;
  }

  setSecondaryFilter(newFilter: string) {
    this.secondaryFilter = newFilter;
  }

  addSortCategory(type: string, category: string, id: number) {
    if (this.sortCategories[type as keyof SortCategoriesType]
        .filter((c: any) => c.id === id.toString()).length === 0) {
      this.sortCategories[type as keyof SortCategoriesType].push({name: category, id: id});
    }
  }

  removeSortCategory(type: string, category_id: number) {
    const sortedItems = this.sortCategories[type as keyof SortCategoriesType];
    for (let i = 0; i < sortedItems.length; i += 1) {
      if (sortedItems[i].id === category_id) {
        this.sortCategories[type as keyof SortCategoriesType].splice(i, 1);
      }
    }
  }

  clearSortCategories() {
    this.sortCategories = {
      groups: [],
      departments: [],
      employees: [],
    };
  }
}

export default new TestInfoDrawerState();
