export const roles = [
  {
    id: 1,
    title: 'Сотрудник',
    value: 'User',
  },
  {
    id: 1,
    title: 'HR-специалист',
    value: 'HR manager',
  },
  {
    id: 1,
    title: 'Super HR-специалист',
    value: 'Super HR manager',
  },
  {
    id: 1,
    title: 'Руководитель',
    value: 'Manager',
  },
];