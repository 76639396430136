import React, { useEffect } from 'react';
import {
  Stack,
  Box,
  Flex,
  useDisclosure,
} from '@chakra-ui/react';
import { Card, Skill } from '@/shared';
import CalendarIcon from '@/assets/icons/calendar.svg';
import { TaskDrawer } from '@/widgets/TaskDrawer/TaskDrawer';
import taskState from '@/widgets/TaskDrawer/store/taskState';
import { SkillsList } from '@/widgets';
import { useSearchParams } from 'react-router-dom';

export interface ITaskCardProps {
  task: any;
}

export const TaskCard: React.FC<ITaskCardProps> = ({ task }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const targetObjectId = Number(searchParams.get('id'));
    if (task?.id === targetObjectId) {
      taskState.setSelectedTask(task);
      onOpen();
      searchParams.delete('id');
      setSearchParams(searchParams);
    }
  }, [])

  function handleSelectTask() {
    taskState.setSelectedTask(task);
    onOpen();
  }

  return (
    <div>
      <button
        onClick={handleSelectTask}
        style={{ textAlign: 'left', width: '100%' }}
      >
        <Card>
          <Stack spacing={4}>
            <Flex gap={4}>
              <h3>{task.title}</h3>
            </Flex>

            <Box>{task.description}</Box>

            <Flex align="center" gap={2}>
              <CalendarIcon
                style={{
                  width: 20,
                  height: 20,
                }}
              />
              <Box>{task.start_date} - {task.end_date}</Box>
            </Flex>

            <Box>
              <h5>Навыки</h5>
              <Box py={2}>
                <SkillsList skills={task?.skill} />
              </Box>
            </Box>
          </Stack>
        </Card>
      </button>
      <TaskDrawer isOpen={isOpen} onClose={onClose}/>
    </div>
  );
}

