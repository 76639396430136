import React, { useState } from 'react';
import {
  Container,
  Top,
  DepartmentLeft,
  Groups,
  GroupsContent,
  Right,
  H6,
} from './styles';
import CollapseIcon from '@/assets/icons/collapse.svg';
import AddIcon from '@/assets/icons/add.svg';
import { Group } from '../Group/Group';
import {
  Button,
  useDisclosure,
} from '@chakra-ui/react';
import { CreateGroupPopup, EditDepartmentPopup  } from '@/features/department';
import EditIcon from '@/assets/icons/edit.svg';
import { deleteDepartment } from '@/features/department';
import departmentsState from '@/features/department';
import { getCompany } from '@/entities/company/api';

export interface DepartmentProps {
  department: any;
}

export const Department: React.FC<DepartmentProps> = ({ department }) => {
  const { isOpen: isCreateOpen, onOpen: onCreateOpen, onClose: onCreateClose } = useDisclosure();
  const { isOpen: isEditOpen, onOpen: onEditOpen, onClose: onEditClose } = useDisclosure();
  const [isCollapsed, setIsCollapsed] = useState(true);

  const companyId = JSON.parse(localStorage.getItem('user'))?.company[JSON.parse(localStorage.getItem('user'))?.company.length - 1];
  const userRole = JSON.parse(localStorage.getItem('user'))?.role;

  function handleGetDepartments() {
    getCompany(companyId)
      .then((res: any) => {
        departmentsState.setDepartments(res.data.group);
        console.log(res.data.group);
      })
      .catch((error) => {
        console.log(error);
      })
  }

  function handleDelete() {
    deleteDepartment(department.id)
      .then((res: any) => {
        handleGetDepartments();
      })
      .catch((error) => {
        console.log(error);
      })
  }

  return (
    <>
      <Container>
        <Top>
            <DepartmentLeft>
              <h3>{department.name}</h3>
              {/* <p>{department.count} отдела</p> */}
            </DepartmentLeft>
            <Right>
              {userRole === 'Super HR manager' &&
                <>
                  <Button
                    onClick={handleDelete}
                    border='1px var(--addable-gray) solid'
                    borderRadius='8px'
                    bg='var(--main-white)'
                    leftIcon={<EditIcon color='var(--main-purple)' />} 
                  >
                    <H6>Удалить</H6>
                  </Button>
                  <Button
                    onClick={onEditOpen}
                    border='1px var(--addable-gray) solid'
                    borderRadius='8px'
                    bg='var(--main-white)'
                    leftIcon={<EditIcon color='var(--main-purple)' />} 
                  >
                    <H6>Редактировать</H6>
                  </Button>
                </>
              }
              <button
                onClick={() => setIsCollapsed(!isCollapsed)}
              >
                <CollapseIcon
                  style={{
                    transform: isCollapsed ? '' : 'rotate(180deg)', transition: 'all .2s ease-in-out'
                  }}
                />
              </button>
            </Right>
        </Top>
        {!isCollapsed && 
          <Groups>
            <div style={{width: '100%'}}></div>
            <GroupsContent>
              <Button
                width='100%'
                borderRadius='8px'
                bg='var(--main-purple)'
                _hover={{ bg: 'var(--hover-purple)' }}
                leftIcon={<AddIcon color='#fff' />} 
                onClick={onCreateOpen}
              >
                <h6 style={{ color: '#fff' }}>Добавить отдел</h6>
              </Button>
              {department?.children?.map((child: any) => 
                <div key={child.id}>
                  {child.level === 1 &&
                    <Group group={child} key={child.id} />
                  }
                </div>
              )}
            </GroupsContent>
          </Groups>
        }
      </Container>
      <CreateGroupPopup isOpen={isCreateOpen} onClose={onCreateClose} department={department}/>
      <EditDepartmentPopup isOpen={isEditOpen} onClose={onEditClose} department={department}/>
    </>
  )
}
