import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: 100%;
    max-height: calc(100vh - 120px);
    overflow: auto;
`;
