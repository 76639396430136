import { makeAutoObservable } from 'mobx';
import { ITest } from '@/shared/types/tests/types';

class testsState {
  tests: ITest[] = [];
  currentTest: ITest;
  isLoading = true;

  departments: any[];
  groups: any[];
  employees: any[];

  constructor() {
    makeAutoObservable(this);
  }

  addTest(test: ITest) {
    this.tests.push(test);
  }

  setTests(tests: ITest[]) {
    this.tests = tests;
    this.isLoading = false;
  }

  removeTest(id: number) {
    const tests = this.tests.filter((test) => test.id !== id);
    this.tests = tests;
  }

  setCurrent(test: ITest) {
    this.currentTest = test;
  }

  setDepartments(departments: any[]) {
    this.departments = departments;
  }

  setGroups(groups: any[]) {
    this.groups = groups;
  }

  setEmployees(employees: any[]) {
    this.employees = employees;
  }
}

export default new testsState();
