import axios from 'axios';
import { IVacanci } from '@/shared';

const API = process.env.API_URL;
const access = JSON.parse(localStorage.getItem('accessToken'));
const refresh = JSON.parse(localStorage.getItem('refreshToken'));

export const companyVacancy = async (companyId: number, vacancyId: number) => {
  const res = await axios({
      url: `${API}/company-vacancies/`,
      method: 'POST',
      headers: {
          'Content-Type': 'application/json',
          "Access-Control-Allow-Headers": "*",
          "Authorization": `Bearer ${access}`,
      },
      data: {
        company: companyId,
        company_position: vacancyId,
      },
  })
  
  return res
} 

export const searchVacanci = async (term: string) => {
  const res = await axios<IVacanci[]>({
      url: `${API}/vacancies/?is_public=true&search=${term}&limit=50`,
      method: 'GET',
      headers: {
          'Content-Type': 'application/json',
          "Access-Control-Allow-Headers": "*",
          "Authorization": `Bearer ${access}`,
      },
  })
  
  return res
}

export const searchCompanyVacanci = async (term: string) => {
  const res = await axios<IVacanci[]>({
      url: `${API}/company-vacancies/?is_public=true&search=${term}&limit=50`,
      method: 'GET',
      headers: {
          'Content-Type': 'application/json',
          "Access-Control-Allow-Headers": "*",
          "Authorization": `Bearer ${access}`,
      },
  })
  
  return res
}

export const createVacancy = async (vacancy: IVacanci) => {
  const res = await axios<IVacanci>({
      url: `${API}/vacancies/`,
      method: 'POST',
      headers: {
          'Content-Type': 'application/json',
          "Access-Control-Allow-Headers": "*",
          "Authorization": `Bearer ${access}`,
      },
      data: vacancy,
  })
  
  return res
}

export const editVacancy = async (vacancy: IVacanci) => {
  const res = await axios<IVacanci>({
      url: `${API}/vacancies/${vacancy.id}/`,
      method: 'PATCH',
      headers: {
          'Content-Type': 'application/json',
          "Access-Control-Allow-Headers": "*",
          "Authorization": `Bearer ${access}`,
      },
      data: vacancy,
  })
  
  return res
} 

export const deleteVacancy = async (vacancyId: number) => {
  const res = await axios<IVacanci[]>({
      url: `${API}/vacancies/${vacancyId}/`,
      method: 'DELETE',
      headers: {
          'Content-Type': 'application/json',
          "Access-Control-Allow-Headers": "*",
          "Authorization": `Bearer ${access}`,
      },
  })
  
  return res
} 
