import React, { useEffect, useState } from 'react';
import { Popover, PopoverTrigger, PopoverContent, PopoverBody, PopoverArrow } from '@chakra-ui/react';
import Bell from '@/assets/icons/bell.svg';
import { getUnreadNotifications } from '@/features/notifications';
import {
  NotificationsButton,
  Signal,
  NotificationsList,
} from './styles';
import { NotificationCard } from '@/features/notifications';
import notificationsState from '../../store/notificationsState';
import { observer } from 'mobx-react-lite';

export const NotificationsPopover: React.FC = observer(() => {

  useEffect(() => {
    getUnreadNotifications()
      .then((res: any) => {
        notificationsState.setNotifications(res.data.unread_list);
      })
      .catch(e => {
        console.log(e);
      })
  }, [])

  return (
    <Popover placement='right'>
      <PopoverTrigger>
        <NotificationsButton>
          {notificationsState.notifications.length > 0 && <Signal/>}
          <Bell
            width={24}
            height={24}
          />
        </NotificationsButton>
      </PopoverTrigger>
      <PopoverContent width={'338px'} height={'fit-content'}>
        <PopoverArrow/>
        <PopoverBody>
          <NotificationsList>
            {notificationsState.notifications?.map((notification: any) =>
              <NotificationCard notification={notification} key={notification.id}/>
            )}
            {notificationsState.notifications?.length === 0 && <h5>Список уведомлений пуст</h5>}
          </NotificationsList>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  )
});
