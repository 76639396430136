import styled from 'styled-components';

export const Title = styled.div`
  font-weight: 600;
  max-width: calc(100% - 200px);
`;

export const Description = styled.div`
  font-size: 12px;
`;

export const PreviewInfo = styled.div`
  font-size: 10px;
  opacity: 50%;

  display: flex;
  gap: 0.5rem;
`;

export const CardHover = styled.div`
  cursor: pointer;
`;

export const PopoverButton = styled.button`
    display: block;
    width: 100%;
    height: 28px;
    font-size: 12px;
    text-align: left;
`;


export const CompletedHint = styled.button`
    background-color: rgba(17, 160, 22, 0.3);
    color: rgb(17, 160, 22);
    font-size: 10px;
    padding-inline: 0.5rem;
    padding-block: 2px;
    border-radius: 1rem;
`;


export const NotCompletedHint = styled.button`
    background-color: rgba(247, 152, 9, 0.3);
    color: rgb(247, 152, 9);
    font-size: 10px;
    padding-inline: 0.5rem;
    padding-block: 2px;
    border-radius: 1rem;
`;
