import { makeAutoObservable } from "mobx";

class HRTestCreationDrawerState {
  open = false;

  width = 800;

  constructor() {
    makeAutoObservable(this);
  }

  openDrawer() {
    this.open = true;
  }

  closeDrawer() {
    this.open = false;
  }
}

export default new HRTestCreationDrawerState();
