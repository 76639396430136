import styled from "styled-components";

export const Container = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    width: 296px;
    display: flex;
    flex-direction: column;
    gap: 8px;
`;

export const Image = styled.img`
    width: 296px;
    height: 296px;
    border-radius: 50%;
`;

export const Info = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
`;

export const Top = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const Place = styled.div`
    display: flex;
    gap: 6px;
`;

export const Section = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`;

export const Resume = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
`;

export const File = styled.div`
    display: flex;
    align-items: center;
    gap: 4px;
    padding: 4px;
    border-radius: 8px;
    background: var(--main-white);
    width: 100%;
`;

export const PopoverButton = styled.button`
    font-size: 12px;
`;
