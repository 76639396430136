import React, { useState, useEffect } from 'react';
import { Card } from '@/shared';
import { IUser } from '@/shared';
import { CourseCard, getActiveUserCourses, getCourseById } from '@/entities/course';
import coursesState from '@/entities/course/store/coursesState';
import { observer } from 'mobx-react-lite';
import { Container, Grid } from './styles';

export interface CoursesWidgetProps {
  user: IUser;
}

export const CoursesWidget: React.FC<CoursesWidgetProps> = observer(({ user }) => {
  const userId = JSON.parse(localStorage.getItem('user'))?.id;
  
  function handleGetActiveCourses() {
    getActiveUserCourses(userId)
      .then((res) => {
        res.data.forEach((el: any) => {
          getCourseById(el.course)
            .then((res) => {
              coursesState.setActiveCourses([...coursesState.activeCourses, res.data]);
            })
            .catch((e) => {
              console.log(e);
            })
        });
      })
      .catch((e) => {
        console.log(e);
      })
  }

  useEffect(() => {
    handleGetActiveCourses();
  }, [user])

  return (
    <Container>
      <h3>Активные курсы</h3>
      <Grid>
        {coursesState.activeCourses.map(course =>
          <Card key={course.id}>
            <CourseCard course={course} key={course.id}/>
          </Card>
        )}
      </Grid>
    </Container>
  )
});
