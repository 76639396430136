import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import {
  CompetenceAnalyticsFilters,
  CompetenceDropdown,
} from '@/widgets';

import { ImportTestsPopup } from '@/features/projects';

import { getEmployees, getTempEmployees } from '@/features/employee';
import { getExternalTests } from '@/features/externalTest';
import { getCompany } from '@/entities/company/api';
import { getCompanyGroups } from '@/features/department';

import competenceAnalyticsState from './store/competenceAnalyticsState';

import {
  InputGroup,
  InputLeftElement,
  Input,
  Button,
  Flex,
  Popover,
  PopoverTrigger,
  PopoverContent,
  useDisclosure,
} from '@chakra-ui/react';

import {
  TopMenu,
  TopMenuBar,
  Limit,
  TestResultsTable,
  TableBody,
  TableHeader,
  TableRow,
  TableHeaderElement,
  TableRowElement,
  GroupCompetenceAnalytics,
  EmployeeCompetenceAnalytics,
  EmptyBlock,
  HeadInfo,
  HeadInfoItem,
  HeadInfoItemDot,
  NoData,
  ExternalTestsNoData,
} from './styles';
import { ArrowOpen, CrossAdd } from '@/UI';

export const CompetenceAnalytics: React.FC = observer(() => {
  const { isOpen: importIsOpen, onOpen: importOnOpen, onClose: importOnClose } = useDisclosure();

  const [employees, setEmployees] = useState([]);
  const [tempEmployees, setTempEmployees] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [groups, setGroups] = useState([]);
  const [externalTests, setExternalTests] = useState([]);

  const user = JSON.parse(
    localStorage.getItem('user')
  );
  const company = JSON.parse(
    localStorage.getItem('company')
  );
  const companyId = user?.company[
    JSON.parse(
      localStorage.getItem('user')
    )?.company.length - 1
  ];

  function handleGetEmployees() {
    getEmployees(companyId)
      .then((response: any) => {
        setEmployees(response.data);
      })
      .catch((error) => {
        console.log(error);
      })
  }

  function handleGetTempEmployees() {
    getTempEmployees(companyId)
      .then((response: any) => {
        setTempEmployees(response.data);
      })
      .catch((error) => {
        console.log(error);
      })
  }

  function handleGetExternalTests() {
    getExternalTests()
      .then((response: any) => {
        setExternalTests(response.data);
      })
      .catch((error) => {
        console.log(error);
      })
  }

  useEffect(() => {
    handleGetEmployees();
    handleGetTempEmployees();
    handleGetExternalTests();
  }, [])

  return (
    <div>
      <TopMenu>
        <TopMenuBar>
          <div>
            <div>
              <h3>Результаты тестов</h3>
            </div>
              {company.name === 'Staffcop Demo' && 
              <Limit>
                Загружено файлов: {externalTests ? externalTests.length : 0} / 6
              </Limit>
              }
          </div>
          {company.name === 'Staffcop Demo' ? externalTests.length < 6 &&
            <Button
              borderRadius='8px'
              bg='var(--main-purple)'
              _hover={{ bg: '#2D53DA' }}
              color='#fff'
              onClick={importOnOpen}
              style={{
                width: '35px',
              }}
            >
              {/* <h6 style={{ color: '#fff' }}>Импортировать тесты</h6> */}
              <CrossAdd
                color="#FFFFFF"
              />
            </Button>
            :
            <Button
              borderRadius='8px'
              bg='var(--main-purple)'
              _hover={{ bg: '#2D53DA' }}
              color='#fff'
              onClick={importOnOpen}
              style={{
                width: '35px',
              }}
            >
              {/* <h6 style={{ color: '#fff' }}>Импортировать тесты</h6> */}
              <CrossAdd
                color="#FFFFFF"
              />
            </Button>
          }
        </TopMenuBar>
        <TestResultsTable>
          <TableHeader>
            <TableHeaderElement>
              Тест
            </TableHeaderElement>
            <TableHeaderElement>
              Результат
            </TableHeaderElement>
            <TableHeaderElement>
              ФИО
            </TableHeaderElement>
            <TableHeaderElement>
              Компетенция
            </TableHeaderElement>
          </TableHeader>
          <TableBody>
            {externalTests.length > 0 && externalTests.map((externalTest: any) => 
              <TableRow key={externalTest.id}>
                <TableRowElement
                  style={{
                    fontWeight: 'bold',
                  }}
                >
                  {externalTest.name}
                </TableRowElement>
                <TableRowElement>
                  {externalTest.score} / 100
                </TableRowElement>
                <TableRowElement>
                  {externalTest.user ? externalTest.user : externalTest.temp_user}
                </TableRowElement>
                <TableRowElement>
                  {externalTest.competence.name}
                </TableRowElement>
              </TableRow>
            )}
            {externalTests.length < 1 &&
              <ExternalTestsNoData>
                Нет данных
              </ExternalTestsNoData>
            }
          </TableBody>
        </TestResultsTable>
      </TopMenu>
      <h3>Аналитика</h3>
      <CompetenceAnalyticsFilters
        employees={employees}
        tempEmployees={tempEmployees}
        departments={departments}
        groups={groups}
      />
      {competenceAnalyticsState.filter === 'all' ? 
        <GroupCompetenceAnalytics>
          <EmptyBlock>
          </EmptyBlock>
          <HeadInfo>
            <HeadInfoItem>
              <HeadInfoItemDot 
                style={{backgroundColor: '#E0E3EF'}}
              />
              <div>
                Слабый
              </div>
            </HeadInfoItem>
            <HeadInfoItem>
              <HeadInfoItemDot 
                style={{backgroundColor: '#B7C6FF'}}
              />
              <div>
                Уверенный
              </div>
            </HeadInfoItem>
            <HeadInfoItem>
              <HeadInfoItemDot 
                style={{backgroundColor: '#6B8BFF'}}
              />
              <div>
                Сильный
              </div>
            </HeadInfoItem>
          </HeadInfo>
          {competenceAnalyticsState.current.competences.length === 0 ?
            <NoData>
              Нет данных
            </NoData>
          :
            competenceAnalyticsState.current.competences.map((competence: any) => 
              <CompetenceDropdown
                key={competence.name}
                competence={competence}
                isGroup={true}
              />
            )
          }
        </GroupCompetenceAnalytics>
      : 
        <EmployeeCompetenceAnalytics>
          <EmptyBlock>
          </EmptyBlock>
          <HeadInfo>
            <HeadInfoItem>
              <HeadInfoItemDot 
                style={{backgroundColor: '#E0E3EF'}}
              />
              <div>
                Слабый
              </div>
            </HeadInfoItem>
            <HeadInfoItem>
              <HeadInfoItemDot 
                style={{backgroundColor: '#B7C6FF'}}
              />
              <div>
                Уверенный
              </div>
            </HeadInfoItem>
            <HeadInfoItem>
              <HeadInfoItemDot 
                style={{backgroundColor: '#6B8BFF'}}
              />
              <div>
                Сильный
              </div>
            </HeadInfoItem>
          </HeadInfo>
          {competenceAnalyticsState.current.competences.length === 0 ?
            <NoData>
              Нет данных
            </NoData>
          :
            competenceAnalyticsState.current.competences.map((competence: any) => 
              <CompetenceDropdown
                key={competence.name}
                competence={competence}
                isGroup={false}
              />
            )
          }
        </EmployeeCompetenceAnalytics>
      }
      <ImportTestsPopup isOpen={importIsOpen} onClose={importOnClose} />
    </div>
  )
});
