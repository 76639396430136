import React, { useState, useEffect } from 'react';
import { getTestResults } from '@/entities/tests/api';
import {
  Button,
  TableContainer,
  Tbody,
  Thead,
  Table,
  Th,
  Tr,
  Td,
} from '@chakra-ui/react';
import dayjs from 'dayjs';

export interface ITestResultsProps {
  test_id: number;
}

export const TestResults: React.FC<ITestResultsProps> = ({ test_id }) => {
  const [results, setResults] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isFetching, setIsFetching] = useState(false);
  const [totalCount, setTotalCount] = useState(0);

  const increment = 10;
  let [count, setCount] = useState(increment);

  useEffect(() => {
    handleGetResults().then(() => {
      setIsFetching(false);
    });
  }, [count])

  async function handleGetResults() {
    return getTestResults(test_id, count - increment, increment)
      .then((res: any) => {
        setResults([...results, ...res.data.results]);
        setTotalCount(res.data.count);

        if (res.data.results[0]) {
          const questions = res.data.results[0].row.map((col: any) => {
            return { id: col.question.id, text: col.question.text };
          });
          setQuestions(questions);
        }

        setIsLoading(false)
      })
      .catch((error) => {
        console.log(error);
      })
    }

  if (isLoading) {
    return <div>Загрузка...</div>
  }

  return (
    <>
      <TableContainer>
        <Table size='sm'>
            <Thead>
              <Tr>
                <Th>Дата заполнения</Th>
                <Th>Имя пользователя</Th>

                {questions && questions.length > 0 && questions.map((q: any) => {
                  return (
                    <Th key={q.id}>{q.text}</Th>
                  );
                })}
              </Tr>
            </Thead>
            <Tbody>
              {results && results.map((row) => {
                const username = `${row.user.patronymic} ${row.user.first_name} ${row.user.last_name}`;
                return (
                  <Tr key={row.id}>
                    <Td>{dayjs(row.finished_at, 'DD.MM.YYYY HH:mm').format('DD/MM/YYYY')}</Td>
                    <Td>{username}</Td>
                    {row.row.map((col: any) => {
                      const answers = col.column
                      .map((i: any) => i.answer?.image ? 'картинка' : i.answer.text)
                      .join(', ');
                      return (
                        <Td key={col.id}>{answers ? answers : 'пусто'}</Td>
                      );
                    })}
                  </Tr>
                );
              })}
            </Tbody>
        </Table>
      </TableContainer>
      {(totalCount > count) &&
        <Button
          my={4}
          disabled={isFetching}
          onClick={() => {
            setIsFetching(true);
            setCount(count + increment);
          }}
        >
        {isFetching && <>Загрузка...</>}
        {!isFetching &&
          <>
            Показать еще {results &&
                (totalCount > count + increment)
                ? increment
                : (totalCount - count)
              }
          </>
        }
        </Button>
      }
    </>
  );
}

