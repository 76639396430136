import React, { useState, useEffect } from 'react';
import {
  Button,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  InputGroup,
  Box,
  Stack,
  Flex,
} from '@chakra-ui/react';
import {
  SkillsWrapper,
  SkillList,
  SkillButton,
} from './styles';
import { Skill } from '@/shared';
import editProjectPopupState from '@/features/projects/ui/EditProjectPopup/store/EditProjectPopupState';
import { DatePicker, ConfigProvider } from 'antd';
const { RangePicker } = DatePicker;
import { useDebounce } from '@/shared';
import { searchSkills } from '@/features/employee';
import { observer } from 'mobx-react-lite';
import dayjs from 'dayjs';

import { Card } from '@/shared';

export const RoleEditing: React.FC = observer(() => {
  const [role, setRole] = useState(editProjectPopupState.selectedRole.title);

  const [roleDates, setRoleDates] = useState([]);
  const [roleId, setRoleId] = useState(editProjectPopupState.selectedRole.id);

  const [skills, setSkills] = useState(editProjectPopupState.selectedRole.skill);
  const [searchSoftTerm, setSearchSoftTerm] = useState('');
  const [searchHardTerm, setSearchHardTerm] = useState('');
  const [softSkills, setSoftSkills] = useState([]);
  const [hardSkills, setHardSkills] = useState([]);

  const debouncedSearchSoftTerm = useDebounce(searchSoftTerm, 500);
  const debouncedSearchHardTerm = useDebounce(searchHardTerm, 500);

  useEffect(() => {
    if (searchHardTerm) {
      handleHardSearch('Hard');
    }
  }, [debouncedSearchHardTerm])

  useEffect(() => {
    if (searchSoftTerm) {
      handleSoftSearch('Soft');
    }
  }, [debouncedSearchSoftTerm])

  function handleHardSearch(type: string) {
    searchSkills(debouncedSearchHardTerm, type)
    .then((res: any) => {
      setHardSkills(res.data);
    })
    .catch((error) => {
      console.log(error);
    })
  }

  function handleSoftSearch(type: string) {
    searchSkills(debouncedSearchSoftTerm, type)
    .then((res: any) => {
      setSoftSkills(res.data);
    })
    .catch((error) => {
      console.log(error);
    })
  }

  function addSkill(addedSkill: any) {
    let flag = true;
    skills.forEach((s: any) => {
      if (s.name === addedSkill.name) {
        flag = false;
      } 
    })
    if (flag) {
      setSkills([...skills, addedSkill]);
    }
  } 

  function removeSkill(removedSkill: any) {
    const updatedSkills = skills.filter((skill: any) => {
      return skill?.name !== removedSkill?.name;
    })
    setSkills(updatedSkills);
  }

  function handleRoleDatesChange(dates: any) {
    setRoleDates(dates);
  }

  function saveRole() {
    let flag = true;

    if (role && skills.length > 0 && roleDates && roleDates.length > 0) {
      const newRole = {
        id: roleId,
        title: role,
        start_date: roleDates[0].format('DD.MM.YYYY'),
        end_date: roleDates[1].format('DD.MM.YYYY'),
        skill: skills,
      };

      for (let i = 0; i < editProjectPopupState.roles.length; i += 1) {
        if (roleId === editProjectPopupState.roles[i].id) {
          const updatedRoles = editProjectPopupState.roles.slice();
          updatedRoles.splice(i, 1, newRole);
          editProjectPopupState.setRoles(updatedRoles);
          flag = false;
          break;
        }
      }

      if (flag) {
        editProjectPopupState.setRoles([...editProjectPopupState.roles, newRole]);
      }

      editProjectPopupState.setIsEditing(false);
    }
  }

  useEffect(() => {
    setRole(editProjectPopupState.selectedRole.title);
    const start_date = dayjs(editProjectPopupState.selectedRole.start_date, 'DD.MM.YYYY');
    const end_date = dayjs(editProjectPopupState.selectedRole.end_date, 'DD.MM.YYYY');

    if (editProjectPopupState.selectedRole.start_date &&
      editProjectPopupState.selectedRole.end_date) {
      setRoleDates([start_date, end_date]);
    } else {
      setRoleDates([]);
    }
    setRoleId(editProjectPopupState.selectedRole.id);

    setSkills(editProjectPopupState.selectedRole.skill);
    setSearchSoftTerm('');
    setSearchHardTerm('');
    setSoftSkills([]);
    setHardSkills([]);
  }, [editProjectPopupState.selectedRole.id]);
  
  return (
    <Card>
      <FormControl>
        <FormLabel>Название роли</FormLabel>
        <Input
          id='role'
          placeholder='Название роли'
          type='text'
          size='sm'
          variant='flushed'
          value={role}
          onChange={(e) =>  {setRole(e.target.value)}}
        />
        <FormErrorMessage>This field is required</FormErrorMessage>
      </FormControl>

      <FormControl>
        <FormLabel>Hard skills</FormLabel>
        <InputGroup>
          <Input
            value={searchHardTerm}
            onChange={e => setSearchHardTerm(e.target.value)}
            color='messenger'
            height={'28px'}
            variant='flushed'
            placeholder='Введите значение'
          />
        </InputGroup>
        <Stack mt={4}>
          {searchHardTerm &&
            <SkillsWrapper>
              <SkillList>
              {hardSkills.map((skill: any, index: number) =>
                <Skill title={skill?.name} key={Date.now() + index}>
                  <SkillButton type='button' onClick={() => addSkill(skill)}>+</SkillButton>
                </Skill>
              )}
              </SkillList>
              {hardSkills.length === 0 &&
                  <Skill title={'Новый'}>
                    <SkillButton type='button' onClick={() => {
                      addSkill({
                        id: Date.now(),
                        name: searchHardTerm,
                        type: "Hard"
                      })
                      setSearchHardTerm('')
                    }}>+</SkillButton>
                  </Skill>
                }
            </SkillsWrapper>
          }
        </Stack>
        <FormErrorMessage>This field is required</FormErrorMessage>
      </FormControl>

      <FormControl>
        <FormLabel>Soft skills</FormLabel>
        <InputGroup>
          <Input
            value={searchSoftTerm}
            onChange={e => setSearchSoftTerm(e.target.value)}
            color='messenger'
            height={'28px'}
            variant='flushed'
            placeholder='Введите значение'
          />
        </InputGroup>
        <Stack mt={4}>
          {searchSoftTerm &&
            <SkillsWrapper>
              <SkillList>
              {softSkills.map((skill: any, index: number) =>
                <Skill title={skill?.name} key={Date.now() + index}>
                  <SkillButton type='button' onClick={() => addSkill(skill)}>+</SkillButton>
                </Skill>
              )}
              </SkillList>
              {softSkills.length === 0 &&
                  <Skill title={'Новый'}>
                    <SkillButton type='button' onClick={() => {
                      addSkill({
                        id: Date.now(),
                        name: searchSoftTerm,
                        type: "Soft"
                      })
                      setSearchSoftTerm('')
                    }}>+</SkillButton>
                  </Skill>
                }
            </SkillsWrapper>
          }
        </Stack>
        <FormErrorMessage>This field is required</FormErrorMessage>
      </FormControl>

      <SkillsWrapper>
        <SkillList>
          {skills && skills.map((skill: any) => 
            <Skill title={skill.name} key={skill.id}>
              <SkillButton type='button' onClick={() => removeSkill(skill)}>-</SkillButton>
            </Skill>
          )}
        </SkillList>
      </SkillsWrapper>

      <div></div>

      <FormControl>
        <FormLabel>Сроки роли</FormLabel>
        {/*
          By default, MUI Drawer component has a z-index of 1200
          Source: https://mui.com/material-ui/customization/z-index/
        */}
        <ConfigProvider
          theme={{
            components: {
              DatePicker: {
                zIndexPopup: 2000,
              },
            },
          }}
        >
          {(roleDates && roleDates.length > 0) ?
            <RangePicker
              defaultPickerValue={[roleDates[0], roleDates[1]]}
              defaultValue={[roleDates[0], roleDates[1]]}
              value={[roleDates[0], roleDates[1]]}
              style={{ width: '100%', paddingInline: 0 }}
              variant="borderless"
              onChange={handleRoleDatesChange}
            /> :
            <RangePicker
              style={{ width: '100%', paddingInline: 0 }}
              variant="borderless"
              onChange={handleRoleDatesChange}
            />
          }
        </ConfigProvider>

        <FormErrorMessage>This field is required</FormErrorMessage>
      </FormControl>
      <Flex justifyContent="end" gap={4}>
        <Button
          type='button'
          variant='outline'
          color='var(--main-purple)'
          borderRadius='8px'
          onClick={() => {editProjectPopupState.setIsEditing(false)}}
        >
          Отмена
        </Button>

        <Button
          type='button'
          borderRadius='8px'
          bg='var(--main-purple)'
          _hover={{ bg: 'var(--hover-purple)' }}
          color='#fff'
          onClick={saveRole}
        >
          Сохранить
        </Button>
      </Flex>
    </Card>
  );
});

