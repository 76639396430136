import axios from 'axios';
import { IUser } from '@/shared';

const API = process.env.API_URL;
const access = JSON.parse(localStorage.getItem('accessToken'));
const refresh = JSON.parse(localStorage.getItem('refreshToken'));
const userId = JSON.parse(localStorage.getItem('user'))?.id;

export const createTask = async (data: any) => {
  const res = await axios({
    url: `${API}/tasks/`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      "Access-Control-Allow-Headers": "*",
      "Authorization": `Bearer ${access}`,
    },
    data: data
  })
    
  return res
}

export const createProject = async (data: any) => {
  const res = await axios({
    url: `${API}/projects/`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      "Access-Control-Allow-Headers": "*",
      "Authorization": `Bearer ${access}`,
    },
    data: data
  })
    
  return res
}

export const addTask = async (projectId: number, tasksId: number[]) => {
  const res = await axios({
    url: `${API}/projects/${projectId}/`,
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      "Access-Control-Allow-Headers": "*",
      "Authorization": `Bearer ${access}`,
    },
    data: {
      tasks: tasksId
    }
  })
    
  return res
}

export const deleteProject = async (projectId: number) => {
  const res = await axios({
    url: `${API}/projects/${projectId}/`,
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      "Access-Control-Allow-Headers": "*",
      "Authorization": `Bearer ${access}`,
    },
    data: {
      projectId: projectId
    }
  })
    
  return res
}

export const deleteTask = async (taskId: number) => {
  const res = await axios({
    url: `${API}/tasks/${taskId}/`,
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      "Access-Control-Allow-Headers": "*",
      "Authorization": `Bearer ${access}`,
    },
    data: {
      taskId: taskId
    }
  })
    
  return res
}

export const editProject = async (projectId: number, data: any) => {
  const res = await axios({
    url: `${API}/projects/${projectId}/`,
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      "Access-Control-Allow-Headers": "*",
      "Authorization": `Bearer ${access}`,
    },
    data: data
  })
    
  return res
}

export const editTask = async (taskId: number, data: any) => {
  const res = await axios({
    url: `${API}/tasks/${taskId}/`,
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      "Access-Control-Allow-Headers": "*",
      "Authorization": `Bearer ${access}`,
    },
    data: data
  })
    
  return res
}

export const sendProjectRequest = async (projectId: number, roleId: number, senderId: number, receiverId: number) => {
  const newRequest = {
    is_active: 'true',
    project: projectId,
    expected_role: roleId,
    sender: senderId,
    receiver: receiverId,
  } 

  const res = await axios({
    url: `${API}/project-requests/`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      "Access-Control-Allow-Headers": "*",
      "Authorization": `Bearer ${access}`,
    },
    data: newRequest
  })
    
  return res
}

export const sendTaskRequest = async (taskId: number, receiverId: number) => {
  const newRequest = {
    is_active: 'true',
    task: taskId,
    sender: userId,
    receiver: receiverId,
  } 

  const res = await axios({
    url: `${API}/task-requests/`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      "Access-Control-Allow-Headers": "*",
      "Authorization": `Bearer ${access}`,
    },
    data: newRequest
  })
    
  return res
}

export const getApplicants = async (projectId: number, roleId: number) => {
  const res = await axios({
    url: `${API}/project-requests/?project__id=${projectId}&expected_role=${roleId}`,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      "Access-Control-Allow-Headers": "*",
      "Authorization": `Bearer ${access}`,
    },
  })
    
  return res
}

export const getTaskRequests = async (taskId: number) => {
  const res = await axios({
    url: `${API}/task-requests/?task__id=${taskId}&is_active=True`,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      "Access-Control-Allow-Headers": "*",
      "Authorization": `Bearer ${access}`,
    },
  })
    
  return res
}

export const roleAssign = async (roleId: number, employeeId: number) => {
  const res = await axios({
    url: `${API}/expected_roles/${roleId}/`,
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      "Access-Control-Allow-Headers": "*",
      "Authorization": `Bearer ${access}`,
    },
    data: {
      user: employeeId
    }
  })
    
  return res
}

export const createProjectReview = async (data: any) => {
  const res = await axios({
    url: `${API}/project-reviews/`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      "Access-Control-Allow-Headers": "*",
      "Authorization": `Bearer ${access}`,
    },

    data: data
  })
    
  return res
}

export const createTaskReview = async (data: any) => {
  const res = await axios({
    url: `${API}/task-reviews/`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      "Access-Control-Allow-Headers": "*",
      "Authorization": `Bearer ${access}`,
    },

    data: data
  })
    
  return res
}

export const acceptRoleRequest = async (requestId: number) => {
  const res = await axios({
    url: `${API}/project-requests/${requestId}/accept/`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      "Access-Control-Allow-Headers": "*",
      "Authorization": `Bearer ${access}`,
    },
  })
    
  return res
}

export const acceptTaskRequest = async (requestId: number) => {
  const res = await axios({
    url: `${API}/task-requests/${requestId}/accept/`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      "Access-Control-Allow-Headers": "*",
      "Authorization": `Bearer ${access}`,
    },
  })
    
  return res
}

export const cancelRoleRequest = async (requestId: number) => {
  const res = await axios({
    url: `${API}/project-requests/${requestId}/cancel/`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      "Access-Control-Allow-Headers": "*",
      "Authorization": `Bearer ${access}`,
    },
  })
    
  return res
}

export const cancelTaskRequest = async (requestId: number) => {
  const res = await axios({
    url: `${API}/task-requests/${requestId}/cancel/`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      "Access-Control-Allow-Headers": "*",
      "Authorization": `Bearer ${access}`,
    },
  })
    
  return res
}
