import React, { useEffect, useState } from 'react';
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Container,
  Heading,
  Button,
  HStack,
  VStack,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Textarea,
  Box,
} from '@chakra-ui/react';
import { PopoverButton } from './styles';
import AddIcon from '@/assets/icons/add.svg';
import {
  OpenEndedQuestion,
  SingleChoiceQuestion,
  MultipleChoiceQuestion,
} from '@/features/tests';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { DatePicker, ConfigProvider } from 'antd';
import { observer } from 'mobx-react-lite';
import testEditState from './store/testEditState';
import { IQuestion, dateConvert } from '@/shared';
import { editTest } from '@/features/tests';
import dayjs from 'dayjs';
import testEditDrawerState from '@/widgets/TestsList/store/testEditDrawerState';
import { getTest } from '@/entities/tests';
import testsState from '@/pages/DashboardHRTests/store/testsState';

const { RangePicker } = DatePicker;

type Inputs = {
  name: string;
  datepicker: any[];
  description: string;
}

export const TestEditForm: React.FC = observer(() => {
  const {
    register,
    handleSubmit,
    control,
    reset,
    getValues,
    formState: { errors },
  } = useForm<Inputs>();

  const [dates, setDates] = useState([]);

  function handleGetTest() {
    setDates([]);
    getTest(testEditState.test?.id)
      .then((res) => {
        if (testEditState.test.id === res.data.id) {
          testEditState.setTest(res.data);
          const start_date = dayjs(testEditState.test.start_date, 'DD.MM.YYYY');
          const end_date = dayjs(testEditState.test.end_date, 'DD.MM.YYYY');
          setDates([start_date, end_date]);
        }
      })
      .catch(e => {
        console.log(e);
      })
  }

  useEffect(() => {
    if (testEditState.test.id) {
      handleGetTest();
    }
  }, [testEditState.test?.id])

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    const updatedTest = {
      id: testEditState.test.id,
      title: data.name || testEditState.test.title,
      description: data.description || testEditState.test.description,
      start_date: dateConvert((data?.datepicker)?.[0]?.['$d']) || testEditState.test.start_date,
      end_date: dateConvert((data?.datepicker)?.[1]?.['$d']) || testEditState.test.end_date,
      questions: testEditState.test.questions,
    }

    editTest(updatedTest)
      .then((res) => {
        const newTests = testsState.tests.map((test) => {
          if (test.id === testEditState.test.id) {
            return res.data;
          }

          return test;
        });
        testsState.setTests(newTests);
        testEditDrawerState.closeDrawer();
      })  
      .catch((e) => {
        console.log(e);
      })
  }

  function handleChange(dates: any) {
    setDates(dates);
  }

  useEffect(() => {
    if (dates && dates.length > 0) {
      const start_date = dayjs(dates[0]).format('DD.MM.YYYY');
      const end_date = dayjs(dates[1]).format('DD.MM.YYYY');
      testEditState.setDeadline(start_date, end_date);
    }
  }, [JSON.stringify(dates)]);

  return (
    <Container maxW="full" my={4}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <VStack spacing={4} alignItems="flex-start">
          <FormControl isRequired isInvalid={getValues('name') && getValues('name').length > 128}>
            <FormLabel>Название теста</FormLabel>
            <Input
              variant="flushed"
              placeholder='Название теста'
              defaultValue={testEditState.test.title}
              {...register('name', { maxLength: 128 })}
            />
            <FormErrorMessage>
              Количество символов должно быть меньше 128
            </FormErrorMessage>
          </FormControl>

          <FormControl isRequired>
            <FormLabel>Дедлайн</FormLabel>

            {/*
              By default, MUI Drawer component has a z-index of 1200
              Source: https://mui.com/material-ui/customization/z-index/
            */}
            <ConfigProvider
              theme={{
                components: {
                  DatePicker: {
                    zIndexPopup: 2000,
                  },
                },
              }}
            >
              <Controller
                control={control}
                name="datepicker"
                render = {({ field: { onChange, value } }) => {
                  if (dates && dates.length > 0) {
                    const start_date = dates[0];
                    const end_date = dates[1];
                    return (
                      <RangePicker
                        defaultPickerValue={[start_date, end_date]}
                        defaultValue={[start_date, end_date]}
                        value={[start_date, end_date]}
                        style={{ paddingInline: 0 }}
                        variant="borderless"
                        onChange={handleChange}
                        format="DD/MM/YYYY"
                      />
                    );
                  } else {
                    return (
                      <RangePicker
                        style={{ paddingInline: 0 }}
                        variant="borderless"
                        onChange={handleChange}
                        format="DD/MM/YYYY"
                      />
                    );
                  }
                }}
              />
            </ConfigProvider>

            {errors.name && <span>This field is required</span>}
          </FormControl>

          <FormControl>
            <FormLabel>Описание задачи</FormLabel>
            <Textarea
              {...register('description')} 
              defaultValue={testEditState.test.description}
              placeholder='Описание теста'
              size='lg'
              variant='outline'
            />
            {errors.name && <span>This field is required</span>}
          </FormControl>

          <Container mt={4} maxW="3xl">
            <VStack w="full" spacing={10}>
              {testEditState.test.questions?.map((question: IQuestion) => {
                if (question.type === 'single') {
                  return (
                    <Box w="full" key={question.id}>
                      <SingleChoiceQuestion question={question} mode="edit" />
                    </Box>
                  );
                } else if (question.type === 'multiple') {
                  return (
                    <Box w="full" key={question.id}>
                      <MultipleChoiceQuestion question={question} mode="edit" />
                    </Box>
                  );
                } else if (question.type === 'text') {
                  return (
                    <Box w="full" key={question.id}>
                      <OpenEndedQuestion question={question} mode="edit" />
                    </Box>
                  );
                }
              })}
            </VStack>
          </Container>

          <Heading>
            <Popover
              placement='right-start'
            >
              <PopoverTrigger>
                <Button
                  size="lg"
                  rightIcon={<AddIcon color="var(--main-purple)"/>}
                  bg="none"
                >
                  Вопросы
                </Button>
              </PopoverTrigger>
              <PopoverContent width="full">
                <PopoverBody>
                  <PopoverButton
                    type="button"
                    onClick={() => {
                      testEditState.addQuestion({
                        id: Date.now(),
                        type: 'single',
                        text: '',
                        answers: [],
                      });
                    }}
                  >
                    Единственный выбор
                  </PopoverButton>

                  <PopoverButton
                    type="button"
                    onClick={() => {
                      testEditState.addQuestion({
                        id: Date.now(),
                        type: 'multiple',
                        text: '',
                        answers: [],
                      });
                    }}
                  >
                      Множественный выбор
                  </PopoverButton>
                  
                  <PopoverButton
                    type="button"
                    onClick={() => {
                      testEditState.addQuestion({
                        id: Date.now(),
                        type: 'text',
                        text: '',
                        answers: [{
                          id: Date.now(),
                          text: '',
                        }],
                      });
                    }}
                  >
                      Развернутый
                  </PopoverButton>
                </PopoverBody>
              </PopoverContent>
            </Popover>
          </Heading>

          <HStack w="full">
            <Button
              type='button'
              width='100%'
              variant='outline'
              onClick={() => {
                handleGetTest();
              }}
              color='var(--main-purple)'
              borderRadius='8px'
            >
              Отмена
            </Button>
            <Button
              type='submit'
              width='100%'
              borderRadius='8px'
              bg='var(--main-purple)'
              _hover={{ bg: 'var(--hover-purple)' }}
              color='#fff'
            >
              Сохранить
            </Button>
          </HStack>
        </VStack>
      </form>
    </Container>
  );
});
