import dayjs, { Dayjs } from "dayjs";
import { num_word } from '@/shared';
import duration from 'dayjs/plugin/duration';
dayjs.extend(duration);

export const get_duration = (date1: Dayjs, date2: Dayjs) => {  
  const diff = Math.abs(date1.diff(date2));

  const years = Math.round(dayjs.duration(diff).asYears());
  const months = Math.round(dayjs.duration(diff).asMonths() % 12);

  if (years < 1) {
    if (months < 1) {
      return 'Меньше месяца';
    }

    else if (months >= 1) {
      return `${months} ${num_word(months, ['месяц', 'месяца', 'месяцев'])}`;
    }
  }

  else if (years >= 1) {
    if (months < 1) {
      return `${years} ${num_word(years, ['год', 'года', 'лет'])}`;
    }

    else if (months >= 1) {
      return `${years} ${num_word(years, ['год', 'года', 'лет'])} ${months} ${num_word(months, ['месяц', 'месяца', 'месяцев'])}`;
    }
  }
}
