import { Skill } from '@/shared';
import {
  Flex,
  Button,
  Text,
  Box,
  useDisclosure
} from '@chakra-ui/react';
import React from 'react';
import { SkillsPopover } from './ProjectDrawer';
import { TEmployeeRole, TProject } from '@/shared/types/projects/types';
import CalendarIcon from '@/assets/icons/calendar.svg';
import projectState from './store/projectState';
import { observer } from 'mobx-react-lite';
import UserApplyIcon from '@/assets/icons/user-apply.svg';
import { editProject } from '@/features/projects';
import { RoleApplicantsPopup } from '@/features/projects';
import { sendProjectRequest } from '@/features/projects';
import toast, { Toaster } from 'react-hot-toast';
import { PersonLine } from '@/shared';
import { ProjectReview } from '@/features/projects';
import { CrossDelete } from '@/UI'

export interface RoleCardProps {
  role: TEmployeeRole;
  project: TProject;
}

export const RoleCard: React.FC<RoleCardProps> = observer(({ project, role }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const user = JSON.parse(localStorage.getItem('user'));

  function handleSendRequest() {
    sendProjectRequest(projectState.selectedProject.id, role.id, user?.id, projectState.selectedProject.owner.id)
      .then((res) => {
        toast.success('Ваша заявка отправлена!');
      })
      .catch((e) => {
        toast.error('Заявка уже отправлена!');
      })
  }

  function handleDeleteContractor() {
    let left_users = [];
    for (const role_to_delete of projectState.selectedProject.expected_roles) {
      if (role_to_delete.user && role_to_delete.user.id !== role.user.id) {
        left_users.push(role_to_delete.user.id);
      }
    }
    editProject(
      projectState.selectedProject.id,
      {
        owner: projectState.selectedProject.owner,
        contractors: left_users,
      }
    ).then((res) => {
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
      })
  }

  return (
    <>
      <Flex key={role.id} justifyContent="space-between">
        <Flex key={role.id} gap={2} alignItems="center">
          <Skill title={role.title} />

          <SkillsPopover role={role} />

          <Flex gap={2} alignItems="center">
            <CalendarIcon height={18} width={18} />
            <Text fontSize="sm">{role.start_date} - {role.end_date}</Text>
          </Flex>
        </Flex>

        {(user.id === projectState.selectedProject?.owner.id) ?
          <>
            {role.user === null ?
              <Button
                type='submit'
                borderRadius='8px'
                bg='var(--main-purple)'
                _hover={{ bg: 'var(--hover-purple)' }}
                color='#fff'
                onClick={onOpen}
              >
                Посмотреть заявки
              </Button> :
              <Flex alignItems="center" gap={2}>
                <ProjectReview
                  project={projectState.selectedProject}
                  person={role.user}
                />
                <button
                  onClick={handleDeleteContractor}
                >
                  <CrossDelete/>
                </button>
              </Flex>
            }
          </> :
          <>
            {role.user === null ?
              <Button
                leftIcon={<UserApplyIcon height={18} width={18} />}
                color="var(--main-purple)"
                bg="none"
                onClick={handleSendRequest}
                px={0}
                py={1}
                height="full"
              >
                Подать заявку
              </Button> :
              <ProjectReview
                project={projectState.selectedProject}
                person={role.user}
              />
            }
          </>
        }
      </Flex>
      <RoleApplicantsPopup isOpen={isOpen} onClose={onClose} role={role}/>
      <Toaster
        position="top-right"
      />
    </>
  )
});
