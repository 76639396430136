// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.d3-tip {
  background-color: var(--main-lightgray);
  padding: 5px 10px;
  border-radius: 5px;
  box-shadow: 1px 1px 1px 2px #808080;
  font-size: 1rem;
  max-width: 300px;
}

.d3-tip-title {
  font-weight: 600;
}
`, "",{"version":3,"sources":["webpack://./src/widgets/BubbleChart/style.css"],"names":[],"mappings":"AAAA;EACE,uCAAuC;EACvC,iBAAiB;EACjB,kBAAkB;EAClB,mCAAmC;EACnC,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".d3-tip {\n  background-color: var(--main-lightgray);\n  padding: 5px 10px;\n  border-radius: 5px;\n  box-shadow: 1px 1px 1px 2px #808080;\n  font-size: 1rem;\n  max-width: 300px;\n}\n\n.d3-tip-title {\n  font-weight: 600;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
