import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 14px 21px;
    gap: 10px;
`;

export const ContainerInner = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0;
    gap: 10px;
`;

export const ContainerInLine = styled.div`
    display: flex;
    gap: 10px;
`;

export const StepHeaderContainer = styled.div`
    font-size: 20px;
    font-weight: bold;
    padding: 0 20px 0 0;
`;

export const MarginBlock = styled.div`
    weight: 100%;
    height: 10px;
`;

export const Input = styled.input`
    width: 100%;
`;

export const Paragraph = styled.div`
    width: 100%;
    font-size: 14px;
    padding: 0;
    margin: 0;
`;
export const CustomUl = styled.ul`
    width: 100%;
    padding: 0 0 0 20px;
    margin: 10px 0 10px 0;
`;
export const CustomLi = styled.li`
    margin: 0 0 5px 0;
    list-style-type: circle;
`;