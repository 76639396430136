import React, { useState, useEffect } from 'react';
import {
  Stack,
  HStack,
  FormControl,
  FormLabel,
  Input,
  Button,
  CheckboxGroup,
  Checkbox,
  CloseButton,
  Flex,
  Image,
  Box,
} from '@chakra-ui/react';
import AddIcon from '@/assets/icons/add.svg';
import testCreationState from '@/widgets/TestCreationForm/store/testCreationState';
import testEditState from '@/widgets/TestEditForm/store/testEditState';
import { Question, LabelStyles } from './styles';
import { IQuestion, IAnswer } from '@/shared/types/tests/types';
import { useDebounce } from '@/shared';
import { observer } from 'mobx-react-lite';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css'

export interface IMultipleChoiceQuestionProps {
  question: IQuestion;
  mode: 'create' | 'edit';
}

export interface IAnswerCheckboxInputProps {
  oneChecked: boolean;
  question_id: number;
  answer: IAnswer;
  mode: 'create' | 'edit';
  checkAnswerMode?: () => string;
  setAnswerMode?: any;
}

const AnswerCheckboxInput: React.FC<IAnswerCheckboxInputProps> = ({ oneChecked, question_id, answer, mode, checkAnswerMode, setAnswerMode }) => {
  const [text, setText] = useState('');
  const debouncedText = useDebounce(text, 500);

  useEffect(() => {
    if (mode === 'create') {
      testCreationState.setAnswerText(question_id, answer.id, debouncedText);
    } else if (mode === 'edit') {
      testEditState.setAnswerText(question_id, answer.id, debouncedText);
    }
  }, [debouncedText]);

  return (
    <HStack>
      <Checkbox
        borderColor="var(--main-purple)"
        size="lg"
        value={answer.id.toString()}
        isRequired={oneChecked ? false : true}
      />

      <Input
        onChange={(e: any) => {setText(e.target.value)}}
        variant="flushed"
        placeholder="ответ"
        defaultValue={answer.text}
      />

      <CloseButton
        position="absolute"
        right={0}
        color="var(--main-purple)"
        onClick={() => {
          if (mode === 'create') {
            testCreationState.removeAnswer(question_id, answer.id);
          } else if (mode === 'edit') {
            testEditState.removeAnswer(question_id, answer.id);
          }
          setAnswerMode(checkAnswerMode());
        }}
      />
    </HStack>
  );
}

const AnswerCheckboxInputImage: React.FC<IAnswerCheckboxInputProps> = ({ oneChecked, question_id, answer, mode, checkAnswerMode, setAnswerMode }) => {
  const [fileContent, setFileContent] = useState<any>('');
  if (!answer?.image_uploaded_on) {
    useEffect(() => {
      const reader = new FileReader();

      reader.onload = () => {
        setFileContent(reader.result);
      };

      reader.readAsDataURL(answer?.image);
    }, []);
  }

  return (
    <HStack>
      <Checkbox
        borderColor="var(--main-purple)"
        size="lg"
        value={answer.id.toString()}
        isRequired={oneChecked ? false : true}
      />

      <Flex w="50%" align="center" gap={2}>
        <Box mt={2}>
          <Zoom>
            <Image
              borderRadius="md"
              src={answer?.image_uploaded_on ? answer?.image : fileContent}
              alt="Картинка к ответу"
            />
          </Zoom>
        </Box>

        <CloseButton
          size="lg"
          right={0}
          color="var(--main-purple)"
          onClick={() => {
            if (mode === 'create') {
              testCreationState.removeAnswer(question_id, answer.id);
            } else if (mode === 'edit') {
              testEditState.removeAnswer(question_id, answer.id);
            }
            setAnswerMode(checkAnswerMode());
          }}
        />
      </Flex>
    </HStack>
  );
}

export const MultipleChoiceQuestion: React.FC<IMultipleChoiceQuestionProps> = observer(({ question, mode }) => {
  const [title, setTitle] = useState('');
  const [atLeastOneChecked, setAtLeastOneChecked] = useState(question.answers.filter((o: any) => o.is_right).length > 0);
  const [fileContent, setFileContent] = useState<any>(
    question.images &&
    (question.images.length > 0 ? question.images[0].image : null)
  );
  const [answerMode, setAnswerMode] = useState(checkAnswerMode);

  function checkAnswerMode() {
    if (question?.answers.length === 0) {
      return 'both';
    }

    if (question?.answers.filter((ans) => ans?.image).length > 0) {
      return 'images';
    } else {
      return 'text';
    }
  }

  const debouncedTitle = useDebounce(title, 500);

  useEffect(() => {
    if (mode === 'create') {
      testCreationState.setQuestionTitle(question.id, debouncedTitle);
    } else if (mode === 'edit') {
      testEditState.setQuestionTitle(question.id, debouncedTitle);
    }
  }, [debouncedTitle]);

  function handleChoiceChange(question_id: number, choices: (string | number)[]) {
    if (mode === 'create') {
      testCreationState.setRightAnswers(question_id, choices.map((c) => c.toString()))
    } else if (mode === 'edit') {
      testEditState.setRightAnswers(question_id, choices.map((c) => c.toString()))
    }
    setAtLeastOneChecked(Boolean(choices.length));
  }

  function handleQuestionPhoto(e: any) {
    const file = e.target.files[0];

    const reader = new FileReader();
    reader.onload = () => {
      setFileContent(reader.result);

      if (mode === 'create') {
        testCreationState.setQuestionImage(question.id, {image: file});
      } else if (mode === 'edit') {
        testEditState.setQuestionImage(question.id, {image: file});
      }
    };

    reader.readAsDataURL(file);
  }

  function handleAnswerPhoto(e: any) {
    const file = e.target.files[0];

    if (mode === 'create') {
      testCreationState.addAnswer(question.id, { id: Date.now(), image: file });
    } else if (mode === 'edit') {
      testEditState.addAnswer(question.id, { id: Date.now(), image: file });
    }
    setAnswerMode(checkAnswerMode());
  }

  return (
    <Stack
      position="relative"
      w="full"
    >
      <Stack>
        <Stack spacing={4}>
          <FormControl isRequired>
            <FormLabel>
              <Question>
                Вопрос
              </Question>
            </FormLabel>

            <Input
              onChange={(e: any) => {setTitle(e.target.value)}}
              variant="flushed"
              placeholder='Вопрос с множественным ответом'
              defaultValue={question.text}
            />

            <Flex align="center" gap={2}>
              <Box mt={2}>
                {(!fileContent) &&
                  <LabelStyles
                    onClick={() => {}}
                  >
                    <Box
                      fontSize="sm"
                      fontWeight="900"
                    >
                      Фото
                    </Box>
                    <AddIcon color="var(--main-purple)"/>

                    <input
                      style={{display: 'none'}}
                      id="file-upload"
                      type="file"
                      onChange={handleQuestionPhoto}
                    />
                  </LabelStyles>
                }

                {fileContent && 
                  <Box my={4}>
                    <Zoom>
                      <Image
                        borderRadius="md"
                        src={fileContent}
                        alt="Картинка к вопросу"
                      />
                    </Zoom>
                  </Box>
                }
              </Box>

              {fileContent &&
                <CloseButton
                  size="lg"
                  right={0}
                  color="var(--main-purple)"
                  onClick={() => {
                    setFileContent('');
                    if (question.images) {
                      question.images = [];
                    }
                  }}
                />
              }
            </Flex>
          </FormControl>

          <FormControl>
            <CheckboxGroup
              onChange={(choices) => {handleChoiceChange(question.id, choices)}}
              defaultValue={mode === 'edit' && testEditState.getRightAnswerIds(question.id)}
            >
              <Stack spacing={4}>
                {question.answers.map((answer) => {
                  if (answer?.image) {
                    return (
                      <AnswerCheckboxInputImage
                        key={answer.id}
                        oneChecked={atLeastOneChecked}
                        question_id={question.id}
                        answer={answer}
                        mode={mode}
                        checkAnswerMode={checkAnswerMode}
                        setAnswerMode={setAnswerMode}
                      />
                    )
                  } else {
                    return (
                      <AnswerCheckboxInput
                        key={answer.id}
                        oneChecked={atLeastOneChecked}
                        question_id={question.id}
                        answer={answer}
                        mode={mode}
                        checkAnswerMode={checkAnswerMode}
                        setAnswerMode={setAnswerMode}
                      />
                    )
                  }
                }
                )}
              </Stack>
            </CheckboxGroup>
          </FormControl>
        </Stack>

        <HStack>
          {(answerMode === 'text' || answerMode === 'both') &&
            <Button
              size="sm"
              bg="none"
              color="var(--main-purple)"
              rightIcon={<AddIcon color="var(--main-purple)"/>}
              onClick={() => {
                const newAnswer = { id: Date.now(), text: '' };
                if (mode === 'create') {
                  testCreationState.addAnswer(question.id, newAnswer);
                } else if (mode === 'edit') {
                  testEditState.addAnswer(question.id, newAnswer);
                }
                setAnswerMode(checkAnswerMode());
              }}
            >Вариант</Button>
          }

        {(answerMode === 'images' || answerMode === 'both') &&
          <LabelStyles>
            <Box
              fontSize="sm"
              fontWeight="900"
            >
              Фото
            </Box>
            <AddIcon color="var(--main-purple)"/>

            <input
              style={{display: 'none'}}
              id="file-upload"
              type="file"
              onChange={handleAnswerPhoto}
            />
          </LabelStyles>
        }
        </HStack>
      </Stack>

      <CloseButton
        position="absolute"
        top={0}
        right={0}
        size="lg"
        onClick={() => {
          if (mode === 'create') {
            testCreationState.removeQuestion(question.id)
          } else if (mode === 'edit') {
            testEditState.removeQuestion(question.id)
          }
        }}
      />
    </Stack>
  );
});
