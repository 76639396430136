import React from 'react';
import {
  Flex,
  Box,
  Avatar,
} from '@chakra-ui/react';
import avatar from '@/assets/images/avatar.jpg';

export interface IPersonLineProps {
  image: any;
  first_name: string;
  last_name: string;
}

export const PersonLine: React.FC<IPersonLineProps> = ({ image, first_name, last_name }) => {
  return (
    <Flex
      gap={2}
      align="center"
    >
      <Avatar
        boxSize="26px"
        src={image ? image.image : avatar}
      />
      <Box>
        {last_name} {first_name}
      </Box>
    </Flex>
  )
}
