import { makeAutoObservable } from 'mobx';
import { ITest } from '@/shared/types/tests/types';

type TFilter = 'my' | 'employees';

class managerTestState {
  myTests: ITest[] = [];
  employeesTests: ITest[] = [];
  filter: TFilter = 'my';

  myTestsIsLoading = true;
  employeesTestsIsLoading = true;

  constructor() {
    makeAutoObservable(this);
  }

  setMyTests(tests: ITest[]) {
    this.myTests = tests;
    this.myTestsIsLoading = false;
  }

  setEmployeesTests(tests: ITest[]) {
    this.employeesTests = tests;
    this.employeesTestsIsLoading = false;
  }

  setFilter(value: TFilter) {
    this.filter = value;
  }
}

export default new managerTestState();
