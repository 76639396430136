import styled from "styled-components";

export const Top = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Title = styled.h3`

`;

export const CollapseButton = styled.button`
    display: flex;
    gap: 4px;
    align-items: center;
    color: var(--main-purple);
    font-size: 12px;
    font-weight: 600;
`;
