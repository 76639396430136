import React, { useState, useEffect } from 'react';
import {
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverArrow,
  PopoverTrigger,
  PopoverAnchor,
  Button,
  Flex,
  Box,
  Avatar,
  Popover,
  useDisclosure,
} from '@chakra-ui/react';
import { DimmedText } from './styles';
import StarIcon from '@/assets/icons/star.svg';
import avatar from '@/assets/images/avatar.jpg';
// @ts-ignore
import ReactStars from "react-rating-stars-component";
import { IUser, TTask, num_word } from '@/shared';
import { TaskReviewPopup } from '@/features/projects/';
import { PersonLine } from '@/shared';

export interface ITaskReviewProps {
  person: IUser;
  task: TTask;
}

export const TaskReview: React.FC<ITaskReviewProps> = ({ person, task }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: popupIsOpen, onOpen: popupOnOpen, onClose: popupOnClose } = useDisclosure();

  const [rating, setRating] = useState(person?.projects_rating);

  const user = JSON.parse(localStorage.getItem('user')); 

  const ratingDefault = {
    value: rating,
    size: 20,
    count: 5,
    emptyIcon: <StarIcon />,
    filledIcon: <StarIcon />,
    activeColor: 'var(--main-purple)',
    edit: false,
  }

  const personIsContractor = task.contractors.map((c) => c.id).includes(person.id);
  const userIsContractor = task.contractors.map((c) => c.id).includes(user.id);

  return (
    <>
      <Popover
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
      >
        <PopoverTrigger>
          <Box display="inline-block">
            <PopoverAnchor>
              <Box
                _hover={{cursor: 'pointer'}}
              >
                <PersonLine
                  image={person?.image}
                  last_name={person?.last_name}
                  first_name={person?.first_name}
                />
              </Box>
            </PopoverAnchor>
          </Box>
        </PopoverTrigger>

        <PopoverContent w="full">
          {person.id === user.id ?
            <PopoverHeader fontWeight="bold">
              Ваша оценка
            </PopoverHeader> :
            <>
              {task.owner.id === person.id ?
                <PopoverHeader fontWeight="bold">
                  Оценка руководителя
                </PopoverHeader> :
                <PopoverHeader fontWeight="bold">
                  Оценка сотрудника
                </PopoverHeader>
              }
            </>
          }


          <PopoverArrow />

          <PopoverBody>
            <Flex direction="column" alignItems="end" gap={2}>
              <Box alignSelf="start">
                  <Flex alignItems="center" alignSelf="center" gap={2}>
                    <ReactStars
                      {...ratingDefault}
                    />
                    <DimmedText>
                      {person?.count_reviews} {num_word(person?.count_reviews, ['отзыв', 'отзыва', 'отзывов'])}
                    </DimmedText>
                  </Flex>
              </Box>

              {
                ((personIsContractor && user.id === task.owner.id) ||
                (userIsContractor && person.id === task.owner.id)) &&
                !task.is_bound &&
                <Button
                  borderRadius='8px'
                  bg='var(--main-purple)'
                  _hover={{ bg: 'var(--hover-purple)' }}
                  p={2}
                  onClick={popupOnOpen}
                >
                  <h6 style={{ color: '#fff' }}>Оценить</h6>
                </Button>
              }
            </Flex>
          </PopoverBody>
        </PopoverContent>
      </Popover>
      <TaskReviewPopup person={person} task={task} isOpen={popupIsOpen} onClose={popupOnClose} />
    </>
  );
}
