import { makeAutoObservable } from "mobx";
import { IVacanci } from "@/shared";

class VacanciesState {
  vacancies = {
    all: [] as IVacanci[],
    company: [] as IVacanci[],
  };
  vacanciesFilter = 'global';

  constructor() {
    makeAutoObservable(this);
  }

  setAllVacancies(vacancies: IVacanci[]) {
    this.vacancies.all = vacancies;
  }

  setCompanyVacancies(vacancies: any[]) {
    this.vacancies.company = vacancies;
  }

  setFilter(newFilter: string) {
    this.vacanciesFilter = newFilter;
  }

  deleteVacancy(vacancyId: number) {
    this.vacancies.company = this.vacancies.company.filter((v) => v.id !== vacancyId)
  }
}

export default new VacanciesState();
