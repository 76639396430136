import axios from 'axios';
import { ICompany } from '@/shared';

const API = process.env.API_URL;
const access = JSON.parse(localStorage.getItem('accessToken'));
const refresh = JSON.parse(localStorage.getItem('refreshToken'));

/**
 * Gets competences for the whole company
 * @return {object}  Data from the server
 */
export const getCompetences = async () => {
    const response = await axios<any>({
      url: `${API}/competences/`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        "Access-Control-Allow-Headers": "*",
        "Authorization": `Bearer ${access}`,
      },
    })
  
    return response;
  }