import React, { useState, useEffect } from 'react';
import {
  Button,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Flex,
  Select,
} from '@chakra-ui/react';
import { DatePicker, ConfigProvider } from 'antd';
import { observer } from 'mobx-react-lite';
import dayjs from 'dayjs';
import educationState from '../store/educationState';
import { Card } from '@/shared';
import { TEducation } from '@/entities/user/ui/EducationCard/EducationCard';

const educationTypes = [
  {
    title: "Среднее общее образование (9 классов)",
    id: 1,
  },
  {
    title: "Среднее полное образование (11 классоов)",
    id: 2,
  },
  {
    title: "Среднее профессиональное образование",
    id: 3,
  },
  {
    title: "Бакалавриат",
    id: 4,
  },
  {
    title:  "Специалитет",
    id: 5,
  },
  {
    title: "Магистратура",
    id: 6,
  },
  {
    title: "Аспирантура",
    id: 7,
  },
  {
    title: "Докторантура",
    id: 8,
  },
];

export const EducationCreation: React.FC = observer(() => {
  const [title, setTitle] = useState('');
  const [country, setCountry] = useState('');
  const [city, setCity] = useState('');
  const [type, setType] = useState('');
  const [educationDates, setEducationDates] = useState([]);

  useEffect(() => {
    setTitle(educationState.selected.organization_name);
    setCountry(educationState.selected.country);
    setCity(educationState.selected.city);
    setType(educationState.selected.type);
    
    const start_date = dayjs(educationState.selected.start_date, ['DD.MM.YYYY', 'DD.MM.YY']);
    const end_date = dayjs(educationState.selected.end_date, ['DD.MM.YYYY', 'DD.MM.YY']);

    if (educationState.selected.start_date &&
      educationState.selected.end_date) {
      setEducationDates([start_date, end_date]);
    } else {
      setEducationDates([]);
    }
  }, [educationState.selected])

  const { RangePicker } = DatePicker;

  function handleDatesChange(dates: any) {
    setEducationDates(dates);
  }

  function handleReset() {
    educationState.setIsFormActive(false);
    educationState.setSelected({});
    setTitle('');
    setCountry('');
    setCity('');
    setType('');
    setEducationDates([]);    
  }

  function handleSave() {
    if (title && country && city && educationDates[0] && educationDates[1]) {
      const updatedEducation: TEducation = {
        id: educationState.selected.id,
        type: type,
        organization_name: title,
        country: country,
        city: city,
        start_date: educationDates[0].format('DD.MM.YYYY'),
        end_date: educationDates[1].format('DD.MM.YYYY'),
      }
  
      educationState.remove(educationState.selected.id);
      educationState.setEducations([...educationState.educations, updatedEducation]);
  
      educationState.setSelected({});
      setTitle('');
      setCountry('');
      setCity('');
      setType('');
      setEducationDates([]);          
    } 
  }
  
  return (
    <Card>
      <FormControl>
        <FormLabel>Образовательное учреждение</FormLabel>
        <Input
          id='title'
          placeholder='Образовательное учреждение'
          type='text'
          size='sm'
          variant='flushed'
          value={title}
          onChange={(e) => {setTitle(e.target.value)}}
        />
        <FormErrorMessage>This field is required</FormErrorMessage>
      </FormControl>

      <FormControl>
        <FormLabel>Страна</FormLabel>
        <Input
          id='country'
          placeholder='Страна'
          type='text'
          size='sm'
          variant='flushed'
          value={city}
          onChange={(e) => {setCity(e.target.value)}}
        />
        <FormErrorMessage>This field is required</FormErrorMessage>
      </FormControl>

      <FormControl>
        <FormLabel>Город</FormLabel>
        <Input
          id='city'
          placeholder='Город'
          type='text'
          size='sm'
          variant='flushed'
          value={country}
          onChange={(e) => {setCountry(e.target.value)}}
        />
        <FormErrorMessage>This field is required</FormErrorMessage>
      </FormControl>

      <Select
        isReadOnly={false}
        variant='flushed'
        placeholder='Выберите ступень образования'
        value={type}
        onChange={e => setType(e.target.value)}
      >
        <>{educationTypes.map(education =>
          <option key={education.id} value={education.title}>{education.title}</option> 
        )}</> 
      </Select>

      <FormControl>
        <FormLabel>Дата</FormLabel>
        {/*
          By default, MUI Drawer component has a z-index of 1200
          Source: https://mui.com/material-ui/customization/z-index/
        */}
        <ConfigProvider
          theme={{
            components: {
              DatePicker: {
                zIndexPopup: 2000,
              },
            },
          }}
        >
          {(educationDates && educationDates.length > 0) ?
            <RangePicker
              defaultPickerValue={[educationDates[0], educationDates[1]]}
              defaultValue={[educationDates[0], educationDates[1]]}
              value={[educationDates[0], educationDates[1]]}
              style={{ width: '100%', paddingInline: 0 }}
              variant="borderless"
              onChange={handleDatesChange}
            /> :
            <RangePicker
              style={{ width: '100%', paddingInline: 0 }}
              variant="borderless"
              onChange={handleDatesChange}
            />
          }
        </ConfigProvider>

        <FormErrorMessage>This field is required</FormErrorMessage>
      </FormControl>
      <Flex justifyContent="end" gap={4}>
        <Button
          type='button'
          variant='outline'
          color='var(--main-purple)'
          borderRadius='8px'
          onClick={handleReset}
        >
          Отмена
        </Button>

        <Button
          type='button'
          borderRadius='8px'
          bg='var(--main-purple)'
          _hover={{ bg: 'var(--hover-purple)' }}
          color='#fff'
          onClick={handleSave}
        >
          Сохранить
        </Button>
      </Flex>
    </Card>
  );
});
