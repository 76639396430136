import React, { useState, useEffect } from 'react';
import { CoursesTopbar, CoursesList } from '@/widgets';
import { getCourses } from '@/features/employee';
import { observer } from 'mobx-react-lite';
import coursesState from '@/entities/course/store/coursesState';
import { Filters } from '@/features/common';
import { getEmployeeById } from '@/features/employee';
import { getUserCoursesByStatus } from '@/entities/course';
import { getActiveUserCourses } from '@/entities/course';
import { getCourseById } from '@/entities/course';

export const Courses: React.FC = observer(() => {
  const [userCoursesIds, setUserCoursesIds] = useState([]);
  const [userCourses, setUserCourses] = useState([]);
  const userId = JSON.parse(localStorage.getItem('user'))?.id;

  const querySearch = window.location.search.substr(1).split('=')[1];

  function handleGetAllCourses() {
    if (!querySearch) {
      getCourses()
        .then((res: any) => {
          coursesState.setCourses(res.data);
        })
        .catch((error) => {
          console.log(error);
        })
    }
  }

  function handleGetActiveCourses() {
    getActiveUserCourses(userId)
      .then((res) => {
        res.data.forEach((el: any) => {
          getCourseById(el.course)
            .then((res) => {
              coursesState.setActiveCourses([...coursesState.activeCourses, res.data]);
            })
            .catch((e) => {
              console.log(e);
            })
        });
      })
      .catch((e) => {
        console.log(e);
      })
  }

  function handleGetArchivedCourses() {
    getUserCoursesByStatus(userId, 'Done')
      .then((res) => {
        res.data.forEach((el: any) => {
          getCourseById(el.course)
            .then((res) => {
              coursesState.setArchivedCourses([...coursesState.archivedCourses, res.data]);
            })
            .catch((e) => {
              console.log(e);
            })
        });
      })
      .catch((e) => {
        console.log(e);
      })
  }

  function handleGetPausedCourses() {
    getUserCoursesByStatus(userId, 'Paused')
      .then((res) => {
        res.data.forEach((el: any) => {
          getCourseById(el.course)
            .then((res) => {
              coursesState.setStopedCourses([...coursesState.stopedCourses, res.data]);
            })
            .catch((e) => {
              console.log(e);
            })
        });
      })
      .catch((e) => {
        console.log(e);
      })
  }

  useEffect(() => {
    handleGetAllCourses();
    handleGetActiveCourses();
    handleGetArchivedCourses();
    handleGetPausedCourses();
  }, [])

  const options = [
    {
      id: 1,
      name: 'Основные курсы',
      onClick: () => coursesState.setFilter('all'),
    },
    {
      id: 2,
      name: 'Активные курсы',
      onClick: () => coursesState.setFilter('active'),
    },
    {
      id: 3,
      name: 'Архив',
      onClick: () => coursesState.setFilter('archived'),
    },
    {
      id: 4,
      name: 'Приоставноленные',
      onClick: () => coursesState.setFilter('stoped'),
    },
  ];

  return (
    <>
      <CoursesTopbar/>
      <Filters options={options}/>
      <CoursesList/>
    </>
  )
});
