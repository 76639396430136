import React, { useState, useEffect } from 'react';
import {
  Box,
  Flex,
  Button,
  useDisclosure,
} from '@chakra-ui/react';
import CollapseIcon from '@/assets/icons/collapse.svg';
import { TaskDrawer } from '@/widgets';
import { IUser, TTask } from '@/shared';
import { Skill } from '@/shared';
import { Reviews } from '@/entities/projects';
import { SkillsList } from '@/widgets';
import { getTaskUserReviews } from '@/entities/projects';
import taskState from '@/widgets/TaskDrawer/store/taskState';

export interface ITaskUserReviewsProps {
  user: IUser;
  task: TTask;
}

export const TaskUserReviews: React.FC<ITaskUserReviewsProps> = ({ user, task }) => {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [isLoaded, setIsLoaded] = useState(false);
  const [reviews, setReviews] = useState([]);

  const { isOpen, onOpen, onClose } = useDisclosure();

  function handleOpen() {
    taskState.setSelectedTask(task);
    onOpen();
  }

  useEffect(() => {
    if (!isCollapsed && !isLoaded) {
      getTaskUserReviews(task.id, user.id)
        .then((res) => {
          setReviews(res.data);

          setIsLoaded(true);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [isCollapsed]);

  return (
    <Box py={2} px={4} background="var(--main-white)" borderRadius="lg">
      <Flex align="center" gap={4}>
        <Button background="inherit" onClick={handleOpen}>
          <h5>{task.title} (задача)</h5>
        </Button>

        {(user.id === task.owner.id) ? 
          <Skill title="Руководитель" /> :
          <SkillsList skills={task?.skill} />
        }

        <Button
          onClick={() => setIsCollapsed(!isCollapsed)}
          background="none"
          marginLeft="auto"
        >
          <CollapseIcon
            style={{
              transform: isCollapsed ? '' : 'rotate(180deg)', transition: 'all .2s ease-in-out'
            }}
          />
        </Button>
      </Flex>

      {!isCollapsed &&
        <>
          {!isLoaded ?
            <Box>Загрузка...</Box> :
            <>
              {reviews.length > 0 ? 
                <Reviews reviews={reviews} /> :
                <Box>Оценок нет</Box>
              }
            </>
          }
        </>
      }
      <TaskDrawer isOpen={isOpen} onClose={onClose}/>
    </Box>
  );
}
