import axios from 'axios';

const API = process.env.API_URL;
const access = JSON.parse(localStorage.getItem('accessToken'));
const refresh = JSON.parse(localStorage.getItem('refreshToken'));

export const getCourseById= async (id: number) => {
    const res = await axios({
        url: `${API}/courses/${id}/` ,
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            "Access-Control-Allow-Headers": "*",
            "Authorization": `Bearer ${access}`,
        },
    })
    
    return res
}

export const getActiveUserCourses = async (userId: number) => {
    const res = await axios({
        url: `${API}/course-user/?user__id=${userId}&is_active=True` ,
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            "Access-Control-Allow-Headers": "*",
            "Authorization": `Bearer ${access}`,
        },
    })
    
    return res
}

export const getUserCoursesByStatus = async (userId: number, status: string) => {
    const res = await axios({
        url: `${API}/course-user/?user__id=${userId}&status=${status}` ,
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            "Access-Control-Allow-Headers": "*",
            "Authorization": `Bearer ${access}`,
        },
    })
    
    return res
}

export const getStepikData = async () => {
    const res = await axios({
        url: `${API}/stepik_clients/get_stepik_client_by_user/` ,
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            "Access-Control-Allow-Headers": "*",
            "Authorization": `Bearer ${access}`,
        },
    })
    
    return res
}

export const logoutStepik = async () => {
    const res = await axios({
        url: `${API}/stepik_clients/logout/` ,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            "Access-Control-Allow-Headers": "*",
            "Authorization": `Bearer ${access}`,
        },
    })
    
    return res
}
