import React, { useState } from 'react';
import {
    FormErrorMessage,
    FormLabel,
    FormControl,
    Input,
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
} from '@chakra-ui/react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { EducationCard } from '@/entities/user';
import { TEducation } from '@/entities/user/ui/EducationCard/EducationCard';
import AddIcon from '@/assets/icons/add.svg';
import { EducationCreation } from '../EducationCreation/EducationCreation';
import educationState from '../store/educationState';
import { observer } from 'mobx-react-lite';
import { changeEducation } from '@/features/employee';

interface IFormFileds {
  title: string;
  country: string;
  city: string;
  educationStep: string;
  startDate: string;
  endDate: string;
}

export interface EditPlacePopupProps {
  isOpen: boolean;
  onClose: () => void;
  educations: TEducation[];
}

export const EditPlacePopup: React.FC<EditPlacePopupProps> = observer(({ isOpen, onClose, educations }) => {
  const { 
    register,
    handleSubmit,
    formState: { errors } 
  } = useForm<IFormFileds>();

  const [isAwaiting, setIsAwaiting] = useState(false);

  const userId = JSON.parse(localStorage.getItem('user'))?.id;

  const onSubmit: SubmitHandler<IFormFileds> = (data) => {
    setIsAwaiting(true);
    changeEducation(userId, educationState.educations)
      .then((res: any) => {
        onClose();
      })
      .catch((e: any) => {
        console.log(e);
      })
      .finally(() => {
        setIsAwaiting(false);
      })
  }

  function handleAddEmpty() {
    educationState.setSelected({
      id: Date.now(),
      organization_name: '',
      type: '',
      start_date: '',
      end_date: '',
      country: '',
      city: '',
    });

    educationState.setIsFormActive(true);
  }

  return (
    <Modal
      size={'xl'}
      blockScrollOnMount={false}
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalOverlay />
      <form onSubmit={handleSubmit(onSubmit)}>
          <ModalContent borderRadius={16}>
            <ModalHeader alignItems={'center'}>
              <h3 style={{ textAlign: 'center' }}>Редактирование образования</h3>
            </ModalHeader>
            <ModalCloseButton top={'14px'} />
            <ModalBody
              padding='20px'
              display='flex'
              flexDirection='column'
              gap='20px'
              borderTop='1px solid var(--addable-gray)'
              borderBottom='1px solid var(--addable-gray)'
            >
              {educations && educations.map((education) =>
                <EducationCard education={education} type='popup' key={education.id}/>
              )}

              {educationState.isFormActive &&
                <EducationCreation/>
              }

              <Button
                borderRadius='8px'
                bg='#fff'
                leftIcon={<AddIcon color='var(--main-purple)' />} 
                variant='outline'
                onClick={handleAddEmpty}
              >
                <h6 style={{ color: 'var(--main-purple)' }}>Добавить образование</h6>
              </Button>
            </ModalBody>

            <ModalFooter gap='8px' display='flex'>
              <Button
                type='button'
                width='100%'
                onClick={onClose}
                variant='outline'
                color='var(--main-purple)'
                borderRadius='8px'
              >
                Отмена
              </Button>
              <Button
                type='submit'
                width='100%'
                borderRadius='8px'
                bg='var(--main-purple)'
                _hover={{ bg: 'var(--hover-purple)' }}
                color='#fff'
                isLoading={isAwaiting}
              >
                Сохранить изменения
              </Button>
            </ModalFooter>
          </ModalContent>
      </form>
    </Modal>
  )
});
