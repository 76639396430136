import { makeAutoObservable } from "mobx";
import testEditState from "@/widgets/TestEditForm/store/testEditState";

class TestEditDrawerState {
  open = false;

  width = 800;

  constructor() {
    makeAutoObservable(this);
  }

  openDrawer() {
    this.open = true;
  }

  closeDrawer() {
    this.open = false;
    testEditState.resetTest();
  }
}

export default new TestEditDrawerState();
