import styled from "styled-components";

export const Wrapper = styled.div`
    position: relative;
    padding-top: 4px;
`;

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    min-width: 106px;
    padding: 8px 4px;
    border-radius: 16px;
    transition: all .2s;
    cursor: pointer;
    outline-color: var(--addable-gray);
 
    &:hover {
        outline: 1px var(--addable-gray) solid;
        background: var(--main-white);
    }
`;

export const Image = styled.img`
    width: 48px;
    height: 48px;
    border-radius: 50%;
`;

export const Info = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2px;
`;

export const Text = styled.p`
    font-size: 12px;
    max-width: 120px;
    text-align: center;
`;

export const SuperHR = styled.div`
    position: absolute;
    top: 0;
    z-index: 10; 
    display: flex;
    align-items: center;
    height: 18px;
    padding: 0 6px;
    border-radius: 4px;
    background: #ECF0FE; 
    border: 1px solid #DAE2FC;
    font-size: 12px;
    line-height: 16px;
    color: var(--main-purple);
`;