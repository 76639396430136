import React, { useState, useEffect } from 'react';
import {
  Stack,
  Flex,
  Box,
  Button,
  Divider,
  Drawer,
  DrawerContent,
  DrawerCloseButton,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverArrow,
  Text,
  Grid,
  GridItem,
  useDisclosure,
} from '@chakra-ui/react';
import {
  DimmedText,
  CollapseButton,
} from './styles';
import AlertIcon from '@/assets/icons/alert-circle.svg';
import { num_word } from '@/shared';
import CollapseIcon from '@/assets/icons/collapse.svg';
import { TEmployeeRole } from '@/shared/types/projects/types';
import { Skill } from '@/shared';
import { observer } from 'mobx-react-lite';
import { ProjectReview } from '@/features/projects';
import projectState from '@/widgets/ProjectDrawer/store/projectState';
import projectsPageState from '@/pages/Projects/store/projectsPageState';
import { ProjectTasksTable } from '@/entities/projects';
import { deleteProject } from '@/features/projects';
import { EditProjectPopup } from '@/features/projects';
import editProjectPopupState from '@/features/projects/ui/EditProjectPopup/store/EditProjectPopupState';
import { Link } from 'react-router-dom';
import { RoleCard } from './RoleCard';

export interface IProjectDrawerProps {
  isOpen: boolean;
  onClose: () => void;
}

export const ProjectDrawer: React.FC<IProjectDrawerProps> = observer(({ isOpen, onClose }) => {
  const user = JSON.parse(localStorage.getItem('user'));

  const [isCollapsed, setIsCollapsed] = useState(true);
  const btnRef = React.useRef();
  
  const { isOpen: projectIsOpen, onOpen: projectOnOpen, onClose: projectOnClose } = useDisclosure();

  function handleDeleteProject() {
    deleteProject(projectState.selectedProject?.id)
        .then((res: any) => {
          projectsPageState.deleteProject(projectState.selectedProject?.id);
          onClose();

          window.location.reload();
        })
        .catch((e: any) => {
            console.log(e);
        })
  }

  return (
    <>
      <Drawer
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
        finalFocusRef={btnRef}
        size="xl"
      >
        <DrawerContent overflow="auto">
          <Box p={6}>
            <DrawerCloseButton size="lg" />
            <Box>
              <h3>{projectState.selectedProject?.title}</h3>
              <DimmedText>
                {projectState.selectedProject?.tasks.length} {num_word(projectState.selectedProject?.tasks.length, ['задача', 'задачи', 'задач'])}
              </DimmedText>
            </Box>
          </Box>

          <Divider />

          <Grid px={6} py={4} templateColumns="repeat(2, 1fr)" gap={2}>
            <GridItem>
              <DimmedText>Создатель</DimmedText>
            </GridItem>

            <GridItem>
              <ProjectReview
                person={projectState.selectedProject?.owner}
                project={projectState.selectedProject}
              />
            </GridItem>

            <GridItem>
              <DimmedText>Дедлайн</DimmedText>
            </GridItem>

            <GridItem>
              {projectState.selectedProject?.end_date}
            </GridItem>
          </Grid>

          <Divider />

          <Box p={6}>
            {projectState.selectedProject?.description}
          </Box>

          <Stack spacing={2} p={6}>
            <h5>Роли</h5>
            <Stack spacing={2}>
              {projectState.selectedProject?.expected_roles.length > 0 &&
                projectState.selectedProject?.expected_roles
                .slice(0, isCollapsed ? 4 : projectState.selectedProject?.expected_roles.length)
                .map((role: TEmployeeRole) => 
                  <RoleCard
                    project={projectState.selectedProject}
                    key={role.id}
                    role={role}
                  />
                )}

              {projectState.selectedProject?.expected_roles?.length > 4 &&
                <Box py={2}>
                  <CollapseButton
                    onClick={() => setIsCollapsed(!isCollapsed)}
                  >
                    Показать {isCollapsed ? <>больше</> : <>меньше</>}
                    <CollapseIcon
                      style={{
                          transform: isCollapsed ? '' : 'rotate(180deg)', transition: 'all .2s ease-in-out'
                      }}
                      width={10}
                      height={5}
                    />
                  </CollapseButton>
                </Box>
              }
            </Stack>
          </Stack>

          {(user.id === projectState.selectedProject?.owner.id) &&
            <Box p={6}>
              <Link
                to='/dashboard-manager-employees'
                >
                  <Button
                    type='button'
                    width='100%'
                    variant='outline'
                    color='var(--main-purple)'
                    borderRadius='8px'
                  >
                      Искать исполнителей
                  </Button>
                </Link>
            </Box>
          }

          <Stack p={6} spacing={4}>
            {(projectState.selectedProject?.expected_roles
              .filter((role) => !!role.user)
              .map((role) => role.user.id).includes(user.id) ||
              user.id === projectState.selectedProject?.owner.id
             ) &&
              <ProjectTasksTable project={projectState.selectedProject} />
            }
          </Stack>

          {(user.id === projectState.selectedProject?.owner.id) &&
            <Stack p={6} spacing={2}>
              <Button
                type='button'
                width='100%'
                variant='outline'
                color='var(--main-purple)'
                borderRadius='8px'
                onClick={() => {
                  projectOnOpen();
                  editProjectPopupState.setRoles(projectState.selectedProject?.expected_roles);
                }}
              >
                Редактировать проект
              </Button>

              <Button
                width='100%'
                border='1px var(--addable-gray) solid'
                borderRadius='8px'
                variant='outline'
                bg='var(--main-white)'
                color='var(--addable-red)'
                onClick={handleDeleteProject}
              >
                Удалить проект
              </Button>
            </Stack>
          }
          <EditProjectPopup isOpen={projectIsOpen} onClose={projectOnClose} />
        </DrawerContent>
      </Drawer>
    </>
  );
});

export interface ISkillsPopoverProps {
  role: TEmployeeRole;
}

export const SkillsPopover: React.FC<ISkillsPopoverProps> = ({ role }) => {
  return (
    <Popover>
      <PopoverTrigger>
        <Box _hover={{cursor: 'pointer'}}>
          <AlertIcon height={18} width={18} color="var(--main-purple)" />
        </Box>
      </PopoverTrigger>

      <PopoverContent>
        <PopoverHeader>
          <Text fontWeight="bold" fontSize={12}>Необходимые навыки</Text>
        </PopoverHeader>

        <PopoverArrow />

        <PopoverBody>
          <Flex gap={2} wrap={'wrap'}>
            {role.skill.length > 0 ?
              <>
                {role.skill.map((skill: any) => 
                  <Skill key={skill.id} title={skill.name}/>
                )}
              </> :
              <Box>Требуемых навыков нет</Box>
            }
          </Flex>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  )
}
