import React, { useState, useEffect } from 'react';
import {
  InputGroup,
  InputLeftElement,
  Input,
  Button,
  Flex,
  Popover,
  PopoverTrigger,
  PopoverContent,
  useDisclosure,
} from '@chakra-ui/react';
import {
  Container,
  Top,
  Buttons,
  PopoverButton,
} from './styles';
import { useDebounce } from '@/shared';
import AddIcon from '@/assets/icons/add.svg';
import SearchIcon from '@/assets/icons/search.svg';
import {
  getTasks,
  getTasksBySkills,
} from '@/entities/tasks';
import projectsPageState from '@/pages/Projects/store/projectsPageState';
import { CreateProjectPopup, CreateTaskPopup } from '@/features/projects';

export const TasksTopBar: React.FC = () => {
  const [taskSearchTerm, setTaskSearchTerm] = useState('');
  const [skillSearchTerm, setSkillSearchTerm] = useState('');
  const { isOpen: projectIsOpen, onOpen: projectOnOpen, onClose: projectOnClose } = useDisclosure();
  const { isOpen: taskIsOpen, onOpen: taskOnOpen, onClose: taskOnClose } = useDisclosure();
  const role = JSON.parse(localStorage.getItem('user'))?.role;

  const debouncedTaskSearchTerm = useDebounce(taskSearchTerm, 500);
  const debouncedSkillSearchTerm = useDebounce(skillSearchTerm, 500);

  function handleTaskNameSearch(debouncedSearchTerm: string) {
    getTasks(debouncedSearchTerm)
      .then((res: any) => {
        projectsPageState.setTasks(res.data);
      })
      .catch((error) => {
        console.log(error);
      })
  }

  function handleTaskSkillSearch(debouncedSearchTerm: string) {
    getTasksBySkills(debouncedSearchTerm)
      .then((res: any) => {
        projectsPageState.setTasks(res.data);
      })
      .catch((error) => {
        console.log(error);
      })
  }

  useEffect(() => {
    if (typeof debouncedTaskSearchTerm === 'string') {
      handleTaskNameSearch(debouncedTaskSearchTerm);
    }
  }, [debouncedTaskSearchTerm]);

  useEffect(() => {
    if (typeof debouncedSkillSearchTerm === 'string') {
      handleTaskSkillSearch(debouncedSkillSearchTerm);
    }
  }, [debouncedSkillSearchTerm]);

  return (
    <>
      <Container>
        <Top>
          <InputGroup padding={'6px'}>
            <InputLeftElement pointerEvents='none'>
              <SearchIcon
                color='var(--addable-gray)'
                width={18}
                height={18}
              />
            </InputLeftElement>
            <Input
              value={taskSearchTerm}
              onChange={e => setTaskSearchTerm(e.target.value)}
              color='messenger'
              height={'28px'}
              variant='flushed'
              placeholder='Поиск по всем задачам'
            />
          </InputGroup>
          
          <InputGroup padding={'6px'}>
            <InputLeftElement pointerEvents='none'>
              <SearchIcon
                color='var(--addable-gray)'
                width={18}
                height={18}
              />
            </InputLeftElement>
            <Input
              value={skillSearchTerm}
              onChange={e => setSkillSearchTerm(e.target.value)}
              color='messenger'
              height={'28px'}
              variant='flushed'
              placeholder='Поиск по навыкам в задаче'
            />
          </InputGroup>

          {role === 'Manager' && 
          <>
            <Popover>
              <PopoverTrigger>
                <Buttons>
                  <Button
                    borderRadius='8px'
                    bg='var(--main-purple)'
                    _hover={{ bg: 'var(--hover-purple)' }}
                    leftIcon={<AddIcon color='#fff' />} 
                  >
                    <h6 style={{ color: '#fff' }}>Создать</h6>
                  </Button>
                </Buttons>
              </PopoverTrigger>
              <PopoverContent w="full">
                <Flex direction="column" alignItems="start">
                  <PopoverButton
                    onClick={projectOnOpen}
                  >
                    Новый проект
                  </PopoverButton>
                  <PopoverButton
                    onClick={taskOnOpen}
                  >
                    Новая задача
                  </PopoverButton>
                </Flex>
              </PopoverContent>
            </Popover>
            <CreateProjectPopup isOpen={projectIsOpen} onClose={projectOnClose} />
            <CreateTaskPopup isOpen={taskIsOpen} onClose={taskOnClose} />
          </>
          }
        </Top>
      </Container>
    </>
  )
}
