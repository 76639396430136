import React from 'react';
import { TestAssessmentDrawer } from '@/widgets/TestAssessmentDrawer/TestAssessmentDrawer';
import { TestDashboard } from '@/widgets/TestDashboard/TestDashboard';
import { TestsList } from '@/widgets';
import { EmployeesTopBar } from '@/widgets/TestsTopBar';
import employeesTestState from './store/employeesTestState';
import { observer } from 'mobx-react-lite';

export const EmployeeTests: React.FC = observer(() => {
  return (
    <div>
      <TestDashboard>
        <EmployeesTopBar />
        {employeesTestState.isLoading ?
          <div>Загрузка...</div> :
          <TestsList tests={employeesTestState.tests}/>
        }
      </TestDashboard>
      <TestAssessmentDrawer/>
    </div>
  )
});
