import React, { useEffect, useMemo, useState } from 'react';

// Styles
import {
  Container,
  DatesWrapper,
} from './styles';

import { Filters } from '@/features/common';
import { Select } from '@chakra-ui/react';
import competenceAnalyticsState from '@/pages/CompetenceAnalytics/store/competenceAnalyticsState';
import { observer } from 'mobx-react-lite';
import { DatePicker } from 'antd';
import { IUser } from '@/shared';

// Handlers
import {
  getCompetenceAnalyticsForCompany,
  getCompetenceAnalyticsForEmployee,
  getCompetenceAnalyticsForTempEmployee,
} from '@/features/competenceAnalytics';

import { dateConvert } from '@/shared';

export interface CompetenceAnalyticsFiltersProps {
  employees: IUser[];
  tempEmployees: any[];
  departments: any[];
  groups: any[];
}

export const CompetenceAnalyticsFilters: React.FC<CompetenceAnalyticsFiltersProps> = observer(({ employees, tempEmployees, departments, groups }) => {
  const [employeeIdToFetch, setEmployeeIdToFetch] = useState<number>();
  // const [currentDepartmentId, setCurrentDepartmentId] = useState<number>();
  // const [currentGroupId, setCurrentGroupId] = useState<number>();
  const [startDate, setStartDate] = useState<any>();
  const [endDate, setEndDate] = useState<any>();

  // Variables that give information about data loading 
  // or application fault
  const [isLoading, setIsLoading] = useState<boolean>();
  const [isError, setIsError] = useState<boolean>();

  const hrOptions = [
    {
      id: 1,
      name: 'Все',
      onClick: () => {
        competenceAnalyticsState.clearCompetenceAnalytics();
        competenceAnalyticsState.setFilter('all');
      },
    },
    // {
    //   id: 2,
    //   name: 'По отделам',
    //   onClick: () => {
    //     competenceAnalyticsState.clearCompetenceAnalytics();
    //     competenceAnalyticsState.setFilter('groups');
    //   },
    // },
    // {
    //   id: 3,
    //   name: 'По департаментам',
    //   onClick: () => {
    //     competenceAnalyticsState.clearCompetenceAnalytics();
    //     competenceAnalyticsState.setFilter('departments');
    //   },
    // },
    {
      id: 4,
      name: 'По сотруднику',
      onClick: () => {
        competenceAnalyticsState.clearCompetenceAnalytics();
        competenceAnalyticsState.setFilter('employee');
      },
    },
  ];

  const managerOptions = [
    // {
    //   id: 1,
    //   name: 'По отделам',
    //   onClick: () => {
    //     competenceAnalyticsState.clearCompetenceAnalytics();
    //     competenceAnalyticsState.setFilter('groups');
    //   },
    // },
    // {
    //   id: 2,
    //   name: 'По департаментам',
    //   onClick: () => {
    //     competenceAnalyticsState.clearCompetenceAnalytics();
    //     competenceAnalyticsState.setFilter('departments');
    //   },
    // },
    {
      id: 3,
      name: 'По сотруднику',
      onClick: () => {
        competenceAnalyticsState.clearCompetenceAnalytics();
        competenceAnalyticsState.setFilter('employee');
      },
    },
  ];

  const user = JSON.parse(
    localStorage.getItem('user')
  );
  const companyId = user?.company[
    JSON.parse(
      localStorage.getItem('user')
    )?.company.length - 1
  ];

  // Time to show notifications for
  const timeoutTime: number = 3000;

  useEffect(() => {
    if (user?.role === 'Manager' || user?.role === 'HR Manager') {
      competenceAnalyticsState.setFilter('employee');
    }
    else if (user?.role === 'Super HR manager') {
      competenceAnalyticsState.setFilter('all');
    }
  }, [])

  useEffect(() => {
    switch (competenceAnalyticsState.filter) {
      case 'all':
        handleGetCompanyCompetenceAnalytics('current');
        break;
      case 'employee':
        competenceAnalyticsState.clearCompetenceAnalytics();
        break;
      default:
        competenceAnalyticsState.clearCompetenceAnalytics();
        break;
    }
  }, [competenceAnalyticsState.filter]);

  // useEffect(() => {
  //   if (employeeIdToFetch) {
  //     handleGetUserCompetenceAnalytics('current', employeeIdToFetch);
  //   }
  // }, [employeeIdToFetch])

  function get_level_by_score(score: number, gradations: any) {
    const level: string = 'Master';

    const gradation_level_mapping: any = {
      0: 'Beginner',
      1: 'Intermediate',
      2: 'Master',
    }

    for (let i = 0; i < gradations.length; i++) {
      if (score < gradations[i]) {
        return gradation_level_mapping[i];
      }
    }

    return level;
  }

  function saveCompetenceData(data: any, type: string) {
    if (employeeIdToFetch) {
    }
    let competences: any = {};
    for (const competence_object of data) {
      // If competence doesn't yet exist in final competences list, adding it
      if (competences[competence_object.competence.id] === undefined) {
        let indicators: any = {};

        // Making indicator data structre
        for (const indicator_object of competence_object.indicators) {
          // If indicator doesn't exist
          if (indicators[indicator_object.indicator.id] === undefined) {
            let indicatorLevels: any = {};
            indicatorLevels[
              get_level_by_score(
                indicator_object.score,
                indicator_object.indicator.gradations,
              )
            ] = 1;

            indicators[indicator_object.indicator.id] = {
              name: indicator_object.indicator.name,
              levels: indicatorLevels,
            }
          }
          // If indicator exists
          else {
            const indicator_level = get_level_by_score(
              indicator_object.score,
              indicator_object.indicator.gradations,
            )
            if (indicators[indicator_object.indicator.id].levels[indicator_level] === undefined) {
              indicators[indicator_object.indicator.id].levels[indicator_level] = 1;
            }
            else {
              indicators[indicator_object.indicator.id].levels[indicator_level]++;
            }
          }
        }

        let levels: any = {};
        levels[competence_object.level] = 1;

        // Making competence data structure
        competences[competence_object.competence.id] = {
          name: competence_object.competence.name,
          indicators: indicators,
          levels: levels,
        }
      }
      // If it exists, modifying it
      else {
        let indicators = competences[competence_object.competence.id].indicators;
        for (const indicator_object of competence_object.indicators) {
          // If indicator doesn't exist
          if (indicators[indicator_object.indicator.id] === undefined) {
            let indicatorLevels: any = {};
            indicatorLevels[indicator_object.level] = 1;

            indicators[indicator_object.indicator.id] = {
              name: indicator_object.indicator.name,
              levels: indicatorLevels,
            }
          }
          // If indicator exists
          else {
            if (indicators[indicator_object.indicator.id].levels[indicator_object.level] === undefined) {
              indicators[indicator_object.indicator.id].levels[indicator_object.level] = 1;
            }
            else {
              indicators[indicator_object.indicator.id].levels[indicator_object.level]++;
            }
          }
        }

        competences[competence_object.competence.id].indicators = indicators;
        if (competences[competence_object.competence.id].levels[competence_object.level] === undefined) {
          competences[competence_object.competence.id].levels[competence_object.level] = 1;
        }
        else {
          competences[competence_object.competence.id].levels[competence_object.level]++;
        }
      }
    }
    let finalCompetences: any = Object.values(competences);

    finalCompetences.map((competence: any) => {competence.indicators = Object.values(competence.indicators)})

    if (type === 'current') {
      competenceAnalyticsState.setCurrentCompetences(finalCompetences);
    }
    else {
      competenceAnalyticsState.setProgressCompetences(finalCompetences);
    }
  }

  function showError() {
    setIsError(true);
    setTimeout(() => {
      setIsError(false);
    }, timeoutTime);
  }

  function handleGetCompanyCompetenceAnalytics(type: string = 'current') {
    // Handles data change,
    // restricts user from interacting with page 
    // while data is being retrieved and saved
    setIsLoading(true);

    getCompetenceAnalyticsForCompany(
      type,
      companyId,
      startDate,
      endDate,
    )
      .then((response) => {
        saveCompetenceData(response.data, type);
      })
      .catch((error) => {
        console.log(error);
        showError();
      })
      .finally(() => {
        setIsLoading(false);
      })
  }

  function handleGetUserCompetenceAnalytics(
    type: string = 'current',
    employeeId: number,
  ) {
    // Handles data change,
    // restricts user from interacting with page 
    // while data is being retrieved and saved
    setIsLoading(true);

    getCompetenceAnalyticsForEmployee(
      type,
      employeeId,
      startDate,
      endDate,
    )
      .then((response) => {
        saveCompetenceData(response.data, type);
      })
      .catch((error) => {
        console.log(error);
        showError();
      })
      .finally(() => {
        setIsLoading(false);
      })
  }

  function handleGetTempUserCompetenceAnalytics(
    type: string = 'current',
    employeeId: number,
  ) {
    // Handles data change,
    // restricts user from interacting with page 
    // while data is being retrieved and saved

    getCompetenceAnalyticsForTempEmployee(
      type,
      employeeId,
      startDate,
      endDate,
    )
      .then((response) => {
        saveCompetenceData(response.data, type);
      })
      .catch((error) => {
        console.log(error);
        showError();
      })
      .finally(() => {
        setIsLoading(false);
      })
  }

  return (
    <Container>
      <Filters options={
        user?.role === 'Manager' ? managerOptions :
        user?.role === 'HR manager' || user?.role === 'Super HR manager' ? hrOptions : []
      }/>
      {competenceAnalyticsState.filter === 'employee' &&
        <Select
          onChange={
            (e: any) => {
              let values: any = e.target.value.split(' ');
              switch(values[0]) {
                case 'user':
                  handleGetUserCompetenceAnalytics('current', Number(values[1]));
                  break;
                case 'temp_user':
                  handleGetTempUserCompetenceAnalytics('current', Number(values[1]));
                  break;
                default:
                  break;
              }
            }
          }
          isReadOnly={false}
          variant='flushed'
          placeholder='Выберите сотрудника'
          style={{
            fontSize: '12px'
          }}
        >
          {employees.map((employee: IUser) =>
            <option value={'user ' + String(employee.id)}>{employee.last_name} {employee.first_name} {employee.patronymic}</option>
          )}
          {tempEmployees.map((tempEmployee: any) =>
            <option value={'temp_user ' + String(tempEmployee.id)}>{tempEmployee.last_name} {tempEmployee.first_name} (нет в списке сотрудников)</option>
          )}
        </Select>
      }
    </Container>
  )
});