import styled from "styled-components";

export const Card = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 16px;
    border-radius: 16px;
    background: var(--main-lightgray);
    border: 1px solid var(--addable-gray);
`;

export const Top = styled.div`
    display: flex;
    justify-content: space-between; 
`;

export const Left = styled.div`
    display: flex;
    align-items: center;
    gap: 16px;
`;

export const Right = styled.div`
    display: flex;
    align-items: center;
    gap: 16px;
`;

export const PopoverButton = styled.button`
    font-size: 12px;
`;

export const HrList = styled.div`
    display: flex;
    gap: 16px;
    max-width: calc(100vw - 306px);
    overflow-x: auto;
    overflow-y: hidden;
`;

export const AddButton = styled.button`
    display: flex;
    min-width: 90px;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    padding: 8px 0;
    color: var(--main-purple);
`;

export const Slider = styled.div`
    width: 40px;
    height: 20px;
    padding: 1px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: #F4F6F6;
    cursor: pointer;
    transition: .3s;
`;

export const SliderBullet = styled.div`
    width: 18px;
    height: 18px;
    background-color: #3D63EA;
    transition: .3s;
`;
