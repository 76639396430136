import React from 'react';
import styled from 'styled-components';

interface ProgressBarProps extends Omit<React.ComponentProps<'div'>, 'ref'> {
  width?: string;
  value: number;
  max: number;
  bgColor?: string;
}

const Container = styled.div`
  border: 1px solid var(--addable-gray);
  border-radius: 10px;

  margin: 0.5rem 0;
  overflow: auto;
`;

const Filler = styled.div`
  height: 100%;

  border-radius: inherit;
`;

const Children = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: start;

  white-space: nowrap;

  gap: 0.5rem;

  padding: 10px;
`;

export const ProgressBar = ({ children, width='100%', value, max, bgColor }: ProgressBarProps) => {
  const completed = max === 0 ? 0 : value / max * 100;

  return (
    <Container style={{ width: width }}>
      <Filler style={{ width: `${completed}%`, backgroundColor: bgColor ? bgColor : '#C8D3FB'}}>
        <Children>
          {children}
        </Children>
      </Filler>
    </Container>
  );
};
