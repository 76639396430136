import styled from "styled-components";

export const Columns = styled.div`
  width: 100%;
  display: flex;
  gap: 16px;
`;

export const ProgressWrapper = styled.div`
  flex: 1 0 400px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  min-height: auto;
`;

export const ProgressTitle = styled.div`
  display: block;
  width: 280px;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const Count = styled.div`
  white-space: nowrap; 
  font-size: 12px;
  line-height: 12px;
  color: var(--main-black);
  opacity: .5;
`;
