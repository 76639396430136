import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import testInfoDrawerState from '@/widgets/TestsList/store/testInfoDrawerState';
import Drawer from '@mui/material/Drawer';
import {
  CloseButton,
  Divider,
  Select,
  Box,
  useDisclosure,
} from '@chakra-ui/react';
import CollapseIcon from '@/assets/icons/collapse.svg';
import { TestProgressionPopup } from '@/entities/tests/ui/TestProgressionPopup/TestProgressionPopup';
import { Filters } from '@/features/common';
import {
  SingleChoiceQuestionStats,
  MultipleChoiceQuestionStats,
  OpenEndedQuestionStats,
} from '@/entities/tests';
import {
  ContainerStyles,
  DrawerHeaderStyles,
  Grouping,
  DimmedHeading,
  Disabled,
  PopupBtn,
  Wrapper,
  CategoryStyles,
} from './styles';
import {
  getTestStats,
  getGroupsDepartmentsTestStats,
  getUserTestStats,
  getTest,
} from '@/entities/tests/api';
import { getEmployees } from '@/features/employee';
import { getCompany } from '@/entities/company/api';
import { getCompanyGroups } from '@/features/department';
import { EmptyResponse } from '../EmptyResponse/EmptyResponse';
import { TestResults } from '@/entities/tests';
import testsState from '@/pages/DashboardHRTests/store/testsState';

const mainCategories = [
  {
    id: 1,
    name: 'Статистика',
    onClick: () => {
      testInfoDrawerState.setMainFilter('statistics');
      testInfoDrawerState.setSecondaryFilter('all');
    },
  },
  {
    id: 2,
    name: 'Таблица ответов',
    onClick: () => {
      testInfoDrawerState.setMainFilter('answers');
      testInfoDrawerState.setSecondaryFilter('all');
    },
  },
];

const secondaryCategories = [
  {
    id: 1,
    name: 'Все',
    onClick: () => {
      testInfoDrawerState.setSecondaryFilter('all');
    },
  },
  {
    id: 2,
    name: 'По департаментам',
    onClick: () => {
      testInfoDrawerState.setSecondaryFilter('departments');
    },
  },
  {
    id: 3,
    name: 'По отделам',
    onClick: () => {
      testInfoDrawerState.setSecondaryFilter('groups');
    },
  },
  {
    id: 4,
    name: 'По сотруднику',
    onClick: () => {
      testInfoDrawerState.setSecondaryFilter('employees');
    },
  },
];

type TCategory = {
  id: number,
  name: string,
}

export interface ICategoryProps {
  type: string;
  category: TCategory;
}

const Category: React.FC<ICategoryProps> = ({ type, category }) => {
  return (
    <CategoryStyles>
      {category.name}
      <CloseButton
        onClick={() => {
          testInfoDrawerState.removeSortCategory(type, category.id);
        }}
      />
    </CategoryStyles>
  );
}

export const TestInfoDrawer: React.FC = observer(() => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isLoading, setIsLoading] = useState(true);
  const [stats, setStats] = useState([]);

  const [departments, setDepartments] = useState([]);
  const [groups, setGroups] = useState([]);
  const [employees, setEmployees] = useState([]);

  useEffect(() => {
    if (testsState.departments && testsState.groups && testsState.employees) {
      setDepartments(testsState.departments);
      setGroups(testsState.groups);
      setEmployees(testsState.employees.map((u: any) => {
        return {
          id: u.id,
          name: `${u.patronymic} ${u.first_name} ${u.last_name}`,
        }
      }));
    }
  }, [
    JSON.stringify(testsState.departments),
    JSON.stringify(testsState.groups),
    JSON.stringify(testsState.employees),
  ]);

  function handleGetSortedStats(testId: number) {
    const filter = testInfoDrawerState.secondaryFilter;
    let sortedCategories: any = [];

    if (filter === 'groups') {
      sortedCategories = testInfoDrawerState.sortCategories.groups;
    } else if (filter === 'departments') {
      sortedCategories = testInfoDrawerState.sortCategories.departments;
    } else if (filter === 'employees') {
      sortedCategories = testInfoDrawerState.sortCategories.employees;
    }
    console.log(filter, JSON.stringify(sortedCategories));

    let ids: number[] = [];
    if (sortedCategories) {
      ids = sortedCategories.map((c: TCategory) => c.id)
    }

    if (filter === 'all') {
      getTestStats(testInfoDrawerState.test.id)
        .then((res) => {
          if (testId === testInfoDrawerState.test.id) {
            if (filter === testInfoDrawerState.secondaryFilter) {
              setStats(res.data.questions);
            }
          }
        })
        .catch(e => {
          console.log(e);
        })
    } else if (filter === 'groups' || filter === 'departments') {
      if (ids.length > 0) {
        getGroupsDepartmentsTestStats(testInfoDrawerState.test.id, ids)
          .then((res) => {
            if (filter === testInfoDrawerState.secondaryFilter) {
              setStats(res.data.questions);
            }
          })
          .catch(e => {
            console.log(e);
          })
      } else {
        setStats([]);
      }
    } else if (filter === 'employees') {
      if (ids.length > 0) {
        getUserTestStats(testInfoDrawerState.test.id, ids)
          .then((res) => {
            if (filter === testInfoDrawerState.secondaryFilter) {
              setStats(res.data.questions);
            }
          })
          .catch(e => {
            console.log(e);
          })
      } else {
        setStats([]);
      }
    }
  }

  useEffect(() => {
    if (testInfoDrawerState.test.id) {
      setIsLoading(true);

      handleGetTestInfo();

      handleGetSortedStats(testInfoDrawerState.test.id);
    }
  }, [
    testInfoDrawerState.test.id,
    testInfoDrawerState.mainFilter,
    testInfoDrawerState.secondaryFilter,
    JSON.stringify(testInfoDrawerState.sortCategories),
  ])

  useEffect(() => {
    testInfoDrawerState.clearSortCategories();
    testInfoDrawerState.setMainFilter('statistics');
    testInfoDrawerState.setSecondaryFilter('all');
  }, [testInfoDrawerState.test.id]);

  function handleDrawerClose() {
    testInfoDrawerState.closeDrawer();
  };

  function handleSelect(e: any) {
    const target = e.target as HTMLSelectElement;
    const index = e.target.selectedIndex;
    const optionElement = e.target.childNodes[index];
    const id = optionElement.getAttribute('data-id');

    testInfoDrawerState.addSortCategory(
      testInfoDrawerState.secondaryFilter,
      target.value,
      id,
    );

    e.target.selectedIndex = 0;
  }

  function handleGetTestInfo() {
    if (testInfoDrawerState.test.id) {
      getTest(testInfoDrawerState.test.id)
        .then((res: any) => {
          if (testInfoDrawerState.test.id === res.data.id) {
            testInfoDrawerState.setTest(res.data);
            setIsLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
        })
    }
  }

  return (
    <Drawer
      key={testInfoDrawerState.test.id}
      sx={{
        width: testInfoDrawerState.width,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: testInfoDrawerState.width,
          boxSizing: 'border-box',
        },
      }}
      variant="persistent"
      anchor="right"
      open={testInfoDrawerState.open}
    >
      <ContainerStyles>
        <DrawerHeaderStyles>
          <h3>{testInfoDrawerState.test.title}</h3>
          <CloseButton
            size='lg'
            onClick={handleDrawerClose}
          />
        </DrawerHeaderStyles>
      </ContainerStyles>
      <Divider />

      {testInfoDrawerState.test.description &&
        <ContainerStyles>
          <div>{testInfoDrawerState.test.description}</div>
        </ContainerStyles>
      }
      
      <ContainerStyles>
        <h3>Количество ответов - {testInfoDrawerState.test.completed_by?.length || 0}/
        <Disabled>{testInfoDrawerState.test?.invited.length}</Disabled></h3>
      
        <PopupBtn onClick={onOpen}>Показать, кто не прошел 
        <CollapseIcon
          width={18}
          height={18}
        />
        </PopupBtn>
        <TestProgressionPopup isOpen={isOpen} onClose={onClose}/>
      </ContainerStyles>

      <ContainerStyles>
        <Filters options={mainCategories}/>
      </ContainerStyles>

      <ContainerStyles>
        {testInfoDrawerState.mainFilter === 'statistics' &&
        <>
          <Grouping>
            <DimmedHeading>Группировка по</DimmedHeading>
            <Filters options={secondaryCategories}/>
          </Grouping>

          {testInfoDrawerState.secondaryFilter === 'groups' &&
          <>
            <ContainerStyles>
              <Disabled>
                <Select
                  variant='flushed'
                  defaultValue=""
                  onChange={handleSelect}
                >
                  <option hidden disabled value="">Выберите отдел</option>
                  {groups && groups.map((option) => {
                    return (
                      <option key={option.id} data-id={option.id} value={option.name}>{option.name}</option>
                    );
                  })}
                </Select>
              </Disabled>

              <Wrapper>
                <Grouping>
                  {testInfoDrawerState.sortCategories[testInfoDrawerState.secondaryFilter]
                  .filter((value: TCategory) => Boolean(value))
                  .map((category: TCategory) => 
                    <div key={category.id}>
                      <Category type={testInfoDrawerState.secondaryFilter} category={category}/>
                    </div>
                  )}
                </Grouping>
              </Wrapper>
            </ContainerStyles>
          </>
          }

          {testInfoDrawerState.secondaryFilter === 'departments' &&
          <>
            <ContainerStyles>
              <Disabled>
                <Select
                  variant='flushed'
                  defaultValue=""
                  onChange={handleSelect}
                >
                  <option hidden disabled value="">Выберите департамент</option>
                  {departments && departments.map((option) => {
                    return (
                      <option key={option.id} data-id={option.id} value={option.name}>{option.name}</option>
                    );
                  })}
                </Select>
              </Disabled>

              <Wrapper>
                <Grouping>
                  {testInfoDrawerState.sortCategories[testInfoDrawerState.secondaryFilter]
                  .filter((value: TCategory) => Boolean(value))
                  .map((category: TCategory) => 
                    <div key={category.id}>
                      <Category type={testInfoDrawerState.secondaryFilter} category={category}/>
                    </div>
                  )}
                </Grouping>
              </Wrapper>
            </ContainerStyles>
          </>
          }

          {testInfoDrawerState.secondaryFilter === 'employees' &&
          <>
            <ContainerStyles>
              <Disabled>
                <Select
                  variant='flushed'
                  defaultValue=""
                  onChange={handleSelect}
                >
                  <option hidden disabled value="">Выберите сотрудника</option>
                  {employees && employees.map((option) => {
                    return (
                      <option key={option.id} data-id={option.id} value={option.name}>{option.name}</option>
                    );
                  })}
                </Select>
              </Disabled>

              <Wrapper>
                <Grouping>
                  {testInfoDrawerState.sortCategories[testInfoDrawerState.secondaryFilter]
                  .filter((value: any) => Boolean(value))
                  .map((category: any) => 
                    <div key={category.id}>
                      <Category type={testInfoDrawerState.secondaryFilter} category={category}/>
                    </div>
                  )}
                </Grouping>
              </Wrapper>
            </ContainerStyles>
          </>
          }

          {isLoading ?
          <div>Загрузка...</div> :
          <>
            {stats.length > 0 &&
              stats.map((info: any) => {

              return (
                <Box key={info.question.id}>
                  {info.question.type === 'single' &&
                    <SingleChoiceQuestionStats
                      title={info.question.text}
                      image={info.question.images.length > 0 ? info.question.images[0].image : null}
                      respondentCount={info.total_count}
                      answers={info.answers}
                    />
                  }
                  {info.question.type === 'multiple' &&
                    <MultipleChoiceQuestionStats
                      title={info.question.text}
                      image={info.question.images.length > 0 ? info.question.images[0].image : null}
                      respondentCount={info.total_count}
                      answers={info.answers}
                    />
                  }
                  {info.question.type === 'text' &&
                    <OpenEndedQuestionStats
                      answerCount={testInfoDrawerState.test.completed_by?.length}
                      image={info.question.images.length > 0 ? info.question.images[0].image : null}
                      title={info.question.text}
                      answers={info.answers}
                      question={info.question}
                      filters={testInfoDrawerState.sortCategories}
                      currentFilter={testInfoDrawerState.secondaryFilter}
                    />
                  }
                </Box>
              )}
            )}

            {stats.length === 0 &&
              <EmptyResponse />
            }
          </>
          }
        </>
        }

      {testInfoDrawerState.mainFilter === 'answers' &&
        testInfoDrawerState.test.id && 
        <TestResults test_id={testInfoDrawerState.test.id}/>
      }

      </ContainerStyles>
    </Drawer>
  );
});
