import React from 'react';
import {
  TaskContainer,
} from './styles';
import { TaskDrawer } from '@/widgets';
import { useDisclosure } from '@chakra-ui/react';
import taskState from '../TaskDrawer/store/taskState';

interface TaskProps {
  task: any;
}

export const Task: React.FC<TaskProps> = ({ task }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  
  function handleOpen() {
    taskState.setSelectedTask(task.task);
    onOpen();
  }

  return (
    <>
      <TaskContainer onClick={handleOpen}>
        <h5>{task.title}</h5>
      </TaskContainer>
      <TaskDrawer isOpen={isOpen} onClose={onClose}/>
    </>
  )
}
