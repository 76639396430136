import React from 'react';
import { Container } from './styles';
import { Task } from './Task';

export interface TaskListColumnProps {
  tasks: any;
}

export const TaskListColumn: React.FC<TaskListColumnProps> = ({ tasks }) => {
  return (
    <Container>
      {tasks?.map((task: any) =>
        <Task key={task?.id} task={task} />
      )}
    </Container>
  )
}