import React from 'react';
import { Top, Inputs, Bottom } from '@/app/layouts/Auth/styles';
import { SubmitHandler, useForm } from 'react-hook-form';
import { ButtonSolid } from '@/UI';
import { BackButton } from '@/UI';
import {
  FormErrorMessage,
  FormLabel,
  FormControl,
  Input,
} from '@chakra-ui/react';
import { recoveryPassword } from '@/entities/user/api';

interface IFormFileds {
  email: string;
}

export const RecoveryPassword: React.FC = () => {
  const { 
    register,
    handleSubmit,
    formState: { errors, isSubmitting } 
  } = useForm<IFormFileds>();

  const onSubmit: SubmitHandler<IFormFileds> = (data) => {
    recoveryPassword(data.email)
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Top><h3>Восстановление пароля</h3></Top>
      <Inputs>
      <FormControl isInvalid={Boolean(errors.email)}>
          <FormLabel>E-mail *</FormLabel>
          <Input
            id='email'
            {...register('email', { 
              required: 'Обязательное поле'
            })}
            placeholder='example.email@gmail.com'
            type='email'
            size='sm'
            variant='flushed'
          />
          <FormErrorMessage>{errors.email && <>{errors.email.message}</>}</FormErrorMessage>
        </FormControl>
      </Inputs>
      <Bottom>
        <ButtonSolid
          type='submit'
          disabled={isSubmitting ? true : false}
        >
          Отправить ссылку
        </ButtonSolid>
        <BackButton>Назад</BackButton>
      </Bottom>
    </form>
  )
}
