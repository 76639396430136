import React from 'react';
import { CompetencesTopBar } from '@/widgets';
import { CompetencesList } from '@/widgets';
import { ListWrapper } from './styles';
import { Filters } from '@/features/common';
import vacanciesState from '@/entities/vacanci/store/vacanciesState';

export const Competences: React.FC = () => {
  return (
    <>
      <CompetencesTopBar/>
      <ListWrapper>
        <CompetencesList/>
      </ListWrapper>
    </>
  )
}
