import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
`;

export const Inputs = styled.div`
    width: 100%;
    display: flex;
    gap: 32px;
`;
