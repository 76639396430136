import { makeAutoObservable } from "mobx";
import dayjs from "dayjs";

class WorkExperienceState {
  experience: any[] = [];
  editedExperience: any[] = [];

  isEditing = false;
  selectedExp: any = {};

  constructor() {
    makeAutoObservable(this);
  }

  sortExperience(experience: any[]) {
    if (!experience) return [];

    const updatedExperience = experience.slice()
      .sort((first: any, second: any) => {
        const first_start_date = dayjs(first.start_date, 'DD.MM.YYYY');
        const second_start_date = dayjs(second.start_date, 'DD.MM.YYYY');
        return first_start_date.diff(second_start_date);
      })
      .reverse();

    return updatedExperience;
  }

  setExperience(experience: any[]) {
    this.experience = this.sortExperience(experience);
    this.editedExperience = experience;
  }

  setEditedExperience(experience: any[]) {
    this.editedExperience = this.sortExperience(experience);
  }

  setIsEditing(status: boolean) {
    this.isEditing = status;
  }

  setSelectedExp(exp: any) {
    this.selectedExp = exp;
  }

  removeExp(experience_id: number) {
    this.editedExperience = this.editedExperience
      .filter((experience) => experience.id !== experience_id);
  }
}

export default new WorkExperienceState();
