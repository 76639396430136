import styled from "styled-components";

export const Left = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 286px;
`;

export const Right = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  width: 148px;
`;

export const Top = styled.div`
  display: flex;
  justify-content: space-between;
`;
