import { makeAutoObservable } from "mobx";
import { ITest } from '@/shared/types/tests/types';

interface IUserAnswer {
  answer: number;
  chosen?: boolean;
  text?: string;
}

class TestAssessmentState {
  test: ITest = {
    title: '',
    start_date: '',
    end_date: '',
    questions: [],
    completed: false,
  };

  results: any = {};

  constructor() {
    makeAutoObservable(this);
  }

  setTest(test: ITest) {
    this.test = test;
  }

  setUserAnswers(question_id: number, answer_ids: string[]) {
    const q_index = this.test.questions.findIndex((q) => q.id === question_id);

    this.test.questions[q_index]?.answers.forEach((answer, a_index) => {
      if (answer_ids.includes(answer.id.toString())) {
        this.test.questions[q_index].answers[a_index].is_right = true;
      } else {
        this.test.questions[q_index].answers[a_index].is_right = false;
      }
    });
  }

  setAnswerText(question_id: number, answer_id: number, text: string) {
    const q_index = this.test.questions.findIndex((q) => q.id === question_id);

    const answers = this.test.questions[q_index]?.answers;
    const a_index = answers?.findIndex((a) => a.id === answer_id);

    if (a_index !== -1) {
      this.test.questions[q_index].answers[a_index].text = text;
    }
  }

  resetAnswers() {
    this.test.questions.forEach((q, q_index) => {
      q.answers.forEach((a, a_index) => {
        this.test.questions[q_index].answers[a_index].is_right = false;
      })
    })
  }

  processAnswers() {
    const questions = this.test.questions.map((q) => {

      let answers = [] as IUserAnswer[];

      if (q.type === 'text') {
        answers = q.answers.map((a) => ({
          answer: a.id,
          text: a.text,
        }));
      } else {
        answers = q.answers.map((a) => ({
          answer: a.id,
          chosen: a.is_right,
        }));
      }

      return {
        question: q.id,
        answers: answers,
      }
    });

    return { test: this.test?.id, questions: questions };
  }

  complete() {
    this.test.completed = true;
  }

  setResults(results: any) {
    this.results = results;
  }
}

export default new TestAssessmentState();
