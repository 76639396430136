import { makeAutoObservable } from "mobx";

class DepartmentsState {
  departments: any[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  setDepartments(departments: any[]) {
    this.departments = departments;
  }
}

export default new DepartmentsState();
