import React, { useState, useEffect } from 'react';
import {
  Container,
  Top,
  Description,
  Text,
  CollapseButton,
  SkillsWrapper,
  SkillsTitle,
  ProgressBar
} from './styles';
import {
    Progress,
} from '@chakra-ui/react';
import { SkillsList } from '@/widgets';
import CollapseIcon from '@/assets/icons/collapse.svg';

export interface ICourseCardProps {
  course: any;
}

export const CourseCard: React.FC<ICourseCardProps> = ({ course }) => {
  const userId = JSON.parse(localStorage.getItem('user'))?.id; 
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [progress, setProgress] = useState(null);

  useEffect(() => {
    course.users.forEach((courseUser: any) => {
        if (userId === courseUser.user) {
            setProgress(courseUser.progress);
        }
      });
  }, [])

  return (
    <Container>
        <Top>
            <h5>{course.title}</h5>
        </Top>
        <Description
            style={{
                maxHeight: isCollapsed ? '64px' : 'fit-content'
            }}
        >
            <Text>{course.description}</Text>
        </Description>
        {course.description &&
            <CollapseButton
                onClick={() => setIsCollapsed(!isCollapsed)}
            >
                Показать {isCollapsed ? <>больше</> : <>меньше</>}
                <CollapseIcon
                    style={{
                        transform: isCollapsed ? '' : 'rotate(180deg)', transition: 'all .2s ease-in-out'
                    }}
                    width={10}
                    height={5}
                />
            </CollapseButton>
        }
        {typeof progress === 'number' &&
            <ProgressBar>
                <>{progress}%</>
                <Progress
                    background='var(--addable-gray)'
                    borderRadius={'100px'}
                    color='var(--main-purple)'
                    value={progress}
                    height='4px'
                    width={'100%'}
                />
            </ProgressBar>
        }
        {/* <SkillsWrapper>
            <SkillsTitle>Навыки</SkillsTitle>
            {course?.soft_skills && <SkillsList skills={course?.skills}/>}
        </SkillsWrapper> */}
        <a
            style={{ textDecoration: 'underline', color: 'var(--main-purple)' }}
            href={course.link}
            target='_blank'
        >
            Перейти к курсу
        </a>
    </Container>
  )
}
