import { makeAutoObservable } from "mobx";

type TAnalyticsType = 'current' | 'inProgress';
type TFilter = 'all' | 'groups' | 'departments' | 'employee' | 'team';

class AnalyticsState {
  analyticsType: TAnalyticsType = 'current';
  filter: TFilter = 'all';

  current: any = {
    hardSkills: [],
    softSkills: [],
    interests: [],
  }

  progress: any = {
    hardSkills: [],
    softSkills: [],
    interests: [],
  }

  constructor() {
    makeAutoObservable(this);
  }

  setAnalyticsType(value: TAnalyticsType) {
    this.analyticsType = value;
  }

  setFilter(value: TFilter) {
    this.filter = value;
  }

  setCurrentSofts(skills: any) {
    const sorted = skills.sort((a: any, b: any) => a?.count < b?.count);
    this.current.softSkills = sorted;
  }

  setCurrentHards(skills: any) {
    const sorted = skills.sort((a: any, b: any) => a?.count < b?.count);
    this.current.hardSkills = sorted;
  }

  setCurrentInterests(interests: any) {
    const sorted = interests.sort((a: any, b: any) => a?.count < b?.count);
    this.current.interests = sorted;
  }

  setProgressSofts(skills: any) {
    this.progress.softSkills = skills;
  }

  setProgressHards(skills: any) {
    this.progress.hardSkills = skills;
  }

  setProgressInterests(interests: any) {
    this.progress.interests = interests;
  }

  clearAnalytic() {
    this.current = {
      hardSkills: [],
      softSkills: [],
      interests: [],
    }
  
    this.progress = {
      hardSkills: [],
      softSkills: [],
      interests: [],
    }
  }
}

export default new AnalyticsState();
