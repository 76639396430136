import styled from 'styled-components';

export const PopoverButton = styled.button`
    display: block;
    width: 100%;
    height: 28px;
    font-size: 12px;
    text-align: left;
`;

