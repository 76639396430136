import React from 'react';
import {
  Box,
} from '@chakra-ui/react';
import {
  InProgressHint,
  CompletedHint,
  NotStartedHint,
} from './styles';
import LoadingIcon from '@/assets/icons/loading.svg';
import HexagonIcon from '@/assets/icons/hexagon.svg';
import CheckIcon from '@/assets/icons/check.svg';

export interface ITaskStatusProps {
  status: string;
}

export const TaskStatusHint: React.FC<ITaskStatusProps> = ({ status }) => {
  if (status === 'In process') {
    return (
      <InProgressHint>
        <Box>В процессе</Box>
        <LoadingIcon />
      </InProgressHint>
    );
  } else if (status === 'Done') {
    return (
      <CompletedHint>
        <Box>Завершено</Box>
        <CheckIcon />
      </CompletedHint>
    );
  } else if (status === 'Not started' || status === 'Unavailable') {
    return (
      <NotStartedHint>
        <Box>Не начато</Box>
        <HexagonIcon />
      </NotStartedHint>
    );
  }
}
