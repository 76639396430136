import React, { useState, useEffect } from 'react';
import {
  Container,
  Top,
} from './styles';
import {
  InputGroup,
  InputLeftElement,
  Input,
} from '@chakra-ui/react';
import SearchIcon from '@/assets/icons/search.svg';
import { useDebounce } from '@/shared';
import managerTestState from '@/pages/ManagerTests/store/managerTestState';
import { getEmployeeTests, getGroupTests } from '@/entities/tests';
import { getEmployeeById } from '@/features/employee';

export const ManagerTopBar: React.FC = () => {
  const [searchMyTerm, setSearchMyTerm] = useState('');
  const [searchEmployeesTerm, setSearchEmployeesTerm] = useState('');

  const debouncedSearchMyTerm = useDebounce(searchMyTerm, 500);
  const debouncedSearchEmployeesTerm = useDebounce(searchEmployeesTerm, 500);

  const userId = JSON.parse(localStorage.getItem('user'))?.id;
  
  function handleMySearch() {
    managerTestState.myTestsIsLoading = true;
    getEmployeeTests(userId, debouncedSearchMyTerm)
      .then((res: any) => {
        managerTestState.setMyTests(res.data);
      })
      .catch((error) => {
        console.log(error);
      })
  }

  function handleEmployeesSearch() {
    managerTestState.employeesTestsIsLoading = true;
    getEmployeeById(userId)
      .then((res: any) => {
        getGroupTests(res.data?.group, debouncedSearchEmployeesTerm)
          .then((res: any) => {
            managerTestState.setEmployeesTests(res.data);
          })
          .catch(e => {
            console.log(e);
          })
      })
      .catch(e => {
        console.log(e);
      })
  }

  useEffect(() => {
    if (typeof debouncedSearchMyTerm === 'string') {
      handleMySearch();
    }
  }, [debouncedSearchMyTerm]);

  useEffect(() => {
    if (typeof debouncedSearchEmployeesTerm === 'string') {
      handleEmployeesSearch();
    }
  }, [debouncedSearchEmployeesTerm]);

  return (
    <>
      <Container>
        <Top>
          {managerTestState.filter === 'my' &&
            <InputGroup padding={'6px'}>
              <InputLeftElement pointerEvents='none'>
                <SearchIcon
                  color='var(--addable-gray)'
                  width={18}
                  height={18}
                />
              </InputLeftElement>
              <Input
                value={searchMyTerm}
                onChange={e => setSearchMyTerm(e.target.value)}
                color='messenger'
                height={'28px'}
                variant='flushed'
                placeholder={'Поиск по вашим тестам'}
              />
            </InputGroup>
          }
          {managerTestState.filter === 'employees' &&
            <InputGroup padding={'6px'}>
              <InputLeftElement pointerEvents='none'>
                <SearchIcon
                  color='var(--addable-gray)'
                  width={18}
                  height={18}
                />
              </InputLeftElement>
              <Input
                value={searchEmployeesTerm}
                onChange={e => setSearchEmployeesTerm(e.target.value)}
                color='messenger'
                height={'28px'}
                variant='flushed'
                placeholder={'Поиск по тестам сотрудников'}
              />
            </InputGroup>
          }
        </Top>
      </Container>
    </>
  )
}

