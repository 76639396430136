import styled from 'styled-components';

export const Container = styled.div`
    display: flex;  
    align-items: center;
    width: max-content;
    word-break: break-all;
    gap: 6px;
    min-height: 24px;
    padding: 0 6px;
    border-radius: 4px;
    background: var(--main-purple);
    border: 1px solid var(--hover-purple);
`;

export const Text = styled.p`
    font-size: 12px;
    color: var(--main-white);
`;
