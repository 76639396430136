import styled from "styled-components";

export const Anvelope = styled.div`
  box-sizing: border-box;
  width: 100%;
`;

export const GradationBar = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;
`;

export const Gradation = styled.div`
  box-shadow: inset 0 1px #E6E6E6;
  box-sizing: border-box;
  height: 100%;
  padding: 12px;
  display: flex;
  align-items: center;
  transition: .3s;
  font-size: 11px;
`;