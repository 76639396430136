import React, { useState, useEffect } from 'react';
import { 
  Stack,
  HStack,
  FormControl,
  FormLabel,
  Input,
  Button,
  CloseButton,
  Box,
  Flex,
  Image,
} from '@chakra-ui/react';
import { Question, LabelStyles } from './styles';
import testCreationState from '@/widgets/TestCreationForm/store/testCreationState';
import testEditState from '@/widgets/TestEditForm/store/testEditState';
import { IQuestion } from '@/shared/types/tests/types';
import AddIcon from '@/assets/icons/add.svg';
import { useDebounce } from '@/shared';
import { observer } from 'mobx-react-lite';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css'

export interface IOpenEndedQuestionProps {
  question: IQuestion;
  mode: 'create' | 'edit';
}

export const OpenEndedQuestion: React.FC<IOpenEndedQuestionProps> = observer(({ question, mode }) => {
  const [title, setTitle] = useState('');
  const [answer, setAnswer] = useState('');
  const [fileContent, setFileContent] = useState<any>(
    question.images &&
    (question.images.length > 0 ? question.images[0].image : null)
  );

  const debouncedTitle = useDebounce(title, 500);
  const debouncedAnswer = useDebounce(answer, 500);

  useEffect(() => {
    if (mode === 'create') {
      testCreationState.setQuestionTitle(question.id, debouncedTitle);
    } else if (mode === 'edit') {
      testEditState.setQuestionTitle(question.id, debouncedTitle);
    }
  }, [debouncedTitle]);

  useEffect(() => {
    if (mode === 'create') {
      testCreationState.setAnswerText(question.id, question.answers[0] && question.answers[0].id, debouncedAnswer);
    } else if (mode === 'edit') {
      testEditState.setAnswerText(question.id, question.answers[0] && question.answers[0].id, debouncedAnswer);
    }
  }, [debouncedAnswer]);

  function handleQuestionPhoto(e: any) {
    const file = e.target.files[0];

    const reader = new FileReader();
    reader.onload = () => {
      setFileContent(reader.result);

      if (mode === 'create') {
        testCreationState.setQuestionImage(question.id, {image: file});
      } else if (mode === 'edit') {
        testEditState.setQuestionImage(question.id, {image: file});
      }
    };

    reader.readAsDataURL(file);
  }

  return (
    <Stack
      position="relative"
      w="full"
    >
      <FormControl isRequired>
        <FormLabel>
          <Question>
            Вопрос
          </Question>
        </FormLabel>

        <Input
          onChange={(e: any) => {setTitle(e.target.value)}}
          variant="flushed"
          placeholder='Вопрос с развернутым ответом'
          defaultValue={question.text}
        />
      </FormControl>

      <HStack>
        <Flex align="center" gap={2}>
          <Box mt={2}>
            {(!fileContent) &&
              <LabelStyles
                onClick={() => {}}
              >
                <Box
                  fontSize="sm"
                  fontWeight="900"
                >
                  Фото
                </Box>
                <AddIcon color="var(--main-purple)"/>

                <input
                  style={{display: 'none'}}
                  id="file-upload"
                  type="file"
                  onChange={handleQuestionPhoto}
                />
              </LabelStyles>
            }

            {fileContent && 
              <Box my={4}>
                <Zoom>
                  <Image
                    borderRadius="md"
                    src={fileContent}
                    alt="Картинка к вопросу"
                  />
                </Zoom>
              </Box>
            }
          </Box>

          {fileContent &&
            <CloseButton
              size="lg"
              right={0}
              color="var(--main-purple)"
              onClick={() => {
                setFileContent('');
                if (question.images) {
                  question.images = [];
                }
              }}
            />
          }
        </Flex>
      </HStack>

      <Input
        onChange={(e: any) => {setAnswer(e.target.value)}}
        variant="flushed"
        placeholder='краткий ответ'
        defaultValue={question?.answers[0]?.text}
      />

      <CloseButton
        position="absolute"
        top={0}
        right={0}
        size="lg"
        onClick={() => {
          if (mode === 'create') {
            testCreationState.removeQuestion(question.id);
          } else if (mode === 'edit') {
            testEditState.removeQuestion(question.id);
          }
        }}
      />
    </Stack>
  );
});
