import axios from 'axios';

const API = process.env.API_URL;
const access = JSON.parse(localStorage.getItem('accessToken'));
const refresh = JSON.parse(localStorage.getItem('refreshToken'));

const competenceAnalyticsBaseUrl: string = `${API}/analytics/competences`;


/**
 * Gets competence analytics for the whole company
 * @param  {string} type      Type of analytics (current or inProgress)
 * @param  {number} companyId Id of the company to get analytics for
 * @param  {string} startDate Date to count the analytics from
 * @param  {string} endDate   Date to count the analytics up to
 * @return {object}           Data from the server
 */
export const getCompetenceAnalyticsForCompany = async (
  type: string,
  companyId: number,
  startDate?: string,
  endDate?: string
) => {
  const response = await axios<any>({
    // url: `${competenceAnalyticsBaseUrl}/${apiType}/?for=company&company_id=${companyId}&start_date=${startDate ? startDate : ''}&end_date=${endDate ? endDate : ''}`,
    url: `${competenceAnalyticsBaseUrl}/?for=company&company_id=${companyId}&type=${type}&start_date=${startDate ? startDate : ''}&end_date=${endDate ? endDate : ''}`,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      "Access-Control-Allow-Headers": "*",
      "Authorization": `Bearer ${access}`,
    },
  })

  return response;
}

/**
 * Gets competence analytics for a specific company employee
 * @param  {string} type       Type of analytics (current or inProgress)
 * @param  {number} employeeId Id of the employee to get analytics for
 * @param  {string} startDate  Date to count the analytics from
 * @param  {string} endDate    Date to count the analytics up to
 * @return {object}            Data from the server
 */
export const getCompetenceAnalyticsForEmployee = async (
  type: string,
  employeeId: number,
  startDate?: string,
  endDate?: string
) => {
  // const apiType = type === 'inProgress' ? 'in-progress' : 'current';
  
  const response = await axios<any>({
    // url: `${competenceAnalyticsBaseUrl}/${apiType}/?for=user&user_id=${employeeId}&start_date=${startDate ? startDate : ''}&end_date=${endDate ? endDate : ''}`,
    url: `${competenceAnalyticsBaseUrl}/?for=user&user_id=${employeeId}&type=${type}&start_date=${startDate ? startDate : ''}&end_date=${endDate ? endDate : ''}`,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Headers': '*',
      'Authorization': `Bearer ${access}`,
    }
  })

  return response;
}

export const getCompetenceAnalyticsForTempEmployee = async (
  type: string,
  employeeId: number,
  startDate?: string,
  endDate?: string
) => {
  // const apiType = type === 'inProgress' ? 'in-progress' : 'current';
  
  const response = await axios<any>({
    // url: `${competenceAnalyticsBaseUrl}/${apiType}/?for=temp_user&temp_user_id=${employeeId}&start_date=${startDate ? startDate : ''}&end_date=${endDate ? endDate : ''}`,
    url: `${competenceAnalyticsBaseUrl}/?for=temp_user&user_id=${employeeId}&type=${type}&start_date=${startDate ? startDate : ''}&end_date=${endDate ? endDate : ''}`,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Headers': '*',
      'Authorization': `Bearer ${access}`,
    }
  })

  return response;
}