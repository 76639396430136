import styled from "styled-components";

export const GradationBar = styled.div`
    background-color: #F0F3FF;
    box-sizing: border-box;
    width: 100%;
    height: 15px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    overflow: hidden;
    border-radius: 4px;
`;

export const Gradation = styled.div`
    background-color: #B7C6FF;
    box-sizing: border-box;
    height: 100%;
    display: flex;
    align-items: center;
    transition: .3s;
    font-size: 11px;
`;