import React, { useState, useEffect } from 'react';
import {
  Container,
  Top,
  Title,
  ButtonText,
  ProgressWrapper,
  Procent,
  Info,
  Left,
  P,
  Right,
  SubTitle,
  Deadline,
  DeadlineButton,
} from './styles';
import EditIcon from '@/assets/icons/edit.svg';
import { Button, Progress, useDisclosure } from '@chakra-ui/react';
import { getVacancyById } from '@/entities/vacanci';
import {
  EditCareerPopup,
  setCareerDeadline,
} from '@/features/career';
import { IUser } from '@/shared';
import AddIcon from '@/assets/icons/add.svg';
import { DatePicker } from 'antd';

export interface CareerWidgetProps {
  user: IUser;
}

export const CareerWidget: React.FC<CareerWidgetProps> = ({ user }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [vacancy, setVacancy] = useState<any>();
  const [specialization, setSpecialization] = useState<any>();
  const [activeElement, setActiveElement] = useState('button');
  const [date, setDate] = useState();
  const [deadline, setDeadline] = useState(user?.career_tree_deadline);
  
  const vacancyId = user?.active_vacancy?.id;

  useEffect(() => {
    let next_step_vacancy: any = user?.specialization;

    if (next_step_vacancy !== undefined && next_step_vacancy !== null) {
      while (
        next_step_vacancy.children !== undefined && 
        next_step_vacancy.children.length > 0
      ) {
        next_step_vacancy = next_step_vacancy.children[0];
      }
    }

    setSpecialization(next_step_vacancy);
  }, [])

  useEffect(() => {
    setCareerDeadline(user?.id, date)
      .then((res) => {
        setDeadline(res.data?.career_tree_deadline);
        setActiveElement('button');
      })
      .catch((e) => {
        console.log(e);
      })
  }, [date])

  function handleCareerDatesChange(date: any) {
    setDate(date.format('DD.MM.YYYY'));
  }

  return (
    <>
      <Container>
        <Top>
          <Title>{specialization?.title}</Title>
          <Button
            onClick={onOpen}
            border='1px var(--addable-gray) solid'
            borderRadius='8px'
            bg='var(--main-white)'
            leftIcon={<EditIcon color='var(--main-purple)' />} 
          >
            <ButtonText>Редактировать</ButtonText>
          </Button>
        </Top>
        <ProgressWrapper>
          <Procent>{user?.career_progress} %</Procent>
          <Progress
            colorScheme={'whiteAlpha'}
            background='#6B89F5'
            borderRadius={'100px'}
            value={user?.career_progress}
            height='4px'
            width={'100%'}
          />
        </ProgressWrapper>
        <Info>
          <Left>
            <P>{specialization?.description}</P>
          </Left>
          <Right>
            <SubTitle>Сроки получения</SubTitle>
            <Deadline>{deadline}</Deadline>
            {activeElement === 'button' &&
              <DeadlineButton
                onClick={() => setActiveElement('picker')}
              >
                <AddIcon
                  width={16}
                  height={16}
                  color='#fff'
                />
                Добавить сроки
              </DeadlineButton>
            }
            {activeElement === 'picker' &&
              <DatePicker
                style={{ width: '100%', paddingInline: 12, background: '#fff' }}
                variant="borderless"
                onChange={handleCareerDatesChange}
                format="DD/MM/YYYY"
              />
            }
          </Right>
        </Info>
      </Container>
      <EditCareerPopup isOpen={isOpen} onClose={onClose} />
    </>
  )
}
