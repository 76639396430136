import React, { useState, useEffect } from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    Select,
} from '@chakra-ui/react';
import { Filters } from '@/features/common';
import { IUser } from '@/shared';
import { inviteUser } from '@/features/tests';
import { observer } from 'mobx-react-lite';
import { getGroupById } from '@/features/department';
import testsState from '@/pages/DashboardHRTests/store/testsState';

export const GiveTestPopup = observer(({ isOpen, onClose }: {isOpen: boolean, onClose: () => void }) => {
  const [filter, setFilter] = useState('departments');

  const [selectedEmployeeId, setSelectedEmployeeId] = useState<number>();
  const [selectedGroupId, setSelectedGroupId] = useState<number>();
  const [selectedDepartmentId, setselectedepartmentId] = useState<number>();

  const options = [
    {
      id: 1,
      name: 'По департаментам',
      onClick: () => setFilter('departments'),
    },
    {
      id: 2,
      name: 'По отделам',
      onClick: () => setFilter('groups'),
    },
    {
      id: 3,
      name: 'По сотруднику',
      onClick: () => setFilter('employee'),
    },
  ];

  function inviteEmployee() {
    if (selectedEmployeeId) {
      const invited: number[] = [selectedEmployeeId];
      inviteUser(testsState.currentTest?.id, invited)
        .then((res: any) => {
          console.log(res.data);
        })
        .catch((error) => {
          console.log(error);
        })
    }
  }

  function inviteGroup() {
    if (selectedGroupId) {
      getGroupById(selectedGroupId)
        .then((res) => {
          const invited: number[] = [];

          res.data.users.forEach((user: IUser) => {
            invited.push(user.id);
          })
          inviteUser(testsState.currentTest?.id, invited)
            .then((res: any) => {
              console.log(res.data);
            })
            .catch((error) => {
              console.log(error);
            })
        })
        .catch(e => {
          console.log(e);
        })
    }
  }

  function inviteDepartment() {
    if (selectedDepartmentId) {
      getGroupById(selectedDepartmentId)
        .then((res) => {
          const invited: number[] = [];
          testsState.currentTest.invited.forEach((user: IUser) => {
            invited.push(user.id);
          })
          res.data.children.forEach((group: any) => {
            group.users.forEach((user: IUser) => {
              invited.push(user.id);
            })
          }); 

          inviteUser(testsState.currentTest?.id, invited)
          .then((res: any) => {
            console.log(res.data);
          })
          .catch((error) => {
            console.log(error);
          })
        })
        .catch(e => {
          console.log(e);
        })
    }
  }

  function handleInvite() {
    inviteEmployee();
    inviteDepartment();
    inviteGroup();
    onClose();
  }

  return (
    <Modal
      size={'2xl'}
      blockScrollOnMount={false}
      isOpen={isOpen}
      onClose={onClose}
      isCentered
    >
      <ModalOverlay />
        <ModalContent borderRadius={16}>
          <ModalHeader alignItems={'center'}>
            <h3 style={{ textAlign: 'center' }}>Назначить тест</h3>
          </ModalHeader>
          <ModalCloseButton top={'14px'} />
          <ModalBody
            padding='20px'
            display='flex'
            flexDirection='column'
            gap='30px'
            borderTop='1px solid var(--addable-gray)'
            borderBottom='1px solid var(--addable-gray)'
          >
            <Filters options={options}/>
            {filter === 'employee' &&
              <Select
                onChange={(e: any) => setSelectedEmployeeId(e.target.value)}
                isReadOnly={false}
                variant='flushed'
                placeholder='Выберите сотрудника'
              >
                {testsState.employees?.map((employee: IUser) =>
                  <option key={employee.id} value={employee.id}>{employee.last_name} {employee.first_name} {employee.patronymic}</option> 
                )}
              </Select>
            }
            {filter === 'departments' &&
              <Select
                onChange={(e: any) => setselectedepartmentId(e.target.value)}
                isReadOnly={false}
                variant='flushed'
                placeholder='Выберите департамент'
              >
                {testsState.departments?.map((department: any) =>
                  <option key={department.id} value={department.id}>{department.name}</option> 
                )}
              </Select>
            }
            {filter === 'groups' &&
              <Select
                onChange={(e: any) => setSelectedGroupId(e.target.value)}
                isReadOnly={false}
                variant='flushed'
                placeholder='Выберите отдел'
              >
                {testsState.groups?.map((group: any) =>
                  <option key={group.id} value={group.id}>{group.name}</option> 
                )}
              </Select>
            }
          </ModalBody>
          <ModalFooter gap='8px' display='flex'>
            <Button
              type='button'
              width='100%'
              onClick={onClose}
              variant='outline'
              color='var(--main-purple)'
              borderRadius='8px'
            >
              Отмена
            </Button>

            <Button
              onClick={handleInvite}
              type='submit'
              width='100%'
              borderRadius='8px'
              bg='var(--main-purple)'
              _hover={{ bg: 'var(--hover-purple)' }}
              color='#fff'
            >
              Сохранить
            </Button>
          </ModalFooter>
        </ModalContent>
    </Modal>
  );
});
