import React, { useState, useEffect } from 'react';
import {
  Stack,
  HStack,
  FormControl,
  CheckboxGroup,
  Checkbox,
  Box,
  Image,
  Flex,
} from '@chakra-ui/react';
import { IQuestion, IAnswer } from '@/shared/types/tests/types';
import testAssessmentState from '@/widgets/TestAssessmentDrawer/store/testAssessmentState';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css'

export interface IMultipleChoiceAssessmentProps {
  question: IQuestion;
}

export interface IAnswerCheckboxInputProps {
  question_id: number;
  answer: IAnswer;
}

const AnswerCheckboxInput: React.FC<IAnswerCheckboxInputProps> = ({ question_id, answer }) => {
  return (
    <HStack>
      <Checkbox
        borderColor="var(--main-purple)"
        size="lg"
        value={answer.id.toString()}
      />

      {answer?.image ?
        <Flex my={2} w="50%" justify="center">
          <Zoom>
            <Image borderRadius="md"  src={answer?.image} alt="Картинка к вопросу" />
          </Zoom>
        </Flex> :
        <Box>{answer.text}</Box>
      }
    </HStack>
  );
}

export const MultipleChoiceAssessment: React.FC<IMultipleChoiceAssessmentProps> = ({ question }) => {
  const [choices, setChoices] = useState([]);

  useEffect(() => {
    testAssessmentState.setUserAnswers(question.id, choices);
  }, [JSON.stringify(choices)]);

  return (
    <Stack w="full">
      <FormControl>
        <Stack spacing={4}>
          <h3>{question.text}</h3>
          {question.images[0] &&
            <Flex my={2} w="50%" justify="center">
              <Zoom>
                <Image
                  borderRadius="md"
                  src={question.images[0].image}
                  alt="Картинка к вопросу"
                />
              </Zoom>
            </Flex>
          }

          <CheckboxGroup onChange={(choices) => {setChoices(choices)}}>
            <Stack spacing={4}>
              {question?.answers.map((answer) => 
                <AnswerCheckboxInput key={answer.id} question_id={question.id} answer={answer} />
              )}
            </Stack>
          </CheckboxGroup>
        </Stack>
      </FormControl>
    </Stack>
  );
}
