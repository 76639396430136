import styled from "styled-components";

export const Top = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Title = styled.h3`

`;

export const H6 = styled.h6`
    color: var(--main-purple);
`;

export const PlacesList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
