import React, { useEffect, useState } from 'react';
import { CareerSteps } from '@/widgets';
import { Filters } from '@/features/common';
import { Page, CareerWidgetWrapper } from './styles';
import { CareerWidget } from '@/widgets';
import { CareerSkills } from '@/widgets';
import { getEmployeeById } from '@/features/employee';

export const Career: React.FC = () => {
  const [activeTab, setActiveTab] = useState<string>('road');
  const [user, setUser] = useState(null);

  const userId = JSON.parse(localStorage.getItem('user'))?.id;

  useEffect(() => {
    getEmployeeById(userId)
      .then((res) => {
        setUser(res.data)
      })
      .catch((e) => {
        console.log(e);
      })
  }, [])

  const options = [
    {
      id: 1,
      name: 'Карьерный путь',
      onClick: () => setActiveTab('road'),
    },
    {
      id: 2,
      name: 'Список навыков',
      onClick: () => setActiveTab('skills'),
    },
  ];

  return (
    <Page>
      <Filters options={options}/>
      {activeTab === 'road' &&
        <CareerSteps/>
      }
      {activeTab === 'skills' &&
        <CareerSkills/>
      }
      {user &&
        <CareerWidgetWrapper>
          <CareerWidget user={user}/>
        </CareerWidgetWrapper>
      }
    </Page>
  )
}
