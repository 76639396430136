import React from 'react';
import { Page, Container } from './styles';
import { Card } from '@/shared'; 
import { EmptyResponse } from '../EmptyResponse/EmptyResponse';
import { CourseCard } from '@/entities/course';
import { observer } from 'mobx-react-lite';
import coursesState from '@/entities/course/store/coursesState';

export const CoursesList: React.FC = observer(() => {
  return (
    <Page>
      {coursesState.coursesFilter === 'all' &&
        <>
          {coursesState.courses.length === 0 && <EmptyResponse/>}
          <Container>
            {coursesState.courses.map(course =>
              <Card key={course.id}>
                <CourseCard course={course} key={course.id}/>
              </Card>
            )}
          </Container>
        </>
      }
      {coursesState.coursesFilter === 'active' &&
        <>
          {coursesState.activeCourses.length === 0 && <EmptyResponse/>}
          <Container>
            {coursesState.activeCourses.map(course =>
              <Card key={course.id}>
                <CourseCard course={course} key={course.id}/>
              </Card>
            )}
          </Container>
        </>
      }
      {coursesState.coursesFilter === 'archived' &&
        <>
          {coursesState.archivedCourses.length === 0 && <EmptyResponse/>}
          <Container>
            {coursesState.archivedCourses.map(course =>
              <Card key={course.id}>
                <CourseCard course={course} key={course.id}/>
              </Card>
            )}
          </Container>
        </>
      }
      {coursesState.coursesFilter === 'stoped' &&
        <>
          {coursesState.stopedCourses.length === 0 && <EmptyResponse/>}
          <Container>
            {coursesState.stopedCourses.map(course =>
              <Card key={course.id}>
                <CourseCard course={course} key={course.id}/>
              </Card>
            )}
          </Container>
        </>
      }
    </Page>
  )
});
