import React from 'react';
import { TaskCard } from '@/entities/projects';
import { observer } from 'mobx-react-lite';
import { EmptyResponse } from '../EmptyResponse/EmptyResponse';
import projectsPageState from '@/pages/Projects/store/projectsPageState';

export const TaskList: React.FC = observer(() => {
  return (
    <>
      {projectsPageState.tasks.length === 0 &&
        <EmptyResponse/>
      }
      {projectsPageState.tasks && projectsPageState.tasks.map((task) => 
        <TaskCard key={task.id} task={task} />
      )}
    </>
  )
});
