import React, { useEffect, useState } from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    Input,
    InputGroup,
    InputLeftElement,
    Avatar,
} from '@chakra-ui/react';
import SearchIcon from '@/assets/icons/search.svg';
import { useDebounce } from '@/shared';
import {
  Line,
  Person,
} from './styles';
import avatar from '@/assets/images/avatar.jpg';
import { IUser } from '@/shared';
import { getSurveyedUsers } from '@/features/tests';
import testInfoDrawerState from '@/widgets/TestsList/store/testInfoDrawerState';
import { observer } from 'mobx-react-lite';
import { PersonCard } from '@/shared';

export const TestProgressionPopup = observer(({ isOpen, onClose }: {isOpen: boolean, onClose: () => void }) => {
  const [searchTerm, setSearchTerm] = useState('');
  /*
  const [unSurveyed, setUnSurveyed] = useState<IUser[]>([]);
  const [surveyed, setSurveyed] = useState<IUser[]>([]);
  */

  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  
  function handleSearch() {
    console.log(debouncedSearchTerm);
  }

  useEffect(() => {
    if (typeof debouncedSearchTerm === 'string') {
      handleSearch();
    }
  }, [debouncedSearchTerm]);

  /*
  useEffect(() => {
    getSurveyedUsers(testInfoDrawerState.test.id)
      .then((res) => {
        console.log(JSON.stringify(res.data, null, '  '));
        setUnSurveyed(res.data);
      })
      .catch(e => {
        console.log(e);
      })
  }, [])
  */

  return (
    <Modal
      size={'4xl'}
      blockScrollOnMount={false}
      isOpen={isOpen}
      onClose={onClose}
      isCentered
    >
      <ModalOverlay />
        <ModalContent borderRadius={16}>
          <ModalHeader alignItems={'center'}>
            <h3 style={{ textAlign: 'center' }}>Прохождение теста</h3>
          </ModalHeader>
          <ModalCloseButton top={'14px'} />
          <ModalBody
            padding='20px'
            display='flex'
            flexDirection='column'
            gap='30px'
            borderTop='1px solid var(--addable-gray)'
            borderBottom='1px solid var(--addable-gray)'
          >
            {/* <InputGroup padding={'6px'}>
              <InputLeftElement pointerEvents='none'>
                <SearchIcon
                  color='var(--addable-gray)'
                  width={18}
                  height={18}
                />
              </InputLeftElement>
              <Input
                value={searchTerm}
                onChange={e => setSearchTerm(e.target.value)}
                color='messenger'
                height={'28px'}
                variant='flushed'
                placeholder='Поиск по сотрудникам компании'
              />
            </InputGroup> */}

            <div>
              <h5>Прошедшие тест</h5>
              <Line>
                {testInfoDrawerState.test.completed_by?.map((user: IUser) =>
                  <PersonCard
                    role={user?.role}
                    key={user.id}
                    id={user?.id}
                    image={user.image}
                    name={`${user.last_name} ${user.first_name} ${user.patronymic}`}
                    vacancy={(user.vacancy && user.vacancy.length > 0) && user.vacancy[0]}
                  />
                )}
                {testInfoDrawerState.test.completed_by?.length === 0 && <>Пока никто не прошел тест</>}
              </Line>
            </div>

            <div>
              <h5>Не прошедшие</h5>
              <Line>
                {testInfoDrawerState.test.not_completed_by?.map((user: any) => 
                  <PersonCard
                    role={user?.role}
                    id={user?.id}
                    key={user.id}
                    image={user.image}
                    name={`${user.last_name} ${user.first_name} ${user.patronymic}`}
                    vacancy={(user.vacancy && user.vacancy.length > 0) && user.vacancy[0]}
                  />
                )}
                {testInfoDrawerState.test.not_completed_by?.length === 0 && <>Все прошли тест</>}
              </Line>
            </div>
          </ModalBody>

          <ModalFooter gap='8px' display='flex'>
            <Button
              type='button'
              width='100%'
              onClick={onClose}
              variant='outline'
              color='var(--main-purple)'
              borderRadius='8px'
            >
              Закрыть
            </Button>
          </ModalFooter>
        </ModalContent>
    </Modal>
  );
});
