import styled from "styled-components";

export const Container = styled.div`
  margin-top: 98px;
  min-width: 208px;
  border-right: 1px solid #e6e6e6;
`;

export const TaskContainer = styled.button`
  text-align: left;
  width: 100%;
  height: fit-content;
  min-height: 77px;
  max-height: 77px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  border-top: 1px solid #e6e6e6;
  padding: 16px 0;
  overflow: auto;
`;