import React, { useState, useEffect } from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    Divider,
    Button,
    Box,
    VStack,
} from '@chakra-ui/react';
import {
  Line,
} from './styles';
import avatar from '@/assets/images/avatar.jpg';
import { TEmployeeRole } from '@/shared/types/projects/types';
import { getApplicants } from '@/features/projects';
import projectState from '@/widgets/ProjectDrawer/store/projectState';
import { observer } from 'mobx-react-lite';
import { acceptRoleRequest } from '@/features/projects';
import { PersonCard } from '@/shared';

export interface IRoleApplicantsPopupProps {
  isOpen: boolean;
  onClose: () => void;
  role: TEmployeeRole;
}

export const RoleApplicantsPopup: React.FC<IRoleApplicantsPopupProps> = observer(({ isOpen, onClose, role }) => {
  const [requests, setRequests] = useState([]);

  useEffect(() => {
    if (isOpen) {
      handleGetRequests();
    }
  }, [isOpen])

  function handleGetRequests() {
    getApplicants(projectState.selectedProject.id, role.id)
      .then((res) => {
        setRequests(res.data);
      })
      .catch((e) => {
        console.log(e);
      })
  }

  function handleAcceptRequest(requestId: number) {
    acceptRoleRequest(requestId)
      .then((res) => {
        onClose();
      })
      .catch((e) => {
        console.log(e);
      })
  }
  
  return (
    <Modal
      size={'4xl'}
      blockScrollOnMount={false}
      isOpen={isOpen}
      onClose={onClose}
      isCentered
    >
      <ModalOverlay />

      <ModalContent borderRadius={16}>
        <ModalHeader alignItems={'center'}>
          <h3 style={{ textAlign: 'center' }}>Заявки на роль «{role.title}»</h3>
        </ModalHeader>

        <ModalCloseButton top={'14px'} />

        <Divider />

        <ModalBody
          padding='20px'
          display='flex'
          flexDirection='column'
          gap='30px'
        >
          <Line>
            {requests?.map((request: any) => 
              <VStack key={request.sender.id}>
                <PersonCard
                  role={request?.sender?.role}
                  id={request?.sender?.id}
                  image={request?.sender.image}
                  name={`${request.sender.last_name} ${request.sender.first_name} ${request.sender.patronymic}`}
                  vacancy={(request?.sender.vacancy && request?.sender.vacancy.length > 0) && request?.sender.vacancy[0]}
                />
                <Button
                  type='button'
                  variant='ghost'
                  color='var(--main-purple)'
                  borderRadius='8px'
                  fontSize={12}
                  onClick={() => handleAcceptRequest(request?.id)}
                >
                  Дать роль
                </Button>
              </VStack>
            )}
            {requests?.length === 0 &&
              <Box w="full" textAlign="center" fontSize={16}>Заявок нет</Box>
            }
          </Line>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
});
