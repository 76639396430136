import React from 'react';
import { Container } from './styles';
import { Project } from './Project';

export interface ProjectListColumnProps {
  projects: any;
}

export const ProjectListColumn: React.FC<ProjectListColumnProps> = ({ projects }) => {
  return (
    <Container>
      {projects?.map((project: any) =>
        <Project project={project} key={Number(project.id)}/>
      )}
    </Container>
  )
}
