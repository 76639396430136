import { makeAutoObservable } from "mobx";

class NotificationsState {
  notifications: any[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  setNotifications(notifications: any[]) {
    this.notifications = notifications;
  }

  addNotification(notification: any) {
    this.notifications = [...this.notifications, notification];
  }

  deleteNotification(notificationId: number) {
    this.notifications = this.notifications.filter((notify) => notify.id !== notificationId)
  }
}

export default new NotificationsState();
