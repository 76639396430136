import styled from "styled-components";

export const Page = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
`;

export const CareerWidgetWrapper = styled.div`
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1;
  width: calc(100vw - 60px - 224px);
`;
