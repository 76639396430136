import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  gap: 8px;
  padding: 8px;
`;

export const Status = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 8px;
`;

export const Details = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`;

export const Title = styled.h5`
  font-size: 12px;
  font-weight: 600;
`;

export const Text = styled.p`
  font-weight: 10px;
  opacity: .5;
`;

export const Buttons = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Button = styled.button`
  font-size: 12px;
  font-weight: 600;
  color: var(--main-purple);
`;
