import React, { useState } from 'react';
import { IVacanci } from '@/shared';
import {
    Container,
    Top,
    Description,
    Text,
    CollapseButton,
    SkillsWrapper,
    SkillsTitle,
} from './styles';
import DotsIcon from '@/assets/icons/dots.svg';
import CollapseIcon from '@/assets/icons/collapse.svg';
import { SkillsList } from '@/widgets';
import { observer } from 'mobx-react-lite';
import vacanciesState from '../store/vacanciesState';
import {
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverBody,
} from '@chakra-ui/react';
import { addVacanciForCompany } from '@/features/company/api';
import { deleteVacancy } from '@/features/vacanci';
import { EditVacancyPopup } from '@/features/vacanci';
import { useDisclosure } from '@chakra-ui/react';
import toast, { Toaster } from 'react-hot-toast';

export interface VacanciCardProps {
    vacanci: IVacanci;
}

export const VacanciCard: React.FC<VacanciCardProps> = observer(({ vacanci }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [isCollapsed, setIsCollapsed] = useState(true);
    const companyId = JSON.parse(localStorage.getItem('user')).company[JSON.parse(localStorage.getItem('user')).company.length - 1];

    function handleAddVacanciForCompany() {
        addVacanciForCompany(vacanci.id, companyId)
            .then((res) => {
                console.log(res.data);
                toast.success('Вакансия успешно добавлена в локальную базу!');
            })
            .catch((e) => {
                console.log(e);
            })
    }

    function handleDeleteVacancy() {
        deleteVacancy(vacanci.id)
            .then((res) => {
                vacanciesState.deleteVacancy(vacanci.id);
            })
            .catch((e) => {
                console.log(e);
            })
    }

  return (
    <>
        <Container>
            <Top>
                <h5>{vacanci.grade} {' '} {vacanci.title}</h5>
                <Popover placement='bottom-end'>
                    <PopoverTrigger>
                        <button>
                            <DotsIcon color='var(--main-purple)'/>
                        </button>
                    </PopoverTrigger>
                    {vacanciesState.vacanciesFilter === 'global' &&
                        <PopoverContent width={'155px'}>
                            <PopoverBody>
                                <button
                                    style={{ fontSize: '12px' }}
                                    onClick={handleAddVacanciForCompany}
                                >
                                    Добавить в свою базу
                                </button>
                            </PopoverBody>
                        </PopoverContent>
                    }
                    {vacanciesState.vacanciesFilter === 'company' &&
                        <PopoverContent width={'174px'}>
                            <PopoverBody>
                                <button
                                    style={{ fontSize: '12px' }}
                                    onClick={onOpen}
                                >
                                    Редактировать вакансию
                                </button>
                                <button
                                    style={{
                                        fontSize: '12px',
                                        color: 'var(--addable-red)'
                                    }}
                                    onClick={handleDeleteVacancy}
                                >
                                    Удалить из базы
                                </button>
                            </PopoverBody>
                        </PopoverContent>
                    }
                </Popover>
            </Top>
            <Description
                style={{
                    maxHeight: isCollapsed ? '64px' : 'fit-content'
                }}
            >
                <Text>{vacanci?.description}</Text>
            </Description>
            {vacanci?.description &&
                <CollapseButton
                    onClick={() => setIsCollapsed(!isCollapsed)}
                >
                    Показать {isCollapsed ? <>больше</> : <>меньше</>}
                    <CollapseIcon
                        style={{
                            transform: isCollapsed ? '' : 'rotate(180deg)', transition: 'all .2s ease-in-out'
                        }}
                        width={10}
                        height={5}
                    />
                </CollapseButton>
            }
            <SkillsWrapper>
                <SkillsTitle>Hard Skills</SkillsTitle>
                {vacanci?.hard_skills && <SkillsList skills={vacanci?.hard_skills}/>}
            </SkillsWrapper>
            <SkillsWrapper>
                <SkillsTitle>SoftSkills</SkillsTitle>
                {vacanci?.soft_skills && <SkillsList skills={vacanci?.soft_skills}/>}
            </SkillsWrapper>
        </Container>
        <EditVacancyPopup isOpen={isOpen} onClose={onClose} vacancy={vacanci}/>
        <Toaster position="top-right" />
    </>
  )
});
