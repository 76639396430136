import React, { useEffect, useState } from 'react';
import { StepCard } from '@/entities/step';
import { Container } from './styles';
import { getStepsByVacancy, getStepsBySpecialization, getStepsByInterests } from '@/features/career';
import careerState from '@/features/career/store/careerState';
import { observer } from 'mobx-react-lite';
import { getEmployeeById } from '@/features/employee';

export const CareerSteps: React.FC = observer(() => {
  const [steps, setSteps] = useState([]);
  const [isAwaiting, setIsAwaiting] = useState(false);
  const userId = JSON.parse(localStorage.getItem('user'))?.id;

  useEffect(() => {
    setIsAwaiting(true);
    getEmployeeById(userId)
      .then((res: any) => {
        const steps: any[] = [res.data.specialization];
        const skills: any[] = [];

        res.data.specialization.skill.map((skill: any) => {
          skills.push(skill);
        })

        res.data.specialization.children.forEach((step: any) => {
          steps.push(step);
          step.skill.forEach((skill: any) => {
            skills.push(skill);
          })
        })

        steps.push(res.data.active_vacancy);
        setSteps(steps.reverse());

        careerState.setSkills(skills);
      })
      .catch(e => {
        console.log(e);
      })
      .finally(() => {
        setIsAwaiting(false);
      })
  }, [])

  return (
    <Container>
      {isAwaiting && <>Loading...</>}
      {steps.map((step: any, index) =>
        <div
          style={{ 
            width: '296px',
            marginTop: `${(steps.length - index - 1) * 74}px`,
          }}
          key={index}
        >
          <StepCard step={step} index={index}/>
        </div>
      )}
    </Container>
  )
});
