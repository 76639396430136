import styled from "styled-components";

export const ContainerStyles = styled.div`
  width: 100%;
  padding: 1rem;
`;

export const DrawerHeaderStyles = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Grouping = styled.div`
  overflow: auto;
  padding-bottom: 1rem;
  display: flex;
  align-items: center;
  gap: 1rem;
`;

export const DimmedHeading = styled.h6`
  color: var(--disabled);
`;

export const Disabled = styled.span`
  color: var(--disabled);
`;

export const PopupBtn = styled.div`
  color: var(--main-purple);
  margin: 0.5rem 0;
  display: flex;
  gap: 1rem;

  cursor: pointer;
`;

export const CategoryStyles = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 5px;
  padding: 0 0.5rem;
  border-radius: 5px;
  white-space: nowrap;

  background-color: #DAE2FC;
  color: var(--main-purple);
`;

export const Wrapper = styled.div`
  padding: 1rem 0;
`;
