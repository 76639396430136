import styled from "styled-components";

export const Page = styled.div`
    height: 100%;
`;

export const Container = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 296px);
    gap: 16px;

    @media screen and (max-width: 1268px) {
        grid-template-columns: repeat(3, 296px);
    }
`;
