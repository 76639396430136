import React from 'react';
import {
  Box,
  Flex,
  Stack,
  Divider,
} from '@chakra-ui/react';
// @ts-ignore
import ReactStars from "react-rating-stars-component";
import EmptyStarIcon from '@/assets/icons/empty-star.svg';
import StarIcon from '@/assets/icons/star.svg';
import dayjs from 'dayjs';

export interface IReviewsProps {
  reviews: any;
}

export const Reviews: React.FC<IReviewsProps> = ({ reviews }) => {
  const mark = reviews
    .reduce((acc: number, review: any) => acc + review.mark, 0) / reviews.length;

  const ratingDefault = {
    value: mark,
    size: 16,
    count: 5,
    emptyIcon: <EmptyStarIcon />,
    filledIcon: <StarIcon />,
    activeColor: 'grey',
    edit: false,
  }

  return (
    <Box>
      <Flex align="center" gap={4}>
        <Box opacity={0.5}>Оценка</Box>
        <ReactStars
          {...ratingDefault}
        />
      </Flex>

      <Stack pt={2} spacing={2}>
        {reviews.map((review: any) => 
          <>
            <Divider />
            <Stack spacing={1}>
              <Box fontSize={14} opacity={0.5}>
                {dayjs(review.created_on, 'DD.MM.YYYY HH:mm:ss').format('HH:mm DD.MM.YY')}
              </Box>
              <Box>{review.text}</Box>
            </Stack>
          </>
        )}
      </Stack>

    </Box>
  );
}
