import React from 'react';
import {RouterProvider} from "react-router-dom";
import { router } from './providers';
import { ChakraProvider } from '@chakra-ui/react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { ConfigProvider } from 'antd';
import ruRU from 'antd/lib/locale/ru_RU';
import './styles/index.scss';

const theme = createTheme();

export const App: React.FC = () => {
    return (
        <ThemeProvider theme={theme}>
          <ChakraProvider>
            <ConfigProvider locale={ruRU}>
              <RouterProvider router={router} />
            </ConfigProvider>
          </ChakraProvider>
        </ThemeProvider>
    );
};
