import React, { useState, useEffect } from 'react';
import { 
    Container,
    Image,
    Info,
    PopoverButton,
    Section,
} from './styles';
import { Card } from '@/shared';
import avatar from '@/assets/images/avatar.jpg';
import { SkillsList } from '@/widgets';
import { getCompany } from '@/entities/company/api';
import { getGroupById } from '@/features/department';
import companyState from '@/entities/company/store/companyState';
import { observer } from 'mobx-react-lite';
import { getVacancyById } from '@/entities/vacanci';
import { Top, Resume, File } from './styles';
import ResumeIcon from '@/assets/icons/resume.svg';
import DotsIcon from '@/assets/icons/dots.svg';
import { Popover, PopoverBody, PopoverContent, PopoverTrigger, useDisclosure, Stack } from '@chakra-ui/react';
import { FileUploadPopup } from '@/features/user/ui/FileUploadPopup/FileUploadPopup';
import EditIcon from '@/assets/icons/edit.svg';
import { EditInteresesPopup } from '@/features/user';
import { getInterests } from '@/features/user';
import { deleteResume } from '@/features/user';

export interface ProfileProps {
    user: any;
}

export const Profile: React.FC<ProfileProps> = observer(({ user }) => {
  const { isOpen: isAvatarOpen, onOpen: onAvatarOpen, onClose: onAvatarClose } = useDisclosure();
  const { isOpen: isResumeOpen, onOpen: onResumeOpen, onClose: onResumeClose } = useDisclosure();
  const { isOpen: isInteresesOpen, onOpen: onInteresesOpen, onClose: onInteresesClose } = useDisclosure();
  const [group, setGroup] = useState<any>();
//   const [vacancy, setVacancy] = useState<any>();
  const [interests, setInterests] = useState([]);
  const vacancy = user?.active_vacancy;
  const localUserId = JSON.parse(localStorage.getItem('user'))?.id;

  useEffect(() => {
    getCompany(user?.company[0].company)
        .then((res: any) => {
            if (res?.status === 200) {
                companyState.setCompany(res.data);
            }
        })
        .catch((error) => {
            console.log(error);
        })
    
    if (user?.group.length) {
      getGroupById(user?.group[0])
        .then((res: any) => {
            if (res?.status === 200) {
                setGroup(res.data);
            }
        })
        .catch((error) => {
            console.log(error);
        })
    }

//     getVacancyById(vacancyId)
//         .then((res) => {
//             setVacancy(res.data);
//         })
//         .catch((e) => {
//             console.log(e);
//         })
  }, [])

  useEffect(() => {
    getInterests(user?.id)
      .then((res: any) => {
        if (res?.status === 200) {
          setInterests(res.data.interests);
        }
      })
      .catch((error) => {
        console.log(error);
      })
  }, [])

  function handleDeleteResume() {
    deleteResume()
        .then((res) => {
            
        })
        .catch((e) => {
            console.log(e);
        })
  }

  return (
    <Container>
        {user?.image ?
            <div
                style={{
                    width: '296px',
                    height: '296px',
                    borderRadius: '50%',
                    backgroundImage: `url(${user.image.image})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover'
                }}
            >
            </div>  :
            <Image src={avatar}/>
        }
        {user?.id === localUserId &&
            <button onClick={onAvatarOpen}>загрузить</button>
        }
        <Card>
            <Info>
                <div>
                    <h5>{user?.first_name} {user?.patronymic} {user?.last_name}</h5>
                    {<>{user?.age}{' '}</>} 
                    {user?.gender === 'MALE' ? <>муж</> :
                        user?.gender === 'FEMALE' ? <>Жен</> :
                        <></>
                    }
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '18px' }}>
                    <h5>{vacancy?.grade} {vacancy?.title}</h5>
                    <div>
                        {group?.name && <div>Отдел {group.name}</div>}
                        {companyState.company?.name && <div>Компания «{companyState.company.name}»</div>}
                    </div>
                </div>
                <div>
                <h5>Дата рождения</h5>
                <h5>{user?.birthday}</h5>
                </div>
            </Info>
            {/* <Place>
                <>работает</><h5>в г.Москва</h5>
            </Place> */}
            <Section>
                <Top>
                    <h5>Интересы</h5>
                    <button onClick={onInteresesOpen}>
                        <EditIcon
                            width={20}
                            height={20}
                            color='var(--main-purple)'
                        />
                    </button>
                </Top>
                <SkillsList skills={interests}/>
            </Section>
            <Section>
                <h5>Резюме</h5>
                <Resume>
                    <File>
                        <ResumeIcon
                            width={24}
                            height={24}
                        />
                        {user?.resume ? 
                            <a href={user?.resume?.file} target='_blank' style={{ fontWeight: '400' }}>
                                Открыть резюме
                            </a> :
                            <p>Резюме не добавлено</p>
                        }
                    </File>
                    <Popover placement='bottom-end'>
                        <PopoverTrigger>
                            <button>
                                <DotsIcon
                                    color='var(--main-purple)'
                                    width={20}
                                    height={20}
                                />
                            </button>
                        </PopoverTrigger>
                        <PopoverContent w="full">
                            <PopoverBody>
                              <Stack align="start">
                                <PopoverButton onClick={onResumeOpen}>Загрузить файл</PopoverButton>
                                <PopoverButton
                                    style={{ color: 'var(--addable-red)' }}
                                    onClick={handleDeleteResume}
                                >
                                    Удалить
                                </PopoverButton>
                              </Stack>
                            </PopoverBody>
                        </PopoverContent>
                    </Popover>
                </Resume>
            </Section>
        </Card>
        <FileUploadPopup type='avatar' isOpen={isAvatarOpen} onClose={onAvatarClose}/>
        <FileUploadPopup type='resume' isOpen={isResumeOpen} onClose={onResumeClose}/>
        <EditInteresesPopup isOpen={isInteresesOpen} onClose={onInteresesClose}/>
    </Container>
  )
});
