import React, { useState, useEffect } from 'react';
import { Filters } from '@/features/common';
import analyticsState from './store/analyticsState';
import { observer } from 'mobx-react-lite';
import { getEmployees } from '@/features/employee';
import { getCompany } from '@/entities/company/api';
import { AnalyticFilters } from '@/widgets';
import { getCompanyGroups } from '@/features/department';
import {
  Columns,
  ProgressWrapper,
  Count,
  ProgressTitle,
} from './styles';
import { AnalyticColumn } from '@/widgets';
import { BubbleChart } from '@/widgets';
import { ProgressBar } from '@/shared';
import { num_word } from '@/shared';
import { Flex, Box } from '@chakra-ui/react';

function getRandomInt(min: any, max: any) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

const letters = [];
for (let i = 'A'.charCodeAt(0); i <= 'z'.charCodeAt(0); i += 1) {
  letters.push(String.fromCharCode(i));
}

export const random: any = [];
for (let i = 0; i < 100; i += 1) {
  const arr = [];
  const len = getRandomInt(2, 20);

  for (let j = 0; j < len; j += 1) {
    arr.push(letters[getRandomInt(0, letters.length)]);
  }

  random.push(
    {
      "title": arr.join(''),
      "count": getRandomInt(1, 100),
    }
  )
}

export const Analytics: React.FC = observer(() => {
  const [employees, setEmployees] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [groups, setGroups] = useState([]);

  const [softSkillsMax, setSoftSkillsMax] = useState(0);
  const [hardSkillsMax, setHardSkillsMax] = useState(0);

  const pageOptions = [
    {
      id: 1,
      name: 'Текущая',
      onClick: () => {
        analyticsState.setAnalyticsType('current');
      },
    },
    {
      id: 2,
      name: 'В процессе',
      onClick: () => {
        analyticsState.setAnalyticsType('inProgress');
      },
    },
  ];

  const companyId = JSON.parse(localStorage.getItem('user'))?.company[JSON.parse(localStorage.getItem('user'))?.company.length - 1];

  function handleGetCompany() {
    getCompany(companyId)
      .then((res) => {
        setDepartments(res.data.group);
      })
      .catch((e) => {
        console.log(e);
      })
  }

  function handleGetGroups() {
    getCompanyGroups(companyId)
      .then((res: any) => {
        setGroups(res.data.group);
      })
      .catch((error) => {
        console.log(error);
      })
  }

  function handleGetEmployees() {
    getEmployees(companyId)
      .then((res: any) => {
        setEmployees(res.data);
      })
      .catch((error) => {
        console.log(error);
      })
  }

  useEffect(() => {
    handleGetGroups();
    handleGetEmployees();
    handleGetCompany();
  }, [])

  useEffect(() => {
    let state: any;
    if (analyticsState.analyticsType === 'inProgress') {
      let maxValue = 0;

      for (const skill of analyticsState.progress.softSkills) {
        if (skill.count > maxValue) {
          maxValue = skill.count
        }
      }
      setSoftSkillsMax(maxValue);

      maxValue = 0;

      for (const skill of analyticsState.progress.hardSkills) {
        if (skill.count > maxValue) {
          maxValue = skill.count
        }
      }
      setHardSkillsMax(maxValue);
    }
  }, [
      analyticsState.filter,
      analyticsState.analyticsType,
      analyticsState.progress.hardSkills,
      analyticsState.progress.softSkills
    ]
  )

  return (
    <div>
      <Filters options={pageOptions} style={{ width: '100%' }} />
      <AnalyticFilters employees={employees} departments={departments} groups={groups} />
      {analyticsState.analyticsType === 'current' &&
        <Columns>
          {/* <BubblesWidget title='Интересы' items={analyticsState.current.interests} />
          <BubblesWidget title='Soft Skills' items={analyticsState.current.softSkills} />
          <BubblesWidget title='Hard skills' items={analyticsState.current.hardSkills} /> */}
          <AnalyticColumn title={'Интересы'}>
            {analyticsState.current.interests.length > 0 ?
              <>
                <ProgressWrapper>
                  <BubbleChart data={analyticsState.current.interests.slice(0, 20)} />
                </ProgressWrapper>
                {
                  analyticsState.current.interests.slice(20).map((skill: any) => 
                  <Flex align="center" gap={2}>
                    <ProgressBar value={skill.count} max={10}>{skill.title}</ProgressBar>
                    {analyticsState.filter !== 'employee' &&
                      <Count>{skill.count} {num_word(skill.count, ['человек', 'человека', 'человек'])}</Count>
                    }
                  </Flex>
                )
                }
              </> :
              <Box textAlign="center" opacity={0.5}>Данных нет</Box>
            }
          </AnalyticColumn>
          <AnalyticColumn title={'Soft Skills'}>
            {analyticsState.current.softSkills.length > 0 ?
              <>
                <ProgressWrapper>
                  <BubbleChart data={analyticsState.current.softSkills.slice(0, 20)} />
                </ProgressWrapper>
                {
                  analyticsState.current.softSkills.slice(20).map((skill: any) =>
                    <Flex align="center" gap={2}>
                      <ProgressBar value={skill.count} max={10}>
                        <ProgressTitle>{skill.title}</ProgressTitle>
                      </ProgressBar>
                      {analyticsState.filter !== 'employee' &&
                        <Count>{skill.count} {num_word(skill.count, ['человек', 'человека', 'человек'])}</Count>
                      }
                    </Flex>
                  )
                }
              </> :
              <Box textAlign="center" opacity={0.5}>Данных нет</Box>
            }
          </AnalyticColumn>
          <AnalyticColumn title={'Hard skills'}>
            {analyticsState.current.hardSkills.length > 0 ?
              <>
                <ProgressWrapper>
                  <BubbleChart data={analyticsState.current.hardSkills.slice(0, 20)} />
                </ProgressWrapper>
                {
                  analyticsState.current.hardSkills.slice(20).map((skill: any) =>
                    <Flex align="center" gap={2}>
                      <ProgressBar value={skill.count} max={10}>
                        <ProgressTitle>{skill.title}</ProgressTitle>
                      </ProgressBar>
                      {analyticsState.filter !== 'employee' &&
                        <Count>{skill.count} {num_word(skill.count, ['человек', 'человека', 'человек'])}</Count>
                      }
                    </Flex>
                  )
                }
              </> :
              <Box textAlign="center" opacity={0.5}>Данных нет</Box>
            }
          </AnalyticColumn>
        </Columns>
      }
      {analyticsState.analyticsType === 'inProgress' &&
        <Columns>
          <AnalyticColumn title={'Soft Skills'}>
            {
              analyticsState.progress.softSkills.map((skill: any) =>
                <Flex align="center" gap={2}>
                  <ProgressBar value={skill.count} max={softSkillsMax}>
                    <ProgressTitle>{skill.title}</ProgressTitle>
                  </ProgressBar>
                  {analyticsState.filter !== 'employee' &&
                    <Count>{skill.count} {num_word(skill.count, ['человек', 'человека', 'человек'])}</Count>
                  }
                </Flex>
              )
            }
          </AnalyticColumn>
          <AnalyticColumn title={'Hard skills'}>
            {analyticsState.progress.hardSkills.map((skill: any) =>
              <Flex align="center" gap={2}>
                <ProgressBar value={skill.count} max={hardSkillsMax}>{skill.title}</ProgressBar>
                {analyticsState.filter !== 'employee' &&
                  <Count>{skill.count} {num_word(skill.count, ['человек', 'человека', 'человек'])}</Count>
                }
              </Flex>
            )}
          </AnalyticColumn>
        </Columns>
      }
    </div>
  )
});
