import React from 'react';
import { ProjectCard } from '@/entities/projects';
import { observer } from 'mobx-react-lite';
import { EmptyResponse } from '../EmptyResponse/EmptyResponse';
import projectsPageState from '@/pages/Projects/store/projectsPageState';

export const ProjectList: React.FC = observer(() => {
  return (
    <>
      {projectsPageState.projects.length === 0 &&
        <EmptyResponse/>
      }
      {projectsPageState.projects && projectsPageState.projects.map((project) =>
          <ProjectCard project={project} key={project.id}/>
      )}
    </>
  )
});
