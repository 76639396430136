import React, { useEffect, useState } from 'react';
import {
  Stack,
  FormControl,
  Input,
  Image,
  Flex,
} from '@chakra-ui/react';
import { IQuestion, IAnswer } from '@/shared/types/tests/types';
import { useDebounce } from '@/shared';
import testAssessmentState from '@/widgets/TestAssessmentDrawer/store/testAssessmentState';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css'

export interface IOpenEndedAssessmentProps {
  question: IQuestion;
}

export const OpenEndedAssessment: React.FC<IOpenEndedAssessmentProps> = ({ question }) => {
  const [answer, setAnswer] = useState('');

  const debouncedAnswer = useDebounce(answer, 500);

  useEffect(() => {
    testAssessmentState.setAnswerText(question.id, question.answers[0].id, debouncedAnswer);
  }, [debouncedAnswer]);

  return (
    <Stack w="full">
      <FormControl isRequired>
        <Stack spacing={4}>
          <h3>{question.text}</h3>
          {question.images[0] &&
            <Flex my={2} w="50%" justify="center">
              <Zoom>
                <Image
                  borderRadius="md"
                  src={question.images[0].image}
                  alt="Картинка к вопросу"
                />
              </Zoom>
            </Flex>
          }

          <Input
            onChange={(e: any) => {setAnswer(e.target.value)}}
            variant="flushed"
            placeholder='краткий ответ'
          />
        </Stack>
      </FormControl>
    </Stack>
  );
}
