import React, { useEffect } from 'react';
import { ProjectList } from '@/widgets/ProjectList/ProjectList';
import { ProjectsTopBar } from '@/widgets/ProjectsTopBar/ProjectsTopBar';
import { TaskList } from '@/widgets/TaskList/TaskList';
import projectsPageState from './store/projectsPageState';
import { observer } from 'mobx-react-lite';
import { Filters } from '@/features/common';
import { TasksTopBar } from '@/widgets';
import { useSearchParams } from 'react-router-dom';

export const Projects: React.FC = observer(() => {
  const [searchParams, setSearchParams] = useSearchParams();
  const typeName = searchParams.get('type');
  const type = (typeName === 'task') ? 1 : 0;

  const options = [
    {
      id: 1,
      name: 'Проекты',
      onClick: () => projectsPageState.setFilter('projects'),
    },
    {
      id: 2,
      name: 'Задачи',
      onClick: () => projectsPageState.setFilter('tasks'),
    },
  ];

  useEffect(() => {
    if (typeName === 'project') {
      projectsPageState.setFilter('projects');
    }
    if (typeName === 'task') {
      projectsPageState.setFilter('tasks');
    }
    if (typeName === 'task_bound') {
      projectsPageState.setFilter('projects');
    }

    searchParams.delete('type');
    setSearchParams(searchParams);
  }, []) 

  return (
    <>
      {projectsPageState.filter === 'projects' &&
        <ProjectsTopBar />
      }
      {projectsPageState.filter === 'tasks' &&
        <TasksTopBar/>
      }
      <Filters options={options} defaultOption={options[type]} />
      {projectsPageState.filter === 'projects' &&
        <ProjectList />
      }
      {projectsPageState.filter === 'tasks' &&
        <TaskList />
      }
    </>
  )
});
