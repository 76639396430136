import React, { useState } from 'react';
import {
  Box,
  Flex,
  List,
  ListItem,
} from '@chakra-ui/react';
import CollapseIcon from '@/assets/icons/collapse.svg';
import { useSelect } from 'downshift';
import { TaskStatusHint } from '@/entities/projects';
import { editTask } from '@/features/projects';
import taskState from '@/widgets/TaskDrawer/store/taskState';

export interface ISelectTaskStatusProps {
  taskId: number;
  status: string;
}

export const SelectTaskStatus: React.FC<ISelectTaskStatusProps> = ({ taskId, status }) => {
  const items = [
    { id: 1, name: 'Unavailable' },
    { id: 2, name: 'In process' },
    { id: 3, name: 'Done' },
  ];

  function itemToString(item: any) {
    return item.name;
  }

  const [selectedItem, setSelectedItem] = useState(status);
  const [isLoading, setIsLoading] = useState(false);

  const {
    isOpen,
    getToggleButtonProps,
    getMenuProps,
    getItemProps,
  } = useSelect({
    items: items,
    itemToString,
    selectedItem,
    onSelectedItemChange: ({selectedItem: newSelectedItem}) => {
      setIsLoading(true);
      editTask(taskId, { status: newSelectedItem.name, owner: taskState.selectedTask.owner })
        .then((res) => {
          setSelectedItem(newSelectedItem.name);
          taskState.setStatus(newSelectedItem.name);
        }).then((res) => {
          setIsLoading(false);
        })
        .catch((error) => {
          console.log(error);
        })
    }
  });

  return (
    <Box>
      <Flex
        _hover={{cursor: 'pointer'}}
        align="center"
        gap={2}
        {...getToggleButtonProps()}
      >
        <TaskStatusHint status={selectedItem} />
        <CollapseIcon
          style={{
            width: 16,
            height: 16,
            color: 'var(--main-purple)',
            transform: isOpen || isLoading ? 'rotate(180deg)' : '', transition: 'all .2s ease-in-out',
          }}
          width={10}
          height={5}
        />
      </Flex>
      {isOpen &&
        <List
          spacing={3}
          p={3}
          m={1}
          borderRadius={4}
          boxShadow="md"
          position="absolute"
          zIndex={2000}
          backgroundColor="var(--main-white)"
          {...getMenuProps()}
        >
          {isOpen &&
            items.map((item, index) => (
              <ListItem
                key={item.id}
                {...getItemProps({item, index})}
              >
                <TaskStatusHint status={item.name} />
              </ListItem>
            ))}
        </List>
      }
    </Box>
  )
}


