import React, { useState, useEffect } from 'react';
import {
  Container,
  Status,
  Details,
  Title,
  Text,
  Buttons,
  Button,
} from './styles';
import SuccessIcon from '@/assets/icons/success.svg';
import ErrorIcon from '@/assets/icons/error.svg';
import { setNotificationAsRead } from '@/features/notifications';
import notificationsState from '../../store/notificationsState';
import {
  acceptRoleRequest,
  acceptTaskRequest,
  cancelRoleRequest,
  cancelTaskRequest,
} from '@/features/projects';

export interface NotificationCardProps {
  notification: any;
}

export const NotificationCard: React.FC<NotificationCardProps> = ({ notification }) => {
  const user = JSON.parse(localStorage.getItem('user'));

  const redirectQueryParameters = notification?.relation_project_id ?
  `&type=projects&project_id=${notification?.relation_project_id}&task_id=${notification?.target_object_id}` :
  `&type=${notification?.target_type}&id=${notification?.target_object_id}`;
  
  function handleReadNotification() {
    setNotificationAsRead(notification.id)
      .then(() => {
        notificationsState.deleteNotification(notification.id);
      })
      .catch((e) => {
        console.log(e);
      })
  }

  function handleAcceptNotification() {
    if (notification?.target_type === 'project') {
      acceptRoleRequest(notification?.action_object_object_id)
        .then(() => {
//           notificationsState.deleteNotification(notification.id);
        })
        .catch((e) => {})
    }
    if (notification?.target_type === 'task') {
      acceptTaskRequest(notification?.action_object_object_id)
        .then(() => {
//           notificationsState.deleteNotification(notification.id);
        })
        .catch((e) => {})
    }
    setNotificationAsRead(notification.id)
      .then(() => {
        notificationsState.deleteNotification(notification.id);
      })
      .catch((e) => {
        console.log(e);
      })
  }

  function handleRejectNotification() {
    if (notification?.target_type === 'project') {
      cancelRoleRequest(notification?.action_object_object_id)
        .then(() => {
//           notificationsState.deleteNotification(notification.id);
        })
        .catch((e) => {})
    }
    if (notification?.target_type === 'task') {
      cancelTaskRequest(notification?.action_object_object_id)
      .then(() => {
//         notificationsState.deleteNotification(notification.id);
      })
      .catch((e) => {})
    }
    setNotificationAsRead(notification.id)
      .then(() => {
        notificationsState.deleteNotification(notification.id);
      })
      .catch((e) => {
        console.log(e);
      })
  }

  return (
    <Container>
      {(notification.level === 'success' || notification.level === 'info' || notification.level === 'warning') &&
        <Status style={{ background: 'rgba(17, 160, 22, .1)' }}>
          <SuccessIcon
            width={24}
            height={24}
          />
        </Status>
      }
      {(notification.level === 'error') &&
        <Status style={{ background: 'rgba(248, 60, 60, .1)' }}>
          <ErrorIcon
            width={24}
            height={24}
          />
        </Status>
      }
      <Details>
        <Title>{notification?.verb}</Title>
        <Text>{notification?.description}</Text>
        <Buttons>
          {(notification?.level === 'success' ||  notification?.level === 'info') &&
            <>
              <Button>
                <a href={user?.role === 'Manager' ? 
                  `/dashboard-manager-projects?${redirectQueryParameters}` :

                  `/dashboard-employee-projects?${redirectQueryParameters}`
                }>
                  Перейти
                </a>
              </Button>
              <Button onClick={handleReadNotification}>Отметить прочитанным</Button>
            </>
          }
          {notification?.level === 'warning' &&
            <>
              <Button onClick={handleAcceptNotification}>Принять</Button>
              <Button onClick={handleRejectNotification}>Отклонить</Button>
            </>
          }
        </Buttons>
        <Text>{notification?.timestamp.substring(0, 10).replace(/\-/g, ".").split(".").reverse().join(".")}</Text>
      </Details>
    </Container>
  )
}
