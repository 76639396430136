import React, { useState, useEffect } from 'react';
import {
  Stack,
  HStack,
  FormControl,
  RadioGroup,
  Radio,
  Box,
  Image,
  Flex,
} from '@chakra-ui/react';
import { IQuestion, IAnswer } from '@/shared/types/tests/types';
import testAssessmentState from '@/widgets/TestAssessmentDrawer/store/testAssessmentState';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css'

export interface ISingleChoiceAssessmentProps {
  question: IQuestion;
}

export interface IAnswerRadioInputProps {
  question_id: number;
  answer: IAnswer;
  chosen: string;
}

const AnswerRadioInput: React.FC<IAnswerRadioInputProps> = ({ question_id, answer, chosen }) => {
  return (
    <HStack>
      <Radio
        checked={answer.id.toString() === chosen || answer.is_right}
        borderColor="var(--main-purple)"
        size="lg"
        value={answer.id.toString()}
        _checked={{
          backgroundColor: 'var(--main-purple)',
        }}
      />

      {answer?.image ?
        <Flex my={2} w="50%" justify="center">
          <Zoom>
            <Image borderRadius="md"  src={answer?.image} alt="Картинка к вопросу" />
          </Zoom>
        </Flex> :
        <Box>{answer.text}</Box>
      }
    </HStack>
  );
}

export const SingleChoiceAssessment: React.FC<ISingleChoiceAssessmentProps> = ({ question }) => {
  const [choice, setChoice] = useState('');

  useEffect(() => {
    testAssessmentState.setUserAnswers(question.id, [choice]);
  }, [choice]);

  return (
    <Stack w="full">
      <FormControl isRequired>
        <Stack spacing={4}>
          <h3>{question.text}</h3>
          {question.images[0] &&
            <Flex my={2} w="50%" justify="center">
              <Zoom>
                <Image
                  borderRadius="md"
                  src={question.images[0].image}
                  alt="Картинка к вопросу"
                />
              </Zoom>
            </Flex>
          }

          <RadioGroup onChange={(choice) => {setChoice(choice)}}>
            <Stack spacing={4}>
              {question?.answers.map((answer) => 
                <AnswerRadioInput key={answer.id} question_id={question.id} answer={answer} chosen={choice} />
              )}
            </Stack>
          </RadioGroup>
        </Stack>
      </FormControl>
    </Stack>
  );
}
