import styled from 'styled-components';

export const Line = styled.div`
  justify-content: start;
  display: flex;
  overflow: auto;
  padding: 0.5rem 0 1rem;
`;

export const Person = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;

  min-width: 120px;
  width: 120px;
  padding: 0 0.5rem;
  text-align: center;
  line-height: 1;
`;
