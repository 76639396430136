import React, { useState, useEffect } from 'react';

import {
  Container,
  ContainerInLine,
  ContainerInner,
  StepHeaderContainer,
  MarginBlock,
  Input,
  CustomUl,
  CustomLi,
} from './styles';

import { observer } from 'mobx-react-lite';

import { uploadExternalTestResults } from '@/features/tests';

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Textarea,
  Select,
  Stack,
  Divider,
  InputGroup,
  useEditable,
} from '@chakra-ui/react';
import { features } from 'process';
import { BorderRight } from '@mui/icons-material';
import { Competence } from '@/features/employee/ui/EditCompetencesPopup/styles';

interface ImportTestsPopupProps {
  isOpen: boolean;
  onClose: () => void;
}

export const ImportTestsPopup: React.FC<ImportTestsPopupProps> = observer(({ isOpen, onClose }) => {
  const waitTime = 3000;

  const [exportedFiles, setExportedFiles] = useState<any>();
  const [loadStage, setLoadStage] = useState<string>();
  const [buttonStage, setButtonStage] = useState<any>();

  function fetchFile(event: React.ChangeEvent<HTMLInputElement>) {
    const selectedFiles = event.target.files;
    console.log(selectedFiles);
    setExportedFiles(selectedFiles);
  }

  function handleUploadFiles() {
    if (exportedFiles) {
      setLoadStage('Импортирование...');

      const data: any = {
        files: [],
      };
  
      for (const file of exportedFiles) {
        data.files.push(file);
      }
      console.log(data);
      uploadExternalTestResults(data)
        .then((response) => {
          onClose();
          setLoadStage('Файлы импортированы');
          setExportedFiles(null);
          window.location.reload();
        })
        .catch((error) => {
          console.log(error);
          setLoadStage('Ошибка при импорте');
          setExportedFiles(null);
        })
        .finally(() => {
          setTimeout(() => {
            setLoadStage('Импорт');
          }, waitTime);
        })
    }
    else {
      setLoadStage('Прикрепите файлы');
      setTimeout(() => {
        setLoadStage('Импорт');
      }, waitTime);
    }

  }

  useEffect(() => {
    setLoadStage('Импорт');
  }, [])


  return (
    <Modal
      size={'2xl'}
      closeOnOverlayClick={true}
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent
        style={{
          borderRadius: '16px',
        }}
      >
        <ModalHeader alignItems={'center'}>
          <h3 style={{ textAlign: 'center' }}>Импорт тестов</h3>
        </ModalHeader>

        <ModalCloseButton top={'10px'} size="lg" />

        <Divider />

        <Container>
          <h3>
            Загрузите файлы
          </h3>
          <input
            type="file"
            onChange={fetchFile}
            accept="pdf"
            multiple
          />
        </Container>

        <Divider />

        <ModalFooter gap='8px' display='flex'>
          <Button
            type='button'
            width='100%'
            onClick={onClose}
            variant='outline'
            color='var(--main-purple)'
            borderRadius='8px'
          >
            Отмена
          </Button>

          <Button
            type='button'
            width='100%'
            borderRadius='8px'
            bg='var(--main-purple)'
            _hover={{ bg: '#2D53DA' }}
            color='#fff'
            onClick={handleUploadFiles}
            style={{
              pointerEvents: loadStage !== 'Импорт' ? 'none' : 'auto',
            }}
          >
            {loadStage}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
});