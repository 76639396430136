import React, { useState, useEffect } from 'react';
import {
  Stack,
  HStack,
  FormControl,
  FormLabel,
  Input, Button,
  RadioGroup,
  Radio,
  Box,
  CloseButton,
  Flex,
  Image,
} from '@chakra-ui/react';
import AddIcon from '@/assets/icons/add.svg';
import { Question, LabelStyles } from './styles';
import testCreationState from '@/widgets/TestCreationForm/store/testCreationState';
import testEditState from '@/widgets/TestEditForm/store/testEditState';
import { IQuestion, IAnswer } from '@/shared/types/tests/types';
import { useDebounce } from '@/shared';
import { observer } from 'mobx-react-lite';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css'

export interface ISingleChoiceQuestionProps {
  question: IQuestion;
  mode: 'create' | 'edit';
}

export interface IAnswerRadioInputProps {
  question_id: number;
  answer: IAnswer;
  mode: 'create' | 'edit';
  checkAnswerMode?: () => string;
  setAnswerMode?: any;
}

const AnswerRadioInput: React.FC<IAnswerRadioInputProps> = ({ question_id, answer, mode, checkAnswerMode, setAnswerMode }) => {
  const [text, setText] = useState('');

  function handleOnChange() {
    if (mode === 'create') {
      testCreationState.setRightAnswers(question_id, [answer.id.toString()]);
    } else if (mode === 'edit') {
      testEditState.setRightAnswers(question_id, [answer.id.toString()]);
    }
  }
  
  const debouncedText = useDebounce(text, 500);

  useEffect(() => {
    if (mode === 'create') {
      testCreationState.setAnswerText(question_id, answer.id, debouncedText);
    } else if (mode === 'edit') {
      testEditState.setAnswerText(question_id, answer.id, debouncedText);
    }
  }, [debouncedText]);

  return (
    <HStack>
      <Radio
        onChange={handleOnChange}
        borderColor="var(--main-purple)"
        size="lg"
        value={answer.id.toString()}
        _checked={{
          backgroundColor: 'var(--main-purple)',
        }}
      />

      <Input
        onChange={(e: any) => {setText(e.target.value)}}
        variant="flushed"
        placeholder="ответ"
        defaultValue={answer.text}
      />

      <CloseButton
        position="absolute"
        right={0}
        color="var(--main-purple)"
        onClick={() => {
          if (mode === 'create') {
            testCreationState.removeAnswer(question_id, answer.id);
          } else if (mode === 'edit') {
            testEditState.removeAnswer(question_id, answer.id);
          }
          setAnswerMode(checkAnswerMode());
        }}
      />
    </HStack>
  );
}

const AnswerRadioInputImage: React.FC<IAnswerRadioInputProps> = ({ question_id, answer, mode, checkAnswerMode, setAnswerMode }) => {
  const [fileContent, setFileContent] = useState<any>('');
  if (!answer?.image_uploaded_on) {
    useEffect(() => {
      const reader = new FileReader();

      reader.onload = () => {
        setFileContent(reader.result);
      };

      reader.readAsDataURL(answer?.image);
    }, []);
  }

  function handleOnChange() {
    if (mode === 'create') {
      testCreationState.setRightAnswers(question_id, [answer.id.toString()]);
    } else if (mode === 'edit') {
      testEditState.setRightAnswers(question_id, [answer.id.toString()]);
    }
  }

  return (
    <HStack>
      <Radio
        onChange={handleOnChange}
        borderColor="var(--main-purple)"
        size="lg"
        value={answer.id.toString()}
        _checked={{
          backgroundColor: 'var(--main-purple)',
        }}
      />

      <Flex w="50%" align="center" gap={2}>
        <Box mt={2}>
          <Zoom>
            <Image
              borderRadius="md"
              src={answer?.image_uploaded_on ? answer?.image : fileContent}
              alt="Картинка к ответу"
            />
          </Zoom>
        </Box>

        <CloseButton
          size="lg"
          right={0}
          color="var(--main-purple)"
          onClick={() => {
            if (mode === 'create') {
              testCreationState.removeAnswer(question_id, answer.id);
            } else if (mode === 'edit') {
              testEditState.removeAnswer(question_id, answer.id);
            }
            setAnswerMode(checkAnswerMode());
          }}
        />
      </Flex>
    </HStack>
  );
}

export const SingleChoiceQuestion: React.FC<ISingleChoiceQuestionProps> = observer(({ question, mode }) => {
  const [title, setTitle] = useState('');
  const [fileContent, setFileContent] = useState<any>(
    question.images &&
    (question.images.length > 0 ? question.images[0].image : null)
  );
  const [answerMode, setAnswerMode] = useState(checkAnswerMode);

  function checkAnswerMode() {
    if (question?.answers.length === 0) {
      return 'both';
    }

    if (question?.answers.filter((ans) => ans?.image).length > 0) {
      return 'images';
    } else {
      return 'text';
    }
  }
  
  const debouncedTitle = useDebounce(title, 500);

  useEffect(() => {
    if (mode === 'create') {
      testCreationState.setQuestionTitle(question.id, debouncedTitle);
    } else if (mode === 'edit') {
      testEditState.setQuestionTitle(question.id, debouncedTitle);
    }
  }, [debouncedTitle]);

  function handleQuestionPhoto(e: any) {
    const file = e.target.files[0];

    const reader = new FileReader();
    reader.onload = () => {
      setFileContent(reader.result);

      if (mode === 'create') {
        testCreationState.setQuestionImage(question.id, {image: file});
      } else if (mode === 'edit') {
        testEditState.setQuestionImage(question.id, {image: file});
      }
    };

    reader.readAsDataURL(file);
  }

  function handleAnswerPhoto(e: any) {
    const file = e.target.files[0];

    if (mode === 'create') {
      testCreationState.addAnswer(question.id, { id: Date.now(), image: file });
    } else if (mode === 'edit') {
      testEditState.addAnswer(question.id, { id: Date.now(), image: file });
    }
    setAnswerMode(checkAnswerMode());
  }

  return (
    <Stack
      position="relative"
      w="full"
    >
      <FormControl isRequired>
        <Stack spacing={4}>
          <Box>
            <FormLabel>
              <Question>
                Вопрос
              </Question>
            </FormLabel>

            <Input
              onChange={(e: any) => {setTitle(e.target.value)}}
              variant="flushed"
              placeholder='Вопрос с единственным ответом'
              defaultValue={question.text}
            />

            <Flex align="center" gap={2}>
              <Box mt={2}>
                {(!fileContent) &&
                  <LabelStyles
                    onClick={() => {}}
                  >
                    <Box
                      fontSize="sm"
                      fontWeight="900"
                    >
                      Фото
                    </Box>
                    <AddIcon color="var(--main-purple)"/>

                    <input
                      style={{display: 'none'}}
                      id="file-upload"
                      type="file"
                      onChange={handleQuestionPhoto}
                    />
                  </LabelStyles>
                }

                {fileContent && 
                  <Box my={4}>
                    <Zoom>
                      <Image
                        borderRadius="md"
                        src={fileContent}
                        alt="Картинка к вопросу"
                      />
                    </Zoom>
                  </Box>
                }
              </Box>

              {fileContent &&
                <CloseButton
                  size="lg"
                  right={0}
                  color="var(--main-purple)"
                  onClick={() => {
                    setFileContent('');
                    if (question.images) {
                      question.images = [];
                    }
                  }}
                />
              }
            </Flex>
          </Box>

          <RadioGroup defaultValue={mode === 'edit' && testEditState.getRightAnswerIds(question.id)[0]}>
            <Stack spacing={4}>
              {question.answers.map((answer) => { 
                if (answer?.image) {
                  return (
                    <AnswerRadioInputImage
                      key={answer.id}
                      question_id={question.id}
                      answer={answer}
                      mode={mode}
                      checkAnswerMode={checkAnswerMode}
                      setAnswerMode={setAnswerMode}
                    />
                  )
                } else {
                  return (
                    <AnswerRadioInput
                      key={answer.id}
                      question_id={question.id}
                      answer={answer}
                      mode={mode}
                      checkAnswerMode={checkAnswerMode}
                      setAnswerMode={setAnswerMode}
                    />
                  )
                }
              }
              )}
            </Stack>
          </RadioGroup>
        </Stack>
      </FormControl>

      <HStack>
        {(answerMode === 'text' || answerMode === 'both') &&
          <Button
            size="sm"
            bg="none"
            color="var(--main-purple)"
            rightIcon={<AddIcon color="var(--main-purple)"/>}
            onClick={() => {
              if (mode === 'create') {
                testCreationState.addAnswer(question.id, { id: Date.now(), text: '' });
              } else if (mode === 'edit') {
                testEditState.addAnswer(question.id, { id: Date.now(), text: '' });
              }
              setAnswerMode(checkAnswerMode());
            }}
          >Вариант</Button>
        }

        {(answerMode === 'images' || answerMode === 'both') &&
          <LabelStyles>
            <Box
              fontSize="sm"
              fontWeight="900"
            >
              Фото
            </Box>
            <AddIcon color="var(--main-purple)"/>

            <input
              style={{display: 'none'}}
              id="file-upload"
              type="file"
              onChange={handleAnswerPhoto}
            />
          </LabelStyles>
        }
      </HStack>

      <CloseButton
        position="absolute"
        top={0}
        right={0}
        size="lg"
        onClick={() => {
          if (mode === 'create') {
            testCreationState.removeQuestion(question.id)
          } else if (mode === 'edit') {
            testEditState.removeQuestion(question.id)
          }
        }}
      />
    </Stack>
  );
});
