import styled from 'styled-components';

export const DimmedText = styled.div`
  display: inline-block;
  font-size: 12px;
  opacity: 50%;
  font-weight: 400;
`;

export const CollapseButton = styled.button`
    display: flex;
    gap: 4px;
    align-items: center;
    color: var(--main-purple);
    font-size: 12px;
    font-weight: 600;
`;
