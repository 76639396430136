import React, { useState, useEffect } from 'react';
import { Task } from "frappe-gantt-react";
import {
  TaskListColumn,
  ProjectListColumn,
  DiagramGantt,
  CreatedProjectsTopBar,
  CreatedTasksTopBar,
  MyProjectsTopBar,
  MyTasksTopBar,
  EmptyResponse,
} from '@/widgets';
import {
  Container,
  Content
} from './styles';
import './styles';
import { Filters } from '@/features/common';
import { observer } from 'mobx-react-lite';
import myProjectsState from '@/widgets/MyProjectsTopBar/store/myProjectsState';
import myTasksState from '@/widgets/MyTasksTopBar/store/myTasksState';
import createdProjectsState from '@/widgets/CreatedProjectsTopBar/store/createdProjectsState';
import createdTasksState from '@/widgets/CreatedTasksTopBar/store/createdTasksState';

type TFilter = 'myProjects' | 'myTasks';

export const EmployeeProjectsTasks: React.FC = observer(() => {
  const [filter, setFilter] = useState<TFilter>('myProjects');
  const [items, setItems] = useState([]);

  useEffect(() => {
    if (filter === 'myProjects') {
      setItems(myProjectsState.projects);
    }
    if (filter === 'myTasks') {
      setItems(myTasksState.tasks);
    }
  }, [filter, myProjectsState.projects, myTasksState.tasks, createdProjectsState.projects, createdTasksState.tasks])

  const options = [
    {
      id: 1,
      name: 'Мои проекты',
      onClick: () => setFilter('myProjects'),
    },
    {
      id: 2,
      name: 'Мои задачи',
      onClick: () => setFilter('myTasks'),
    },
  ];

  return (
    <Container>
      {filter === 'myProjects' &&
        <MyProjectsTopBar/>
      }
      {filter === 'myTasks' &&
        <MyTasksTopBar/>
      }
      <Filters options={options}/>
      {items?.length === 0 ? 
        <EmptyResponse/> :
        <Content>
          {(filter === 'myProjects') && <ProjectListColumn projects={items}/>}
          {(filter === 'myTasks') && <TaskListColumn tasks={items}/>}
          <DiagramGantt tasks={items}/>
        </Content>
      }
    </Container>
  )
});
