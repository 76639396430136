import React, { useState, useEffect } from 'react';
import {
  InputGroup,
  InputLeftElement,
  Input,
  Button,
  Flex,
  Popover,
  PopoverTrigger,
  PopoverContent,
  useDisclosure,
} from '@chakra-ui/react';
import {
  Container,
  Top,
  Buttons,
  PopoverButton,
} from './styles';
import { useDebounce } from '@/shared';
import AddIcon from '@/assets/icons/add.svg';
import SearchIcon from '@/assets/icons/search.svg';
import { CreateProjectPopup, CreateTaskPopup, ImportTasksPopup } from '@/features/projects';
import createdProjectsState from './store/createdProjectsState';
import { getCreatedProjects } from '@/entities/projects';
import { useSearchParams } from 'react-router-dom';

export const CreatedProjectsTopBar: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [projectSearchTerm, setProjectSearchTerm] = useState(
    searchParams.get('search') ?
    searchParams.get('search') :
    ''
  );
  const { isOpen: projectIsOpen, onOpen: projectOnOpen, onClose: projectOnClose } = useDisclosure();
  const { isOpen: taskIsOpen, onOpen: taskOnOpen, onClose: taskOnClose } = useDisclosure();
  const { isOpen: importIsOpen, onOpen: importOnOpen, onClose: importOnClose } = useDisclosure();

  const debouncedProjectSearchTerm = useDebounce(projectSearchTerm, 500);

  const user = JSON.parse(localStorage.getItem('user'));

  function handleProjectSearch(debouncedSearchTerm: string) {
    setProjectSearchTerm(searchParams.get('search'));

    getCreatedProjects(user?.id, debouncedSearchTerm)
      .then((items) => {
        createdProjectsState.setProjects(items);
      })
      .then((res) => {
        searchParams.delete('search');
        setSearchParams(searchParams);
      })
      .catch((error) => {
        console.log(error);
      })
  }

  useEffect(() => {
    if (typeof debouncedProjectSearchTerm === 'string') {
      handleProjectSearch(debouncedProjectSearchTerm);
    }
  }, [debouncedProjectSearchTerm]);

  return (
    <>
      <Container>
        <Top>
          <InputGroup padding={'6px'}>
            <InputLeftElement pointerEvents='none'>
              <SearchIcon
                color='var(--addable-gray)'
                width={18}
                height={18}
              />
            </InputLeftElement>
            <Input
              value={projectSearchTerm}
              onChange={e => setProjectSearchTerm(e.target.value)}
              color='messenger'
              height={'28px'}
              variant='flushed'
              placeholder='Поиск по созданным вами проектам'
            />
          </InputGroup>

          <>
            <Popover>
              <PopoverTrigger>
                <Buttons>
                  <Button
                    borderRadius='8px'
                    bg='var(--main-purple)'
                    _hover={{ bg: 'var(--hover-purple)' }}
                    leftIcon={<AddIcon color='#fff' />} 
                  >
                    <h6 style={{ color: '#fff' }}>Создать</h6>
                  </Button>
                </Buttons>
              </PopoverTrigger>
              <PopoverContent w="full">
                <Flex direction="column" alignItems="start">
                  <PopoverButton
                    onClick={projectOnOpen}
                  >
                    Новый проект
                  </PopoverButton>
                  <PopoverButton
                    onClick={taskOnOpen}
                  >
                    Новая задача
                  </PopoverButton>
                </Flex>
              </PopoverContent>
            </Popover>
            <Button
              borderRadius='8px'
              padding='10px 35px'
              bg='var(--main-purple)'
              _hover={{ bg: 'var(--hover-purple)' }}
              onClick={importOnOpen}
            >
              <h6 style={{ color: '#fff' }}>Импортировать задачи</h6>
            </Button>
            <CreateProjectPopup isOpen={projectIsOpen} onClose={projectOnClose} />
            <CreateTaskPopup isOpen={taskIsOpen} onClose={taskOnClose} />
            <ImportTasksPopup isOpen={importIsOpen} onClose={importOnClose} />
          </>
        </Top>
      </Container>
    </>
  )
}
