import React from 'react';
import { Container } from './styles';

export interface ICardProps extends Omit<React.ComponentProps<'div'>, 'ref'> {
  isActive?: boolean;
}

export const Card: React.FC<ICardProps> = ({ children, isActive }) => {
  return (
    <Container
      style={{
        background: isActive ? 'var(--main-purple)' : '',
      }}
    >
      {children}
    </Container>
  )
}
