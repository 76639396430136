import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: fit-content;
  padding: 16px;
  border-radius: 16px;
  background-color: #F7F6F6;
  box-shadow: inset 0 0 0 1px #E6E6E6;
`;

export const Top = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
`;

export const Line = styled.div`
  height: 1px;
  width: 100%;
  background-color: #E6E6E6;
  margin: 14px 0 5px 0;
`;

export const Indicators = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const Indicator = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

export const IndicatorDot = styled.div`
  width: 7px;
  min-width: 7px;
  height: 7px;
  border-radius: 10px;
  background-color: #D9DFF4;
`;

export const IndicatorBody = styled.div`
  display: flex;
  flex-direction: column;
`;

export const IndicatorHeader = styled.div`
  font-size: 13px;
  font-weight: 600;
`;

export const IndicatorTest = styled.div`
  font-size: 12px;
`;

export const CompetenceEditButton = styled.button`
  transition: .3s;
  padding: 3px 7px;
  border-radius: 4px;
  text-align: left;
  &:hover {
    background-color: #EAEAEA;
  }
`;

export const CompetenceEditOptionButton = styled.button`
  transition: .3s;
  padding: 3px 7px;
  border-radius: 4px;
  text-align: left;
  width: 100%;
  &:hover {
    background-color: #EEEEEE;
  }
`;