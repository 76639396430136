import React, { useState } from 'react';
import styles from './styles.module.scss';

export type TOption = {
  id: number;
  name: string;
  onClick: () => void;
  isDisabled?: boolean;
}

export interface IOptions extends Omit<React.ComponentProps<'div'>, 'ref'> {
  options: TOption[];
  defaultOption?: TOption;
}

export const Filters: React.FC<IOptions> = ({ options, defaultOption, ...props }) => {
  const [active, setActive] = useState(defaultOption ? defaultOption?.name : options[0]?.name);

  function handleSetFilter(option: TOption) {
    setActive(option?.name);
    option.onClick();
  }

  return (
    <div className={styles.container} {...props}>
      {options.map(option =>
        <button
          style={{
            minWidth: 'fit-content',
            width: `${100 / options.length}%`,
          }}
          disabled={option?.isDisabled}
          key={option?.id}
          onClick={() => handleSetFilter(option)}
          className={
            active === option?.name ?
              styles.button + ' ' + styles.active :
              styles.button
          }
        >
          <h6>{option?.name}</h6>
        </button>
      )}
    </div>
  )
}
