import React, { useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button
} from '@chakra-ui/react';
import { Filters } from '@/features/common';
import { observer } from 'mobx-react-lite';
import careerState from '../../store/careerState';
import { Section, ListWrapper, List } from './styles';
import { VacancyVariants } from './VacancyVariants';
import { SpecializationVariants } from './SpecializationVariants';
import { InterestsVariants } from './InterestsVariants';
import { saveCareer } from '../../api';

export interface EditCareerPopupProps {
  isOpen: boolean;
  onClose: () => void;
}

export const EditCareerPopup: React.FC<EditCareerPopupProps> = observer(({ isOpen, onClose }) => {
  const [selected, setSelected] = useState<any>();

  function handleUpdateCareer(e: any) {
    e.preventDefault();
  }

  const options = [
    {
      id: 1,
      name: 'По должности',
      onClick: () => careerState.setActiveCareer('vacancy'),
    },
    {
      id: 2,
      name: 'По компетенции',
      onClick: () => careerState.setActiveCareer('specialization'),
    },
    {
      id: 3,
      name: 'По интересам',
      onClick: () => careerState.setActiveCareer('interests'),
    },
  ];

  function handleSaveCareer() {
    if (careerState.activeCareer === 'vacancy') {
      saveCareer(careerState.selectedStepsByVacancy)
        .then((res: any) => {
          console.log(res);
          onClose();
          window.location.reload();
        })
        .catch(e => {
          console.log(e);
        })
    }
    if (careerState.activeCareer === 'specialization') {
      saveCareer(careerState.selectedStepsBySpecialization)
        .then((res: any) => {
          console.log(res);
          onClose();
          window.location.reload();
        })
        .catch(e => {
          console.log(e);
        })
    }
    if (careerState.activeCareer === 'interests') {
      saveCareer(careerState.selectedStepsByInterests)
        .then((res: any) => {
          console.log(res);
          onClose();
          window.location.reload();
        })
        .catch(e => {
          console.log(e);
        })
    }
  }

  return (
    <Modal size={'xl'} blockScrollOnMount={false} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <form onSubmit={handleUpdateCareer}>
          <ModalContent borderRadius={16}>
            <ModalHeader alignItems={'center'}>
              <h3 style={{ textAlign: 'center' }}> Редактирование карьеры</h3>
            </ModalHeader>
            <ModalCloseButton top={'14px'} />

            <ModalBody
              padding='20px'
              display='flex'
              flexDirection='column'
              gap='30px'
              borderTop='1px solid var(--addable-gray)'
              borderBottom='1px solid var(--addable-gray)'
            >
              <Section>
                <h6>Направления развития</h6>
                <Filters options={options}/>
              </Section>

              <Section>
                <h5>
                  <>Направления развития в зависимости от </> 
                  {careerState.activeCareer === 'vacancy' && <>должности</>}
                  {careerState.activeCareer === 'specialization' && <>компетенции</>}
                  {careerState.activeCareer === 'interests' && <>интересов</>}
                </h5>
                <ListWrapper>
                  <List>
                    {careerState.activeCareer === 'vacancy' && 
                      <VacancyVariants/>
                    }
                    {careerState.activeCareer === 'specialization' &&
                      <SpecializationVariants/>
                    }
                    { careerState.activeCareer === 'interests' &&
                      <InterestsVariants/>
                    }
                  </List>
                </ListWrapper>
              </Section>
            </ModalBody>

            <ModalFooter gap='8px' display='flex'>
              <Button
                type='button'
                width='100%'
                onClick={onClose}
                variant='outline'
                color='var(--main-purple)'
                borderRadius='8px'
              >
                Отмена
              </Button>
              <Button
                type='button'
                onClick={handleSaveCareer}
                width='100%'
                borderRadius='8px'
                bg='var(--main-purple)'
                _hover={{ bg: 'var(--hover-purple)' }}
                color='#fff'
              >
                Сохранить изменения
              </Button>
            </ModalFooter>
          </ModalContent>
      </form>
    </Modal>
  )
});
