import React, { useState, useEffect } from 'react';

import {
  Competence,
  Top,
  TopBody,
  ArrowContainer,
  CompetenceName,
  CompetenceLevel,
  Line,
  Indicators,
  Indicator,
  IndicatorHeader,
  IndicatorLevel,
  IndicatorTop,
} from './styles';

import { ProfileLevelState } from '@/widgets';
import { ArrowOpen } from '@/UI';

export interface ProfileCompetenceCardProps {
  instance: any;
}

export const ProfileCompetenceCard: React.FC<ProfileCompetenceCardProps> = ({ instance }) => {
  const [isOpen, setIsOpen] = useState(false);

  const enRusMap: any = {
    'Beginner': 'Слабый',
    'Intermediate': 'Уверенный',
    'Master': 'Сильный',
  };

  function get_level_by_score(score: number, gradations: any) {
    const level: string = 'Master';

    const gradation_level_mapping: any = {
      0: 'Beginner',
      1: 'Intermediate',
      2: 'Master',
    }

    for (let i = 0; i < gradations.length; i++) {
      if (score < gradations[i]) {
        return gradation_level_mapping[i];
      }
    }

    return level;
  }

  return (
    <Competence>
      <Top
        onClick={() => setIsOpen(!isOpen)}
      >
        <TopBody>
          <CompetenceName>
            <h5>
              {instance.competence.name}
            </h5>
          </CompetenceName>
          <CompetenceLevel>
            {enRusMap[instance.level]}
          </CompetenceLevel>
        </TopBody>
        {instance.indicators &&
          <ArrowContainer
            style={{ transform: isOpen ? 'rotateX(180deg)' : 'rotateX(0deg)' }}
          >
            <ArrowOpen/>
          </ArrowContainer>
        }
      </Top>
      {instance.indicators && instance.indicators.length > 0 && 
        <div
          style={{
            padding: '0 16px',
          }}
        >
          <Line
            style={{
              margin: isOpen ? '0 0 16px 0' : '0',
              height: isOpen ? '1px' : '0px',
              opacity: isOpen ? '1' : '0',
            }}
          />
        </div>
      }
      {instance.indicators && 
        <Indicators
          style={{
            height: isOpen ? String(instance.indicators.length * 39.5 + (instance.indicators.length - 1) * 16 + 16) + 'px' : '0px',
            opacity: isOpen ? '1' : '0',
            padding: isOpen ? '0 16px 16px 16px' : '0 16px 0 16px',
          }}
        >
          {instance.indicators.map((indicatorInstance: any) => 
            <Indicator key={indicatorInstance.indicator.name}>
              <IndicatorTop>
                <IndicatorHeader>
                  {indicatorInstance.indicator.name}
                </IndicatorHeader>
                <IndicatorLevel>
                  {enRusMap[get_level_by_score(indicatorInstance.score, indicatorInstance.indicator.gradations)]}
                </IndicatorLevel>
              </IndicatorTop>
              <ProfileLevelState
                instance={indicatorInstance}
              />
            </Indicator>
          )}
        </Indicators>
      }
    </Competence>
  )
}




