import React, { useState, useEffect } from 'react';
import {
  Stack,
  Grid,
  GridItem,
  Box,
  Flex,
  useDisclosure,
} from '@chakra-ui/react';
import {
  DimmedText,
  CardHeading,
} from './styles';
import { Card, Skill, TTask } from '@/shared';
import { num_word } from '@/shared';
import CalendarIcon from '@/assets/icons/calendar.svg';
import { ProjectDrawer, TaskDrawer } from '@/widgets';
import projectState from '@/widgets/ProjectDrawer/store/projectState';
import taskState from '@/widgets/TaskDrawer/store/taskState';
import { SkillsList } from '@/widgets';
import { useSearchParams } from 'react-router-dom';

export interface IProjectCardProps {
  project: any;
}

export const ProjectCard: React.FC<IProjectCardProps> = ({ project }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: taskIsOpen, onOpen: taskOnOpen, onClose: taskOnClose } = useDisclosure();

  const skills = project.expected_roles
    .map((role: any) => role.skill).flat();

  const roles = project.expected_roles
    .map((role: any) => ({id: role.id, name: role.title}));

  function handleSelectProject() {
    projectState.setSelectedProject(project);
    onOpen();
  }

  useEffect(() => {
    const targetObjectId = Number(searchParams.get('id'));
    const taskTargetObjectId = Number(searchParams.get('task_id'));

    if (targetObjectId) {
      if (project?.id === targetObjectId) {
        projectState.setSelectedProject(project);
        onOpen();
        searchParams.delete('id');
        setSearchParams(searchParams);
      }
    }

    else if (taskTargetObjectId) {
      const task_id = Number(searchParams.get('task_id'));
      const project_id = Number(searchParams.get('project_id'));

      searchParams.delete('task_id');
      searchParams.delete('project_id');
      setSearchParams(searchParams);

      if (project?.id === project_id) {
        projectState.setSelectedProject(project);
        onOpen();
        const task = project?.tasks.find((task: TTask) => task.id === task_id);
        taskState.setSelectedTask(task);
        taskOnOpen();
      }
    }
  }, [])

  return (
    <>
      <button
        onClick={handleSelectProject}
        style={{ textAlign: 'left' }}
      >
        <Card>
          <Stack spacing={4}>
            <Box>
              <CardHeading>{project?.title}</CardHeading>
              <DimmedText>
                {project.tasks?.length} {num_word(project.tasks?.length, ['задача', 'задачи', 'задач'])}
              </DimmedText>
            </Box>

            <Box>{project.description}</Box>

            <Flex align="center" gap={2}>
              <CalendarIcon
                style={{
                  width: 20,
                  height: 20,
                }}
              />
              <Box>{project.start_date} - {project.end_date}</Box>
            </Flex>

            <Grid templateColumns="repeat(2, 1fr)">
              <GridItem>
                <h5>Роли</h5>
                <Box py={2}>
                  <SkillsList skills={roles} />
                </Box>
              </GridItem>

              <GridItem>
                <h5>Навыки</h5>
                <Box py={2}>
                  <SkillsList skills={skills} />
                </Box>
              </GridItem>
            </Grid>
          </Stack>
        </Card>
      </button>
      <ProjectDrawer isOpen={isOpen} onClose={onClose} />
      <TaskDrawer isOpen={taskIsOpen} onClose={taskOnClose} />
    </>
  );
}
