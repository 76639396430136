import React from 'react';
import {
  Stack,
  Box,
  Text,
  Image,
  Flex,
} from '@chakra-ui/react';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css'

export interface IOpenEndedResultProps {
  info: any;
}

export const OpenEndedResult: React.FC<IOpenEndedResultProps> = ({ info }) => {
  const [answer, ...rest] = info.answers;
  const title = info.question.text;
  const image = info.question.images.length > 0 ? info.question.images[0].image : null;

  return (
    <Stack
      position="relative"
      w="full"
    >
      <Stack spacing={4}>
        <h5>{title}</h5>

        {image &&
          <Flex my={2} w="50%" justify="center">
            <Zoom>
              <Image
                borderRadius="md"
                src={image}
                alt="Картинка к вопросу"
              />
            </Zoom>
          </Flex>
        }

        <Stack spacing={4}>
          <Box>
            <Text as="b">Ваш ответ:</Text> {info.text}
          </Box>
          <Box>
            <Text as="b">Ответ составителя теста:</Text> {answer.answer.text}
          </Box>
        </Stack>
      </Stack>
    </Stack>
  );
}
