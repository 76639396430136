import React, { Fragment, useEffect, useState } from 'react';
import { Card } from '@/shared';
import {
  Top,
  Title,
  H6,
  CollapseButton,
  PathParent,
  Path,
  Circle,
} from './styles';
import {
  Button,
  HStack,
  Stack,
  Text,
  useDisclosure,
  } from '@chakra-ui/react';
import EditIcon from '@/assets/icons/edit.svg';
import CollapseIcon from '@/assets/icons/collapse.svg';
import { EditPopup } from './EditPopup/EditPopup';
import workExperienceState from './store/workExperienceState';
import { get_duration } from '@/shared';
import dayjs from 'dayjs';
import { IUser } from '@/shared';
import { observer } from 'mobx-react-lite';

export interface IWorkExperienceProps {
  user: IUser;
}

export const WorkExperience: React.FC<IWorkExperienceProps> = observer(({ user }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isLoaded, setIsLoaded] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(true);

  useEffect(() => {
    if (!isLoaded) {
      workExperienceState.setExperience(user?.work_experience);

      setIsLoaded(true);
    }
  }, []);

  return (
    <Card>
      <Top>
        <Title>Опыт работы</Title>
        <Button
          onClick={onOpen}
          border='1px var(--addable-gray) solid'
          borderRadius='8px'
          bg='var(--main-white)'
          leftIcon={<EditIcon color='var(--main-purple)' />} 
        >
          <H6>Редактировать</H6>
        </Button>
      </Top>

      <PathParent>
        {
          workExperienceState.experience.length > 0 &&
          workExperienceState.experience
        .slice(0, isCollapsed ? 2 : workExperienceState.experience.length)
        .map((exp: any) =>  {
          const start_date = dayjs(exp.start_date, ['DD.MM.YYYY', 'DD.MM.YY']);
          const end_date = dayjs(exp.end_date, ['DD.MM.YYYY', 'DD.MM.YY']);
          const duration = get_duration(start_date, end_date);

          return (
            <Fragment key={exp.id}>
              <Path />
              <PathParent>
                <Circle />
                <Stack spacing={1}>
                  <HStack>
                    <h5>{exp.position}</h5>
                    <Text as="span">в {exp.company_name}</Text>
                  </HStack>
                  <Text as="span">{duration}</Text>
                </Stack>
              </PathParent>
            </Fragment>
          );
        })}
      </PathParent>

    {workExperienceState.experience.length > 2 &&
      <CollapseButton
        onClick={() => setIsCollapsed(!isCollapsed)}
      >
        Показать {isCollapsed ? <>больше</> : <>меньше</>}
        <CollapseIcon
          style={{
              transform: isCollapsed ? '' : 'rotate(180deg)', transition: 'all .2s ease-in-out'
          }}
          width={10}
          height={5}
        />
      </CollapseButton>
    }

      <EditPopup user_id={user?.id} isOpen={isOpen} onClose={onClose} />
    </Card>
  )
});
