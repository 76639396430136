import { Tr, Td, Box } from '@chakra-ui/react';
import { Flex } from 'antd';
import React from 'react';
import { TaskStatusHint } from '@/entities/projects';
import ClockIcon from '@/assets/icons/clock-fast-forward.svg';
import { useDisclosure } from '@chakra-ui/react';
import { TaskDrawer } from '@/widgets';
import taskState from '@/widgets/TaskDrawer/store/taskState';
import { PersonLine } from '@/shared';

export interface TableCardProps {
  task: any;
}

export const TableCard: React.FC<TableCardProps> = ({ task }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  function handleOpen() {
    taskState.setSelectedTask(task);
    onOpen();
  }

  return (
    <>
      <Tr onClick={handleOpen} style={{ cursor: 'pointer' }}>
        <Td fontWeight="bold">{task.title}</Td>
        <Td>
          <Flex align="center" gap={2}>
            <ClockIcon height={18} width={18} />
            {task.end_date}
          </Flex>
        </Td>
        <Td>
          <TaskStatusHint status={task?.status} />
        </Td>
        <Td>
          {task.contractors.length > 0 ?
          <PersonLine
            image={task.contractors[0]?.image}
            last_name={task.contractors[0]?.last_name}
            first_name={task.contractors[0]?.first_name}
          /> :
          <Box>Нет исполнителя</Box>
          }
        </Td>
      </Tr>
      <TaskDrawer isOpen={isOpen} onClose={onClose}/>
    </>
  )
}
