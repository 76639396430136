import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
    padding-bottom: 16px;
`;

export const Grid = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 296px);
    gap: 16px;
`;
