import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  padding: 1rem;
`;

export const Grouping = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

export const Disabled = styled.span`
  color: var(--disabled);
`;
