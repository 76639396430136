import { makeAutoObservable } from "mobx";
import { ITest, IQuestion, IAnswer } from '@/shared/types/tests/types';

class TestEditState {
  test: ITest = {
    title: '',
    start_date: '',
    end_date: '',
    questions: [],
  };

  constructor() {
    makeAutoObservable(this);
  }

  setTest(test: ITest) {
    this.test = test;
  }

  resetTest() {
    this.test = {
      title: '',
      start_date: '',
      end_date: '',
      questions: [],
    };
  }

  addQuestion(question: IQuestion) {
    this.test.questions.push(question);
  }

  setQuestionTitle(id: number, title: string) {
    this.test.questions.forEach((question) => {
      if (question.id === id) {
        question.text = title;
      }
    });
  }

  removeQuestion(id: number) {
    const questions = this.test.questions.filter((q) => q.id !== id);
    this.test.questions = questions;
  }

  setAnswers(question_id: number, answers: IAnswer[]) {
    this.test.questions.forEach((question, index) => {
      if (question.id === question_id) {
        this.test.questions[index].answers = answers;
      }
    });
  }

  addAnswer(question_id: number, answer: IAnswer) {
    const q_index = this.test.questions.findIndex((q) => q.id === question_id);

    this.test.questions[q_index].answers.push(answer);
  }

  removeAnswer(question_id: number, answer_id: number) {
    const q_index = this.test.questions.findIndex((q) => q.id === question_id);
    const answers = this.test.questions[q_index].answers.filter((a) => a.id !== answer_id);

    this.test.questions[q_index].answers = answers;
  }


  setAnswerText(question_id: number, answer_id: number, text: string) {
    const q_index = this.test.questions.findIndex((q) => q.id === question_id);

    const answers = this.test.questions[q_index]?.answers;
    const a_index = answers?.findIndex((a) => a.id === answer_id);

    this.test.questions[q_index].answers[a_index].text = text;
  }

  setRightAnswers(question_id: number, correct_ids: string[]) {
    const q_index = this.test.questions.findIndex((q) => q.id === question_id);

    this.test.questions[q_index]?.answers.forEach((answer, a_index) => {
      if (correct_ids.includes(answer.id.toString())) {
        this.test.questions[q_index].answers[a_index].is_right = true;
      } else {
        this.test.questions[q_index].answers[a_index].is_right = false;
      }
    });
  }

  getRightAnswerIds(question_id: number) {
    const q_index = this.test.questions.findIndex((q) => q.id === question_id);

    const answers = this.test.questions[q_index]?.answers;
    if (answers) {
      return answers.filter((a) => a.is_right).map((a) => a.id.toString());
    }
  }

  resetAnswers() {
    this.test.questions.forEach((q, q_index) => {
      if (q.type !== 'text') {
        q.answers.forEach((a, a_index) => {
          this.test.questions[q_index].answers[a_index].is_right = false;
        })
      }
    })
  }

  reset() {
    this.test.questions = [];
  }

  setDeadline(start_date: string, end_date: string) {
    this.test.start_date = start_date;
    this.test.end_date = end_date;
  }

  setQuestionImage(id: number, image: any) {
    const q_index = this.test.questions.findIndex((q) => q.id === id);

    this.test.questions[q_index].images = [image];
  }
}

export default new TestEditState();
