import styled from 'styled-components';

export const Question = styled.span`
  font-size: 12px;
  opacity: 40%;
`;

export const LabelStyles = styled.label`
  cursor: pointer;
  display: inline-flex;
  gap: 0.5rem;
  align-items: center;
  color: var(--main-purple);
  padding: 0.5rem;
  border-radius: 5px;

  &:hover {
    background: var(--chakra-colors-gray-200);
  }

  &:active {
    background: var(--chakra-colors-gray-300);
  }
`;
