import React, { useState, useEffect } from 'react';
import {
  Container,
  Top,
} from './styles';
import {
  InputGroup,
  InputLeftElement,
  Input,
} from '@chakra-ui/react';
import SearchIcon from '@/assets/icons/search.svg';
import testsState from '@/pages/DashboardHRTests/store/testsState';
import { useDebounce } from '@/shared';
import { AddTestDrawerBtn } from '@/entities/tests/ui/AddTestDrawerBtn/AddTestDrawerBtn';
import { getCompanyTests } from '@/entities/tests';

export const HRTopBar: React.FC = () => {
  const [searchTerm, setSearchTerm] = useState('');

  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  const companyId = JSON.parse(localStorage.getItem('user'))?.company[JSON.parse(localStorage.getItem('user'))?.company.length - 1];
  
  function handleSearch() {
    testsState.isLoading = true;
    getCompanyTests(companyId, debouncedSearchTerm)
    .then((res: any) => {
      testsState.setTests(res.data);
    })
    .catch((error) => {
      console.log(error);
    })
  }

  useEffect(() => {
    if (typeof debouncedSearchTerm === 'string') {
      handleSearch();
    }
  }, [debouncedSearchTerm]);

  return (
    <>
    <Container>
      <Top>
        <InputGroup padding={'6px'}>
          <InputLeftElement pointerEvents='none'>
            <SearchIcon
              color='var(--addable-gray)'
              width={18}
              height={18}
            />
          </InputLeftElement>
          <Input
            value={searchTerm}
            onChange={e => setSearchTerm(e.target.value)}
            color='messenger'
            height={'28px'}
            variant='flushed'
            placeholder={'Поиск по созданным тестам'}
          />
        </InputGroup>
        <AddTestDrawerBtn/>
      </Top>
    </Container>
    </>
  )
}

