import React, { useEffect, useState } from 'react';
import { getStepsByVacancy } from '@/features/career';
import careerState from '../../store/careerState';
import { CareerCard } from '@/features/career';
import { List } from './styles';
import { observer } from 'mobx-react-lite';

export const VacancyVariants: React.FC = observer(() => {
  const [selected, setSelected] = useState<any>();
  const [isAwaiting, setIsAwaiting] = useState(false);
  const [selectedCareer, setSelectedCareer] = useState([]);
  const vacancy = JSON.parse(localStorage.getItem('user'))?.active_vacancy;

  function handleGetVariants() {
    setIsAwaiting(true);
    getStepsByVacancy()
      .then((data: any) => {
        careerState.setStepsByVacancy([vacancy]);
        data.data.steps.forEach((newStep: any) => {
          let flag = true;
          careerState.stepsByVacancy.forEach((step: any) => {
            if (step.grade === newStep.grade) {
              flag = false
            }
          })

          if (flag) {
            careerState.setStepsByVacancy([...careerState.stepsByVacancy, newStep]);
          }
        });
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setIsAwaiting(false);
      })
  }

  useEffect(() => {
    if (careerState.stepsByVacancy.length === 0) {
      handleGetVariants();
    }
  }, [])

  function handleSelectGrade() {    
    const steps: any[] = [];

    careerState.stepsByVacancy.forEach((step: any) => {
      let stepValue = 0;
      let selectedValue = 0;

      if (step.grade === 'Lead') { stepValue = 10 }
      if (step.grade === 'Senior') { stepValue = 9 }
      if (step.grade === 'Middle') { stepValue = 8 }
      if (step.grade === 'junior') { stepValue = 7 }

      if (selected?.grade === 'Lead') { selectedValue = 10 }
      if (selected?.grade === 'Senior') { selectedValue = 9 }
      if (selected?.grade === 'Middle') { selectedValue = 8 }
      if (selected?.grade === 'junior') { selectedValue = 7 }

      if (selectedValue >= stepValue) {
        steps.push(step);
      }
    })

    careerState.setSelectedStepsByVacancy(steps.slice(1));
  }

  useEffect(() => {
    handleSelectGrade();
  }, [selected])

  return (
    <div>
      {isAwaiting && <>Loading...</>}
      {careerState.stepsByVacancy[0]?.grade === 'Lead' && <h6>Вы уже достигли самой высокой должности по этому направлению</h6>}
      {careerState.stepsByVacancy.length > 1 &&
        <List>
          {careerState.stepsByVacancy.slice(1).map((step: any, index) =>
            <button
              onClick={() => setSelected(step)}
              style={{ textAlign: 'left' }}
            >
              <CareerCard
                step={step}
                isActive={selected?.title && selected?.title === step?.title ? true : false}
                key={index}
              />
            </button>
          )}
        </List>
      }
    </div>
  )
});
