import React, { useState, useEffect } from 'react';
import { Filters } from '@/features/common';
import {
  Container,
  Top,
  Buttons,
  Bottom,
  H6,
} from './styles';
import {
  InputGroup,
  InputLeftElement,
  Input,
  Button,
  useDisclosure,
} from '@chakra-ui/react';
import AddIcon from '@/assets/icons/add.svg';
import SearchIcon from '@/assets/icons/search.svg';
import { AddHrPopup } from '@/entities/user/ui/AddHrPopup/AddHrPopup';
import { ICompany } from '@/shared';
import { getCompany } from '@/entities/company/api';
import { getEmployees } from '@/features/employee';
import employeesState from '@/pages/Employees/store/employeesState';
import { useDebounce } from '@/shared';
import { searchEmployees, searchEmployeesBySkill } from '@/features/employee/api';
import companyState from '@/entities/company/store/companyState';

const options = [
  {
    id: 1,
    name: 'Все',
    onClick: () => console.log(),
  },
  {
    id: 2,
    name: 'По отделам',
    onClick: () => console.log(),
  },
  {
    id: 3,
    name: 'По департаментам',
    onClick: () => console.log(),
  },
  {
    id: 4,
    name: 'По проектным командам',
    onClick: () => console.log(),
  },
];

export const EmployeesTopBar: React.FC = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [skillSearchTerm, setSkillSearchTerm] = useState('');
  const [company, setCompany] = useState<ICompany>();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  const debouncedSkillSearchTerm = useDebounce(skillSearchTerm, 500);

  const companyId = JSON.parse(localStorage.getItem('user'))?.company[0];
  const role = JSON.parse(localStorage.getItem('user'))?.role;
  
  function handleSearch() {
    searchEmployees(debouncedSearchTerm, companyId)
    .then((res: any) => {
      employeesState.setEmployees(res.data);
    })
    .catch((error) => {
      console.log(error);
    })
  }

  function handleSearchSkill(searchTerm: string) {
    searchEmployeesBySkill(searchTerm, companyId)
      .then((res: any) => {
        if (res?.status === 200) {
          employeesState.setEmployees(res.data);
        }
      })
      .catch((error) => {
        console.log(error);
      })
  }

  function handleGetEmployees() {
    getEmployees(companyId)
      .then((res: any) => {
        if (res?.status === 200) {
          employeesState.setEmployees(res.data);
        }
      })
      .catch((error) => {
        console.log(error);
      })
  }

  useEffect(() => {
    getCompany(companyId)
      .then((res: any) => {
        if (res?.status === 200) {
          companyState.setCompany(res.data);
          handleGetEmployees();
        }
      })
      .catch((error) => {
        console.log(error);
      })
  }, [])

  useEffect(() => {
    if (typeof debouncedSearchTerm === 'string') {
      handleSearch();
    }
  }, [debouncedSearchTerm]);

  useEffect(() => {
    if (typeof debouncedSkillSearchTerm === 'string') {
      handleSearchSkill(debouncedSkillSearchTerm);
    }
  }, [debouncedSkillSearchTerm]);

  return (
    <>
    <Container>
      <Top>
        <InputGroup padding={'6px'}>
          <InputLeftElement pointerEvents='none'>
            <SearchIcon
              color='var(--addable-gray)'
              width={18}
              height={18}
            />
          </InputLeftElement>
          <Input
            value={searchTerm}
            onChange={e => setSearchTerm(e.target.value)}
            color='messenger'
            height={'28px'}
            variant='flushed'
            placeholder='Поиск по сотрудникам компании'
          />
        </InputGroup>

        {(role === 'Manager') &&
          <InputGroup padding={'6px'}>
            <InputLeftElement pointerEvents='none'>
              <SearchIcon
                color='var(--addable-gray)'
                width={18}
                height={18}
              />
            </InputLeftElement>
            <Input
              value={skillSearchTerm}
              onChange={e => setSkillSearchTerm(e.target.value)}
              color='messenger'
              height={'28px'}
              variant='flushed'
              placeholder='Поиск по навыкам'
            />
          </InputGroup>
        }

        {(role === 'HR manager' || role === 'Super HR manager') &&
          <Buttons>
            <Button
              borderRadius='8px'
              bg='var(--main-purple)'
              _hover={{ bg: 'var(--hover-purple)' }}
              leftIcon={<AddIcon color='#fff' />} 
              onClick={onOpen}
            >
              <h6 style={{ color: '#fff' }}>Добавить сотрудника</h6>
            </Button>
          </Buttons>
        }
      </Top>
    </Container>
    <AddHrPopup isOpen={isOpen} onClose={onClose} company={companyState.company}/>
    </>
  )
}
