import React, { useState, useEffect } from 'react';
import { Task } from "frappe-gantt-react";
import {
  ProjectListColumn,
  TaskListColumn,
  DiagramGantt,
  CreatedProjectsTopBar,
  CreatedTasksTopBar,
  MyProjectsTopBar,
  MyTasksTopBar,
  EmptyResponse,
} from '@/widgets';
import {
  Container,
  Content
} from './styles';
import './styles';
import { Filters } from '@/features/common';
import { observer } from 'mobx-react-lite';
import myProjectsState from '@/widgets/MyProjectsTopBar/store/myProjectsState';
import myTasksState from '@/widgets/MyTasksTopBar/store/myTasksState';
import createdProjectsState from '@/widgets/CreatedProjectsTopBar/store/createdProjectsState';
import createdTasksState from '@/widgets/CreatedTasksTopBar/store/createdTasksState';
import { useSearchParams } from 'react-router-dom';

type TFilter = 'myProjects' | 'myTasks' | 'createdProjects' | 'createdTasks';

export const ManagerProjectsTasks: React.FC = observer(() => {
  const [searchParams, setSearchParams] = useSearchParams();
  const tabName = searchParams.get('tab');
  const tab = (tabName === 'createdProjects') ? 2 : 0;

  const [filter, setFilter] = useState<TFilter>('myProjects');
  const [items, setItems] = useState([]);

  useEffect(() => {
    if (tabName === 'myProjects') {
      setFilter('myProjects');
    }
    if (tabName === 'createdProjects') {
      setFilter('createdProjects');
    }
    searchParams.delete('tab');
    setSearchParams(searchParams);
  }, []);

  useEffect(() => {
    if (filter === 'myProjects') {
      setItems(myProjectsState.projects);
    }
    if (filter === 'myTasks') {
      setItems(myTasksState.tasks);
    }
    if (filter === 'createdProjects') {
      setItems(createdProjectsState.projects);
    }
    if (filter === 'createdTasks') {
      setItems(createdTasksState.tasks);
    }
  }, [filter, myProjectsState.projects, myTasksState.tasks, createdProjectsState.projects, createdTasksState.tasks])

  const options = [
    {
      id: 1,
      name: 'Мои проекты',
      onClick: () => setFilter('myProjects'),
    },
    {
      id: 2,
      name: 'Мои задачи',
      onClick: () => setFilter('myTasks'),
    },
    {
      id: 3,
      name: 'Созданные проекты',
      onClick: () => setFilter('createdProjects'),
    },
    {
      id: 4,
      name: 'Созданные задачи',
      onClick: () => setFilter('createdTasks'),
    },
  ];

  return (
    <Container>
      {filter === 'createdProjects' &&
        <CreatedProjectsTopBar/>
      }
      {filter === 'createdTasks' &&
        <CreatedTasksTopBar/>
      }
      {filter === 'myProjects' &&
        <MyProjectsTopBar/>
      }
      {filter === 'myTasks' &&
        <MyTasksTopBar/>
      }
      <Filters options={options} defaultOption={options[tab]} />
      {items?.length === 0 ? 
        <EmptyResponse/> :
        <Content>
          {(filter === 'myProjects' || filter === 'createdProjects') && <ProjectListColumn projects={items}/>}
          {(filter === 'myTasks' || filter === 'createdTasks') && <TaskListColumn tasks={items}/>}
          <DiagramGantt tasks={items}/>
        </Content>
      }
    </Container>
  )
});
