import axios from 'axios';
import { ICompetence } from '@/shared';

const API = process.env.API_URL;
const access = JSON.parse(localStorage.getItem('accessToken'));
const refresh = JSON.parse(localStorage.getItem('refreshToken'));

export const createCompetence = async (competence: ICompetence) => {
  console.log(competence);
  const response = await axios<ICompetence>({
      url: `${API}/competences/`,
      method: 'POST',
      headers: {
          'Content-Type': 'application/json',
          "Access-Control-Allow-Headers": "*",
          "Authorization": `Bearer ${access}`,
      },
      data: competence,
  })
  
  return response
}

export const editCompetence = async (competence: ICompetence) => {
  const response = await axios<ICompetence>({
      url: `${API}/competences/${competence.id}/`,
      method: 'PATCH',
      headers: {
          'Content-Type': 'application/json',
          "Access-Control-Allow-Headers": "*",
          "Authorization": `Bearer ${access}`,
      },
      data: competence,
  })
  
  return response
} 

export const deleteCompetence = async (competenceId: number) => {
  const response = await axios<ICompetence[]>({
      url: `${API}/competences/${competenceId}/`,
      method: 'DELETE',
      headers: {
          'Content-Type': 'application/json',
          "Access-Control-Allow-Headers": "*",
          "Authorization": `Bearer ${access}`,
      },
  })
  
  return response
} 
