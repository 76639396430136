import React, { useState, useEffect, useRef } from 'react';
import {
  Button,
  Image,
  Box,
  Flex,
} from '@chakra-ui/react';
import {
  Container,
  OtherOption,
} from "./styles";
import { Divider } from '@chakra-ui/react';
import dayjs from 'dayjs';
import { getFilteredTextAnswers } from '@/entities/tests/api';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css'

export interface IOpenEndedQuestionStatsProps {
  answerCount: number;
  title: string;
  answers: any[];
  question: any;
  filters: any;
  currentFilter: string;
  image: any;
}

type TCategory = {
  id: number,
  name: string,
}

export const OpenEndedQuestionStats: React.FC<IOpenEndedQuestionStatsProps> = ({ image, answerCount, title, answers, question, filters, currentFilter }) => {
  const [results, setResults] = useState(answers);

  const [isFetching, setIsFetching] = useState(false);
  const [totalCount, setTotalCount] = useState(answerCount);

  const increment = 5;
  let [count, setCount] = useState(increment);

  const firstUpdate = useRef(true);
  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    handleGetResults().then((count) => {
      setIsFetching(false);
      setTotalCount(count);
    });
  }, [count, totalCount])

  async function handleGetResults() {
    let ids: number[] = [];
    if (currentFilter === 'employees') {
      ids = filters.employees.map((c: TCategory) => c.id);
    } else if (currentFilter === 'groups') {
      ids = filters.groups.map((c: TCategory) => c.id);
    } else if (currentFilter === 'departments') {
      ids = filters.departments.map((c: TCategory) => c.id);
    }

    return getFilteredTextAnswers(question.id, count - increment, increment, currentFilter, ids)
      .then((res: any) => {
        setResults([...results, ...res.data.results]);
        setTotalCount(res.data.count);
        return res.data.count;
      })
      .catch((error) => {
        console.log(error);
      })
    }

  return (
    <Container>
      <h3>{title}</h3>
      {image &&
        <Flex my={2} w="50%" justify="center">
          <Zoom>
            <Image borderRadius="md"  src={image} alt="Картинка к вопросу" />
          </Zoom>
        </Flex>
      }
      {results.length > 0 ?
      <>
        {results.map((answer: any, index: number) =>
          <div key={index}>
            <OtherOption>
              <div style={{wordBreak: 'break-all'}}>{answer.text}</div>
              <div>{dayjs(answer.finished_at, 'DD.MM.YYYY HH:mm').format('DD/MM/YY, HH:mm')}</div>
            </OtherOption>
            <Divider/>
          </div>
        )}
      </> :
      <Box pt={4}>Ответов нет</Box>
      }
      {(results.length > count) &&
        <Button
          my={4}
          disabled={isFetching}
          onClick={() => {
            setIsFetching(true);
            setCount(count + increment);
          }}
        >
        {isFetching && <>Загрузка...</>}
        {!isFetching &&
          <>
            Показать еще {results &&
                (totalCount > count + increment)
                ? increment
                : (totalCount - count)
              }
          </>
        }
        </Button>
      }
    </Container>
  );
}

