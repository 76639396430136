import React from 'react';
import { TestDashboard } from '@/widgets/TestDashboard/TestDashboard';
import { TestInfoDrawer } from '@/widgets/TestInfoDrawer/TestInfoDrawer';
import { TestEditDrawer } from '@/widgets/TestEditDrawer/TestEditDrawer';
import { TestCreationDrawer } from '@/widgets/TestCreationDrawer/TestCreationDrawer';
import { TestsList } from '@/widgets';
import { HRTopBar } from '@/widgets/TestsTopBar';
import testsState from './store/testsState';
import { observer } from 'mobx-react-lite';

export const DashboardHRTests: React.FC = observer(() => {
  return (
    <div>
      <TestDashboard>
        <HRTopBar />
        {testsState.isLoading ?
          <div>Загрузка...</div> :
          <TestsList tests={testsState.tests} />
        }
      </TestDashboard>
      <TestInfoDrawer />
      <TestCreationDrawer />
      <TestEditDrawer />
    </div>
  )
});
