import { makeAutoObservable } from 'mobx';
import { ITest } from '@/shared/types/tests/types';

class employeesTestState {
  tests: ITest[] = [];
  isLoading = true;

  constructor() {
    makeAutoObservable(this);
  }

  setTests(tests: ITest[]) {
    this.tests = tests;
    this.isLoading = false;
  }
}

export default new employeesTestState();
