import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Navbar } from '@/widgets';
import { Outlet } from 'react-router-dom';
import { Page, Board } from './styles';

const categories = [
  {
      id: 1,
      title: 'Личное',
      links: [
          {
              id: 1,
              title: 'Опыт и навыки',
              to: '/dashboard-employee-profile',
          },
          {
              id: 2,
              title: 'Проекты и задачи',
              to: '/dashboard-employee-tasks',
          },
          {
            id: 3,
            title: 'Моя карьера',
            to: '/dashboard-employee-career',
          },
          {
              id: 4,
              title: 'Курсы',
              to: '/dashboard-employee-courses',
          },
          {
              id: 5,
              title: 'Тесты',
              to: '/dashboard-employee-tests',
          },
      ],
  },
  {
      id: 2,
      title: 'Другое',
      links: [
          {
              id: 5,
              title: 'Ярмарка проектов',
              to: '/dashboard-employee-projects',
          },
      ],
  },
];

export const EmployeeLayout: React.FC = () => {
  const navigate = useNavigate();

  const user = JSON.parse(localStorage.getItem('user'));

  useEffect(() => {
      if (!user) {
        navigate('/');
      }
  
      if (user?.role !== 'User') {
        navigate('/');
      }
  }, [])

  return (
    <Page>
      <Navbar categories={categories} />
      <Board>
          <Outlet />
      </Board>
    </Page>
  )
}
