import styled from "styled-components";

export const TopMenu = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 0 0 50px 0;
`;

export const TopMenuBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 900px;
`;

export const Limit = styled.div`
  font-size: 12px;
`;

export const TestResultsTable = styled.div`
  max-width: 900px;
  display: flex;
  flex-direction: column;
  margin: 0;
  border-radius: 16px;
  box-shadow: inset 0 0 0 1px #E6E6E6;
  font-size: 12px;
`;

export const TableBody = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 276px;
  overflow: auto;
`;

export const TableHeader = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  padding: 0 16px;
  border-bottom: 1px solid #E6E6E6;
`;

export const TableRow = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  padding: 0 16px;
  box-shadow: 0 1px 0 0 #E6E6E6;
`;

export const TableHeaderElement = styled.div`
  color: #909090;
  padding: 12px 16px 12px 0;
`;

export const TableRowElement = styled.div`
  padding: 12px 16px 12px 0;
`;

export const GroupCompetenceAnalytics = styled.div`
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 250px 9fr;
  width: 100%;
  border: 1px solid #E6E6E6;
  border-radius: 16px;
  overflow: hidden;
  margin: 0 0 50px 0;
`;

export const EmptyBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-bottom: 1px solid #E6E6E6;
  border-right: 1px solid #E6E6E6;
  padding: 12px;
  text-align: right;
  font-size: 12px;
`;

export const HeadInfo = styled.div`
  box-sizing: border-box;
  display: flex;
  height: 50px;
  gap: 20px;
  padding: 12px;
  border-bottom: 1px solid #E6E6E6;
`;

export const HeadInfoItem = styled.div`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 12px;
`;

export const HeadInfoItemDot = styled.div`
  box-sizing: border-box;
  width: 7px;
  height: 7px;
  border-radius: 10px;
`;

export const EmployeeCompetenceAnalytics = styled.div`
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 250px 9fr;
  width: 100%;
  border: 1px solid #E6E6E6;
  border-radius: 16px;
  overflow: hidden;
  margin: 0 0 50px 0;
`;

export const NoData = styled.div`
  display: flex;
  align-items: center;
  height: 50px;
  padding: 12px;
  color: #aaaaaa;
  font-size: 12px;
`;

export const ExternalTestsNoData = styled.div`
  display: flex;
  align-items: center;
  padding: 12px 16px;
  color: #aaaaaa;
  font-size: 12px;
`;