import React from 'react';
import bg from '../../assets/auth-bg.png';
import styled from 'styled-components';

export const Page = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  height: 100vh;
  width: calc(100vw - 224px);
  background-color: var(--main-purple);

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Content = styled.div`
  padding: 20px;
  background: var(--main-white);
  border-radius: 16px;
}
`;

export const Page404: React.FC = () => {
  return (
    <Page
      style={{
        backgroundImage: `url(${bg})`,
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '90%' 
      }}
    >
      <Content>
        <h3>Данная страница находится в разработке</h3>
      </Content>
    </Page>
  )
}
