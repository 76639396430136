import { makeAutoObservable } from "mobx";
import { TEducation } from "@/entities/user/ui/EducationCard/EducationCard";

class EducationState {
  educations: TEducation[] = [];
  selected: TEducation | any = {};
  isFormActive: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  setEducations(educations: TEducation[]) {
    this.educations = educations;
  }

  add(education: TEducation) {
    this.educations = [...this.educations, education];
  }

  setSelected(education: TEducation | {}) {
    this.selected = education;
  }

  remove(id: number) {
    this.educations = this.educations.filter((education) => education.id !== id);
  }

  setIsFormActive(value: boolean) {
    this.isFormActive = value;
  }
}

export default new EducationState();