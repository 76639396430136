import React from 'react';
import Drawer from '@mui/material/Drawer';
import {
  Container,
  DrawerHeaderStyles,
} from './styles';
import {
  CloseButton,
  Divider,
} from '@chakra-ui/react';
import testEditDrawerState from '@/widgets/TestsList/store/testEditDrawerState';
import { observer } from 'mobx-react-lite';
import { TestEditForm } from '@/widgets';

export const TestEditDrawer = observer(() => {
  const handleDrawerClose = () => {
    testEditDrawerState.closeDrawer();
  };

  return (
    <Drawer
      sx={{
        width: testEditDrawerState.width,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: testEditDrawerState.width,
          boxSizing: 'border-box',
        },
      }}
      variant="persistent"
      anchor="right"
      open={testEditDrawerState.open}
    >
      <Container>
        <DrawerHeaderStyles>
          <h3>Редактирование теста</h3>
          <CloseButton
            size='lg'
            onClick={handleDrawerClose}
          />
        </DrawerHeaderStyles>
      </Container>

      <Divider />

      <TestEditForm />
    </Drawer>
  );
});

