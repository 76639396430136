import React, { useState, useEffect } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Textarea,
  Input,
  Select,
  Stack,
  Divider,
  InputGroup,
  Box,
} from '@chakra-ui/react';
import {
  SkillsWrapper,
  SkillList,
  SkillButton,
} from './styles';
import { Skill, TTask } from '@/shared';
import { SubmitHandler, useForm, Controller } from 'react-hook-form';
import { DatePicker, ConfigProvider } from 'antd';
import { searchSkills } from '@/features/employee';
import { useDebounce } from '@/shared';
import projectsPageState from '@/pages/Projects/store/projectsPageState';
import { observer } from 'mobx-react-lite';
import { addTask } from '@/features/projects';
import { editTask } from '@/features/projects';
import { getProjectById, getProjectWithTask } from '@/entities/projects';
import taskState from '@/widgets/TaskDrawer/store/taskState';
import dayjs from 'dayjs';

const { RangePicker } = DatePicker;

interface IFormFileds {
  name: string;
  description: string;
  deadline: any[];
  skills: any[];
  difficulty: string;
  project: any;
}

interface IEditTaskPopupProps {
  isOpen: boolean;
  onClose: () => void;
}

const difficultOptions = [
  '1 stp',
  '2 stp',
  '3 stp',
  '4 stp',
  '5 stp',
];

export const EditTaskPopup: React.FC<IEditTaskPopupProps> = observer(({ isOpen, onClose }) => {
  const { 
    control,
    register,
    setValue,
    handleSubmit,
    formState: { errors, isSubmitting } 
  } = useForm<IFormFileds>();

  const start_date = dayjs(taskState.selectedTask.start_date, 'DD.MM.YYYY');
  const end_date = dayjs(taskState.selectedTask.end_date, 'DD.MM.YYYY');

  const [dates, setDates] = useState([start_date, end_date]);

  const [skills, setSkills] = useState(taskState.selectedTask?.skill);
  const [softSkills, setSoftSkills] = useState([]);
  const [hardSkills, setHardSkills] = useState([]);
  const [searchSoftTerm, setSearchSoftTerm] = useState('');
  const [searchHardTerm, setSearchHardTerm] = useState('');
  const [selectedDifficult, setSelectedDifficult] = useState<any>(taskState.selectedTask?.complexity);

  const [boundProjectIsLoading, setBoundProjectIsLoading] = useState(true);
  const [selectedProjectId, setSelectedProjectId] = useState<any>(undefined);

  function addSkill(addedSkill: any) {
    let flag = true;
    skills.forEach((s) => {
      if (s.name === addedSkill.name) {
        flag = false;
      } 
    })
    if (flag) {
      setSkills([...skills, addedSkill]);
    }
  } 

  function removeSkill(removedSkill: any) {
    const updatedSkills = skills.filter((skill: any) => {
      return skill?.name !== removedSkill?.name;
    })
    setSkills(updatedSkills);
  }


  function handleDatesChange(dates: any) {
    setDates(dates);
    setValue('deadline', dates);
  }

  const debouncedSearchSoftTerm = useDebounce(searchSoftTerm, 500);
  const debouncedSearchHardTerm = useDebounce(searchHardTerm, 500);

  useEffect(() => {
    if (searchSoftTerm) {
      handleSoftSearch('Soft');
    }
  }, [debouncedSearchSoftTerm])

  useEffect(() => {
    if (searchHardTerm) {
      handleHardSearch('Hard');
    }
  }, [debouncedSearchHardTerm])

  function handleHardSearch(type: string) {
    searchSkills(debouncedSearchHardTerm, type)
    .then((res: any) => {
      setHardSkills(res.data);
    })
    .catch((error) => {
      console.log(error);
    })
  }

  function handleSoftSearch(type: string) {
    searchSkills(debouncedSearchSoftTerm, type)
    .then((res: any) => {
      setSoftSkills(res.data);
    })
    .catch((error) => {
      console.log(error);
    })
  }

  useEffect(() => {
    getProjectWithTask(taskState.selectedTask?.id)
      .then((res) => {
        setSelectedProjectId(res.data[0]?.id);
        setBoundProjectIsLoading(false);
      })
      .catch((e) => {
        console.log(e);
      })

  }, []);

  const onSubmit: SubmitHandler<IFormFileds> = (data) => {
    const newTask = {
      title: data.name,
      description: data.description,
      start_date: dates[0].format('DD.MM.YYYY'),
      end_date: dates[1].format('DD.MM.YYYY'),
      status: 'Unavailable',
      complexity: selectedDifficult,
      skill: skills,
      priority: 'Low',
    }

    editTask(taskState.selectedTask.id, newTask)
      .then((taskRes) => {
        if (selectedProjectId) {
          const taskId = taskRes.data.id;

          getProjectById(selectedProjectId)
            .then((projectRes) => {
              const tasks = projectRes.data.tasks.map((task: TTask) => task.id);
              addTask(selectedProjectId, [...tasks, taskId])
                .then((res) => {
                  window.location.reload();
                })
                .catch((e) => {
                  console.log(e);
                })
            })
            .catch((e) => {
              console.log(e);
            })
        } else {
          window.location.reload();
        }
        onClose();
      })
      .catch(e => {
        console.log(e);
      })
  };

  return (
    <Modal size={'4xl'} closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader alignItems={'center'}>
            <h3 style={{ textAlign: 'center' }}>Редактирование задачи</h3>
          </ModalHeader>

          <ModalCloseButton top={'10px'} size="lg" />

          <Divider />

          <ModalBody>
            <Stack my={4} spacing={8}>
              <FormControl isRequired>
                <FormLabel>Название задачи</FormLabel>
                <Input
                  id='name'
                  defaultValue={taskState.selectedTask.title}
                  {...register('name', { 
                    required: 'Обязательное поле'
                  })}
                  placeholder='Название задачи'
                  type='text'
                  size='sm'
                  variant='flushed'
                />
                <FormErrorMessage>{errors.name && <>{errors.name.message}</>}</FormErrorMessage>
              </FormControl>

              <FormControl isRequired>
                <FormLabel>Описание задачи</FormLabel>
                <Textarea
                  id='description'
                  defaultValue={taskState.selectedTask.description}
                  {...register('description', { 
                    required: 'Обязательное поле'
                  })}
                  placeholder='Подробно опишите задачу...'
                  size='lg'
                  variant='outline'
                />
                <FormErrorMessage>{errors.description && <>{errors.description.message}</>}</FormErrorMessage>
              </FormControl>

              <FormControl isRequired>
                <FormLabel>Дедлайн</FormLabel>
                {/*
                  By default, MUI Drawer component has a z-index of 1200
                  Source: https://mui.com/material-ui/customization/z-index/
                */}
                <ConfigProvider
                  theme={{
                    components: {
                      DatePicker: {
                        zIndexPopup: 2000,
                      },
                    },
                  }}
                >
                  <Controller
                    control={control}
                    name="deadline"
                    render = {({ field: { onChange, value } }) => {
                      if (dates && dates.length > 0) {
                        const start_date = dates[0];
                        const end_date = dates[1];
                        return (
                          <RangePicker
                            defaultPickerValue={[start_date, end_date]}
                            defaultValue={[start_date, end_date]}
                            value={[start_date, end_date]}
                            style={{ width: '100%', paddingInline: 0 }}
                            variant="borderless"
                            onChange={handleDatesChange}
                            format="DD/MM/YYYY"
                          />
                        );
                      } else {
                        return (
                          <RangePicker
                            style={{ width: '100%', paddingInline: 0 }}
                            variant="borderless"
                            onChange={handleDatesChange}
                            format="DD/MM/YYYY"
                          />
                        );
                      }
                    }}
                  />
                </ConfigProvider>

                {errors.deadline && <span>This field is required</span>}
              </FormControl>

              <FormControl>
                  <FormLabel>Hard skills *</FormLabel>
                  <InputGroup>
                    <Input
                      value={searchHardTerm}
                      onChange={e => setSearchHardTerm(e.target.value)}
                      color='messenger'
                      height={'28px'}
                      variant='flushed'
                      placeholder='Введите значение'
                    />
                  </InputGroup>
                </FormControl>
                  {searchHardTerm &&
                  <SkillsWrapper>
                  <SkillList>
                    {hardSkills.map((skill: any) =>
                      <Skill title={skill?.name} key={skill?.id}>
                        <SkillButton type='button' onClick={() => addSkill(skill)}>+</SkillButton>
                      </Skill>
                    )}
                  </SkillList>
                  {hardSkills.length === 0 &&
                      <Skill title={'Новый'}>
                        <SkillButton type='button' onClick={() => {
                          addSkill({
                            id: Date.now(),
                            name: searchHardTerm,
                            type: "Hard"
                          })
                          setSearchHardTerm('')
                        }}>+</SkillButton>
                      </Skill>
                    }
                  </SkillsWrapper>
                  }
                  <FormControl>
                    <FormLabel>Soft skills *</FormLabel>
                    <InputGroup>
                      <Input
                        value={searchSoftTerm}
                        onChange={e => setSearchSoftTerm(e.target.value)}
                        color='messenger'
                        height={'28px'}
                        variant='flushed'
                        placeholder='Введите значение'
                      />
                    </InputGroup>
                  </FormControl>
                  {searchSoftTerm &&
                    <SkillsWrapper>
                    <SkillList>
                      {softSkills && softSkills.map((skill: any) =>
                        <Skill title={skill?.name} key={skill?.id}>
                          <SkillButton type='button' onClick={() => addSkill(skill)}>+</SkillButton>
                        </Skill>
                      )}
                    </SkillList>
                    {softSkills.length === 0 &&
                      <Skill title={'Новый'}>
                        <SkillButton type='button' onClick={() => {
                          addSkill({
                            id: Date.now(),
                            name: searchSoftTerm,
                            type: "Soft"
                          })
                          setSearchSoftTerm('')
                        }}>+</SkillButton>
                      </Skill>
                    }
                    </SkillsWrapper>
                  }
                <SkillsWrapper>
                  <SkillList>
                    {skills && skills.map((skill: any) => 
                      <Skill title={skill.name} key={skill.id}>
                        <SkillButton type='button' onClick={() => removeSkill(skill)}>-</SkillButton>
                      </Skill>
                    )}
                  </SkillList>
                </SkillsWrapper>

              <FormControl isRequired>
                <FormLabel>Сложность</FormLabel>
                <Select
                  {...register('difficulty', { 
                    required: 'Обязательное поле'
                  })}
                  variant='flushed'
                  onChange={(e) => setSelectedDifficult(e.target.value)}
                  value={selectedDifficult}
                >
                  <option hidden disabled value="">Выберите сложность</option>
                  {difficultOptions.map((option) =>
                    <option key={option} value={option}>{option}</option>
                  )}
                </Select>
                <FormErrorMessage>{errors.difficulty && <>{errors.difficulty.message}</>}</FormErrorMessage>
              </FormControl>

              <FormControl>
                <FormLabel>Привязка к проекту</FormLabel>
                {boundProjectIsLoading ?
                  <Box>Загрузка...</Box> :
                  <Select
                    variant='flushed'
                    onChange={(e) => setSelectedProjectId(e.target.value)}
                    value={selectedProjectId}
                    defaultValue={selectedProjectId}
                  >
                    <option value={undefined}>Не привязана к проекту</option>
                    {projectsPageState.projects && projectsPageState.projects.map((option: any) =>
                      <option key={option.id} value={option.id}>{option.title}</option>
                    )}
                  </Select>
                }
                <FormErrorMessage>{errors.project && <>{errors.project.message}</>}</FormErrorMessage>
              </FormControl>

            </Stack>
          </ModalBody>

          <Divider />

          <ModalFooter gap='8px' display='flex'>
            <Button
              type='button'
              width='100%'
              onClick={onClose}
              variant='outline'
              color='var(--main-purple)'
              borderRadius='8px'
            >
              Отмена
            </Button>

            <Button
              type='submit'
              width='100%'
              borderRadius='8px'
              bg='var(--main-purple)'
              _hover={{ bg: 'var(--hover-purple)' }}
              color='#fff'
              isLoading={isSubmitting ? true : false}
            >
              Сохранить изменения
            </Button>
          </ModalFooter>
        </ModalContent>
      </form>
    </Modal>
  );
});
