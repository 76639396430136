import styled from "styled-components";

export const Container = styled.div`
  margin-top: 98px;
  min-width: 208px;
  border-right: 1px solid #e6e6e6;
`;

export const ProjectContainer = styled.button`
  text-align: left;
  width: 100%;
  max-height: 77px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  border-top: 1px solid #e6e6e6;
  padding: 16px 0;
  overflow: auto;
`;

export const InfoText = styled.p`
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  color: var(--main-black);
  opacity: .5;
`;
