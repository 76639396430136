import React from 'react';
import {
  Stack,
  TableContainer,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Flex,
  Box,
  Button,
} from '@chakra-ui/react';
import LinkExternalIcon from '@/assets/icons/link-external.svg';
import { TProject } from '@/shared';
import { TableCard } from './TableCard';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

export interface IProjectTasksTableProps {
  project: TProject;
}

export const ProjectTasksTable: React.FC<IProjectTasksTableProps> = ({ project }) => {
  const user = JSON.parse(localStorage.getItem('user')); 
  const tasks = project?.tasks;

  const pathname = useLocation().pathname;

  const tab = (project?.owner.id === user.id) ? 'createdProjects' : 'myProjects';

  if (!tasks || tasks.length === 0) {
    return (
      <Stack spacing={8}>
        <h5>Задачи</h5>
        <Box alignSelf="center">Задач нет</Box>
      </Stack>
    );
  }

  return (
    <>
      <Stack spacing={2}>
        <h5>Задачи</h5>
        <TableContainer>
          <Table size='sm'>
            <Thead>
              <Tr>
                <Th>Название</Th>
                <Th>Деддлайн</Th>
                <Th>Статус</Th>
                <Th>Исполнитель</Th>
              </Tr>
            </Thead>
            <Tbody>
              {tasks && tasks.map((task: any) => 
                <TableCard key={task?.id} task={task}/>
              )}
            </Tbody>
          </Table>
        </TableContainer>
      </Stack>
      {(pathname !== '/dashboard-manager-tasks' && pathname !== '/dashboard-employee-tasks') &&
        <>
          {(user?.role === 'Manager') &&
            <Link to={`/dashboard-manager-tasks?tab=${tab}&search=${project?.title}`}>
              <Button
                rightIcon={<LinkExternalIcon height={18} width={18} />}
                color="var(--main-purple)"
                bg="none"
                alignSelf="start"
              >
                Доска задач проекта
              </Button>
            </Link>
          }
          {user?.role === 'User' &&
            <Link to={`/dashboard-employee-tasks?search=${project?.title}`}>
              <Button
                rightIcon={<LinkExternalIcon height={18} width={18} />}
                color="var(--main-purple)"
                bg="none"
                alignSelf="start"
              >
                Доска задач проекта
              </Button>
            </Link>
          }
        </>
      }
    </>
  );
}
