import axios from 'axios';
import { ICompany } from '@/shared';

const API = process.env.API_URL;
const access = JSON.parse(localStorage.getItem('accessToken'));
const refresh = JSON.parse(localStorage.getItem('refreshToken'));

export const createCompany = async (company: ICompany) => {
    const res = await axios<ICompany>({
        url: `${API}/companies/`,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            "Access-Control-Allow-Headers": "*",
            "Authorization": `Bearer ${access}`,
        },
        data: company,
    })
    
    return res
} 

export const editCompany = async (id: number, company: ICompany) => {
    const res = await axios({
        url: `${API}/companies/${id}/`,
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            "Access-Control-Allow-Headers": "*",
            "Authorization": `Bearer ${access}`,
        },
        data: company
    })
    
    return res
} 

export const deleteCompany = async (id: number) => {
    const res = await axios({
        url: `${API}/companies/${id}/`,
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            "Access-Control-Allow-Headers": "*",
            "Authorization": `Bearer ${access}`,
        },
    })
    
    return res
} 

export const searchCompany = async (term: string) => {
    const res = await axios({
        url: `${API}/companies/?search=${term}`,
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            "Access-Control-Allow-Headers": "*",
            "Authorization": `Bearer ${access}`,
        },
    })
    
    return res
} 

export const addVacanciForCompany = async (vacanciId: number, companyId: number) => {
    const res = await axios({
        url: `${API}/company-vacancies/`,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            "Access-Control-Allow-Headers": "*",
            "Authorization": `Bearer ${access}`,
        },
        data: {
            company_position: vacanciId,
            company: companyId
        }
    })
    
    return res
} 
