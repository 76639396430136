import axios from 'axios';

const API = process.env.API_URL;
const access = JSON.parse(localStorage.getItem('accessToken'));
const refresh = JSON.parse(localStorage.getItem('refreshToken'));


export const getVacancies = async (offset: number, term?: string) => {
    const res = await axios({
        url: `${API}/vacancies/?is_public=true&limit=10&offset=${offset}&search=${term ? term : ''}`,
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            "Access-Control-Allow-Headers": "*",
            "Authorization": `Bearer ${access}`,
        },
    })
    
    return res
} 

export const getCompanyVacancies = async (companyId: number) => {
    const res = await axios({
        url: `${API}/company-vacancies/?company__id=${companyId}&vacancy__is_public=false`,
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            "Access-Control-Allow-Headers": "*",
            "Authorization": `Bearer ${access}`,
        },
    })
    
    return res
} 

export const getVacancyById = async (id: number) => {
    const res = await axios({
        url: `${API}/vacancies/${id}/`,
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            "Access-Control-Allow-Headers": "*",
            "Authorization": `Bearer ${access}`,
        },
    })
    
    return res
} 
