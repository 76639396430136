import React, { useState, useEffect } from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    Divider,
    Button,
    Box,
    VStack,
} from '@chakra-ui/react';
import {
  Line,
} from './styles';
import avatar from '@/assets/images/avatar.jpg';
import { TTask } from '@/shared';
import { observer } from 'mobx-react-lite';
import { getTaskRequests } from '@/features/projects';
import { acceptTaskRequest } from '@/features/projects';
import { PersonCard } from '@/shared';

export interface ITaskApplicantsPopupProps {
  isOpen: boolean;
  onClose: () => void;
  task: TTask;
}

export const TaskApplicantsPopup: React.FC<ITaskApplicantsPopupProps> = observer(({ isOpen, onClose, task }) => {
  const [requests, setRequests] = useState([]);

  useEffect(() => {
    if (isOpen) {
      handleGetRequests();
    }
  }, [task, isOpen])

  function handleGetRequests() {
    if (task?.id) {
      getTaskRequests(task.id)
        .then((res) => {
          setRequests(res.data);
        })
        .catch((e) => {
          console.log(e);
        })
    }
  }

  function handleAcceptRequest(requestId: number) {
    acceptTaskRequest(requestId)
      .then((res) => {
        handleGetRequests();
        onClose();
      })
      .catch((e) => {
        console.log(e);
      })
  }
  
  return (
    <Modal
      size={'4xl'}
      blockScrollOnMount={false}
      isOpen={isOpen}
      onClose={onClose}
      isCentered
    >
      <ModalOverlay />

      <ModalContent borderRadius={16}>
        <ModalHeader alignItems={'center'}>
          <h3 style={{ textAlign: 'center' }}>Заявки на задачу «{task?.title}»</h3>
        </ModalHeader>

        <ModalCloseButton top={'14px'} />

        <Divider />

        <ModalBody
          padding='20px'
          display='flex'
          flexDirection='column'
          gap='30px'
        >
          <Line>
            {requests?.map((request: any) => 
              <VStack spacing={0} key={request.sender.id}>
                <PersonCard
                  role={request.sender?.role}
                  id={request?.sender?.id}
                  image={request?.sender.image}
                  name={`${request?.sender.last_name} ${request?.sender.first_name} ${request?.sender.patronymic}`}
                  vacancy={(request?.sender.vacancy && request?.sender.vacancy.length > 0) && request?.sender.vacancy[0]}
                />

                <Button
                  type='button'
                  variant='ghost'
                  color='var(--main-purple)'
                  borderRadius='8px'
                  fontSize={12}
                  onClick={() => handleAcceptRequest(request?.id)}
                >
                  Назначить
                </Button>
              </VStack>
            )}
            {requests?.length === 0 &&
              <Box w="full" textAlign="center" fontSize={16}>Заявок нет</Box>
            }
          </Line>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
});
