import axios from "axios";
import type { TProject } from "@/shared";

const API = process.env.API_URL;
const access = JSON.parse(localStorage.getItem('accessToken'));
const refresh = JSON.parse(localStorage.getItem('refreshToken'));

export const getProjects = async (searchTerm?: string) => {
  const res = await axios<any>({
      url: `${API}/projects/?local=true&title_only=true&search=${searchTerm ? searchTerm : ''}`,
      method: 'GET',
      headers: {
          'Content-Type': 'application/json',
          "Access-Control-Allow-Headers": "*",
          "Authorization": `Bearer ${access}`,
      },
  })
  
  return res
}

export const getProjectById = async (projectId: number) => {
  const res = await axios<any>({
      url: `${API}/projects/${projectId}/`,
      method: 'GET',
      headers: {
          'Content-Type': 'application/json',
          "Access-Control-Allow-Headers": "*",
          "Authorization": `Bearer ${access}`,
      },
  })
  
  return res
}

export const getProjectInfo = async (projectId: number) => {
  const res = await axios<any>({
      url: `${API}/project-user/?project__id=${projectId}`,
      method: 'GET',
      headers: {
          'Content-Type': 'application/json',
          "Access-Control-Allow-Headers": "*",
          "Authorization": `Bearer ${access}`,
      },
  })
  
  return res
}

export const getMyProjects = async (userId: number, searchTerm?: string) => {
  const res = await axios<any>({
      url: `${API}/projects/?local=true&contractor__id=${userId}&search=${searchTerm ? searchTerm : ''}`,
      method: 'GET',
      headers: {
          'Content-Type': 'application/json',
          "Access-Control-Allow-Headers": "*",
          "Authorization": `Bearer ${access}`,
      },
  })

  const items: any = [];

  res.data.forEach((project: TProject) => {
    const newItem = {
      id: `Task ${project.id}`,
      name: '',
      title: project.title,
      start: (project.start_date.replace(/\./g, "-")).split("-").reverse().join("-"),
      end: (project.end_date.replace(/\./g, "-")).split("-").reverse().join("-"),
      progress: 100,
      dependencies: '',
      custom_class: 'task',
      setDependencies: () => {},

      project: project,
    }

    items.push(newItem);
  });
  
  return items
}

export const getCreatedProjects = async (userId: number, searchTerm?: string) => {
  const res = await axios<any>({
      url: `${API}/projects/?local=true&owner__id=${userId}&search=${searchTerm ? searchTerm : ''}`,
      method: 'GET',
      headers: {
          'Content-Type': 'application/json',
          "Access-Control-Allow-Headers": "*",
          "Authorization": `Bearer ${access}`,
      },
  })

  const items: any = [];

  res.data.forEach((project: TProject) => {
    const newItem = {
      id: `Task ${project.id}`,
      name: '',
      title: project.title,
      start: (project.start_date.replace(/\./g, "-")).split("-").reverse().join("-"),
      end: (project.end_date.replace(/\./g, "-")).split("-").reverse().join("-"),
      progress: 100,
      dependencies: '',
      custom_class: 'task',
      setDependencies: () => {},

      project: project,
    }

    items.push(newItem);
  });
  
  return items
}

export const getProjectReviews = async (projectId: number) => {
  const res = await axios<any>({
      url: `${API}/project-reviews/${projectId}/`,
      method: 'GET',
      headers: {
          'Content-Type': 'application/json',
          "Access-Control-Allow-Headers": "*",
          "Authorization": `Bearer ${access}`,
      },
  })
  
  return res
}

export const getTasksByOwner = async (id: number) => {
  const res = await axios({
    url: `${API}/tasks/?isolated=true&owner__id=${id}`,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      "Access-Control-Allow-Headers": "*",
      "Authorization": `Bearer ${access}`,
    },
  })
    
  return res
}

export const getTasksByContractor = async (id: number) => {
  const res = await axios({
    url: `${API}/tasks/?isolated=true&contractor__id=${id}`,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      "Access-Control-Allow-Headers": "*",
      "Authorization": `Bearer ${access}`,
    },
  })
    
  return res
}

export const getProjectsByContractor = async (id: number) => {
  const res = await axios({
    url: `${API}/projects/?contractor__id=${id}`,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      "Access-Control-Allow-Headers": "*",
      "Authorization": `Bearer ${access}`,
    },
  })
    
  return res
}

export const getProjectsByOwner = async (id: number) => {
  const res = await axios({
    url: `${API}/projects/?local=true&owner__id=${id}`,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      "Access-Control-Allow-Headers": "*",
      "Authorization": `Bearer ${access}`,
    },
  })
    
  return res
}


export const getTaskReviews = async (taskId: number) => {
  const res = await axios<any>({
      url: `${API}/project-reviews/${taskId}/`,
      method: 'GET',
      headers: {
          'Content-Type': 'application/json',
          "Access-Control-Allow-Headers": "*",
          "Authorization": `Bearer ${access}`,
      },
  })
  
  return res
}

export const getTaskUserReviews = async (taskId: number, userId: number) => {
  const res = await axios({
    url: `${API}/task-reviews/?task__id=${taskId}&recipient__id=${userId}`,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      "Access-Control-Allow-Headers": "*",
      "Authorization": `Bearer ${access}`,
    },
  })
    
  return res
}

export const getProjectsBySkills = async (searchTerm?: string) => {
  const res = await axios<any>({
      url: `${API}/projects/?local=true&skill_only=true&search=${searchTerm ? searchTerm : ''}`,
      method: 'GET',
      headers: {
          'Content-Type': 'application/json',
          "Access-Control-Allow-Headers": "*",
          "Authorization": `Bearer ${access}`,
      },
  })
  
  return res
}

export const getProjectWithTask = async (taskId: number) => {
  const res = await axios<any>({
      url: `${API}/projects/?task__id=${taskId}`,
      method: 'GET',
      headers: {
          'Content-Type': 'application/json',
          "Access-Control-Allow-Headers": "*",
          "Authorization": `Bearer ${access}`,
      },
  })

  return res
}

export const getReviews = async (taskId: number, userId: number) => {
  const res = await axios({
    url: `${API}/project-reviews/?project__id=${taskId}&recipient__id=${userId}`,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      "Access-Control-Allow-Headers": "*",
      "Authorization": `Bearer ${access}`,
    },
  })
    
  return res
}
