import React, { useEffect, useMemo, useState } from 'react';
import {
  Container,
  DatesWrapper,
} from './styles';
import { Filters } from '@/features/common';
import { Select } from '@chakra-ui/react';
import analyticsState from '@/pages/Analytics/store/analyticsState';
import { observer } from 'mobx-react-lite';
import { DatePicker } from 'antd';
import { IUser } from '@/shared';
import {
  getAnalyticForCompany,
  getAnalyticForGroup,
  getAnalyticForEmployee,
} from '@/features/analytic';
import { dateConvert } from '@/shared';

export interface AnalyticFiltersProps {
  employees: IUser[];
  departments: any[];
  groups: any[];
}

export const AnalyticFilters: React.FC<AnalyticFiltersProps> = observer(({ employees, departments, groups }) => {
  const [currentEmloyeeId, setCurrentEmloyeeId] = useState<number>();
  const [currentDepartmentId, setCurrentDepartmentId] = useState<number>();
  const [currentGroupId, setCurrentGroupId] = useState<number>();
  const [currentStartDate, setCurrentStartDate] = useState<any>();
  const [currentEndDate, setCurrentEndDate] = useState<any>();

  const user = JSON.parse(localStorage.getItem('user'));

  const companyId = JSON.parse(localStorage.getItem('user'))?.company[JSON.parse(localStorage.getItem('user'))?.company.length - 1];

  useMemo(() => {
    if (user?.role === 'Manager') {
      analyticsState.setFilter('groups');
    }
  }, [])

  const hrOptions = [
    {
      id: 1,
      name: 'Все',
      onClick: () => {
        analyticsState.clearAnalytic();
        analyticsState.setFilter('all');
      },
    },
    {
      id: 2,
      name: 'По отделам',
      onClick: () => {
        analyticsState.clearAnalytic();
        analyticsState.setFilter('groups');
      },
    },
    {
      id: 3,
      name: 'По департаментам',
      onClick: () => {
        analyticsState.clearAnalytic();
        analyticsState.setFilter('departments');
      },
    },
    {
      id: 4,
      name: 'По сотруднику',
      onClick: () => {
        analyticsState.clearAnalytic();
        analyticsState.setFilter('employee');
      },
    },
  ];

  const managerOptions = [
    {
      id: 1,
      name: 'По отделам',
      onClick: () => {
        analyticsState.clearAnalytic();
        analyticsState.setFilter('groups');
      },
    },
    {
      id: 2,
      name: 'По департаментам',
      onClick: () => {
        analyticsState.clearAnalytic();
        analyticsState.setFilter('departments');
      },
    },
    {
      id: 3,
      name: 'По сотруднику',
      onClick: () => {
        analyticsState.clearAnalytic();
        analyticsState.setFilter('employee');
      },
    },
  ];

  function objToArr(obj: any) {
    const arr: any[] = [];
    Object.keys(obj).forEach((key) => {
      const newItem = {
        title: obj[key].title,
        count: obj[key].count,
        verified: obj[key].verified,
      }
      arr.push(newItem)
    })

    return arr;
  }

  function putToStorage(data: any, type: string) {
    if (data.hard_skills) {
      if (type === 'current') {
        analyticsState.setCurrentHards(objToArr(data.hard_skills));
      }
      if (type === 'inProgress') {
        analyticsState.setProgressHards(objToArr(data.hard_skills));
      }
    } else {
      if (type === 'current') {
        analyticsState.setCurrentHards([]);
      }
      if (type === 'inProgress') {
        analyticsState.setProgressHards([]);
      }
    }
    if (data.soft_skills) {
      if (type === 'current') {
        analyticsState.setCurrentSofts(objToArr(data.soft_skills));
      }
      if (type === 'inProgress') {
        analyticsState.setProgressSofts(objToArr(data.soft_skills));
      }
    } else {
      if (type === 'current') {
        analyticsState.setCurrentSofts([]);
      }
      if (type === 'inProgress') {
        analyticsState.setProgressSofts([]);
      }
    }
    if (data.interests) {
      if (type === 'current') {
        analyticsState.setCurrentInterests(objToArr(data.interests));
      }
    } else {
      if (type === 'current') {
        analyticsState.setCurrentInterests([]);
      }
    }
  }

  function handleGetCompanyAnalytic(type: string) {
    getAnalyticForCompany(type, companyId, dateConvert(currentStartDate?.['$d']), dateConvert(currentEndDate?.['$d']))
      .then((res) => {
        putToStorage(res.data, type);
      })
      .catch((e) => {
        console.log(e);
      })
  }

  function handleGetGroupAnalytic(type: string, id: number) {
    getAnalyticForGroup(type, id, dateConvert(currentStartDate?.['$d']), dateConvert(currentEndDate?.['$d']))
      .then((res) => {
        putToStorage(res.data, type);
      })
      .catch((e) => {
        console.log(e);
      })
  }

  function handleGetEmployeeAnalytic(type: string, id: number) {
    getAnalyticForEmployee(type, id, dateConvert(currentStartDate?.['$d']), dateConvert(currentEndDate?.['$d']))
      .then((res) => {
        putToStorage(res.data, type);
      })
      .catch((e) => {
        console.log(e);
      })    
  }

  useEffect(() => {
    if (analyticsState.filter === 'all') {
      handleGetCompanyAnalytic(analyticsState.analyticsType);
      return
    }
    if (analyticsState.filter === 'departments' && currentDepartmentId) {
      handleGetGroupAnalytic(analyticsState.analyticsType, currentDepartmentId);
      return
    }
    if (analyticsState.filter === 'groups' && currentGroupId) {
      handleGetGroupAnalytic(analyticsState.analyticsType, currentGroupId);
      return
    }
    if (analyticsState.filter === 'employee' && currentEmloyeeId) {
      handleGetEmployeeAnalytic(analyticsState.analyticsType, currentEmloyeeId);
      return
    }
  }, [analyticsState.filter, currentEmloyeeId, currentDepartmentId, currentGroupId, currentStartDate, currentEndDate])

  useEffect(() => {
    if (analyticsState.filter === 'all') {
      handleGetCompanyAnalytic(analyticsState.analyticsType);
    }
  }, [analyticsState.analyticsType])

  return (
    <Container>
      <Filters options={
        user?.role === 'Manager' ? managerOptions :
        user?.role === 'HR manager' || user?.role === 'Super HR manager' ? hrOptions : []
      }/>
      {analyticsState.filter === 'all' &&
        <div style={{ height: '35px' }}></div>
      }
      {analyticsState.filter === 'employee' &&
        <Select
          onChange={(e: any) => setCurrentEmloyeeId(e.target.value)}
          isReadOnly={false}
          variant='flushed'
          placeholder='Выберите сотрудника'
        >
          {employees.map((emloyee: IUser) =>
            <option value={emloyee.id}>{emloyee.last_name} {emloyee.first_name} {emloyee.patronymic}</option> 
          )}
        </Select>
      }
      {analyticsState.filter === 'departments' &&
        <Select
          onChange={(e: any) => setCurrentDepartmentId(e.target.value)}
          isReadOnly={false}
          variant='flushed'
          placeholder='Выберите департамент'
        >
          {departments.map((department: any) =>
            <option value={department.id}>{department.name}</option> 
          )}
        </Select>
      }
      {analyticsState.filter === 'groups' &&
        <Select
          onChange={(e: any) => setCurrentGroupId(e.target.value)}
          isReadOnly={false}
          variant='flushed'
          placeholder='Выберите отдел'
        >
          {groups.map((group: any) =>
            <option value={group.id}>{group.name}</option> 
          )}
        </Select>
      }
      <DatesWrapper>
        <DatePicker
          style={{ width: '220px' }}
          placeholder='Выберите начало периода'
          onChange={(date: any) => setCurrentStartDate(date)}
        />
        <div>-</div>
        <DatePicker
          style={{ width: '220px' }}
          placeholder='Выберите конец периода'
          onChange={(date: any) => setCurrentEndDate(date)}
        />
      </DatesWrapper>
    </Container>
  )
});
