import React, { useEffect } from 'react';
import { Card, IUser } from '@/shared';
import { Top, Title, H6, PlacesList } from './styles';
import EditIcon from '@/assets/icons/edit.svg';
import { Button } from '@chakra-ui/react';
import { useDisclosure } from '@chakra-ui/react';
import { EditPlacePopup } from './EditPopup/EditPlacePopup';
import { EducationCard } from '@/entities/user';
import educationState from './store/educationState';
import { observer } from 'mobx-react-lite';

export interface EducationProps {
  user: IUser;
}

export const Education: React.FC<EducationProps> = observer(({ user }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    educationState.setEducations(user?.education);
  }, [])

  return (
    <Card>
      <Top>
        <Title>Образование</Title>
        <Button
          onClick={onOpen}
          border='1px var(--addable-gray) solid'
          borderRadius='8px'
          bg='var(--main-white)'
          leftIcon={<EditIcon color='var(--main-purple)' />} 
        >
          <H6>Редактировать</H6>
        </Button>
      </Top>
      <PlacesList>
        {educationState.educations && educationState.educations.map((education: any) => 
          <EducationCard education={education} key={education.id} type='widget'/>
        )}
      </PlacesList>
      {educationState.educations && <EditPlacePopup educations={educationState.educations} isOpen={isOpen} onClose={onClose}/>}
    </Card>
  )
});
