import React from 'react';
import { ProgressBar } from '@/shared/ui/ProgressBar/ProgressBar';
import {
  Box,
  Image,
  Flex,
} from '@chakra-ui/react';
import {
  Container,
  Grouping,
  RadioStats,
  Disabled,
} from "./styles";
import { num_word } from '@/shared/lib/words';
import { IQuestionStatsProps } from '@/shared/types/tests/types';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css'

export const SingleChoiceQuestionStats: React.FC<IQuestionStatsProps> = ({ image, title, respondentCount, answers }) => {
  const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';

  return (
    <Container>
      <h3>{title}</h3>
      {image &&
        <Flex my={2} w="50%" justify="center">
          <Zoom>
            <Image borderRadius="md"  src={image} alt="Картинка к вопросу" />
          </Zoom>
        </Flex>
      }
      {answers.map((answer: any, index: number) =>
        <div key={answer.id}>
          <Grouping>
            <ProgressBar
              value={answer.count}
              max={respondentCount}
              width="80%"
              bgColor="#C8D3FB"
            >
              <Box>
                <RadioStats>{letters[index % letters.length]}</RadioStats>
              </Box>

              {answer?.image ?
                <Box w={20}>
                  <Zoom>
                    <Image w={20} borderRadius="md" src={answer?.image} />
                  </Zoom>
                </Box> :
                <div style={{wordBreak: 'break-all'}}>{answer.text}</div>
              }
            </ProgressBar>
            <Disabled>
              {answer.count} {num_word(answer.count, ['ответ', 'ответа', 'ответов'])}
            </Disabled>
          </Grouping>
        </div>
      )}
    </Container>
  );
}


