import { makeAutoObservable } from "mobx";
import { ICompany } from "@/shared";

class CompaniesState {
  companies: ICompany[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  setCompanies(companies: ICompany[]) {
    this.companies = companies;
  }
}

export default new CompaniesState();
