import React, { useState, useEffect } from 'react';
import {
  Container,
  Top,
} from './styles';
import {
  InputGroup,
  InputLeftElement,
  Input,
} from '@chakra-ui/react';
import SearchIcon from '@/assets/icons/search.svg';
import { useDebounce } from '@/shared';
import { getCompanyTests } from '@/entities/tests';
import employeesTestState from '@/pages/EmployeeTests/store/employeesTestState';
import { getEmployeeTests } from '@/entities/tests';

export const EmployeesTopBar: React.FC = () => {
  const [searchTerm, setSearchTerm] = useState('');

  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  const userId = JSON.parse(localStorage.getItem('user'))?.id;
  
  function handleSearch() {
    employeesTestState.isLoading = true;
    getEmployeeTests(userId, debouncedSearchTerm)
      .then((res: any) => {
        employeesTestState.setTests(res.data);
      })
      .catch((error) => {
        console.log(error);
      })
  }

  useEffect(() => {
    if (typeof debouncedSearchTerm === 'string') {
      handleSearch();
    }
  }, [debouncedSearchTerm]);

  return (
    <>
    <Container>
      <Top>
        <InputGroup padding={'6px'}>
          <InputLeftElement pointerEvents='none'>
            <SearchIcon
              color='var(--addable-gray)'
              width={18}
              height={18}
            />
          </InputLeftElement>
          <Input
            value={searchTerm}
            onChange={e => setSearchTerm(e.target.value)}
            color='messenger'
            height={'28px'}
            variant='flushed'
            placeholder={'Поиск по вашим тестам'}
          />
        </InputGroup>
      </Top>
    </Container>
    </>
  )
}

