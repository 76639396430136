import styled from "styled-components";

export const Container = styled.div`
    position: relative;
`;

export const Absolute = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    width: 100%;
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
`;

export const Top = styled.div`
    display: flex;
    height: fit-content;
    max-height: 56px;
    align-items: flex-start;
    justify-content: space-between;
    overflow: hidden;
`;

export const Description = styled.div`
    height: fit-content;
    max-height: 64px;
    overflow: hidden;
`;

export const Text = styled.p`
    font-size: 12px;
    line-height: 16px;
`;

export const CollapseButton = styled.button`
    display: flex;
    gap: 4px;
    align-items: center;
    color: var(--main-purple);
    font-size: 12px;
    font-weight: 600;
`;

export const SkillsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
`;

export const SkillsTitle = styled.h5`
    font-size: 12px;
    font-weight: 600;
`;