import React, { useEffect, useState } from 'react';
import {
    FormErrorMessage,
    FormLabel,
    FormControl,
    Input,
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    Select,
} from '@chakra-ui/react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { IUser, dateConvert } from '@/shared';
import { editProfile } from '@/features/user';
import { Left, Right, Top } from './styles';
import avatar from '@/assets/images/avatar.jpg';
import { FileUploadPopup } from '@/features/user/ui/FileUploadPopup/FileUploadPopup';
import { DatePicker, ConfigProvider } from 'antd';
import dayjs from 'dayjs';
import { getCompany } from '@/entities/company/api';

interface IFormFileds {
    first_name: string;
    last_name: string;
    patronymic: string;
    birthday: string;
    email: string;
    company: string;
    department: string;
    role: string;
    isSuperHR: boolean;
    gender: string;
}

export interface EditProfilePopupProps {
  isOpen: boolean;
  onClose: () => void;
  user: IUser;
}

export const EditProfilePopup: React.FC<EditProfilePopupProps> = ({ isOpen, onClose, user }) => {
  const { 
    register,
    handleSubmit,
    formState: { errors, isSubmitting } 
  } = useForm<IFormFileds>();
  const { isOpen: isAvatarOpen, onOpen: onAvatarOpen, onClose: onAvatarClose } = useDisclosure();

  const [birthday, setBirthday] = useState<any>();
  const [gender, setGender] = useState(user?.gender ? user?.gender : '');
  const [userCompany, setUserCompany] = useState<any>();

  const companyId = JSON.parse(localStorage.getItem('user'))?.company[JSON.parse(localStorage.getItem('user'))?.company.length - 1];

  useEffect(() => {
    setBirthday(dayjs(user?.birthday, 'DD.MM.YYYY'));
    setGender(user?.gender);
  }, [user])

  useEffect(() => {
      getCompany(companyId)
        .then((res: any) => {
          setUserCompany(res.data);
        })
        .catch(e => {
          console.log(e);
        })
  }, [])

  const onSubmit: SubmitHandler<IFormFileds> = (data) => {
    data.gender = gender;
    data.birthday = dateConvert(birthday['$d']);

    editProfile(data)
      .then((res: any) => {
        if (res?.status === 200) {
          localStorage.setItem('user', JSON.stringify(res.data));
          onClose();
        }
      })
      .catch((error: any) => {
        console.log(error);
      })
  }

  function handleBirthdateChange(date: any) {
    setBirthday(date);
  }

  return (
    <>
      <Modal size={'xl'} blockScrollOnMount={false} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <form onSubmit={handleSubmit(onSubmit)}>
            <ModalContent borderRadius={16}>
              <ModalHeader alignItems={'center'}>
                <h3 style={{ textAlign: 'center' }}> Редактирование профиля</h3>
              </ModalHeader>
              <ModalCloseButton top={'14px'} />
              <ModalBody
                padding='20px'
                display='flex'
                flexDirection='column'
                gap='30px'
                borderTop='1px solid var(--addable-gray)'
                borderBottom='1px solid var(--addable-gray)'
              >
                <Top>
                  <Left>
                    <h5>Основная информация</h5>
                    <FormControl isInvalid={Boolean(errors.first_name)}>
                      <FormLabel>Имя</FormLabel>
                      <Input
                        id='first_name'
                        {...register('first_name')}
                        placeholder='Мария'
                        defaultValue={user?.first_name}
                        type='text'
                        size='sm'
                        variant='flushed'
                      />
                      <FormErrorMessage>{errors.first_name && <>{errors.first_name.message}</>}</FormErrorMessage>
                    </FormControl>
                    <FormControl isInvalid={Boolean(errors.first_name)}>
                      <FormLabel>Фамилия</FormLabel>
                      <Input
                        id='last_name'
                        {...register('last_name')}
                        placeholder='Васенева'
                        defaultValue={user?.last_name}
                        type='text'
                        size='sm'
                        variant='flushed'
                      />
                      <FormErrorMessage>{errors.last_name && <>{errors.last_name.message}</>}</FormErrorMessage>
                    </FormControl>
                    <FormControl isInvalid={Boolean(errors.patronymic)}>
                      <FormLabel>Отчество</FormLabel>
                      <Input
                        id='patronymic'
                        {...register('patronymic')}
                        placeholder='Евгеньевна'
                        defaultValue={user?.patronymic}
                        type='text'
                        size='sm'
                        variant='flushed'
                      />
                      <FormErrorMessage>{errors.patronymic && <>{errors.patronymic.message}</>}</FormErrorMessage>
                    </FormControl>
                    <FormControl isInvalid={Boolean(errors.department)}>
                      <FormLabel>Пол</FormLabel>
                      <Select
                        isReadOnly={false}
                        variant='flushed'
                        placeholder='Выбрать пол'
                        value={gender}
                        onChange={e => setGender(e.target.value)}
                      >
                        <option value={'MALE'}>Мужской</option> 
                        <option value={'FEMALE'}>Женский</option> 
                        <option value={''}>Не указан</option> 
                      </Select>
                    </FormControl>
                    <ConfigProvider
                      theme={{
                        components: {
                          DatePicker: {
                            zIndexPopup: 2000,
                          },
                        },
                      }}
                    >
                      <FormControl isInvalid={Boolean(errors.birthday)}>
                        <FormLabel>Дата рождения</FormLabel>
                        <DatePicker
                          value={birthday}
                          style={{ width: '100%', paddingInline: 0, background: '#fff' }}
                          variant="borderless"
                          onChange={handleBirthdateChange}
                          format="DD.MM.YYYY"
                        />
                        <FormErrorMessage>{errors.birthday && <>{errors.birthday.message}</>}</FormErrorMessage>
                      </FormControl>
                    </ConfigProvider>
                    <FormControl isInvalid={Boolean(errors.email)}>
                      <FormLabel>Почта</FormLabel>
                      <Input
                        disabled
                        id='email'
                        {...register('email')}
                        placeholder='vaseneva@emp.ru'
                        defaultValue={user?.email}
                        type='text'
                        size='sm'
                        variant='flushed'
                      />
                      <FormErrorMessage>{errors.email && <>{errors.email.message}</>}</FormErrorMessage>
                    </FormControl>
                  </Left>
                  <Right>
                  {user?.image ?
                    <div
                      style={{
                        width: '148px',
                        height: '148px',
                        borderRadius: '50%',
                        backgroundImage: `url(${user.image.image})`,
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover'
                      }}
                    >
                    </div>  :
                    <img src={avatar} alt='avatar'/>
                  }
                  <button onClick={onAvatarOpen}>загрузить</button>
                  </Right>
                </Top>

                <h5>Работа и должность</h5>
                <FormControl isInvalid={Boolean(errors.company)}>
                  <FormLabel>Компания</FormLabel>
                  <Input
                    disabled
                    id='company'
                    defaultValue={userCompany?.name}
                    {...register('company')}
                    type='text'
                    size='sm'
                    variant='flushed'
                  />
                  <FormErrorMessage>{errors.company && <>{errors.company.message}</>}</FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={Boolean(errors.role)}>
                  <FormLabel>Должность</FormLabel>
                  <Input
                    disabled
                    defaultValue={user?.role}
                    id='role'
                    {...register('role')}
                    placeholder='Hr-специалист'
                    type='text'
                    size='sm'
                    variant='flushed'
                  />
                  <FormErrorMessage>{errors.role && <>{errors.role.message}</>}</FormErrorMessage>
                </FormControl>
              </ModalBody>

              <ModalFooter gap='8px' display='flex'>
                <Button
                  type='button'
                  width='100%'
                  onClick={onClose}
                  variant='outline'
                  color='var(--main-purple)'
                  borderRadius='8px'
                >
                  Отмена
                </Button>
                <Button
                  type='submit'
                  width='100%'
                  borderRadius='8px'
                  bg='var(--main-purple)'
                  _hover={{ bg: 'var(--hover-purple)' }}
                  color='#fff'
                  isLoading={isSubmitting ? true : false}
                >
                  Сохранить изменения
                </Button>
              </ModalFooter>
            </ModalContent>
        </form>
      </Modal>
      <FileUploadPopup type='avatar' isOpen={isAvatarOpen} onClose={onAvatarClose}/>
    </>
  )
}