import React, { useEffect } from 'react';
import {
  Stack,
  Flex,
  Box,
  Button,
  Divider,
  Drawer,
  DrawerContent,
  DrawerCloseButton,
  Grid,
  GridItem,
  useDisclosure,
} from '@chakra-ui/react';
import {
  DimmedText,
} from './styles';
import taskState from './store/taskState';
import { observer } from 'mobx-react-lite';
import { TaskStatusHint } from '@/entities/projects/';
import { Skill } from '@/shared';
import { TaskReview } from '@/features/projects';
import { deleteTask } from '@/features/projects';
import { editTask } from '@/features/projects';
import projectsPageState from '@/pages/Projects/store/projectsPageState';
import { EditTaskPopup } from '@/features/projects';
import { SelectTaskStatus } from '@/features/projects';
import toast, { Toaster } from 'react-hot-toast';
import { sendTaskRequest } from '@/features/projects';
import { Link } from 'react-router-dom';
import { TaskApplicantsPopup } from '@/features/projects';
import { CrossDelete } from '@/UI'

export interface ITaskDrawerProps {
  isOpen: boolean;
  onClose: () => void;
}

export const TaskDrawer: React.FC<ITaskDrawerProps> = observer(({ isOpen, onClose }) => {
  const user = JSON.parse(localStorage.getItem('user'));

  const { isOpen: taskIsOpen, onOpen: taskOnOpen, onClose: taskOnClose } = useDisclosure();
  const {
    isOpen: applicantsIsOpen,
    onOpen: applicantsOnOpen,
    onClose: applicantsOnClose
  } = useDisclosure();
  const btnRef = React.useRef();

  function handleDeleteTask() {
    deleteTask(taskState.selectedTask?.id)
        .then((res: any) => {
          projectsPageState.deleteTask(taskState.selectedTask?.id);
          onClose();

          window.location.reload();
        })
        .catch((e: any) => {
            console.log(e);
        })
  }

  function handleSendRequest() {
    sendTaskRequest(taskState.selectedTask.id, taskState.selectedTask.owner.id)
      .then((res) => {
        toast.success('Ваша заявка отправлена!');
      })
      .catch((e) => {
        toast.error('Заявка уже отправлена!');
      })
  }

  function handleDeleteContractor() {
    editTask(taskState.selectedTask.id, { owner: taskState.selectedTask.owner, contractors: [] })
      .then((res) => {
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
      })
  }

  return (
  <>
    <Drawer
      isOpen={isOpen}
      placement="right"
      onClose={onClose}
      finalFocusRef={btnRef}
      size="xl"
    >
      <DrawerContent overflow="auto">
        <Flex h="full" justifyContent="space-between" direction="column">
          <Box>
            <Box p={6}>
              <DrawerCloseButton size="lg" />
              <Box>
                <h3>{taskState.selectedTask?.title}</h3>
              </Box>
            </Box>

            <Divider />

            <Grid px={6} py={4} templateColumns="repeat(2, 1fr)" rowGap={4}>
              <GridItem>
                <DimmedText>Создатель</DimmedText>
              </GridItem>

              <GridItem>
                <TaskReview person={taskState.selectedTask?.owner} task={taskState.selectedTask} />
              </GridItem>

              <GridItem>
                <DimmedText>Статус</DimmedText>
              </GridItem>
              <GridItem>
                {(user.id === taskState.selectedTask?.owner.id || taskState.selectedTask?.contractors.map(obj => obj.id).includes(user.id)) ?
                  <SelectTaskStatus
                    taskId={taskState.selectedTask.id}
                    status={taskState.selectedTask?.status}
                  /> :
                  <TaskStatusHint status={taskState.selectedTask?.status}/>
                }
              </GridItem>

              <GridItem>
                <DimmedText>Исполнитель</DimmedText>
              </GridItem>
              {taskState.selectedTask?.contractors.length > 0 ?
              <GridItem>
                {taskState.selectedTask?.contractors.map((contractor_user) =>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '10px',
                    }}
                  >
                    <TaskReview person={contractor_user} task={taskState.selectedTask} />
                      {(user.id === taskState.selectedTask?.owner.id) ?
                        <button
                          onClick={handleDeleteContractor}
                        >
                          <CrossDelete/>
                        </button>:
                        <div/>
                      }
                  </div>
                )}
              </GridItem> :
              <>
                {(user.id === taskState.selectedTask?.owner.id) ?
                  <GridItem>
                    <Button
                      type='submit'
                      borderRadius='8px'
                      bg='var(--main-purple)'
                      _hover={{ bg: 'var(--hover-purple)' }}
                      color='#fff'
                      onClick={applicantsOnOpen}
                    >
                      Посмотреть заявки
                    </Button>
                  </GridItem> :
                  <>
                    {taskState.selectedTask?.contractors.length > 0 ?
                    <>
                      {taskState.selectedTask?.contractors.map((user) =>
                        <>
                          {user.last_name} {user.first_name}
                        </>
                      )}
                    </> :
                    <DimmedText>Нет исполнителя</DimmedText>
                    }
                  </>
                }
              </>
              }

              <DimmedText>Дедлайн</DimmedText>
              <Box>{taskState.selectedTask?.end_date}</Box>

              <DimmedText>Сложность</DimmedText>
              <Box>{taskState.selectedTask?.complexity}</Box>

              <DimmedText>Навыки</DimmedText>
              <Flex gap={2} wrap={'wrap'}>
                {taskState.selectedTask?.skill.length > 0 ?
                  <>
                    {taskState.selectedTask?.skill.map((skill: any) =>
                      <Skill key={skill.id} title={skill.name}/>
                    )}
                  </> :
                  <DimmedText>Требуемых навыков нет</DimmedText>
                }
              </Flex>
            </Grid>

            <Divider />

            <Box p={6}>
              {taskState.selectedTask?.description}
            </Box>
          </Box>

          <Stack p={6} spacing={2}>
            {(user.id === taskState.selectedTask?.owner.id) ?
              <>
                <Link
                  to='/dashboard-manager-employees'
                  >
                  <Button
                    type='button'
                    width='100%'
                    variant='outline'
                    color='var(--main-purple)'
                    borderRadius='8px'
                  >
                        Искать исполнителей
                  </Button>
                </Link>

                <Button
                  type='button'
                  width='100%'
                  variant='outline'
                  color='var(--main-purple)'
                  borderRadius='8px'
                  onClick={taskOnOpen}
                >
                  Редактировать задачу
                </Button>

                <Button
                  width='100%'
                  border='1px var(--addable-gray) solid'
                  borderRadius='8px'
                  variant='outline'
                  bg='var(--main-white)'
                  color='var(--addable-red)'
                  onClick={handleDeleteTask}
                >
                  Удалить задачу
                </Button>
              </> :
            <>
            {!taskState.selectedTask?.contractors.map((c) => c.id).includes(user.id) &&
            <Stack p={6} spacing={2}>
              <Button
                type='button'
                width='100%'
                variant='outline'
                color='var(--main-purple)'
                borderRadius='8px'
                onClick={handleSendRequest}
              >
                Подать заявку на участие
              </Button>
            </Stack>
            }
            </>
            }
          </Stack>
        </Flex>
      </DrawerContent>
      <EditTaskPopup isOpen={taskIsOpen} onClose={taskOnClose} />
    </Drawer>
    <Toaster
      position="top-right"
    />
    <TaskApplicantsPopup
      isOpen={applicantsIsOpen}
      onClose={applicantsOnClose}
      task={taskState.selectedTask}
    />
  </>
  );
});
