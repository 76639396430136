import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  min-width: 400px;
  padding: 16px;
  border: 1px var(--addable-gray) solid;
  border-radius: 16px; 
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
