import React from 'react';
import { Card } from '@/shared/ui/Card/Card';
import {
  Box,
  Button,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  Flex,
  useDisclosure,
} from '@chakra-ui/react';
import {
  Title,
  Description,
  PreviewInfo,
  CardHover,
  PopoverButton,
  CompletedHint,
  NotCompletedHint,
} from './styles';
import { num_word } from '@/shared/lib/words';
import EditIcon from '@/assets/icons/edit.svg';
import DotsIcon from '@/assets/icons/dots.svg';
import testInfoDrawerState from '@/widgets/TestsList/store/testInfoDrawerState';
import hrTestCreationDrawerState from '@/entities/tests/ui/AddTestDrawerBtn/store/hrTestCreationDrawerState';
import testEditDrawerState from '@/widgets/TestsList/store/testEditDrawerState';
import testAssessmentDrawerState from '@/widgets/TestsList/store/testAssessmentDrawerState';
import testEditState from '@/widgets/TestEditForm/store/testEditState';
import testsState from '@/pages/DashboardHRTests/store/testsState';
import { deleteTest } from '@/features/tests';
import { GiveTestPopup } from '@/features/tests';
import { ITest } from '@/shared';
import testAssessmentState from '@/widgets/TestAssessmentDrawer/store/testAssessmentState';
import { observer } from 'mobx-react-lite';
import managerTestState from '@/pages/ManagerTests/store/managerTestState';

interface ITestCardProps {
  test: ITest;
  role: 'User' | 'HR manager' | 'Super HR manager' | 'Manager';
}

interface ICompletionHintProps {
  isCompleted: boolean;
}

export const CompletionHint: React.FC<ICompletionHintProps> = ({ isCompleted }) => {
  if (isCompleted) {
    return (
      <Box>
        <CompletedHint>Пройден</CompletedHint>
      </Box>
    );
  } else {
    return (
    <Box>
      <NotCompletedHint>Не пройден</NotCompletedHint>
    </Box>
    );
  }
}

export const TestCard: React.FC<ITestCardProps> = observer(({ test, role }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  function openInfoDrawer(test: ITest) {
    testInfoDrawerState.openDrawer();
    testEditDrawerState.closeDrawer();
    hrTestCreationDrawerState.closeDrawer();
    testInfoDrawerState.setTest(test);
  }

  function openAssessmentDrawer(test: ITest) {
    if (test.id === testAssessmentState?.test?.id) {
      return;
    }

    testAssessmentState.setTest(test);
    testAssessmentDrawerState.openDrawer();
  }

  function openEditDrawer(test: ITest) {
    if (test.id === testEditState.test.id) {
      return;
    }

    testEditState.setTest(test);
    testEditDrawerState.openDrawer();
    testInfoDrawerState.closeDrawer();
    hrTestCreationDrawerState.closeDrawer();
  }

  function handleRemoveTest(testId: number) {
    deleteTest(testId)
      .then((res) => {
        testsState.removeTest(testId);
      })  
      .catch(e => {
        console.log(e);
      })
      .finally(() => {
        if (testInfoDrawerState?.test?.id === testId) {
          testInfoDrawerState.closeDrawer();
        }
        if (testEditState?.test?.id === testId) {
          testEditDrawerState.closeDrawer();
        }
      })
  }

  return (
    <Box minW="300px" position="relative">
      <CardHover>
        <Box onClick={() => {
          if (role === 'User' || (role === 'Manager' && managerTestState.filter === 'my')) {
            openAssessmentDrawer(test);
          } else if (
            role === 'Super HR manager' ||
            role === 'HR manager' ||
            (role === 'Manager' && managerTestState.filter === 'employees')
          ) {
            openInfoDrawer(test);
          }
        }}>
          <Card>
            <Flex gap={2}>
              <Title>{test.title}</Title>
              {(role === 'User' || (role === 'Manager' && managerTestState.filter === 'my')) &&
                <CompletionHint isCompleted={test.completed} />
              }
            </Flex>
            <Description>{test.description}</Description>
            {
              (role === 'Super HR manager' ||
              role === 'HR manager' ||
              (role === 'Manager' && managerTestState.filter === 'employees')) &&

              <PreviewInfo>
                <div>{test?.completed_by.length} {num_word(test?.completed_by.length, ['ответ', 'ответа', 'ответов'])}</div>
                <div>•</div>
                <div>{test.updated_at && <>Изменено </>} {test.updated_at}</div>
              </PreviewInfo>
            }
          </Card>
        </Box>

        {(role === 'HR manager' || role === 'Super HR manager') &&
          <Box
            position="absolute"
            right={2}
            top={2}
          >
            {test?.completed_by.length === 0 &&
              <Button
                leftIcon={<EditIcon />}
                color="var(--main-purple)"
                bg="none"
                onClick={() => {openEditDrawer(test)}}
              >
                Редактировать
              </Button>
            }

            <Popover placement='bottom-end'>
              <PopoverTrigger>
                <Button
                  color="var(--main-purple)"
                  size="lg"
                  bg="none"
                >
                  <DotsIcon />
                </Button>
              </PopoverTrigger>

              <PopoverContent width="full" pr={4}>
                <PopoverBody>
                    <PopoverButton
                      onClick={() => {
                        onOpen()
                        testsState.setCurrent(test);
                      }}
                    >
                      Назначить тест
                    </PopoverButton>

                    <PopoverButton
                      style={{ color: 'var(--addable-red)' }}
                      onClick={() => handleRemoveTest(test.id)}
                    >
                      Удалить тест
                    </PopoverButton>
                </PopoverBody>
              </PopoverContent>
            </Popover>
          </Box>
        }
        </CardHover>
      <GiveTestPopup isOpen={isOpen} onClose={onClose}/>
      </Box>
  );
});
