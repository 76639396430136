import styled from "styled-components";

export const IndicatorTable = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  box-shadow: inset 0 0 0 1px #E6E6E6;
  margin: 15px 0 0 0;
  font-size: 12px;
`;

export const IndicatorTableHeader = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  box-shadow: 0 1px 0 0 #E6E6E6;
`;

export const Empty = styled.div`
  box-shadow: 1px 0 0 0 #E6E6E6;
`;

export const IndicatorHeaderGradation = styled.div`
  padding: 16px;
  box-shadow: inset 1px 0 0 0 #E6E6E6;
  font-weight: 600;
`;

export const IndicatorTableRow = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  box-shadow: inset 0 1px 0 0 #E6E6E6;
`;

export const IndicatorMain = styled.div`
  display: flex;
  align-items: center;
  padding: 0 0 0 16px;
  gap: 16px;
`;

export const IndicatorEdit = styled.div`

`;

export const IndicatorName = styled.div`

`;
export const IndicatorGradation = styled.div`
  box-shadow: inset 1px 0 0 0 #E6E6E6;
`;

export const IndicatorNameInput = styled.input`
  font-weight: 600;
  width: 100%;
  padding: 16px 16px 0 0;
  transition: .3s;

  &:hover {
    background-color: #eeeeee;
  }
`;

export const IndicatorTestInput = styled.input`
  width: 100%;
  padding: 0px 16px 0 0;
  
  &:hover {
    background-color: #eeeeee;
  }
`;

export const IndicatorGradationValueInput = styled.input`
  width: 100%;
  padding: 0px 16px 16px 0;
  
  &:hover {
    background-color: #eeeeee;
  }
`;

export const ErrorBlock = styled.div`
  width: 100%;
  transition: .3s;
  display: flex;
  flex-direction: column;

`;