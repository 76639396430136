import styled from "styled-components";

export const Image = styled.img`
    max-width: 20px;
    max-height: 20px;
    min-width: 20px;
    min-height: 20px;
    border-radius: 50%;
`;

export const Text = styled.p`
    font-size: 12px;
`;

export const PopoverButton = styled.button`
    display: block;
    width: 100%;
    height: 28px;
    font-size: 12px;
    text-align: left;
`;

export const Manager = styled.span`
    padding: 0 8px;
    background: #ECF0FE;
    border-radius: 4px;
    border: 1px solid #DAE2FC;

    font-size: 12px;
    line-height: 16px;
    color: #466DF2;
`;
