import React, { useEffect, useState } from 'react';
import careerState from '@/features/career/store/careerState';
import { observer } from 'mobx-react-lite';
import { Skill } from '@/shared';
import { Container, SkillsContainer, List } from './styles';
import { Link } from 'react-router-dom';

export const CareerSkills: React.FC = observer(() => {
  const userRole = JSON.parse(localStorage.getItem('user'))?.role;

  const [skillList, setSkillList] = useState(null);

  // Collects unique skills
  // out of vacancies on user's career track
  function collectUniqueSkills() {
    let skillsTotal: any = {};

    for (const skill of careerState.skills) {
      skillsTotal[skill.name] = skill;
    }

    return Object.values(skillsTotal);
  }

  useEffect(() => {
    setSkillList(collectUniqueSkills());
  }, [])
  
  return (
    <Container>
      <SkillsContainer>
        <h5>Hard Skills</h5>
        <List>
          {skillList && skillList.map((skill: any) =>
            <>
              {skill.type === 'Hard' &&
                <>
                  {userRole === 'User' &&
                    <Link to={`/dashboard-employee-courses?search=${skill.name}`}>
                      <Skill title={skill.name} key={skill.id}/>
                    </Link>
                  }
                  {userRole === 'Manager' &&
                    <Link to={`/dashboard-manager-courses?search=${skill.name}`}>
                      <Skill title={skill.name} key={skill.id}/>
                    </Link>
                  }
                </>
              }
            </>
          )}
        </List>
      </SkillsContainer>

      <SkillsContainer>
      <h5>Soft Skills</h5>
        <List>
          {skillList && skillList.map((skill: any) =>
            <>
              {skill.type === 'Soft' &&
                <>
                  {userRole === 'User' &&
                    <Link to={`/dashboard-employee-courses?search=${skill.name}`}>
                      <Skill title={skill.name} key={skill.id}/>
                    </Link>
                  }
                  {userRole === 'Manager' &&
                    <Link to={`/dashboard-manager-courses?search=${skill.name}`}>
                      <Skill title={skill.name} key={skill.id}/>
                    </Link>
                  }
                </>
              }
            </>
          )}
        </List>
      </SkillsContainer>
    </Container>
  )
});
