import axios from 'axios';
import { ITest } from '@/shared';

const API = process.env.API_URL;
const access = JSON.parse(localStorage.getItem('accessToken'));
const refresh = JSON.parse(localStorage.getItem('refreshToken'));

function getTestFormData (obj: any = {}, mode: string = 'create', formData: any = new FormData(), key: string = '') {
  if (!([Array, File, Object].includes(obj.constructor))) {
    return formData;
  }

  // Handle File recursive
  if (obj instanceof File) {
    formData.append(key, obj);
    return formData;
  }

  for (const prop in obj) {
    // Validate value type
    if (obj[prop] && !([String, Number, Boolean, Array, Object, File].includes(obj[prop].constructor))) {
      continue;
    }

    // Set deep index of prop
    const deepKey = key ? key + `${prop}` : prop;

    // Handle array
    if (Array.isArray(obj[prop])) {
      obj[prop].forEach((item: any, index: number) => {
        getTestFormData(item, mode, formData, `${deepKey}[${index}]`);
      })
      continue;
    }

    if (mode === 'edit' && prop === 'id' && deepKey !== 'id') {
      continue;
    }

    if (prop == 'image' && obj.image_uploaded_on) {
      (typeof obj[prop] === 'object' && obj[prop] && obj[prop].constructor === Object)
        ? getTestFormData(obj['image_url'], mode, formData, deepKey) // Handle object
        : formData.append(key + 'image_url', [undefined, null].includes(obj[prop]) ? '' : obj[prop]) // Handle string, number, boolean
    } else {
      (typeof obj[prop] === 'object' && obj[prop] && obj[prop].constructor === Object)
        ? getTestFormData(obj[prop], mode, formData, deepKey) // Handle object
        : formData.append(deepKey, [undefined, null].includes(obj[prop]) ? '' : obj[prop]) // Handle string, number, boolean
    }
  }

  return formData;
}

export const createTest = async (data: ITest) => {
  const form = getTestFormData(data);

  const res = await axios({
      url: `${API}/tests/` ,
      method: 'POST',
      headers: {
          'Content-Type': 'multipart/form-data',
          "Access-Control-Allow-Headers": "*",
          "Authorization": `Bearer ${access}`,
      },
      data: form,
  })
  
  return res
}

export const editTest = async (data: ITest) => {
  const form = getTestFormData(data, 'edit');

  const res = await axios({
      url: `${API}/tests/${data.id}/` ,
      method: 'PATCH',
      headers: {
          'Content-Type': 'multipart/form-data',
          "Access-Control-Allow-Headers": "*",
          "Authorization": `Bearer ${access}`,
      },
      data: form,
  })
  
  return res
}


export const deleteTest = async (testId: number) => {
  const res = await axios({
      url: `${API}/tests/${testId}` ,
      method: 'DELETE',
      headers: {
          'Content-Type': 'application/json',
          "Access-Control-Allow-Headers": "*",
          "Authorization": `Bearer ${access}`,
      },
  })
  
  return res
}

export const getSurveyedUsers = async (testId: number) => {
  const res = await axios({
      url: `${API}/users/?tests__test=${testId}` ,
      method: 'GET',
      headers: {
          'Content-Type': 'application/json',
          "Access-Control-Allow-Headers": "*",
          "Authorization": `Bearer ${access}`,
      },
  })
  
  return res
}

export const inviteUser = async (testId: number, invitedUsers: number[]) => {
  const res = await axios({
      url: `${API}/tests/${testId}/`,
      method: 'PATCH',
      headers: {
          'Content-Type': 'application/json',
          "Access-Control-Allow-Headers": "*",
          "Authorization": `Bearer ${access}`,
      },
      data: {
        invited: invitedUsers,
      }
  })
  
  return res
}

export const completeTest = async (data: any) => {
  const res = await axios({
      url: `${API}/tests-complete/`,
      method: 'POST',
      headers: {
          'Content-Type': 'application/json',
          "Access-Control-Allow-Headers": "*",
          "Authorization": `Bearer ${access}`,
      },
      data: data,
  })
  
  return res
}

export const uploadExternalTestResults = async (data: any) => {
  console.log(data);
  const res = await axios({
    url: `${API}/external-competence-test-results/`,
    method: 'POST',
    headers: {
        'Content-Type': 'multipart/form-data',
        "Access-Control-Allow-Headers": "*",
        "Authorization": `Bearer ${access}`,
    },
    data: data,
})
}
