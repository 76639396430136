import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const Top = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const Buttons = styled.div`
  width: 176px;
  display: flex;
  justify-content: space-between;
`;

export const Bottom = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const H6 = styled.h6`
  color: var(--disabled);
`;
