import { makeAutoObservable } from 'mobx';

class myProjectsState {
  projects: [] = [];

  constructor() {
    makeAutoObservable(this);
  }

  setProjects(projects: []) {
    this.projects = projects;
  }
}

export default new myProjectsState();
