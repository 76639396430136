import { makeAutoObservable } from "mobx";
import { TProject } from "@/shared";

class ProjectState {
  selectedProject: TProject;
  roles: any[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  setSelectedProject(project: TProject) {
    this.selectedProject = project;
  }

  setRoles(roles: any) {
    this.roles = roles;
  }
}

export default new ProjectState();
