import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';

import {
  GradationBar,
  Gradation,
  Anvelope,
} from './styles';

export interface LevelGradationProps {
  instance: any;
  indicator: boolean;
}

export const LevelGradation: React.FC<LevelGradationProps> = observer(({instance, indicator}) => {
  const [targetInstances, setTargetInstances] = useState([]);
  const [gradation, setGradation] = useState<any>({total: 0});

  useEffect(() => {
    let gradationToSet: any = {
      Beginner: 0,
      Intermediate: 0,
      Master: 0,
    };

    let total: number = 0;

    for (const level of Object.keys(gradationToSet)) {
      gradationToSet[level] = instance.levels[level] === undefined ? 0 : instance.levels[level];
      total += gradationToSet[level];
    }

    setGradation({
      total: total,
      ...gradationToSet,
    })
  }, []);

  return (
    <Anvelope>
      {gradation.total > 0 ?
        <GradationBar>
          <Gradation
            style={{
              backgroundColor: '#F0F3FF',
              width: String(gradation['Beginner'] / gradation.total * 100) + '%',
              display: gradation['Beginner'] === undefined || gradation['Beginner'] === 0 ? 'none' : 'flex',
            }}
          >
            {String(gradation['Beginner'] / gradation.total * 100) + '%'}
          </Gradation>
          <Gradation
            style={{
              backgroundColor: '#B7C6FF',
              width: String(gradation['Intermediate'] / gradation.total * 100) + '%',
              display: gradation['Intermediate'] === undefined || gradation['Intermediate'] === 0 ? 'none' : 'flex',
            }}
          >
            {String(gradation['Intermediate'] / gradation.total * 100) + '%'}
          </Gradation>
          <Gradation
            style={{
              backgroundColor: '#6B8BFF',
              width: String(gradation['Master'] / gradation.total * 100) + '%',
              display: gradation['Master'] === undefined || gradation['Master'] === 0 ? 'none' : 'flex',
            }}
          >
            {String(gradation['Master'] / gradation.total * 100) + '%'}
          </Gradation>
        </GradationBar>
      :
        <Gradation>
          Нет данных
        </Gradation>
      }
    </Anvelope>
  )
});