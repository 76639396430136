import React, { useState, useEffect } from 'react';
import {
  Avatar,
} from '@chakra-ui/react';
import {
  PersonStyles,
} from './styles';
import avatar from '@/assets/images/avatar.jpg';
import { getVacancyById } from '@/entities/vacanci';
import { IVacanci } from '@/shared/types/vacanci/types';
import { Link } from 'react-router-dom';

export interface IPersonCardProps {
  id: number;
  image: any;
  name: string;
  vacancy: IVacanci;
  role: string;
}

export const PersonCard: React.FC<IPersonCardProps> = ({ id, image, role, name, vacancy}) => {
  return (
    <Link 
      to={`dashboard-hr-profile?search=${id}`}
      style={{ fontWeight: '400' }}
    >
      <PersonStyles>
        <Avatar size='lg' src={image ? image.image : avatar}/>
        <div style={{fontSize: 12, fontWeight: 600, lineHeight: 1.2}}>{name}</div>
        <div style={{fontSize: 12, lineHeight: 1.2}}>{vacancy ? vacancy.title : 'Должности нет'}</div>
      </PersonStyles>
    </Link>
  );
}
