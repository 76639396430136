import React, { useState, useEffect } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Textarea,
  Input,
  Box,
  Stack,
  Flex,
  Text,
  Divider,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverCloseButton,
  CloseButton,
} from '@chakra-ui/react';
import { Skill } from '@/shared';
import AddIcon from '@/assets/icons/add.svg';
import CalendarIcon from '@/assets/icons/calendar.svg';
import AlertIcon from '@/assets/icons/alert-circle.svg';
import EditIcon from '@/assets/icons/edit.svg';
import { SubmitHandler, useForm, Controller } from 'react-hook-form';
import { DatePicker, ConfigProvider } from 'antd';
import dayjs from 'dayjs';
import editProjectPopupState from './store/EditProjectPopupState';
import { observer } from 'mobx-react-lite';
import { TEmployeeRole } from '@/shared/types/projects/types';
import { RoleEditing } from '@/features/projects';
import projectState from '@/widgets/ProjectDrawer/store/projectState';
import { editProject } from '@/features/projects/api';

const { RangePicker } = DatePicker;

interface IFormFields {
  name: string;
  description: string;
  deadline: any[];
}

interface IEditProjectPopupProps {
  isOpen: boolean;
  onClose: () => void;
}

export const EditProjectPopup: React.FC<IEditProjectPopupProps> = observer(({ isOpen, onClose }) => {
  const { 
    control,
    register,
    setValue,
    handleSubmit,
    formState: { errors, isSubmitting } 
  } = useForm<IFormFields>();

  const [projectDates, setProjectDates] = useState([]);

  useEffect(() => {
    if (projectState.selectedProject) {
      const start_date = dayjs(projectState.selectedProject.start_date, 'DD.MM.YYYY');
      const end_date = dayjs(projectState.selectedProject.end_date, 'DD.MM.YYYY');
      setProjectDates([start_date, end_date])
    }
  }, []);

  function handleProjectDatesChange(dates: any) {
    setProjectDates(dates);
    setValue('deadline', dates);
  }

  const onSubmit: SubmitHandler<IFormFields> = (data) => {
    const newRoles = editProjectPopupState.roles.map((role) => ({
      id: role.id,
      title: role.title,
      start_date: role.start_date,
      end_date: role.end_date,
      skill: role.skill,
    }));

    const newProject = {
      title: data.name,
      description: data.description,
      start_date: projectDates[0].format('DD.MM.YYYY'),
      end_date: projectDates[1].format('DD.MM.YYYY'),
      status: 'Unavailable',
      expected_roles: newRoles,
    }

    editProject(projectState.selectedProject.id, newProject)
      .then((res) => {
        onClose();
        window.location.reload();
      })
      .catch(e => {
        console.log(e);
      })
  };

  return (
    <Modal size={'4xl'}
      scrollBehavior="inside"
      closeOnOverlayClick={false}
      isOpen={isOpen}
      onClose={onClose}
      isCentered
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader alignItems={'center'}>
            <h3 style={{ textAlign: 'center' }}>Редактирование проекта</h3>
          </ModalHeader>

          <ModalCloseButton top={'10px'} size="lg" />

          <Divider />

          <ModalBody>
            <Stack my={4} spacing={8}>
              <FormControl isRequired>
                <FormLabel>Название проекта</FormLabel>
                <Input
                  id='name'
                  {...register('name', { 
                    required: 'Обязательное поле'
                  })}
                  defaultValue={projectState.selectedProject?.title}
                  placeholder='Название проекта'
                  type='text'
                  size='sm'
                  variant='flushed'
                />
                <FormErrorMessage>{errors.name && <>{errors.name.message}</>}</FormErrorMessage>
              </FormControl>

              <FormControl isRequired>
                <FormLabel>Описание проекта</FormLabel>
                <Textarea
                  id='description'
                  defaultValue={projectState.selectedProject?.description}
                  {...register('description', { 
                    required: 'Обязательное поле'
                  })}
                  placeholder='Подробно опишите проект...'
                  size='lg'
                  variant='outline'
                />
                <FormErrorMessage>{errors.description && <>{errors.description.message}</>}</FormErrorMessage>
              </FormControl>

              <FormControl isRequired>
                <FormLabel>Дедлайн</FormLabel>
                {/*
                  By default, MUI Drawer component has a z-index of 1200
                  Source: https://mui.com/material-ui/customization/z-index/
                */}
                <ConfigProvider
                  theme={{
                    components: {
                      DatePicker: {
                        zIndexPopup: 2000,
                      },
                    },
                  }}
                >
                  <Controller
                    control={control}
                    name="deadline"
                    render = {({ field: { onChange, value } }) => {
                      if (projectDates && projectDates.length > 0) {
                        const start_date = projectDates[0];
                        const end_date = projectDates[1];
                        return (
                          <RangePicker
                            defaultPickerValue={[start_date, end_date]}
                            defaultValue={[start_date, end_date]}
                            value={[start_date, end_date]}
                            style={{ width: '100%', paddingInline: 0 }}
                            variant="borderless"
                            onChange={handleProjectDatesChange}
                            format="DD/MM/YYYY"
                          />
                        );
                      } else {
                        return (
                          <RangePicker
                            style={{ width: '100%', paddingInline: 0 }}
                            variant="borderless"
                            onChange={handleProjectDatesChange}
                            format="DD/MM/YYYY"
                          />
                        );
                      }
                    }}
                  />
                </ConfigProvider>

                {errors.deadline && <span>This field is required</span>}
              </FormControl>

              <Box>
                {editProjectPopupState.roles.map((role: TEmployeeRole) => {
                  const start_date = role.start_date;
                  const end_date = role.end_date;
                  return (
                    <Flex key={role.id} justifyContent="space-between">
                      <Flex gap={2} alignItems="center">
                        <Skill title={role.title} />

                        <Popover>
                          <PopoverTrigger>
                            <Box _hover={{cursor: 'pointer'}}>
                              <AlertIcon height={18} width={18} color="var(--main-purple)" />
                            </Box>
                          </PopoverTrigger>

                          <PopoverContent>
                            <PopoverHeader>
                              <Flex gap={2} alignItems="center">Навыки <Skill title={role.title} /></Flex>
                              <PopoverCloseButton pl={4} size="md" />
                            </PopoverHeader>

                            <PopoverBody>
                              <Flex gap={2} wrap={'wrap'}>
                                {role.skill.length > 0 &&
                                  role.skill.map((skill: any) => 
                                    <Skill key={skill.id} title={skill.name}/>
                                  )
                                }
                              </Flex>
                            </PopoverBody>
                          </PopoverContent>
                        </Popover>

                        <Flex gap={2} alignItems="center">
                          <CalendarIcon height={18} width={18} />
                          <Text fontSize="sm">{start_date} - {end_date}</Text>
                        </Flex>
                      </Flex>

                      <Flex gap={2} alignItems="center">
                        <Button
                          leftIcon={<EditIcon />}
                          color="var(--main-purple)"
                          bg="none"
                          onClick={() => {
                            const start_date = role.start_date;
                            const end_date = role.end_date;
                            editProjectPopupState.setSelectedRole({
                              id: role.id,
                              title: role.title,
                              skill: role.skill,
                              start_date: start_date,
                              end_date: end_date,
                            });
                            editProjectPopupState.setIsEditing(true);
                          }}
                        >
                          Редактировать
                        </Button>

                        <CloseButton
                          onClick={() => {
                            editProjectPopupState.removeRole(role.id);
                          }}
                        />
                      </Flex>
                    </Flex>
                  );
                })}
              </Box>

              {editProjectPopupState.isEditing &&
                <RoleEditing  />
              }

              <Button
                borderRadius='8px'
                bg='#fff'
                leftIcon={<AddIcon color='var(--main-purple)' />} 
                border='1px'
                borderColor='var(--main-purple)'
                onClick={() => {
                  editProjectPopupState.setSelectedRole({
                    id: Date.now(),
                    title: '',
                    skill: [],
                    start_date: '',
                    end_date: '',
                  });
                  editProjectPopupState.setIsEditing(true);
                }}
              >
                <h6 style={{ color: 'var(--main-purple)' }}>Добавить роль в проект</h6>
              </Button>
            </Stack>
          </ModalBody>

          <ModalFooter gap='8px' display='flex'>
            <Button
              type='button'
              width='100%'
              onClick={onClose}
              variant='outline'
              color='var(--main-purple)'
              borderRadius='8px'
            >
              Отмена
            </Button>

            <Button
              type='submit'
              width='100%'
              borderRadius='8px'
              bg='var(--main-purple)'
              _hover={{ bg: 'var(--hover-purple)' }}
              color='#fff'
            >
              Сохранить изменения
            </Button>
          </ModalFooter>
        </ModalContent>
      </form>
    </Modal>
  );
});

