import React from 'react';
import {
  Box,
  Text,
  Stack,
  Flex,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
} from '@chakra-ui/react';
import {
  PopoverButton,
} from './styles';
import DotsIcon from '@/assets/icons/dots.svg';
import AddIcon from '@/assets/icons/add.svg';
import { observer } from 'mobx-react-lite';
import workExperienceState from '../store/workExperienceState';
import { Card, get_duration } from '@/shared';
import dayjs from 'dayjs';
import { FormField } from './FormField';
import { changeWorkExperience } from '@/features/employee';

export interface EditPopupProps {
  user_id: number;
  isOpen: boolean;
  onClose: () => void;
}

export const EditPopup: React.FC<EditPopupProps> = observer(({ user_id, isOpen, onClose }) => {
  function handleSaveChanges() {
    changeWorkExperience(user_id, workExperienceState.editedExperience)
      .then((res: any) => {
        workExperienceState.setExperience(workExperienceState.editedExperience);
        workExperienceState.setIsEditing(false);
        onClose();
      })
      .catch((e: any) => {
        console.log(e);
      })
  }

  return (
    <Modal
      size={'4xl'}
      scrollBehavior="inside"
      closeOnOverlayClick={false}
      blockScrollOnMount={false}
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalOverlay />
        <ModalContent borderRadius={16}>
          <ModalHeader alignItems={'center'}>
            <h3 style={{ textAlign: 'center' }}>Редактирование опыта</h3>
          </ModalHeader>

          <ModalCloseButton top={'14px'} />

          <ModalBody
            padding='20px'
            display='flex'
            flexDirection='column'
            gap='30px'
            borderTop='1px solid var(--addable-gray)'
            borderBottom='1px solid var(--addable-gray)'
          >

          <Stack spacing={4}>
            {
              workExperienceState.editedExperience &&
              workExperienceState.editedExperience.length > 0 &&
              workExperienceState.editedExperience
              .map((exp: any) => {
                const start_date = dayjs(exp.start_date, ['DD.MM.YYYY', 'DD.MM.YY']);
                const end_date = dayjs(exp.end_date, ['DD.MM.YYYY', 'DD.MM.YY']);
                const duration = get_duration(start_date, end_date);

                return (
                  <Card key={exp.id}>
                    <Flex justifyContent="space-between" alignItems="start">
                      <Stack spacing={1}>
                        <h5>{exp.position}</h5>
                        <Text as="span">в {exp.company_name}</Text>
                        <Text as="span" fontSize={10}>{duration}</Text>
                      </Stack>

                      <Popover placement='bottom-end'>
                        <PopoverTrigger>
                          <button type="button">
                            <DotsIcon color='var(--main-purple)'/>
                          </button>
                        </PopoverTrigger>

                        <PopoverContent w="full">
                          <PopoverBody>
                            <Stack align="end">
                              <PopoverButton
                                type="button"
                                onClick={() => {
                                  workExperienceState.setSelectedExp({
                                    id: exp.id,
                                    position: exp.position,
                                    company_name: exp.company_name,
                                    company_city: exp.company_city,
                                    start_date: exp.start_date,
                                    end_date: exp.end_date,
                                  });
                                  workExperienceState.setIsEditing(true);
                                }}
                              >
                                Редактировать
                              </PopoverButton>

                              <PopoverButton
                                type="button"
                                style={{ color: 'var(--addable-red)' }}
                                onClick={() => {
                                  workExperienceState.setIsEditing(false);
                                  workExperienceState.removeExp(exp.id);
                                }}
                              >
                                Удалить
                              </PopoverButton>
                            </Stack>
                          </PopoverBody>
                        </PopoverContent>
                      </Popover>
                    </Flex>
                  </Card>
                );
            })}

            {workExperienceState.isEditing &&
              <FormField />
            }

            <Button
              borderRadius='8px'
              bg='#fff'
              leftIcon={<AddIcon color='var(--main-purple)' />} 
              border='1px'
              borderColor='var(--main-purple)'
              onClick={() => {
                workExperienceState.setSelectedExp({
                  id: Date.now(),
                  position: '',
                  company_name: '',
                  company_city: '',
                  start_date: '',
                  end_date: '',
                });
                workExperienceState.setIsEditing(true);
              }}
            >
              <h6 style={{ color: 'var(--main-purple)' }}>Добавить опыт</h6>
            </Button>
          </Stack>

          </ModalBody>

          <ModalFooter gap='8px' display='flex'>
            <Button
              type='button'
              width='100%'
              variant='outline'
              color='var(--main-purple)'
              borderRadius='8px'
              onClick={() => {
                workExperienceState.setEditedExperience(workExperienceState.experience);
                workExperienceState.setIsEditing(false);
                onClose();
              }}
            >
              Отмена
            </Button>

            <Button
              type='button'
              width='100%'
              borderRadius='8px'
              bg='var(--main-purple)'
              _hover={{ bg: 'var(--hover-purple)' }}
              color='#fff'
              onClick={handleSaveChanges}
            >
              Сохранить изменения
            </Button>
          </ModalFooter>
        </ModalContent>
    </Modal>
  )
});
