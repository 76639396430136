import React, { useState, useEffect } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Stack,
  Divider,
  Box,
} from '@chakra-ui/react';
import { SubmitHandler, useForm, Controller } from 'react-hook-form';
import avatar from '@/assets/images/avatar.jpg';
import StarIcon from '@/assets/icons/star.svg';
// @ts-ignore
import ReactStars from "react-rating-stars-component";
import { IUser, TProject } from '@/shared';
import { createProjectReview } from '@/features/projects/api';
import { PersonLine } from '@/shared';

interface IFormFileds {
  rating: number;
  comment: string;
}

interface IProjectReviewPopupProps {
  person: IUser;
  isOpen: boolean;
  onClose: () => void;
  project: TProject;
}

export const ProjectReviewPopup: React.FC<IProjectReviewPopupProps> = ({ person, project, isOpen, onClose }) => {
  const { 
    control,
    register,
    setValue,
    handleSubmit,
    formState: { errors, isSubmitting } 
  } = useForm<IFormFileds>();

  const [isReviewed, setIsReviewed] = useState(false);

  const user = JSON.parse(localStorage.getItem('user'));

  const [rating, setRating] = useState(1);

  const ratingDefault = {
    size: 20,
    count: 5,
    emptyIcon: <StarIcon />,
    filledIcon: <StarIcon />,
    activeColor: 'var(--main-purple)',
  }

  const onSubmit: SubmitHandler<IFormFileds> = (data) => {
    const newReview = {
      text: data.comment,
      mark: data.rating,
      project: project.id,
      sender: user?.id,
      recipient: person.id,
    }

    createProjectReview(newReview)
      .then((res: any) => {
        onClose();
      })
      .catch((error) => {
        setIsReviewed(true);
        console.log(error);
      })
  }

  return (
    <Modal size={'4xl'} closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <ModalOverlay />

        <ModalContent>
          <ModalHeader alignItems={'center'}>
            <h3 style={{ textAlign: 'center' }}>Оставить отзыв</h3>
          </ModalHeader>

          <ModalCloseButton top={'10px'} size="lg" />

          <Divider />

          <ModalBody>
            <Stack my={4} spacing={8}>
              <PersonLine
                image={person?.image}
                last_name={person?.last_name}
                first_name={person?.first_name}
              />

              <Stack>
                <Box>Проект</Box>
                <Box>{project?.title}</Box>
              </Stack>

              {!isReviewed ?
              <>
                {(project?.owner.id === person?.id) &&
                  <Stack>
                    <Box>Роль</Box>
                    <Box>Руководитель</Box>
                  </Stack>
                }

                <FormControl isRequired>
                  <FormLabel>Оценка</FormLabel>
                  <Box mt={3}>
                    <Controller
                      control={control}
                      name="rating"
                      {...register('rating')}
                      render={({ field: { value, onChange } }) => (
                        <ReactStars
                          {...ratingDefault}
                          value={rating}
                          onChange={(newValue: number) => {
                            setRating(newValue);
                            setValue('rating', newValue);
                          }}
                        />
                      )}
                    />
                  </Box>
                  <FormErrorMessage>{errors.rating && <>{errors.rating.message}</>}</FormErrorMessage>
                </FormControl>

                <FormControl isRequired>
                  <FormLabel>Комментарий</FormLabel>
                  <Input
                    id='comment'
                    {...register('comment', { 
                      required: 'Обязательное поле'
                    })}
                    placeholder='Напишите отзыв'
                    type='text'
                    size='sm'
                    variant='flushed'
                  />
                  <FormErrorMessage>{errors.comment && <>{errors.comment.message}</>}</FormErrorMessage>
                </FormControl>
              </> :
              <Box fontWeight={600} fontSize={18} textAlign="center">Оценить не удалось, вы уже оценили сотрудника на этом проекте</Box>
              }
            </Stack>
          </ModalBody>

          <ModalFooter gap='8px' display='flex'>
            <Button
              type='button'
              width='100%'
              onClick={onClose}
              variant='outline'
              color='var(--main-purple)'
              borderRadius='8px'
            >
              Отмена
            </Button>

            {!isReviewed &&
            <Button
              type='submit'
              width='100%'
              borderRadius='8px'
              bg='var(--main-purple)'
              _hover={{ bg: 'var(--hover-purple)' }}
              color='#fff'
            >
              Отправить отзыв
            </Button>
            }
          </ModalFooter>
        </ModalContent>
      </form>
    </Modal>
  );
}
