import { makeAutoObservable } from "mobx";

class CoursesState {
  courses: any[] = [];
  coursesFilter = 'all';
  activeCourses: any[] = [];
  archivedCourses: any[] = [];
  stopedCourses: any[] = []

  constructor() {
    makeAutoObservable(this);
  }

  setCourses(courses: any[]) {
    this.courses = courses;
  }

  setActiveCourses(courses: any[]) {
    this.activeCourses = courses;
  }

  setArchivedCourses(courses: any[]) {
    this.archivedCourses = courses;
  }

  setStopedCourses(courses: any[]) {
    this.stopedCourses = courses;
  }

  setFilter(newFilter: string) {
    this.coursesFilter = newFilter;
  }
}

export default new CoursesState();
