import { makeAutoObservable } from "mobx";
import { TTask } from "@/shared";

class TaskState {
  selectedTask: TTask;
  open: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  setSelectedTask(task: TTask) {
    this.selectedTask = task;
  }

  setStatus(status: string) {
    this.selectedTask.status = status;
  }
}

export default new TaskState();

