import axios from "axios";
import type { TTask } from "@/shared";

const API = process.env.API_URL;
const access = JSON.parse(localStorage.getItem('accessToken'));
const refresh = JSON.parse(localStorage.getItem('refreshToken'));

export const getTasks = async (searchTerm?: string) => {
  const res = await axios<any>({
      url: `${API}/tasks/?title_only=true&search=${searchTerm ? searchTerm : ''}&isolated=true&local=true`,
      method: 'GET',
      headers: {
          'Content-Type': 'application/json',
          "Access-Control-Allow-Headers": "*",
          "Authorization": `Bearer ${access}`,
      },
  })
  
  return res
}

export const getTaskById = async (id: number) => {
  const res = await axios<any>({
      url: `${API}/tasks/${id}/?detailed=0`,
      method: 'GET',
      headers: {
          'Content-Type': 'application/json',
          "Access-Control-Allow-Headers": "*",
          "Authorization": `Bearer ${access}`,
      },
  })
  
  return res
}

export const getMyTasks = async (userId: number, searchTerm?: string) => {
  const res = await axios<any>({
      url: `${API}/tasks/?contractor__id=${userId}&search=${searchTerm ? searchTerm : ''}`,
      method: 'GET',
      headers: {
          'Content-Type': 'application/json',
          "Access-Control-Allow-Headers": "*",
          "Authorization": `Bearer ${access}`,
      },
  })

  const items: any = [];

  res.data.forEach((task: any) => {
    const newItem = {
      id: `Task ${task.id}`,
      name: '',
      title: task.title,
      start: (task.start_date.replace(/\./g, "-")).split("-").reverse().join("-"),
      end: (task.end_date.replace(/\./g, "-")).split("-").reverse().join("-"),
      progress: 100,
      dependencies: '',
      custom_class: 'task',
      setDependencies: () => {},

      task: task,
    }

    items.push(newItem);
  });
  
  return items
}

export const getCreatedTasks = async (userId: number, searchTerm?: string) => {
  const res = await axios<any>({
      url: `${API}/tasks/?owner__id=${userId}&search=${searchTerm ? searchTerm : ''}`,
      method: 'GET',
      headers: {
          'Content-Type': 'application/json',
          "Access-Control-Allow-Headers": "*",
          "Authorization": `Bearer ${access}`,
      },
  })
  
  const items: any = [];

  res.data.forEach((task: any) => {
    const newItem = {
      id: `Task ${task.id}`,
      name: '',
      title: task.title,
      start: (task.start_date.replace(/\./g, "-")).split("-").reverse().join("-"),
      end: (task.end_date.replace(/\./g, "-")).split("-").reverse().join("-"),
      progress: 100,
      dependencies: '',
      custom_class: 'task',
      setDependencies: () => {},

      task: task,
    }

    items.push(newItem);
  });
  
  return items
}

export const getTasksBySkills = async (searchTerm?: string) => {
  const res = await axios<any>({
      url: `${API}/tasks/?skill_only=true&search=${searchTerm ? searchTerm : ''}&isolated=true&local=true`,
      method: 'GET',
      headers: {
          'Content-Type': 'application/json',
          "Access-Control-Allow-Headers": "*",
          "Authorization": `Bearer ${access}`,
      },
  })
  
  return res
}
