import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import {
  Container,
  Dashboard,
} from './styles';
import { EmptyResponse } from '@/widgets';
import { TestCard } from '@/entities/tests';
import { ITest } from '@/shared';
import { getEmployees } from '@/features/employee';
import { getCompanyGroups } from '@/features/department';
import { getCompany } from '@/entities/company/api';
import testsState from '@/pages/DashboardHRTests/store/testsState';

export interface ITestsListProps {
  tests: ITest[];
}

export const TestsList: React.FC<ITestsListProps> = observer(({ tests }) => {
  const role = JSON.parse(localStorage.getItem('user'))?.role;

  const companyId = JSON.parse(localStorage.getItem('user'))?.company[JSON.parse(localStorage.getItem('user'))?.company.length - 1];

  function handleGetCompany() {
    getCompany(companyId)
      .then((res) => {
        testsState.setDepartments(res.data.group);
      })
      .catch((e) => {
        console.log(e);
      })
  }

  function handleGetGroups() {
    getCompanyGroups(companyId)
      .then((res: any) => {
        testsState.setGroups(res.data.group);
      })
      .catch((error) => {
        console.log(error);
      })
  }

  function handleGetEmployees() {
    getEmployees(companyId)
      .then((res: any) => {
        testsState.setEmployees(res.data);
      })
      .catch((error) => {
        console.log(error);
      })
  }

  useEffect(() => {
    if (testsState.departments || testsState.groups || testsState.employees) {
      return;
    }

    handleGetGroups();
    handleGetEmployees();
    handleGetCompany();
  }, [])

  return (
    <Container>
      <Dashboard>
        {tests && tests?.length > 0
        ? tests.map((test) =>
          <div key={test.id}>
            <TestCard test={test} role={role} />
          </div>
        )
        : <EmptyResponse/>
        }
      </Dashboard>
    </Container>
  )
});
